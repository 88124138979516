import React from 'react';
import BaseComponent from "../BaseComponent";
import ReportDateFilterComponent from "./ReportDateFilterComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Pie} from "react-chartjs-2";

import "./ReportsBTPStatisticsComponent.css";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import LoadingSpinner from "../common/LoadingSpinner";

export default class ReportsBTPStatisticsComponent extends BaseComponent {

    tableFilterTimeout = undefined;

    summaryLegendData = [
        {
            title : "Passed",
            message : "{pass} out of {total} results passed all of the Best Practised Tariff requirements.",
            colour : "#59c134"
        },
        {
            title : "Failed",
            message : "{fail} out of {total} results failed one or more of the Best Practice Tariff requirements.",
            colour : "#c13434"
        }
    ]

    categoryLegendData = [
        {
            key : "noSx",
            title : "Con Surg not present (No cSx)",
            message : "For {noSx} of {total} cases the only standard not achieved was presence of consultant surgeon for intra-operative care",
            colour : "#00e486"
        },
        {
            key : "noAn",
            title : "Con Anaes not present (No cAn)",
            message : "For {noAn} of {total} cases the only standard not achieved was presence of consultant anaesthetist for intra-operative care",
            colour : "#00b6e4"
        },
        {
            key : "noCC",
            title : "No post-op CC (No CC)",
            message : "For {noCC} of {total} cases the only standard not achieved was direct admission to level 2/3 critical care for post-operative care",
            colour : "#a100e4"
        },
        {
            key : "noSxAn",
            title : "Con Surg & Con Anaes not present (No cSx.cAn)",
            message : "For {noSxAn} of {total} cases the standards not achieved were presence of both consultant surgeon and consultant anaesthetist for intra-operative care",
            colour : "#e40096"
        },
        {
            key : "noSxCC",
            title : "Con Surg not present & no post-op CC (No cSx.CC)",
            message : "For {noSxCC} of {total} cases the standards not achieved was presence of consultant surgeon for intra-operative care and direct admission to level 2/3/ critical care for post-operative care",
            colour : "#e47100"
        },
        {
            key : "noAnCC",
            title : "Con Anaes not present & no post-op CC (No cAn.CC)",
            message : "For {noAnCC} of {total} cases the standards not achieved was presence of consultant anaesthetist for intra-operative care and direct admission to level 2/3/ critical care for post-operative care",
            colour : "#81e400"
        },
        {
            key : "noCsxCanCC",
            title : "Con Surg & con Anaes not present and no post-op CC (No cSx.cAn.CC)",
            message : "For {noCsxCanCC} of {total} cases the standards not achieved were all of presence of consultant surgeon and consultant anaesthetist for intra-operative care as well as direct admission to level 2/3/ critical care for post-operative care",
            colour : "#0015e4"
        },
    ]

    constructor(props, context) {
        super(props, context);

        this.complianceChart = React.createRef();
        this.exceptionChart = React.createRef();

        let state;
        if (this.props.savedState !== undefined) {
            state = this.props.savedState;
            state.reportNetworkInFlight = false;
        } else {
            state = {
                filterType : ReportDateFilterComponent.FILTER_TYPE_MONTHLY,
                filterMonthValue : Chronos.now().format("yyyy-MM"),
                filterStartDate : undefined,
                filterEndDate : undefined,
                reportNetworkInFlight : false,
                reportData : null,
                summaryPieData : null,
                categoryPieData : null,
                bptFilters : [],
                bptTableData : [],
                totalRows : 0,
                pageLimit : 30,
                currentPage : 1
            };
        }

        this.initState(state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            if (this.props.stateCallback !== undefined) {
                this.props.stateCallback(this.state);
            }
        }
    }

    dateFilterDidChange = (filterType, date1, date2) => {
        let filterMonthValue = null;
        let filterStartDate = 0;
        let filterEndDate = 0;

        if (filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            filterMonthValue = date1;
        } else {
            filterStartDate = date1;
            filterEndDate = date2;
        }

        this.setState({
            filterType,
            filterMonthValue,
            filterStartDate,
            filterEndDate,
            currentPage : 1,
            totalRows : 0,
            bptFilters : [],
            bptTableData : []
        });

        setTimeout(() => {
            this.fetchReportDataFromNetwork();
            this.fetchBTPTableDataFromNetwork();
        }, 10);
    }

    generateSummaryGraphDataset = (summaryData) => {
        let data = [
            summaryData.pass,
            summaryData.fail,
            summaryData.undetermined
        ];

        let colours = [];
        let labels = [];

        this.summaryLegendData.forEach((item) => {
            colours.push(item.colour);
            labels.push(item.title);
        })

        return {
            datasets:[
                {
                    data: data,
                    backgroundColor: colours,
                    hoverBackgroundColor: colours
                }
            ],
            labels: labels,
        }
    }

    generateCategoryGraphDataset = (categoryData) => {
        let data = [];
        let labels = [];
        let colours = [];

        this.categoryLegendData.forEach((item) => {
            let value = 0;
            categoryData.forEach((category) => {
                if (category.keyName === item.key) {
                    value = category.count;
                    return false;
                }
            });

            data.push(value);
            labels.push(item.title);
            colours.push(item.colour);
        });

        return {
            datasets : [
                {
                    data,
                    backgroundColor : colours,
                    hoverBackgroundColor : colours
                }
            ],
            labels : labels
        }
    }

    getFilterDates = () => {
        let startDate = 0;
        let endDate = 0;

        if (this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            startDate = Chronos.parse(this.state.filterMonthValue + "-01");
            endDate = Chronos.with(startDate.getDate()).add(1, Chronos.MONTHS);

            startDate = parseInt(startDate.seconds());
            endDate = parseInt(endDate.seconds());
        } else {
            startDate = parseInt(Chronos.with(this.state.filterStartDate).seconds());
            endDate = parseInt(Chronos.with(this.state.filterEndDate).seconds());
        }

        return {
            startDate,
            endDate
        };
    }

    fetchReportDataFromNetwork = () => {
        if (this.state.reportNetworkInFlight) return;

        this.setState({
            reportNetworkInFlight : true
        });

        let formData = new FormData();

        let filterDates = this.getFilterDates();

        formData.append("startDate", filterDates.startDate);
        formData.append("endDate", filterDates.endDate);

        Axios.post(ENDPOINTS.report.getBTPSummaryData, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let summaryPieData = this.generateSummaryGraphDataset(resp.data.summary);
                    let categoryPieData = this.generateCategoryGraphDataset(resp.data.categories);

                    this.setState({
                        reportNetworkInFlight : false,
                        summaryPieData,
                        categoryPieData,
                        reportData : resp.data,
                    })
                } else {
                    console.log(resp.error.desc);

                    this.setState({
                        reportNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchBTPTableDataFromNetwork = (page, filters, keyword) => {
        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (filters === undefined) {
            filters = this.state.bptFilters;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let filterDates = this.getFilterDates();

        let formData = new FormData();
        formData.append("page", page);
        formData.append("startDate", filterDates.startDate);
        formData.append("endDate", filterDates.endDate);

        if (filters !== undefined && filters.length > 0) {
            formData.append("filters", JSON.stringify(filters));
        }

        if (keyword !== undefined && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.report.getBPTSummaryTableData, formData)
            .then((r) => {
                let bptTableData = [];
                let tableCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    bptTableData = resp.data.result;
                    tableCount = resp.data.count;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    bptTableData,
                    tableCount
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    replaceLegendTemplate = (data, string) => {
        string = this.replaceTotalTemplate(string);
        for (const [key, value] of Object.entries(data)) {
            string = string.replaceAll("{" + key + "}", value);
        }
        return string;
    }

    replaceCategoryTemplate = (data, string) => {
        string = this.replaceTotalTemplate(string);
        for (let i = 0; i < data.length; i++) {
            string = string.replaceAll("{" + data[i].keyName + "}", data[i].count);
        }
        return string;
    }

    replaceTotalTemplate = (string) => {
        let total = 0;
        if (this.state.reportData.summary.total !== undefined) {
            total = this.state.reportData.summary.total;
        }
        string = string.replaceAll("{total}", total);

        return string;
    }

    isBPTCategoryChecked = (categoryName) => {
        for (let i = 0; i < this.state.bptFilters.length; i++) {
            if (this.state.bptFilters[i] === categoryName) {
                return true;
            }
        }
        return false;
    }

    checkBPTCategory = (categoryName) => {
        let filters = this.state.bptFilters;

        let wasAdded = false;
        for (let i = 0; i < filters.length; i++) {
            if (filters[i] === categoryName) {
                wasAdded = true;
                filters.splice(i, 1);
                break;
            }
        }

        if (!wasAdded) {
            filters.push(categoryName);
        }

        this.setState({
            bptFilters : filters
        });

        clearTimeout(this.tableFilterTimeout);
        this.tableFilterTimeout = setTimeout(() => {
            this.fetchBTPTableDataFromNetwork();
        }, 800);
    }

    bptTableRowClicked = (row) => {
        if (row != null) {
            window.open("/create/" + row.caseRecordId);
        }
    }

    updateTablePage = (index) => {
        this.setState({
            currentPage : index
        });

        this.fetchBTPTableDataFromNetwork(index);
    }

    generateBPTExportOverNetwork = () => {
        if (this.state.exportNetworkInFlight) return;

        this.setState({
            exportNetworkInFlight : true
        });

        let dates = this.getFilterDates();

        let formData = new FormData();
        formData.append("startDate", dates.startDate);
        formData.append("endDate", dates.endDate);
        formData.append("complianceImage", this.complianceChart.current.chartInstance.canvas.toDataURL());
        formData.append("exceptionImage", this.exceptionChart.current.chartInstance.canvas.toDataURL());
        formData.append("complianceConfig", JSON.stringify(this.summaryLegendData));
        formData.append("categoryConfig", JSON.stringify(this.categoryLegendData));

        Axios.post(ENDPOINTS.report.generateBPTSummaryExport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let reportUrl = API.appendAuthTokenToUrl(resp.data.url);
                    console.log(reportUrl);
                    window.open(reportUrl);
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    exportNetworkInFlight : false
                })
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    exportNetworkInFlight : false
                })
            });
    }

    render() {
        let reportBody = [];

        if (this.state.reportNetworkInFlight) {
            reportBody.push(
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <LoadingSpinner/>
                        <strong>Loading, please wait...</strong>
                    </div>
                </div>
            )
        } else if (this.state.summaryPieData == null && this.state.categoryPieData == null) {
            reportBody.push(
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <em>Click "Generate Report" to view Report</em>
                    </div>
                </div>
            )
        } else {
            if (this.state.summaryPieData != null) {
                let exportButton = (
                    <button className={"btn btn-primary"} onClick={this.generateBPTExportOverNetwork}>Export
                        PDF</button>);
                if (this.state.exportNetworkInFlight) {
                    exportButton = (<button className={"btn btn-primary disabled"}>Exporting, please wait...</button>);
                }

                reportBody.push(
                    <div className={"row export-button"}>
                        <div className={"col-12 text-right"}>
                            {exportButton}
                        </div>
                    </div>
                )

                reportBody.push(
                    <div className={"row summary-report-container"}>
                        <div className={"col-12"}>
                            <h3>Total BPT Compliance</h3>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <Pie data={this.state.summaryPieData} options={{legend: {display: false}}}
                                 ref={this.complianceChart}/>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"summary-legend"}>
                                {
                                    this.summaryLegendData.map((item) => (
                                        <div className={"item"}>
                                            <div className={"block"} style={{backgroundColor: item.colour}}/>
                                            <div className={"content-container"}>
                                                <div className={"title"}>{item.title}</div>
                                                <div
                                                    className={"message"}>{this.replaceLegendTemplate(this.state.reportData.summary, item.message)}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )

                reportBody.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <p>Best Practice Tariffs (BPTs) are used within the NHS where ‘evidence of what is best practice is clear and compelling’ (Darzi 2008). They reward (financially) Trusts for adopting best practice. The Emergency Laparotomy BPT identifies consultant delivered care and immediate post-operative critical care support (level 2 or 3) as key elements of best practice. The BPT is assessed for each <strong>high-risk</strong> NELA EmLap, and is passed (paid) if >=80% of all such patients (each financial quarter) receive <b><u>all</u></b> 3 of the elements of care identified below:</p>
                                    <p>Consultant Surgeon presence for operative care <b><u>AND</u></b> Consultant Anaesthetist presence for operative care <b><u>AND</u></b> direct admission to a level 2 or 3 critical care bed post-operatively</p>
                                    <p>If the NELA record is incomplete with respect to whether these standards were met NELA will assume they were <b><u>not</u></b>. Importantly the enhanced payment is an <b>‘all or nothing’</b> tariff: i.e. if the standard is achieved for <b>= 80% of high-risk</b> cases then the higher tariff is paid for <b><u>all</u></b> emergency laparotomies (whether high-risk or not). However if the standard is not delivered for >=80% of high-risk patients the standard (reduced) payment is made for <b><u>all</u></b> emergency laparotomies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            if (this.state.categoryPieData != null) {
                reportBody.push(
                    <div className={"row summary-report-container"}>
                        <div className={"col-12"}>
                            <h3>BPT exception report</h3>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <Pie data={this.state.categoryPieData} options={{legend: {display: false}}}
                                 ref={this.exceptionChart}/>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"summary-legend"}>
                                {
                                    this.categoryLegendData.map((item) => (
                                        <div className={"item"}>
                                            <div className={"block"} style={{backgroundColor: item.colour}}/>
                                            <div className={"content-container"}>
                                                <div className={"title"}>{item.title}</div>
                                                <div
                                                    className={"message"}>{this.replaceCategoryTemplate(this.state.reportData.categories, item.message)}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )

                reportBody.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <p>
                                        This report relates only to those case records where, despite the patient
                                        meeting NELA criteria as ‘high-risk’, they did not receive all 3 of the best
                                        practice elements of care. i.e the patients care did not include 1 or more of:
                                        <ol>
                                            <li>Consultant surgeon in theatre</li>
                                            <li>Consultant anaesthetist in theatre</li>
                                            <li>Critical care post-op</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )

                reportBody.push(
                    <div className={"row bpt-section"}>
                        <div className={"col-12"}>
                            <h3>BPT exception cases</h3>
                        </div>

                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-header"}>
                                    Table Filters
                                </div>
                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        {
                                            this.categoryLegendData.map((category) => (
                                                <div className={"col-6 col-md-4 col-lg-3"}>
                                                    <label>
                                                        <input type={"checkbox"}
                                                               checked={this.isBPTCategoryChecked(category.key)}
                                                               onChange={(() => this.checkBPTCategory(category.key))}/> {category.title}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 table-space"}>
                            <TableComponent
                                className={"common-table alternating clickable"}
                                emptyMessage={"There are no users to display"}
                                data={this.state.bptTableData}
                                onRowClick={(row) => {
                                    this.bptTableRowClicked(row, true);
                                }}>

                                <Column name={"dateCreated"} title={"Date of Case Record"} render={(data, row) => {
                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                }}/>
                                <Column name={"name"} title={"BPT Measurement name"}/>
                                <Column name={"caseRecordId"} title={"Actions"} className={"text-center"}
                                        render={(data, row) => {
                                            return (<button className={"btn btn-primary"}>View</button>)
                                        }}/>
                            </TableComponent>

                            <div className={"paginator-container"}>
                                <div className={"spacer"}/>
                                <div className={"paginator-area"}>
                                    <TablePaginator
                                        totalCount={this.state.totalRows}
                                        pageSize={this.pageLimit}
                                        page={this.state.currentPage}
                                        onClick={(idx) => this.updateTablePage(idx)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className={"reports-btp-statistics-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>BPT Reports</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ReportDateFilterComponent
                            filterType={this.state.filterType}
                            filterStartDate={this.state.filterStartDate}
                            filterEndDate={this.state.filterEndDate}
                            callback={this.dateFilterDidChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        {reportBody}
                    </div>
                </div>
            </div>
        )

    }
}