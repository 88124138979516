import React, {useEffect, useState} from "react";
import {EventUtil} from "../../../util/EventUtil";

import "./CaseRecordManualEntryUserListComponent.css";
import deleteIcon from "../../../assets/qinotify/img/icon_close.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {CommonUtil} from "../../../util/CommonUtil";

export const CaseRecordManualEntryUserListActions = {
    REQUEST_USER : "crmeula.request_user",
    REMOVE_USER : "crmeula.remove_user"
};

export const CaseRecordManualEntryUserListMode = {
    SINGLE : 1,
    MULTI : 2
};

export const CaseRecordManualEntryUserListComponent = (props) => {

    const {data} = props;
    const {mode} = props;
    const {callback} = props;

    const [entries, setEntries] = useState([]);

    useEffect(() => {
        if (data) {
            let useData = data;
            if (typeof data === 'string' || data instanceof String) {
                useData = JSON.parse(data);
            }

            if (!Array.isArray(useData)) {
                setEntries([useData]);
            } else {
                setEntries(useData);
            }
        }
    }, [data]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    let listContent = [];

    if (entries.length > 0) {
        entries.forEach((item) => {
            listContent.push(
                <ManualEntryListItem
                    clickable={false}
                    item={item}
                    callback={(action) => {
                        if (action === "delete") {
                            handleCallback(
                                CaseRecordManualEntryUserListActions.REMOVE_USER,
                                item
                            );
                        }
                    }} />
            )
        });
    } else {
        if (mode === CaseRecordManualEntryUserListMode.SINGLE) {
            listContent.push(
                <ManualEntryListItem
                    clickable={true}
                    callback={(action) => {
                        if (action === "click") {
                            handleCallback(
                                CaseRecordManualEntryUserListActions.REQUEST_USER
                            );
                        }
                    }} />
            )
        } else {
            listContent.push(
                <li className={"list-group-item"}>
                    <em>No people selected</em>
                </li>
            );
        }
    }

    let addButtonElem = [];
    if (mode !== CaseRecordManualEntryUserListMode.SINGLE) {
        addButtonElem = (
            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button
                        className={"btn btn-primary"}
                        onClick={() => {
                            handleCallback(
                                CaseRecordManualEntryUserListActions.REQUEST_USER
                            );
                        }}>
                        Add Person
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={"manual-entry-user-list"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {listContent}
                    </ul>
                </div>
            </div>

            {addButtonElem}

        </div>
    );

}

export const ManualEntryListItem = (props) => {
    const {item} = props;
    const {clickable} = props;
    const {callback} = props;

    let clickAction = undefined;
    let classExtra = "";
    if (clickable) {
        clickAction = (e) => {
            EventUtil.cancel(e);
            if (callback) {
                callback("click");
            }
        };

        classExtra = " clickable";
    }

    let mainContent = [];
    if (item) {
        let staffName = item.givenName + " " + item.familyName;
        if (item.hasOwnProperty("title") && item.title && item.title !== "") {
            staffName = item.title + " " + staffName;
        }

        let subtitle = "";

        if (item.hasOwnProperty("speciality") && item.speciality && item.speciality !== "") {
            subtitle += item.speciality;
        }

        if (item.hasOwnProperty("gmcNumber") && item.gmcNumber && item.gmcNumber !== "") {
            if (subtitle !== "") {
                subtitle += ", ";
            }
            subtitle += item.gmcNumber;
        }


        mainContent = [
            <div className={"flex-fill mr-2"}>
                <div><strong>{staffName}</strong></div>
                <div>{subtitle}</div>
            </div>,
            <button
                className={"btn btn-danger action"}
                aria-label={"Delete"}
                style={{backgroundImage : ImageUtil.background(deleteIcon)}}
                onClick={() => {
                    if (callback) {
                        callback("delete");
                    }
                }}
            />
        ]
    } else {
        mainContent = (
            <div className={"flex-fill"}>
                <em>Select a person</em>
            </div>
        );
    }

    return (
        <li
            className={"list-group-item manual-entry-list-item d-flex align-items-center" + classExtra}
            onClick={clickAction}>

            {mainContent}

        </li>
    )
}