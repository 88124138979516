import {CommonUtil} from "../../util/CommonUtil";
import {SystemSettingsActions} from "./SystemSettingsScreen";
import React, {useEffect, useState} from "react";

export const SystemSettingsDeactivationConfigComponent = (props) => {

    const KEY_DEACTIVATION_DAY = "deactivation_email_day";
    const KEY_DEACTIVATION_TIME = "deactivation_email_time";

    const {configData} = props;
    const {callback} = props;

    const [deactivationHour, setDeactivationHour] = useState();
    const [deactivationMinute, setDeactivationMinute] = useState();

    useEffect(() => {
        setTimeFromConfig();
    }, []);

    useEffect(() => {
        setTimeFromConfig();
    }, [configData]);

    useEffect(() => {
        if (deactivationHour && deactivationMinute) {
            let compiledTime = deactivationHour + ":" + deactivationMinute;
            CommonUtil.safeCallback(callback, SystemSettingsActions.SUBMIT, {key : KEY_DEACTIVATION_TIME, value : compiledTime});
        }
    }, [deactivationHour, deactivationMinute]);

    function setTimeFromConfig() {
        const deactivationTime = CommonUtil.getOrDefault(configData, KEY_DEACTIVATION_TIME, null);

        if (deactivationTime) {
            const timeSplit = deactivationTime.split(":");
            if (timeSplit.length > 1) {
                setDeactivationHour(timeSplit[0]);
                setDeactivationMinute(timeSplit[1]);
            }
        }
    }

    // RENDER

    const days = [
        { value : 1, label : "Monday" },
        { value : 2, label : "Tuesday" },
        { value : 3, label : "Wednesday" },
        { value : 4, label : "Thursday" },
        { value : 5, label : "Friday" },
        { value : 6, label : "Saturday" },
        { value : 7, label : "Sunday" },
    ];

    const hourElems = [];
    for (let i = 0; i < 24; i++) {
        let hour = i;
        if (hour < 10) {
            hour = "0" + hour;
        }
        hourElems.push(
            <option value={hour}>{hour}</option>
        )
    }

    const minuteElems = [];
    for (let i = 0; i < 60; i = i + 15) {
        let minute = i;
        if (minute < 10) {
            minute = "0" + minute;
        }

        minuteElems.push(
            <option value={minute}>{minute}</option>
        )
    }

    return (
        <div className={"card"}>
            <div className={"card-header"}>
                <h5>Deactivation Settings</h5>
            </div>

            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Deactivation Day</label>
                        <select
                            className={"form-control"}
                            value={CommonUtil.getOrDefault(configData, KEY_DEACTIVATION_DAY, "-1")}
                            onChange={(e) => CommonUtil.safeCallback(
                                callback,
                                SystemSettingsActions.SUBMIT,
                                { key : KEY_DEACTIVATION_DAY, value : e.target.value }
                            )}>

                            <option value={"-1"}>Any day</option>
                            {days.map((item) => (
                                <option value={item.value}>{item.label}</option>
                            ))}

                        </select>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Deactivation Notification Time</label>
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-lg-10 col-xl-8"}>
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <select className={"form-control flex-fill"} value={deactivationHour} onChange={(e) => setDeactivationHour(e.target.value)}>
                                        {hourElems}
                                    </select>

                                    <div className={"flex-fill p-2"}>:</div>

                                    <select className={"form-control flex-fill"} value={deactivationMinute} onChange={(e) => setDeactivationMinute(e.target.value)}>
                                        {minuteElems}
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}