import React, {useEffect, useState} from "react";
import {SystemSettingsDeactivationConfigComponent} from "./SystemSettingsDeactivationConfigComponent";
import {CommonUtil} from "../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import {Toast} from "../toast/TokyoToaster";

export const SystemSettingsActions = {
    SUBMIT : "sysset.submit"
};

export const SystemSettingsScreen = (props) => {

    const {title} = props;

    const [configData, setConfigData] = useState({});

    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (title) {
            title("System Settings")
        }

        fetchAppConfigFromNetwork();
    }, []);

    function settingDidCallback(action, data) {
        if (action === SystemSettingsActions.SUBMIT) {
            if (CommonUtil.isObject(data)) {
                const newCD = CommonUtil.cloneObject(configData);
                newCD[data.key] = data.value;
                setConfigData(newCD);
            }
        }
    }

    function submitSettings() {
        submitAppConfigOverNetwork();
    }

    function fetchAppConfigFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();

        Axios.post(ENDPOINTS.appConfig.getAppConfig, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setConfigData(resp.data.appConfig);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ACF1000C"));
            });
    }

    function submitAppConfigOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("data", JSON.stringify(configData));

        Axios.post(ENDPOINTS.appConfig.submitAppConfig, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Settings have been saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setConfigData(resp.data.appConfig);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ACS1000C"));
            })
    }

    // RENDER

    let submitButton = <button className={"btn btn-success disabled"}>Save Changes</button>;
    if (!networkInFlight) {
        submitButton = <button className={"btn btn-success"} onClick={() => submitSettings()}>Save Changes</button>;
    }

    return (
        <div className={"app-section system-settings-screen"}>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-lg-10 col-xl-8"}>
                    <SystemSettingsDeactivationConfigComponent
                        configData={configData}
                        callback={settingDidCallback} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </div>
    )

}