import React from 'react';
import './App.css';
import './assets/bootstrap/css/bootstrap.min.css';
import 'react-block-ui/style.css';
import MainComponent from "./components/MainComponent";

function App() {
  return (
    <MainComponent />
  );
}

export default App;
