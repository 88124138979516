import React from "react";
import BaseComponent from "../../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import AlertModal from "../../common/AlertModal";

import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import './LinkedRecordsComponent.css';
import CaseRecordSelectionDialogComponent from "../../caseRecord/CaseRecordSelectionDialogComponent";
import ClinicalRoleSelectionDialog from "../ClinicalRoleSelectionDialog";

class LinkedRecordsComponent extends BaseComponent {

    keywordFilterTimeout = undefined;
    pageLimit = 10;

    constructor(props, context) {
        super(props, context);
        
        this.initState({
            caseRecords : [],
            caseRecordSelectionOpen : false,
            networkInFlight : false,
            filterKeyword : "",
            currentPage : 1,
            totalRows : 0,
            clinicalRoleSelectionOpen : false,

        });
    }


    // MARK: Life-cycle

    componentDidMount() {
        this.fetchUsersFromNetwork();

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.keywordFilterTimeout);
    }

    // MARK: Actions

    searchInputDidChange = (e) => {
        let keyword = e.target.value;

        console.log("SET TIMEOUT");
        clearTimeout(this.keywordFilterTimeout);
        this.keywordFilterTimeout = setTimeout(() => {
            console.log("TIMEOUT!");
            this.fetchUsersFromNetwork(undefined, keyword);
        }, 800);

        this.handleChange(e);
    }

    didSelectRow(row) {
        // console.log("Row clicked")
    }

    updateTablePage = (index) => {
        this.setState({
            currentPage : index
        });

        this.fetchUsersFromNetwork(index);
    }

    unlinkCaseRecordTapped(caseRecord) {
        let caseRecordTitle = caseRecord.recordId + ": " + caseRecord.givenName + " " + caseRecord.familyName;

        this.showModal(
            "Confirmation",
            "Are you sure you want to remove this User from " + caseRecordTitle + "?",
            [
                {
                    label : "Cancel",
                    click : () => {
                        this.hideModal();
                    }
                },
                {
                    label : "Remove",
                    style : "btn-danger",
                    click : () => {
                        this.hideModal();
                        this.unlinkCaseRecordFromUser(caseRecord);
                    }
                }
            ]);
    }


    // MARK: Alerts

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }


    // MARK: Navigation

    moveToLinkNewCaseRecord() {
        this.setState({
            caseRecordSelectionOpen : true
        });
    }

    moveToViewCaseRecord(caseRecord) {
        this.props.history.push("/create/" + caseRecord.id);
    }

    // MARK: Networking

    prepareRequestFormForNetwork = (page, keyword) => {
        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.filterKeyword;
        }

        let formData = new FormData();
        formData.append("userId", this.props.user.id);
        formData.append("page", page);
        formData.append("limit", this.pageLimit);

        if (keyword !== "") {
            formData.append("keyword", keyword);
        }
        return formData;
    }

    fetchUsersFromNetwork(page, keyword) {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        let formData = this.prepareRequestFormForNetwork(page, keyword)

        Axios.post(ENDPOINTS.user.getCaseRecords, formData)
            .then( (r) => {
                let totalRows = 0;
                let caseRecords = null;

                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        totalRows = response.data.count;
                        caseRecords = response.data.caseRecords;
                        break;

                    case false:
                        // this.showModal(
                        //     "Error",
                        //     response.error.desc + "[" + response.error.code + "]"
                        // );
                        break;
                }

                this.setState({
                    networkInFlight: false,
                    totalRows : totalRows,
                    caseRecords : caseRecords
                });

            }).catch( (error) => {
                console.log(error);
            })
    }

    unlinkCaseRecordFromUser(caseRecord) {
        if (caseRecord === undefined) return;

        let formData = new FormData();
        formData.append("caseRecordId", caseRecord.id);
        formData.append("userId", this.props.user.id);

        Axios.post(ENDPOINTS.caseRecord.removeUserFromCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.fetchUsersFromNetwork();
                } else {
                    this.showError(resp.error.desc);
                }
            })
            .catch((e) => {
                this.showError("Could not remove User from this Case Record at this time. Please try again later.");
            });
    }

    caseRecordWasSelected = (record) => {
        let state = {
            caseRecordSelectionOpen : false,
            selectedCaseRecord : record
        }

        if (record !== undefined) {
            state.clinicalRoleSelectionOpen = true;
        }

        this.setState(state);
    }

    caseRecordRoleIdWasSelected = (role) => {
        if (role !== undefined) {
            this.submitCaseRecordLinkOverNetwork(this.state.selectedCaseRecord, role);
        }

        this.setState({
            clinicalRoleSelectionOpen : false
        });
    }

    submitCaseRecordLinkOverNetwork = (record, roleId) => {
        let formData = new FormData();
        formData.append("caseRecordId", record.id);
        formData.append("userId", this.props.user.id);
        formData.append("clinicalRoleId", roleId);

        Axios.post(ENDPOINTS.caseRecord.addUserToCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.fetchUsersFromNetwork();
                } else {
                    this.showError(resp.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);

                this.showError("There was a problem adding this user to the selected Case Record. Please try again later.");
            });
    }

    showError = (message) => {
        this.showModal("Error", message, [
            {
                label : "OK",
                click : () => {
                    this.hideModal();
                }
            }
        ]);
    }


    // MARK: Render

    render() { 
        return (
            <div className={"card linked-records-component"}>
                {this.renderHeader()}
                {this.renderCaseRecordTable()}
                {this.renderAlertModal()}

                <CaseRecordSelectionDialogComponent
                    open={this.state.caseRecordSelectionOpen}
                    title={"Associate Case Record to User"}
                    callback={this.caseRecordWasSelected} />

                <ClinicalRoleSelectionDialog
                    shown={this.state.clinicalRoleSelectionOpen}
                    callback={this.caseRecordRoleIdWasSelected}
                    label={"Please select a Clinical Role for this User"} />
            </div>
        )
    }


    // MARK: Render Helper Methods

    renderHeader() {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"actions"}>
                        <div className={"spacer"} />
                        <div className={"action"}>
                            <span className={"btn btn-primary"} onClick={() => this.moveToLinkNewCaseRecord()}>Link New Case Record</span>
                        </div>
                        <div className={"fixed-space"} />
                        <div className={"action"}>
                            <div className={"input-group"}>
                                <div className={"input-group-prepend"}>
                                    <span className={"input-group-text"}>Search</span>
                                </div>
                                <input type={"text"} className={"form-control"} name={"filterKeyword"} value={this.state.filterKeyword} onChange={this.searchInputDidChange} placeholder={"Search case records"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderActionButtons = (row) => {
        return (
            <div>
                <span className={"btn btn-primary"} onClick={ (event) => { this.moveToViewCaseRecord(row) }}>View</span>
                &nbsp;
                <span className={"btn btn-danger"} onClick={ (event) => { this.unlinkCaseRecordTapped(row) }}>Remove</span>
            </div>
        );
    }

    renderCaseRecordTable() {
        return (
            <div className={"row table-area"}>
                <div className={"col-12"}>
                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"There are no case records to display"}
                        data={this.state.caseRecords}
                        onRowClick={ (row) => { this.didSelectRow(row, true);}}>

                        <Column name={"recordId"} title={"Record ID"} />
                        <Column name={"recordDate"} title={"Record Date"} />
                        <Column name={"givenName"} title={"Patient Name"} render={(data, row) => {
                            return row.givenName + " " + row.familyName;
                        }} />
                        <Column name={"id"} title={"Actions"} className={"text-center"} render={ (data, row) => this.renderActionButtons(row) } />
                    </TableComponent>

                    <div className={"paginator-container"}>
                        <div className={"spacer"} />
                        <div className={"paginator-area"}>
                            <TablePaginator 
                                totalCount={this.state.totalRows}
                                pageSize={this.pageLimit}
                                page={this.state.currentPage}
                                onClick={ (idx) => this.updateTablePage(idx) } />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAlertModal() {
        return (
            <AlertModal
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                content={this.state.modalContent}
                buttons={this.state.modalButtons}
                dismissHandler={this.hideModal} 
            />
        )
    }

    formatName = (row) => row.givenName + " " + row.familyName;
}
 
export default LinkedRecordsComponent;