import React, {useEffect, useState} from "react";
import {Chronos} from "../../entity/Chronos";

export const MonthYearPickerActions = {
    CHANGE : "monthYearPicker.change"
};

export const MonthYearPicker = (props) => {

    const {date} = props;
    const {callback} = props;

    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    useEffect(() => {
        handleDateInput()
    }, []);

    useEffect(() => {
        handleDateInput();
    }, [date]);

    useEffect(() => {
        handleDateOutput();
    }, [month, year]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleDateInput() {
        if (date) {
            try {
                let dateChronos = Chronos.withTimestampSeconds(date);
                setMonth(parseInt(dateChronos.format("M")));
                setYear(parseInt(dateChronos.format("yyyy")));
            } catch (e) {
                console.log(e);
            }
        }
    }

    function handleDateOutput() {
        if (month && year) {
            try {
                const chronos = Chronos.parse(year + "-" + month + "-01");
                handleCallback(MonthYearPickerActions.CHANGE, chronos.seconds());
            } catch (e) {
                console.log(e);
            }
        }
    }

    // RENDER

    const years = [];
    const months = [];

    let dateChronos = Chronos.now();
    let currentYear = parseInt(dateChronos.format("yyyy"));
    for (let i = 2023; i < currentYear; i++) {
        years.push(i);
    }

    dateChronos.add((parseInt(dateChronos.format("M")) - 1) * -1, Chronos.MONTHS);
    for (let i = 1; i <= 12; i++) {
        months.push(dateChronos.format("MMMM"));
        dateChronos.add(1, Chronos.MONTHS);
    }

    return (
        <div className={"row"}>
            <div className={"col-12 col-md-6 col-lg-8"}>
                <select className={"form-control"} value={month} onChange={(e) => setMonth(e.target.value)}>
                    {months.map((label, index) => (
                        <option value={(index + 1)}>{label}</option>
                    ))}
                </select>
            </div>

            <div className={"col-12 col-md-6 col-lg-4"}>
                <select className={"form-control"} value={year} onChange={(e) => setYear(e.target.value)}>
                    {years.map((year) => (
                        <option value={year}>{year}</option>
                    ))}
                </select>
            </div>
        </div>
    )

}