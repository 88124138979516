import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import {Chronos} from "../../../entity/Chronos";
import React, {useEffect, useState} from "react";
import {FilterText} from "../../filter/FilterText";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";

export const NationalStatisticsLandingComponent = (props) => {

    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(0);

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchReportsFromNetwork();
    }, []);

    useEffect(() => {
        fetchReportsFromNetwork();
    }, [page, keyword]);

    function tableRowWasClicked(row) {
        if (row.token) {
            window.open(
                API.getAPIUrl(
                    ENDPOINTS.report.getNationalStatisticReportDownload + row.token,
                    true
                )
            );
        }
    }

    function fetchReportsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("page", page);
        if (keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.report.getNationalStatisticReports, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(resp.data.data);
                    setTableCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("NSR1000C"));
            });
    }

    return (
        <div className={"national-statistics-landing-component"}>
            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row justify-content-center mt-2"}>
                <div className={"col-12 col-md-10 col-lg-8"}>

                    <div className={"table-area"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"common-table alternating clickable"}
                                emptyMessage={"No reports to display"}
                                data={tableData}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"title"} title={"Report"}/>
                                <Column name={"dateCreated"} title={"Date"} className={"text-center"} render={(data, row) => {
                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                }}/>
                                <Column name={"id"} title={"Actions"} className={"text-center"}
                                    render={(data, row) => {
                                        return (<button className={"btn btn-primary"}>View</button>)
                                    }} />
                            </TableComponent>
                        </div>

                        <div className={"paginator-container mt-2"}>
                            <div className={"spacer"}/>
                            <div className={"paginator-area"}>
                                <TablePaginator
                                    totalCount={tableCount}
                                    pageSize={20}
                                    page={page}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}