import {BaseModal} from "../alertmodal/BaseModal";
import React, {useEffect, useState} from "react";
import {Button, Check, CheckList, Container, Former, FormerActions, Text} from "../form/Former";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Toast} from "../toast/TokyoToaster";

export const SystemAdminEditorModal = (props) => {

    const {shown} = props;
    const {userId} = props;
    const {callback} = props;

    const [user, setUser] = useState();
    const [formState, setFormState] = useState({ active : [1] });
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [error, setError] = useState(null);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    useEffect(() => {
        if (shown) {
            if (userId) {
                fetchUserFromNetwork();
            }
        } else {
            setFormState({});
            setNetworkInFlight(false);
            setForceDismiss(false);
            setUser(null);
        }
    }, [shown]);

    useEffect(() => {
        const formState = { active : 1 };
        if (user) {
            formState.givenName = user.givenName;
            formState.familyName = user.familyName;
            formState.emailAddress = user.emailAddress;
            formState.active = [user.active ? 1 : 0];
        }
        console.log("Form state: ", formState);
        setFormState(formState);
    }, [user]);

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitUserOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            console.log("CHANGE: ", data);
        }
    }

    function fetchUserFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("ids", JSON.stringify([userId]));
        formData.append("page", 1);
        formData.append("pageSize", 1);

        Axios.post(ENDPOINTS.user.getSystemAdminUsers, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setUser(resp.data.data[0]);
                    } else {
                        // TODO Feedback?
                    }
                } else {
                    // TODO Feedback?
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                // TODO Feedback?
            });
    }

    function submitUserOverNetwork(state) {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        setError(null);

        const formData = new FormData();
        formData.append("givenName", state.givenName);
        formData.append("familyName", state.familyName);
        formData.append("emailAddress", state.emailAddress);

        if (state.hasOwnProperty("active")) {
            formData.append("active", state.active.length > 0 ? 1 : 0);
        }

        Axios.post(ENDPOINTS.user.submitSystemAdminUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "User submitted successfully",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("SAS1000C"));
            });
    }

    if (!shown) return [];

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{error}</div>
                </div>
            </div>
        )
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={callback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <h5>Admin User</h5>
                </div>
            </div>

            {errorElem}

            <Former
                state={formState}
                callback={formDidCallback}>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>

                        <Text
                            name={"givenName"}
                            label={"Given Name"}
                            className={"form-control"}
                            mandatory={true} />

                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>

                        <Text
                            name={"familyName"}
                            label={"Family Name"}
                            className={"form-control"}
                            mandatory={true} />

                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>

                        <Text
                            name={"emailAddress"}
                            type={"email"}
                            label={"Email Address"}
                            className={"form-control"}
                            mandatory={true} />

                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>

                        <CheckList name={"active"}>
                            <Check value={"1"}>Active</Check>
                        </CheckList>

                    </Container>
                </Container>

                <Container className={"row mt-2"}>
                    <Container className={"col-12 text-center"}>

                        <Button className={"btn btn-success"} label={"Submit"} />

                        <Button className={"btn btn-danger"} containerClassName={"mt-2"} label={"Cancel"} onClick={() => setForceDismiss(true)} />

                    </Container>
                </Container>

            </Former>

        </BaseModal>
    )

}