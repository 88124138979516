import React, {useRef} from "react";

import {useEffect, useState} from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {Button, Container, File, Former, FormerActions, FormerValues, Text} from "../../form/Former";
import {OrganisationUtil} from "../../../util/OrganisationUtil";
import {Toast} from "../../toast/TokyoToaster";
import {CommonUtil} from "../../../util/CommonUtil";

import "./OrganisationEditorScreen.css";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {ColourPickerModal} from "../../common/ColourPickerModal";
import {OrganisationThemeEditorActions, OrganisationThemeEditorComponent} from "./OrganisationThemeEditorComponent";
import {OrganisationPolicyListActions, OrganisationPolicyListComponent} from "./OrganisationPolicyListComponent";
import {Navigator} from "../../../util/Navigator";

import iconClose from "../../../assets/qinotify/img/icon_close.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import * as domain from "domain";
import {OrganisationEditorAppConfigComponent} from "./OrganisationEditorAppConfigComponent";

export const OrganisationEditorScreen = (props) => {

    const {title} = props;

    const [organisationId, setOrganisationId] = useState(null);
    const [organisation, setOrganisation] = useState(null);
    const [themeData, setThemeData] = useState({});

    const [uploadImage, setUploadImage] = useState(undefined);

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [themeNetworkInFlight, setThemeNetworkInFlight] = useState(false);

    const [formState, setFormState] = useState({});

    const [colourPickerShown, setColourPickerShown] = useState(false);
    const [colourPickerColour, setColourPickerColour] = useState();
    const colourPickerKey = useRef();

    const [policies, setPolicies] = useState([]);
    const [policiesNetworkInFlight, setPoliciesNetworkInFlight] = useState(false);

    const [emailDomains, setEmailDomains] = useState([]);
    const [emailInput, setEmailInput] = useState("");

    useEffect(() => {
        if (title) {
            title("Organisation");
        }

        if (props !== undefined) {
            if (props.match !== undefined) {
                if (props.match.params !== undefined) {
                    if (props.match.params["organisationId"] !== undefined) {
                        setOrganisationId(props.match.params["organisationId"]);
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (organisationId) {
            fetchOrganisationFromNetwork();
            fetchOrganisationPoliciesOverNetwork();
        }
    }, [organisationId]);

    useEffect(() => {
        if (organisation) {
            // Basic details form state
            let image = [];
            let logoImage = OrganisationUtil.getImage(organisation, OrganisationUtil.IMAGE_TYPES.LOGO);
            if (logoImage) {
                image.push(FormerValues.createImage(logoImage.imagePath));
            }

            setFormState({
                name: organisation.name,
                image
            });

            setThemeData(organisation.theme);

            if (organisation.acceptedEmailDomains !== null) {
                setEmailDomains(organisation.acceptedEmailDomains.split(","));
            }
        }
    }, [organisation]);

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitOrganisationOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            console.log("FORMER CHANGE: " + action, data);
            if (data.name === "image" && Array.isArray(data.value) && data.value.length > 0) {
                // Hold onto the changed image file to upload later
                let imageFile = data.value[0].file;
                console.log("Change image:", imageFile);
                setUploadImage(imageFile);
            }
        }
    }

    function summonColourPicker(keyName) {
        colourPickerKey.current = keyName;
        let colourValue = CommonUtil.getOrDefault(themeData, keyName);
        if (colourValue) {
            setColourPickerColour(colourValue);
        }

        setColourPickerShown(true);
    }

    function themEditorDidCallback(action, data) {
        if (action === OrganisationThemeEditorActions.SUBMIT) {
            submitOrganisationThemeOverNetwork();
        } else if (action === OrganisationThemeEditorActions.REQUEST_COLOUR) {
            summonColourPicker(data);
        }
    }

    function colourPickerDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            if (data && data !== "") {
                if (colourPickerKey.current) {
                    const theme = CommonUtil.cloneObject(themeData);
                    theme[colourPickerKey.current] = data;
                    setThemeData(theme);
                }
            }

            setColourPickerShown(false);
        }
    }

    function policyEditorDidCallback(action, data) {
        if (action === OrganisationPolicyListActions.CLICK) {
            moveToPolicyEditor(data.id, data.organisationId);
        } else if (action === OrganisationPolicyListActions.NEW) {
            moveToPolicyEditor(null, organisationId);
        }
    }

    function emailDomainDidSubmit() {
        let domain = emailInput.trim();
        if (domain !== "") {
            if (domain.startsWith("@")) {
                domain = domain.replace("@", "");
            } else if (domain.includes("@")) {
                AlertModal.showError("The specified domain includes a '@'. Email domains should only be what is after the @ symbol. For example: john.smith@nhs.net, the domain will be nhs.net.");
                return;
            }

            let alreadyHaveDomain = false;
            emailDomains.forEach((email) => {
                if (email.toLowerCase() === domain.toLowerCase()) {
                    alreadyHaveDomain = true;
                }
            })

            if (!alreadyHaveDomain) {
                const domains = [...emailDomains];
                domains.push(domain);
                setEmailDomains(domains);
            }
            setEmailInput("");
        }
    }

    function removeDomain(domain) {
        const domains = [...emailDomains];
        for (let i = domains.length - 1; i >= 0; i--) {
            if (domains[i].toLowerCase() === domain.toLowerCase()) {
                domains.splice(i, 1);
            }
        }
        setEmailDomains(domains);
    }

    function moveToPolicyEditor(id, organisationId) {
        if (!id) {
            id = "new";
        }

        Navigator.navigate("/organisation-policy/" + organisationId + "/" + id);
    }

    function fetchOrganisationFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        if (organisationId) {
            formData.append("ids", JSON.stringify([organisationId]));
        }
        formData.append("page", 1);
        formData.append("pageSize", 1);
        formData.append("themeRaw", "true");

        Axios.post(ENDPOINTS.organisation.getOrganisations, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setOrganisation(resp.data.data[0]);
                    } else {
                        AlertModal.showError("Unable to find Organisation. [ORGD2000C]");
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ORGD1000C"));
            });
    }

    function submitOrganisationOverNetwork(state) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        state.id = organisationId;

        const formData = CommonUtil.objectToFormData(state, ["image"]);

        let domainString = "";
        emailDomains.forEach((email) => {
            if (emailDomains.length > 0) {
                domainString += ",";
            }
            domainString += email;
        });
        formData.append("acceptedEmailDomains", emailDomains);

        Axios.post(ENDPOINTS.organisation.submitOrganisation, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.organisation) {
                        setOrganisation(resp.data.organisation);
                    }

                    if (uploadImage !== undefined) {
                        submitOrganisationImageOverNetwork();
                    } else {
                        Toast.show(
                            "Success",
                            "Organisation has been saved",
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                        setSubmitNetworkInFlight(false);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                    setSubmitNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ORGS1000C"));
            });
    }

    function submitOrganisationThemeOverNetwork() {
        if (themeNetworkInFlight) return;
        setThemeNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);
        formData.append("themeData", JSON.stringify(themeData));

        Axios.post(ENDPOINTS.organisation.submitOrganisationTheme, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setThemeData(resp.data.theme);

                    Toast.show(
                        "Success",
                        "Theme has been changed",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setThemeNetworkInFlight(false);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setThemeNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setThemeNetworkInFlight(false);
                AlertModal.showError(API.defaultError("OTS1000C"));
            });
    }

    function submitOrganisationImageOverNetwork() {
        if (!submitNetworkInFlight) setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);
        formData.append("organisationImageTypeId", OrganisationUtil.IMAGE_TYPES.LOGO);
        formData.append("file", uploadImage);

        Axios.post(ENDPOINTS.organisation.submitOrganisationImage, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Organisation has been saved. Logo has been updated.",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.organisation) {
                        setOrganisation(resp.data.organisation);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("OIU1000C"));
            })
    }

    function fetchOrganisationPoliciesOverNetwork() {
        if (policiesNetworkInFlight) return;
        setPoliciesNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);

        Axios.post(ENDPOINTS.organisation.getOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPolicies(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setPoliciesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPoliciesNetworkInFlight(false);
            });
    }

    // RENDER

    let mainContent = [];

    let submitButton = (
        <Button
            label={"Save"}
            containerClassName={"text-center mt-2"}
            className={"btn btn-success"} />
    )
    if (networkInFlight) {
        submitButton = (
            <Button
                label={"Save"}
                containerClassName={"text-center mt-2"}
                className={"btn btn-success disabled"}
                onClick={() => {}} />
        )
    }

    let emailDomainsElem = (<div className={"text-center"}>No domains specified</div>);
    if (emailDomains.length > 0) {
        emailDomainsElem = emailDomains.map((email) => (
            <span className={"email-tag mr-1"} onClick={() => removeDomain(email)}>
                {email}
                <span className={"icon"} style={{backgroundImage : ImageUtil.background(iconClose)}} />
            </span>
        ));
    }

    if (!networkInFlight) {
        mainContent.push(
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-lg-8 col-xl-6"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>

                                    <Former
                                        state={formState}
                                        callback={formDidCallback}>

                                        <Container className={"row mt-2 justify-content-center"}>
                                            <Container className={"col-12 col-lg-8 col-xl-6 text-center"}>
                                                <File
                                                    name={"image"}
                                                    label={"Organisation Logo"}
                                                    preview={"image"}
                                                    multiSelect={false}
                                                    previewRowLength={1}
                                                    previewClassName={"image-preview embed-responsive-16by9 mr-2 mb-2"}
                                                    buttonClassName={"btn btn-primary"}
                                                    className={"text-center"} />
                                            </Container>
                                        </Container>

                                        <Container className={"row"}>
                                            <Container className={"col-12"}>
                                                <Text
                                                    name={"name"}
                                                    mandatory={true}
                                                    className={"form-control"}
                                                    label={"Organisation Name"} />
                                            </Container>
                                        </Container>

                                        <div className={"row mt-4"}>
                                            <div className={"col-12"}>
                                                <div className={"card"}>
                                                    <div className={"card-body"}>
                                                        <label>Accepted Email Domains</label>
                                                        <div className={"alert alert-info"}>
                                                            <small>These are the domains that will be accepted during user registration. For example, for the email address: john.smith@nhs.net, nhs.net is an accepted domain.</small>
                                                        </div>

                                                        <div className={"email-tags"}>
                                                            {emailDomainsElem}
                                                        </div>

                                                        <div className={"input-group mt-2"}>
                                                            <input type={"text"} className={"form-control"} value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                                                            <span className={"input-group-append"}>
                                                                <span className={"input-group-text clickable"} onClick={() => emailDomainDidSubmit()}>Add</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Container className={"row mt-2"}>
                                            <Container className={"col-12"}>
                                                {submitButton}
                                            </Container>
                                        </Container>
                                    </Former>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )

        if (organisation) {
            // Policies
            mainContent.push(
                <OrganisationPolicyListComponent
                    organisationId={organisationId}
                    callback={policyEditorDidCallback} />
            );

            mainContent.push(
                <div className={"row mt-2 justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8"}>
                        <OrganisationEditorAppConfigComponent
                            organisationId={organisationId} />
                    </div>
                </div>
            )

            // Theme
            mainContent.push(
                <OrganisationThemeEditorComponent
                    allowSubmit={!themeNetworkInFlight}
                    themeData={themeData}
                    callback={themEditorDidCallback} />
            );
        }
    } else {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        )
    }

    return (
        <div className={"organisation-editor-screen app-section"}>

            <div className={"row justify-content-center"}>
                <div className={"col-12 col-lg-10"}>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>

                            {mainContent}

                        </div>
                    </div>

                </div>
            </div>

            <ColourPickerModal
                shown={colourPickerShown}
                colour={colourPickerColour}
                callback={colourPickerDidCallback} />

        </div>
    )

}

