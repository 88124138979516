import React, {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import LoadingSpinner from "../../common/LoadingSpinner";
import {CommonUtil} from "../../../util/CommonUtil";
import {Toast} from "../../toast/TokyoToaster";

export const OrganisationPatientDataComponent = (props) => {

    const [orgPatientData, setOrgPatientData] = useState({});
    const [patientDataNetworkInFlight, setPatientDataNetworkInFlight] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const questionData = [
        { key : "CRME.ED_DutyConsNotConfig", label : "ED duty consultant  at time of arrival at hospital" },
        { key : "CRME.Con_EGS_FirstReviewNotConfig", label : "on-call duty consultant surgeon at time of 1st consultant review/admission" },
        { key : "CRME.CT_ClinInfo_ReportNotConfig", label : "performance and reporting of pre-operative CT scanning" },
        { key : "CRME.Consent_MortRiskNotConfig", label : "documentation of estimated mortality risk on the consent form" },
        { key : "CRME.NELAdata_CompNotConfig", label : "data completeness" },
        { key : "CRME.NELA_RecordCreatorNotConfig", label : "case record creator/reporter" },
        { key : "CRME.Th_TraineesNotConfig", label : "trainees in theatre" },
        { key : "CRME.Con_CritCareNotConfig", label : "critical care consultants" },
        { key : "CRME.COTE_InputNotConfig", label : "COTE input" }
    ];

    useEffect(() => {
        fetchOrganisationPatientDataFromNetwork();
    }, []);

    function setPatientData(key, value) {
        console.log("Set Patient Data. Key: " + key + " = " + value);
        const pd = CommonUtil.cloneObject(orgPatientData);
        pd[key] = value;
        console.log(pd);
        setOrgPatientData(pd);
    }

    function fetchOrganisationPatientDataFromNetwork() {
        if (patientDataNetworkInFlight) return;
        setPatientDataNetworkInFlight(true);

        let formData = new FormData();

        Axios.post(ENDPOINTS.organisation.getOrganisationPatientData, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let useData = resp.data.patientData;
                    if (Array.isArray(useData)) {
                        useData = {};
                    }
                    setOrgPatientData(useData);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPatientDataNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("OPD1000C"));
                setPatientDataNetworkInFlight(false);
            });
    }

    function submitOrganisationPatientDataOverNetwork() {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("data", JSON.stringify(orgPatientData));

        Axios.post(ENDPOINTS.organisation.submitOrganisationPatientData, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Configuration has been saved.",
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("OPDS1000C"))
                setSubmitNetworkInFlight(false);
            })
    }

    // RENDER

    let mainContent = [];
    if (patientDataNetworkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        )
    } else {
        let listContent = (
            <ul className={"list-group"}>
                {
                    questionData.map((question, index) => {
                        const elemId = "q" + index;

                        const value = parseInt(CommonUtil.getOrDefault(orgPatientData, question.key, "2"));
                        let checked = value === 2;

                        return (
                            <li className={"list-group-item align-items-center"}>
                                <label className={"stretched-link mb-0"}>
                                    <input
                                        type={"checkbox"}
                                        className={"mr-2"}
                                        checked={checked}
                                        id={elemId}
                                        onChange={(e) => {
                                            setPatientData(question.key, e.target.checked ? 2 : 1);
                                        }}/>

                                    Information about <strong>{question.label}</strong> is collected at this site.</label>
                            </li>
                        )
                    })
                }
            </ul>
        );

        let saveButton = (<button className={"btn btn-success"} onClick={() => submitOrganisationPatientDataOverNetwork()}>Save Changes</button>);
        if (submitNetworkInFlight) {
            saveButton = (
                <button className={"btn btn-success disabled"}>Please wait</button>
            );
        }

        mainContent = [
            <div className={"row"}>
                <div className={"col-12"}>
                    {listContent}
                </div>
            </div>,
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {saveButton}
                </div>
            </div>
        ]
    }

    return (
        <div className={"card"}>
            <div className={"card-body"}>

                {mainContent}

            </div>
        </div>

    )

}