import React from "react";
import BaseComponent from "../../BaseComponent";
import {DataType} from "../../../entity/DataType";
import {ComparatorType} from "../../../entity/ComparatorType";
import {Chronos} from "../../../entity/Chronos";
import {DateUtil} from "../../../entity/DateUtil";

import "./CaseRecordEditorFieldComponent.css";

class CaseRecordEditorFieldComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.onFieldChange = this.onFieldChange.bind(this);
    }

    onFieldChange(e) {
        if (e.target.type === "radio") {
            if (!e.target.checked) {
                return;
            }
        }

        let value = e.target.value;

        if (e.target.type === "datetime-local") {
            try {
                let date = Chronos.parse(value);
                value = date.format("yyyy-MM-dd HH:mm");
            } catch (e) {
                console.log(e);
                return;
            }
        }

        if (this.props.hasOwnProperty("changeCallback")) {
            this.props.changeCallback(this.props.keyName, value, this.props.itemId, this.props.itemTypeId);
        }
    }

    formatDateInputValue = (input) => {
        if (input != null) {
            // console.log("DATE INPUT: " + input);
            try {
                //let date = Chronos.parse(input);
                let date = DateUtil.parseServerDate(input);
                return date.format("yyyy-MM-dd") + "T" + date.format("HH:mm");
            } catch (e) {
                console.log(e);
            }

            if (input != null) {
                // console.log("DATE INPUT: " + input);
                try {
                    //let date = Chronos.parse(input);
                    let date = DateUtil.parseServerDate(input);
                    return date.format("yyyy-MM-dd") + "T" + date.format("HH:mm");
                } catch (e) {
                    console.log(e);
                }
            }
        }
        return null;
    }

    render() {
        let controlElems = [];

        let itemTypeId = 1; // Field
        if (this.props.hasOwnProperty("itemTypeId")) {
            itemTypeId = this.props.itemTypeId;
        }

        let type = DataType.TEXT;
        if (this.props.hasOwnProperty("type")) {
            type = this.props.type;
        }

        let readOnly = false;
        if (this.props.hasOwnProperty("readOnly")) {
            readOnly = this.props.readOnly;
        }

        let comparatorType = -1;
        if (this.props.hasOwnProperty("comparatorType")) {
            comparatorType = this.props.comparatorType;
        }

        let outcomes = [];
        if (this.props.hasOwnProperty("outcomes")) {
            outcomes = this.props.outcomes;
        }

        let displayValue = null;
        if (this.props.hasOwnProperty("displayValue")) {
            displayValue = this.props.displayValue;
        }
        // console.log("Display value: " + displayValue);

        let options = [];
        if (this.props.hasOwnProperty("options")) {
            options = [...this.props.options];
        }

        if (options.length > 0) {
            if (this.props.value === null || this.props.value === "") {
                options.unshift({
                    value : null,
                    label : "(No Data has been provided)"
                });
            }

            controlElems = (
                <div>
                    <select className={"form-control"} value={this.props.value} onChange={this.onFieldChange}>
                        {
                            options.map((option) => (
                                <option value={option.value}>{option.label}</option>
                            ))
                        }
                    </select>
                </div>
            )
        } else {
            if (type === DataType.BOOLEAN && !readOnly) {
                controlElems = (
                    <form className={"radio-container"}>
                        <label className={"radio-label"}><input type={"radio"} checked={this.props.value === "1"} value={"1"} onChange={this.onFieldChange}/> Yes</label>
                        <label className={"radio-label"}><input type={"radio"} checked={this.props.value === "0"} value={"0"} onChange={this.onFieldChange} /> No</label>
                    </form>
                )
            } else {
                let inputValue = this.props.value;
                // console.log("Input Value: " + this.props.value);
                let controlType = "text";
                switch (type) {
                    case DataType.INTEGER:
                    case DataType.DOUBLE:
                        controlType = "number"; break;
                    case DataType.DATETIME:
                        controlType = "datetime-local";
                        inputValue = this.formatDateInputValue(inputValue);
                        break;
                }

                if (comparatorType == ComparatorType.EQUALS && outcomes.length > 0) {
                    controlElems = (
                        <select className={"form-control"} value={inputValue} onChange={this.onFieldChange}>
                            {
                                outcomes.map((outcome) => (
                                    <option value={outcome.value}>{outcome.body}</option>
                                ))
                            }
                        </select>
                    )
                } else {
                    controlElems = (
                        <div>
                            <input type={controlType} className={"form-control"} value={inputValue} onChange={this.onFieldChange} readOnly={readOnly}  />
                        </div>
                    );
                }
            }

        }

        let displayLabel = [];
        if (displayValue != null && displayValue !== "") {
            displayLabel = (
                <label dangerouslySetInnerHTML={{__html: displayValue}} />
            );
        }

        return(
            <div className={"row case-record-editor-field"}>
                <div className={"col-12 col-md-6 col-lg-4"}>
                    <label className={"title-label"}>{this.props.title}</label>
                </div>
                <div className={"col-12 col-md-6 col-lg-8"}>
                    {controlElems}
                    {displayLabel}
                </div>
            </div>
        )
    }
}

export default CaseRecordEditorFieldComponent;