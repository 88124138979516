import {ENDPOINTS} from "../network/API";
import Axios from "axios";
import {DataManager} from "./DataManager";
import AlertModal from "../components/common/AlertModal";
import {Chronos} from "../entity/Chronos";

export const Signal = {
    _types : {
        // Checks to see if session is still active, does not extend
        TYPE_CHECK : "c",
        // Explicit call to extend the session
        TYPE_EXTEND : "e"
    },
    _timeout : undefined,
    _mouseListener : undefined,
    _networkInFlight : false,
    _lastMouseMoveTime : 0,
    _dispatch : (type) => {
        const isExtend = type === Signal._types.TYPE_EXTEND;

        const token = DataManager.getSessionToken();
        if (!token) {
            if (!isExtend) {
                Signal._setupTimeout();
            }
            // If we do not have a session token, abort.
            return;
        }

        if (isExtend) {
            if (Signal._networkInFlight) return;
            Signal._networkInFlight = true;
        }

        const url = ENDPOINTS.login.keepAlive + "/" + type;

        Axios.get(url)
            .then((r) => {
                if (isExtend) {
                    Signal._networkInFlight = false;
                } else {
                    Signal._setupTimeout();
                }
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    if (e.response.status === 401) {
                        AlertModal.show(
                            "Access Timed Out",
                            "Your access has timed out due to inactivity. Please log in again.",
                            [
                                AlertModal.button(
                                    "OK",
                                    () => {
                                        AlertModal.dismiss();
                                    }
                                )
                            ]
                        );
                    }
                }

                if (isExtend) {
                    Signal._networkInFlight = false;
                } else {
                    Signal._setupTimeout();
                }
            });
    },
    _setupTimeout : () => {
        clearTimeout(Signal._timeout);
        Signal._timeout = setTimeout(() => {
            Signal._dispatch(Signal._types.TYPE_CHECK);
        }, 300000);
    },
    init : () => {
        Signal._setupTimeout();

        if (Signal._mouseListener) {
            document.body.removeEventListener("mousemove", Signal._mouseListener);
        }

        Signal._mouseListener = (event) => {
            const now = Chronos.now().seconds();
            const diff = now - Signal._lastMouseMoveTime;
            if (diff > 60) {
                Signal._dispatch(Signal._types.TYPE_EXTEND);
                Signal._lastMouseMoveTime = now;
            }
        };
        document.body.addEventListener("mousemove", Signal._mouseListener);
    }
}