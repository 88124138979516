import React from "react";
import BaseComponent from "../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import './LocationLandingComponent.css';
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";
import LocationEditorComponent from "./LocationEditorComponent";

export default class LocationLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            locations : [],
            locationCount : 0,
            locationPageSize : 10,
            currentPage : 1,
            filterKeyword : "",
            locationNetworkInFlight : false,
            locationName : null,
            submissionLocationId : null,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : []
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.title("Locations");

        this.fetchLocationsFromNetwork();
    }

    moveToLocationDetail = (location) => {
        if (location === undefined) return;

        this.props.history.push("/locations/view/" + location.id);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.fetchLocationsFromNetwork(page);
    }

    fetchLocationsFromNetwork = (page, keyword) => {
        if (this.state.locationNetworkInFlight) return;

        console.log("PB: " + page);

        if (page === undefined) {
            page = this.state.currentPage;
        }

        console.log("PA: " + page);

        if (keyword === undefined) {
            keyword = this.state.filterKeyword;
        }

        this.setState({
            locationNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.state.locationPageSize);

        if (keyword !== undefined && keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.setState({
                        locations : resp.data.locations,
                        locationCount : resp.data.count
                    });
                } else {
                    console.log(resp.error.desc);
                }

                this.setState({
                    locationNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    locationNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.showModal("Error", message, [
            {
                label : "OK",
                click : () => {
                    this.hideModal();
                }
            }
        ]);
    }

    showModal = (title, message, buttons) => {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        return (
            <div className={"location-landing-screen"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <LocationEditorComponent
                            mode={"CREATE"}
                            callback={(id) => this.fetchLocationsFromNetwork()}
                            navigateCallback={(id) => this.moveToLocationDetail({id : id})}
                            errorCallback={(message) => this.showError(message)} />
                    </div>
                </div>
                
                <div className={"card"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <TableComponent
                                className={"common-table alternating clickable"}
                                emptyMessage={"There are no locations to display"}
                                data={this.state.locations}
                                onRowClick={ (row) => { this.moveToLocationDetail(row);}}>

                                <Column name={"name"} title={"Location Name"} />
                                <Column name={"id"} title={"Actions"} className={"text-center"} render={ (data, row) => {
                                    return (<button className={"btn btn-primary"}>View</button>);
                                } } />
                            </TableComponent>

                            <div className={"paginator-container"}>
                                <div className={"spacer"} />
                                <div className={"paginator-area"}>
                                    <TablePaginator
                                        totalCount={this.state.locationCount}
                                        pageSize={this.state.locationPageSize}
                                        page={this.state.currentPage}
                                        onClick={ (page) => this.tablePageDidChange(page) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons}
                    dismissHandler={this.hideModal} />
            </div>
        )
    }

}
