import React from "react";
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./LocationEditorComponent.css";

export default class LocationEditorComponent extends BaseComponent {

    constructor(props, context,) {
        super(props, context);

        let locationId = null;
        let locationName = null;
        if (this.props.location !== undefined) {
            locationId = this.props.location.id;
            locationName = this.props.location.name;
        }

        console.log("LOCATION");
        console.log(this.props.location);
        console.log("Location Name: " + locationName);

        this.initState({
            locationNetworkInFlight : false,
            submissionLocationId : null,
            location : this.props.location,
            locationId : locationId,
            locationName : locationName
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let locationId = null;
        let locationName = null;
        if (this.props.location !== undefined) {
            locationId = this.props.location.id;
            locationName = this.props.location.name;
        }

        if (this.props.location != prevProps.location) {
            console.log("UPDATE LOCATION");
            this.setState({
                location: this.props.location,
                locationId: locationId,
                locationName: locationName
            });
        } else {
            console.log("DIDN'T UPDATE LOCATION");
        }
    }

    submitLocationToNetwork = () => {
        if (this.state.locationNetworkInFlight) return;

        if (this.state.locationName != null && this.state.locationName !== "") {
            this.setState({
                locationNetworkInFlight : true,
                submissionLocationId : null
            });

            let formData = new FormData();

            if (this.state.locationId != null) {
                formData.append("locationId", this.state.locationId);
            }

            formData.append("name", this.state.locationName);

            Axios.post(ENDPOINTS.location.submitLocation, formData)
                .then((r) => {
                    let state = {
                        locationNetworkInFlight : false
                    };

                    let resp = API.parse(r);
                    if (resp.success) {
                        state.submissionLocationId = resp.data.location.id;

                        if (this.props.callback !== undefined) {
                            this.props.callback(resp.data.location.id);
                        }
                    } else {
                        if (this.props.errorCallback !== undefined) {
                            this.props.errorCallback(resp.error.desc);
                        }
                    }

                    this.setState(state);
                })
                .catch((e) => {
                    console.log(e);

                    this.setState({
                        locationNetworkInFlight : false
                    });

                    if (this.props.errorCallback !== undefined) {
                        this.props.errorCallback("An unknown error has occurred. Please try again later.");
                    }
                });
        } else {
            if (this.props.errorCallback !== undefined) {
                this.props.errorCallback("Please provide a Location Name");
            }
        }
    }

    moveToLocationDetail = (locationId) => {
        if (this.props.navigateCallback !== undefined) {
            this.props.navigateCallback(locationId);
        }
    }

    render() {
        let submissionFeedback = [];
        if (this.state.submissionLocationId != null) {
            if (this.props.mode === "CREATE") {
                submissionFeedback = (
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"submission-feedback"}>
                                Successfully created Location. <a href={"#"} onClick={() => this.moveToLocationDetail(this.state.submissionLocationId)}>Click here to view Location Detail.</a>
                            </div>
                        </div>
                    </div>
                );
            } else {
                submissionFeedback = (
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"submission-feedback"}>
                                Successfully updated Location.
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={"location-editor-component"}>
                <div className={"card"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span className={"screen-title"}>{ (this.props.mode === "CREATE") ? "Add" : "Edit" } Location</span>
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12"}>
                            <label>Location Name</label>
                            <input type={"text"} className={"form-control"} name={"locationName"} value={this.state.locationName} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-primary"} onClick={this.submitLocationToNetwork}>{ (this.props.mode === "CREATE") ? "Add Location" : "Save" }</button>
                        </div>
                    </div>

                    {submissionFeedback}
                </div>
            </div>
        )
    }
}