import React from 'react';
import BaseComponent from "../BaseComponent";
import ReportDateFilterComponent from "./ReportDateFilterComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./OpenFeedbackReportComponent.css";

import iconClose from "../../assets/qinotify/img/icon_close.svg";
import UserSelectionDialogComponent from "../users/UserSelectionDialogComponent";
import PatientSelectionDialogComponent from "../users/PatientSelectionDialogComponent";
import ReflectionDetailModalComponent from "./ReflectionDetailModalComponent";

export default class OpenFeedbackReportComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let overrideState = false;

        let filterSegmentId = parseInt(this.getHashProperty("sid", -1));
        let filterFeedbackTypeId = parseInt(this.getHashProperty("ftid", -1));

        let filterType = ReportDateFilterComponent.FILTER_TYPE_MONTHLY;
        let filterStartDate = this.getHashProperty("sd", null);
        let filterEndDate = this.getHashProperty("ed", null);

        if (filterStartDate != null && filterEndDate != null) {
            filterType = ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE;
            filterStartDate = Chronos.withTimestampSeconds(parseInt(filterStartDate)).getDate();
            filterEndDate = Chronos.withTimestampSeconds(parseInt(filterEndDate)).getDate();

            overrideState = true;
        } else {
            filterStartDate = undefined;
            filterEndDate = undefined;
        }

        let state;
        if (this.props.savedState !== undefined && !overrideState) {
            state = this.props.savedState;
            state.tableNetworkInFlight = false;
            state.filterConfigNetworkInFlight = false;
        } else {
            state = {
                filterType,
                filterMonthValue : Chronos.now().format("yyyy-MM"),
                filterStartDate,
                filterEndDate,
                tableNetworkInFlight : false,
                tableData : [],
                totalCount : 0,
                currentPage : 1,
                pageLimit : 30,
                tableKeyword : null,
                patients : [],
                users : [],
                segments : [],
                filterSegmentId,
                feedbackTypes : [],
                filterFeedbackTypeId,
                userSelectionOpen : false,
                patientSelectionOpen : false,
                filterConfigNetworkInFlight : false,
                reflectionDetailOpen : false,
                reflectionDetailId : null,
                exportSubmitButton : API.getAPIUrl(ENDPOINTS.report.exportOpenReflections)
            }
        }

        this.initState(state);
    }

    componentDidMount() {
        this.fetchTableDataFromNetwork();
        this.getFilterConfigFromNetwork();
    }

    componentWillUnmount() {
        window.localStorage.removeItem("temporary_filter_date");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            if (this.props.stateCallback !== undefined) {
                this.props.stateCallback(this.state);
            }
        }
    }

    handleChange = (e) => {
        super.handleChange(e);

        setTimeout(() => {
            this.fetchTableDataFromNetwork();
        }, 10);
    }

    dateFilterDidChange = (filterType, date1, date2) => {
        let filterMonthValue = null;
        let filterStartDate = 0;
        let filterEndDate = 0;

        if (filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            filterMonthValue = date1;
        } else {
            filterStartDate = date1;
            filterEndDate = date2;
        }

        this.setState({
            filterType,
            filterMonthValue,
            filterStartDate,
            filterEndDate,
            currentPage : 1,
            totalRows : 0
        });

        setTimeout(() => {
            this.fetchTableDataFromNetwork();
        }, 10);
    }

    getFilterConfigFromNetwork = () => {
        if (this.state.filterConfigNetworkInFlight) return;

        this.setState({
            filterConfigNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.report.getFeedbackFilterConfig)
            .then((r) => {
                let segments = [];
                let feedbackTypes = [];

                let resp = API.parse(r);
                if (resp.success) {
                    segments = resp.data.segments;
                    feedbackTypes = resp.data.feedbackTypes;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    filterConfigNetworkInFlight : false,
                    segments,
                    feedbackTypes
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    userWasSelected = (user, remove) => {
        if (remove === undefined) {
            remove = false;
        }

        let users = this.state.users;

        if (user !== undefined) {
            let requiresAdding = !remove; // Never attempt to add if we're removing
            for (let i = 0; i < users.length; i++) {
                if (users[i].userId === user.userId) {
                    if (remove) {
                        // Remove item
                        users.splice(i, 1);
                    } else {
                        // Add Item
                        requiresAdding = false;
                    }
                    break;
                }
            }

            if (requiresAdding && !remove) {
                users.push(user)
            }
        }

        this.setState({
            userSelectionOpen : false,
            users
        });

        setTimeout(() => {
            this.fetchTableDataFromNetwork();
        }, 10);
    }

    userWasRequested = () => {
        this.setState({
            userSelectionOpen : true
        });
    }

    patientWasSelected = (patient, remove) => {
        if (remove === undefined) {
            remove = false;
        }

        let patients = this.state.patients;

        if (patient !== undefined) {
            let requiresAdding = !remove; // Never attempt to add if we're removing
            for (let i = 0; i < patients.length; i++) {
                if (patients[i].id === patient.id) {
                    if (remove) {
                        // Remove item
                        patients.splice(i, 1);
                    } else {
                        // Add Item
                        requiresAdding = false;
                    }
                    break;
                }
            }

            if (requiresAdding && !remove) {
                patients.push(patient)
            }
        }

        this.setState({
            patientSelectionOpen : false,
            patients
        });

        setTimeout(() => {
            this.fetchTableDataFromNetwork();
        }, 10);
    }

    patientWasRequested = () => {
        this.setState({
            patientSelectionOpen : true
        });
    }

    reflectionTableWasClicked = (row) => {
        this.openReflectionModal(row.id);
    }

    openReflectionModal = (reflectionId) => {
        this.setState({
            reflectionDetailId : reflectionId,
            reflectionDetailOpen : true
        });
    }

    reflectionModalDidCallback = (type, data) => {
        if (type === "dismiss") {
            this.setState({
                reflectionDetailOpen : false
            });
        } else if (type === "view") {
            window.open("/create/" + data);
        }
    }

    getFilterDates = () => {
        let startDate = 0;
        let endDate = 0;

        if (this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            startDate = Chronos.parse(this.state.filterMonthValue + "-01");
            endDate = Chronos.with(startDate.getDate()).add(1, Chronos.MONTHS);

            startDate = parseInt(startDate.seconds());
            endDate = parseInt(endDate.seconds());
        } else {
            startDate = parseInt(Chronos.with(this.state.filterStartDate).seconds());
            endDate = parseInt(Chronos.with(this.state.filterEndDate).seconds());
        }

        return {
            startDate,
            endDate
        };
    }

    fetchTableDataFromNetwork = (page, keyword) => {
        if (this.state.tableNetworkInFlight) return;

        if (page === undefined) page = this.state.currentPage;
        if (keyword === undefined) keyword = this.state.tableKeyword;

        let patientIds = [];
        this.state.patients.forEach((patient) => {
            patientIds.push(patient.id);
        });

        let userIds = [];
        this.state.users.forEach((user) => {
            userIds.push(user.userId);
        });

        let segmentIds = [];
        if (this.state.filterSegmentId > 0) {
            segmentIds.push(this.state.filterSegmentId);
        }

        let feedbackTypeIds = [];
        if (this.state.filterFeedbackTypeId > 0) {
            feedbackTypeIds.push(this.state.filterFeedbackTypeId);
        }

        let filterDates = this.getFilterDates();

        this.setState({
            tableNetworkInFlight : true,
            exportStartDate : filterDates.startDate,
            exportEndDate : filterDates.endDate,
            exportPatientIds : JSON.stringify(patientIds),
            exportUserIds : JSON.stringify(userIds),
            exportSegmentIds : JSON.stringify(segmentIds),
            exportFeedbackTypeIds : JSON.stringify(feedbackTypeIds)
        });

        let formData = new FormData();
        formData.append("page", page);
        formData.append("startDate", filterDates.startDate);
        formData.append("endDate", filterDates.endDate);

        if (keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        if (patientIds.length > 0) {
            formData.append("patientIds", JSON.stringify(patientIds));
        }

        if (userIds.length > 0) {
            formData.append("userIds", JSON.stringify(userIds));
        }

        if (segmentIds.length > 0) {
            formData.append("segmentIds", JSON.stringify(segmentIds));
        }

        if (feedbackTypeIds.length > 0) {
            formData.append("feedbackTypeIds", JSON.stringify(feedbackTypeIds));
        }

        Axios.post(ENDPOINTS.report.getReflectionTableData, formData)
            .then((r) => {
                let tableData = [];
                let totalCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.result;
                    totalCount = resp.data.count;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    tableNetworkInFlight : false,
                    tableData,
                    totalCount
                });
            })
            .catch((e) => {
                this.setState({
                    tableNetworkInFlight : false
                });

                console.log(e);
            });
    }

    fetchExportFromNetwork = () => {
        if (this.state.exportNetworkInFlight) return;

        this.setState({
            exportNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", this.state.exportStartDate);
        formData.append("endDate", this.state.exportEndDate);
        formData.append("keyword", this.state.tableKeyword);
        formData.append("patientIds", this.state.exportPatientIds);
        formData.append("userIds", this.state.exportUserIds);
        formData.append("segmentIds", this.state.exportSegmentIds);
        formData.append("feedbackTypeIds", this.state.exportFeedbackTypeIds);

        Axios.post(ENDPOINTS.report.generateOpenReflectionExport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let reportUrl = API.appendAuthTokenToUrl(resp.data.url);
                    console.log(reportUrl);
                    window.open(reportUrl);
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    exportNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    exportNetworkInFlight : false
                });
            });
    }

    render() {

        return (
            <div className={"open-feedback-report-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Open Feedback Report</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ReportDateFilterComponent
                            filterType={this.state.filterType}
                            filterStartDate={this.state.filterStartDate}
                            filterEndDate={this.state.filterEndDate}
                            callback={this.dateFilterDidChange} />
                    </div>
                </div>

                <div className={"row table-filters"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <label>Include Patients <span className={"badge badge-primary clickable"} onClick={this.patientWasRequested}>Add</span> </label>
                                        <div className={"widget-clip-container"}>
                                            {
                                                this.state.patients.map((patient) => (
                                                    <span className={"widget-clip"}>
                                                        {patient.givenName + " " + patient.familyName}
                                                        <span className={"close-icon"} style={{backgroundImage : "url(" + iconClose + ")"}} onClick={() => this.patientWasSelected(patient, true)} />
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <label>Include Clinicians <span className={"badge badge-primary clickable"} onClick={this.userWasRequested}>Add</span> </label>
                                        <div className={"widget-clip-container"}>
                                            {
                                                this.state.users.map((user) => (
                                                    <span className={"widget-clip"}>
                                                        {user.givenName + " " + user.familyName}
                                                        <span className={"close-icon"} style={{backgroundImage : "url(" + iconClose + ")"}} onClick={() => this.userWasSelected(user, true)} />
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <label>Filter by Record Segment</label>
                                        <select className={"form-control"} value={this.state.filterSegmentId} name={"filterSegmentId"} onChange={this.handleChange}>
                                            <option value={-1}>All Segments</option>
                                            {
                                                this.state.segments.map((segment) => (
                                                    <option value={segment.id}>{segment.segmentNumber} - {segment.segmentName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <label>Filter by Result</label>
                                        <select className={"form-control"} value={this.state.filterFeedbackTypeId} name={"filterFeedbackTypeId"} onChange={this.handleChange}>
                                            <option value={-1}>All Feedback Types</option>
                                            {
                                                this.state.feedbackTypes.map((type) => (
                                                    <option value={type.id}>{type.feedbackType}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row table-space"}>
                    <div className={"col-12 text-right"}>
                        <button className={"btn btn-primary"} onClick={this.fetchExportFromNetwork}>Export to RTF</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 table-space"}>
                        <TableComponent
                            className={"common-table alternating clickable"}
                            emptyMessage={"There are no users to display"}
                            data={this.state.tableData}
                            onRowClick={ (row) => { this.reflectionTableWasClicked(row);}}>

                            <Column name={"dateCreated"} title={"Feedback Date"} className={"text-small text-center"} render={ (data, row) => {
                                return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm");
                            } } />
                            <Column name={"segmentName"} title={"Record Segment"} />
                            <Column name={"feedbackType"} title={"Result"} className={"text-center"} />
                            <Column name={"givenName"} title={"Clinician Name"} className={"text-small text-center"} render={(data, row) => {
                                return row.givenName + " " + row.familyName;
                            }} />
                            <Column name={"id"} title={"Actions"} className={"text-center"} render={ (data, row) => {
                                return (<button className={"btn btn-primary"}>View</button>)
                            } } />
                        </TableComponent>

                        <div className={"paginator-container"}>
                            <div className={"spacer"} />
                            <div className={"paginator-area"}>
                                <TablePaginator
                                    totalCount={this.state.totalRows}
                                    pageSize={this.pageLimit}
                                    page={this.state.currentPage}
                                    onClick={ (idx) => this.updateTablePage(idx) } />
                            </div>
                        </div>
                    </div>
                </div>

                <UserSelectionDialogComponent
                    open={this.state.userSelectionOpen}
                    callback={this.userWasSelected} />

                <PatientSelectionDialogComponent
                    open={this.state.patientSelectionOpen}
                    callback={this.patientWasSelected} />

                <ReflectionDetailModalComponent
                    open={this.state.reflectionDetailOpen}
                    reflectionId={this.state.reflectionDetailId}
                    callback={this.reflectionModalDidCallback} />

            </div>
        )
    }
}