import React from "react";
import BaseComponent from "../../BaseComponent";

import './AdminDetailsComponent.css';

class AdminDetailsComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);
    }


    // MARK: Life-cycle

    componentDidMount() {

    }

    componentWillUnmount() {

    }
    
    
    // MARK: Actions
    
    tickboxToggled = () => {
        let newState = !this.props.hasDashboardAccount;
        
        if (this.props.hasOwnProperty("onDashboardAccountStateDidChange")) {
            this.props.onDashboardAccountStateDidChange(newState);
        }
    }


    // MARK: Render

    render() { 
        return (
            <div className={"admin-details"}>
                <div className={"admin-details-section"}>
                    <div className={"section-header"}>Admin Details</div>
                    {this.renderTickBox()}
                </div>
            </div>
        )
    }


    // MARK: Render Helper Methods

    renderTickBox() {
        return (
            <div className={"tickbox-container"} onClick={() => this.tickboxToggled()}>
                <input type="checkbox" checked={this.props.hasDashboardAccount}/>
                <span className="checkmark"></span>
                
                <div className={"sub-header"}>This user requires a QiNotify Admin Account</div>
                <div className={"label"}>The login credentials will be the same as used for the QiNotify app.</div>
            </div>
        )
    }
}
 
export default AdminDetailsComponent;