import React from 'react';
import "./AlertModal.css";
import {useEffect, useState} from "react";
import { v4 as uuidv4 } from "uuid";

export const BaseModalActions = {
    CLOSE : "close"
};

export const BaseModalSize = {
    NORMAL : "normal",
    LARGE : "large",
    XLARGE : "x-large"
};

export const BaseModal = (props) => {

    const {shown} = props;
    const {size} = props;
    const {forceDismiss} = props;
    const {className} = props;

    const [key, setKey] = useState(uuidv4);
    const [isDismissing, setIsDismissing] = useState(false);

    useEffect(() => {
        let dismissTimer = undefined;
        if (isDismissing) {
            clearTimeout(dismissTimer);
            dismissTimer = setTimeout(() => {
                setIsDismissing(false);
                handleCallback(BaseModalActions.CLOSE);
            }, 200);
        }

        return () => {
            clearTimeout(dismissTimer);
        }
    }, [isDismissing]);

    useEffect(() => {
        if (forceDismiss === true && !isDismissing) {
            setIsDismissing(true);
        }
    }, [forceDismiss]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    if (!shown) return [];

    let useKey = "balm-" + key;
    let classExtra = "";
    if (isDismissing) {
        useKey += "-d";
        classExtra = " dismissing";
    }

    if (className) {
        classExtra += " " + className;
    }

    let sizeClass = "col-12 col-md-6 col-lg-4";
    if (size === BaseModalSize.LARGE) {
        sizeClass = "col-12 col-md-8 col-lg-6";
    } else if (size === BaseModalSize.XLARGE) {
        sizeClass = "col-12 col-md-10 col-lg-8";
    }

    return (
        <div className={"alert-modal" + classExtra} key={useKey}>
            <div className={"spacer"} />

            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={sizeClass}>
                        <div className={"alert-modal-dialog"}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

            <div className={"spacer"} />
        </div>
    )

}

export const BaseModalButtonTypes = {
    SUCCESS : "success",
    DANGER : "danger"
}

export const BaseModalButton = (props) => {
    const {label} = props;
    const {className} = props;
    const {callback} = props;

    function handleCallback() {
        if (callback) {
            callback();
        }
    }

    let classExtra = "";
    if (className) {
        classExtra = " " + className;
    }

    return (
        <button
            className={"alert-dialog-button" + classExtra}
            onClick={() => handleCallback()}>
            {label}
        </button>
    )
}