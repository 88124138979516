import React, {useState} from "react";
import {BaseModal, BaseModalActions} from "../alertmodal/BaseModal";
import LoadingSpinner from "../common/LoadingSpinner";

export const TwoFactorCodeActions = {
    SUBMIT : "twofactorcode.submit"
};

export const TwoFactorCodeModal = (props) => {

    const {shown} = props;
    const {networkInFlight} = props;
    const {email} = props;
    const {error} = props;
    const {forceDismiss} = props;
    const {callback} = props;

    const [code, setCode] = useState("");

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!networkInFlight && code.length > 0) {
            handleCallback(TwoFactorCodeActions.SUBMIT, code);
        }
    }

    if (!shown) return [];

    let submitButton = (
        <input type={"submit"} className={"btn btn-success"} value={"Verify & Login"} />
    );
    if (networkInFlight) {
        submitButton = (
            <LoadingSpinner inline={true} small={true} />
        )
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert text-bg-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <h5>Check your email</h5>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    An email has been sent to {email}. Please check your inbox and spam folders for the QI Notify Verification Code and enter it below.
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"row mt-2 justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8 col-xl-6"}>
                        <input type={"text"} className={"form-control text-center"} value={code} placeholder={"Enter code"} onChange={(e) => setCode(e.target.value)} />
                    </div>
                </div>

                {errorElem}

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {submitButton}
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>Cancel</button>
                    </div>
                </div>
            </form>

        </BaseModal>
    )

}