import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../entity/Chronos";

import "./CaseRecordFileUploadHistoryModalComponent.css";

import fileIcon from "../../../assets/qinotify/img/icon_file.svg";
import downloadIcon from "../../../assets/qinotify/img/icon_download.svg";
import LoadingSpinner from "../../common/LoadingSpinner";

export default class CaseRecordFileUploadHistoryModalComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            historyNetworkInFlight : false,
            files : []
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let caseRecordFileTypeId = null;
        if (this.props.caseRecordFileTypeId !== prevProps.caseRecordFileTypeId) {
            caseRecordFileTypeId = this.props.caseRecordFileTypeId;
        }

        if (caseRecordFileTypeId != null) {
            this.getCaseRecordFileHistoryFromNetwork(caseRecordFileTypeId);
        }
    }

    dismiss = () => {
        if (this.props.dismissCallback !== undefined) {
            this.props.dismissCallback();
        }

        this.setState({
            files : []
        });
    }

    faderWasClicked = () => {
        let allowFaderClick = true;
        if (this.props.hasOwnProperty("cancellable")) {
            allowFaderClick = this.props.hasOwnProperty("cancellable");
        }

        if (allowFaderClick) {
            this.dismiss();
        }
    }

    downloadWasClicked = (file) => {
        if (file.filePath !== undefined) {
            window.location.href = file.filePath;
        }
    }

    getCaseRecordFileHistoryFromNetwork = (caseRecordFileTypeId) => {
        if (caseRecordFileTypeId === undefined || caseRecordFileTypeId == null) {
            return;
        }

        if (this.state.historyNetworkInFlight) return;

        this.setState({
            historyNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", this.props.caseRecordId);
        formData.append("caseRecordFileTypeId", caseRecordFileTypeId);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecordFileHistory, formData)
            .then((r) => {
                let files = [];

                let resp = API.parse(r);
                if (resp.success) {
                    files = resp.data.files;
                } else {
                    // TODO Error
                    console.log(resp.error);
                }

                this.setState({
                    historyNetworkInFlight : false,
                    files
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        let shown = this.props.shown;
        if (shown === undefined) {
            shown = false;
        }

        console.log("FILE HISTORY SHOWN? " + shown);

        if (!shown) {
            return [];
        }

        let listContent = [];
        if (!this.state.historyNetworkInFlight) {
            listContent = this.state.files.map((file) => (
                <div className={"file-item"}>
                    <div className={"icon"} style={{backgroundImage : "url(" + fileIcon + ")"}} />
                    <div className={"content-area"}>
                        <div className={"content-title"}>{file.originalFilename}</div>
                        <div className={"content-subtitle"}>Uploaded on {Chronos.withTimestampSeconds(file.dateCreated).format("dd/MM/yyyy HH:mm")} by {file.givenName + " " + file.familyName}</div>
                    </div>
                </div>
            ));
            // <div className={"icon clickable"} style={{backgroundImage : "url(" + downloadIcon + ")"}} onClick={() => this.downloadWasClicked(file)} />
        } else {
            listContent = (
                <LoadingSpinner />
            )
        }

        return (
            <div className="modal show case-record-file-history" tabIndex="-1" style={{display : "block"}} onClick={this.faderWasClicked}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.dismiss}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className={"file-list"}>
                            {listContent}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.dismiss}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}