import React from "react";
import logo from "../../assets/qinotify/img/logo.png";
import {Link, NavLink} from "react-router-dom";

import accountIcon from "../../assets/qinotify/img/icon_account.svg";
import {DataManager} from "../../util/DataManager";
import BaseComponent from "../BaseComponent";
import {OrganisationUtil} from "../../util/OrganisationUtil";
import {ThemeUtil} from "../../util/ThemeUtil";
import {Navigator} from "../../util/Navigator";

export default class TopBarComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            user : DataManager.getUser(),
            logoImage : logo
        });
    }


    componentDidMount() {
        super.componentDidMount();

        this.dataManagerListener = (key, value) => {
            if (key === DataManager.keys.user) {
                this.setState({
                    user : DataManager.getUser()
                });
            } else if (DataManager.keys.organisationImages) {
                this.getLogoImage();
            }
        };
        DataManager.addCallback(this.dataManagerListener);

        this.getLogoImage();
    }

    componentWillUnmount() {
        if (this.dataManagerListener) {
            DataManager.removeCallback(this.dataManagerListener);
        }
    }

    getLogoImage = () => {
        let useLogo = logo;

        const orgImages = DataManager.getOrganisationImages();
        if (orgImages && orgImages.length > 0) {
            const orgLogo = ThemeUtil.getBrandImage(orgImages, OrganisationUtil.IMAGE_TYPES.LOGO);
            if (orgLogo) {
                useLogo = orgLogo.imagePath;
            }
        }

        this.setState({
            logo : useLogo
        });
    }

    moveToOrganisations = () => {
        Navigator.navigate("/organisations");
    }

    render() {
        let organisationButton = [];
        if (this.state.user && !this.state.user.organisationId) {
            organisationButton = (
                <button className={"btn btn-outline-light mr-2"} onClick={() => this.moveToOrganisations()}>
                    Change Organisation
                </button>
            )
        }

        let titleContainerElements = [];
        if (this.props.hasOwnProperty("backButtonVisible")) {
            if (this.props.backButtonVisible) {
                titleContainerElements.push(
                    <Link to={this.props.backButtonVisible} className={"nav-back-button"} />
                )
            }
        }

        if (this.props.hasOwnProperty("title")) {
            titleContainerElements.push(
                <div className={"title-text"}>{this.props.title}</div>
            )
        }

        return (
            <div className={"header-topbar"}>
                <div className={"logo"} style={{ backgroundImage : "url(" + this.state.logo + ")" }} />
                <div className={"title-container"}>
                    {titleContainerElements}
                </div>
                <div className={"actions"}>
                    {organisationButton}
                    <NavLink to={"/user-details"} className={"account-icon"} style={{backgroundImage : "url(" + accountIcon + ")"}} />
                </div>
            </div>
        )
    }

}
