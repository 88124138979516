import React from 'react';
import {FilterText} from "../filter/FilterText";
import {useEffect, useRef, useState} from "react";
import {DataManager} from "../../util/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import LoadingSpinner from "../common/LoadingSpinner";
import {ClinicalStandardEditorComponent} from "./ClinicalStandardEditorComponent";
import { v4 as uuidv4 } from "uuid";
import {Toast} from "../toast/TokyoToaster";

export const ClinicalStandardLandingScreen = (props) => {

    const {title} = {props};

    const [standards, setStandards] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [keyword, setKeyword] = useState("");

    const [user, setUser] = useState(null);
    const [organisationId, setOrganisationId] = useState(null);

    const [standardNetworkInFlight, setStandardNetworkInFlight] = useState(false);
    const [organisationNetworkInFlight, setOrganisationNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    useEffect(() => {
        if (title) {
            title("Clinical Standards");
        }

        setUser(DataManager.getUser());

        fetchClinicalStandardsFromNetwork();
        if (isUserSystemAdmin()) {
            fetchOrganisationsFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (isUserSystemAdmin()) {
            fetchOrganisationsFromNetwork();
        }
    }, [user]);

    useEffect(() => {
        fetchClinicalStandardsFromNetwork();
    }, [organisationId, keyword]);

    function isUserSystemAdmin() {
        if (user) {
            return API.isInRoles(parseInt(user.userRoleId), [API.userRoles.SYSTEM_ADMIN]);
        }
        return false;
    }

    function clinicalStandardEditorDidSubmit(clinicalStandardId, text, organisationText) {
        if (clinicalStandardId) {
            let clinicalStandard = null;
            for (let i = 0; i < standards.length; i++) {
                if (standards[i].id === clinicalStandardId || standards[i].localId === clinicalStandardId) {
                    clinicalStandard = standards[i];
                    break;
                }
            }

            if (clinicalStandard) {
                if (text !== undefined) {
                    // Clinical Standard
                    submitClinicalStandardOverNetwork(
                        clinicalStandard.localId,
                        clinicalStandard.id,
                        clinicalStandard.identifier,
                        text
                    );
                } else if (organisationText !== undefined) {
                    // Organisation Standard
                    let orgStandId = undefined;
                    if (clinicalStandard.organisationStandardId) {
                        orgStandId = clinicalStandard.organisationStandardId;
                    }

                    submitOrganisationStandardOverNetwork(
                        orgStandId,
                        clinicalStandard.id,
                        organisationText
                    );
                } else {
                    console.log("??? Both text and organisationText were not provided.");
                }
            } else {
                console.log("Uh oh! Could not find standard for ID: " + clinicalStandardId);
            }
        }
    }

    function setClinicalStandardIdentifier(id, identifier) {
        let newStandards = [...standards];
        for (let i = 0; i < newStandards.length; i++) {
            if (newStandards[i].id === id || newStandards[i].localId === id) {
                newStandards[i].identifier = identifier;
                break;
            }
        }
        setStandards(newStandards);
    }

    function addClinicalStandard() {
        const newStandards = [...standards];
        newStandards.push({
            localId : uuidv4(),
            identifier : "",
            text : ""
        });
        setStandards(newStandards);
    }

    function fetchClinicalStandardsFromNetwork() {
        if (standardNetworkInFlight) return;
        setStandardNetworkInFlight(true);

        const formData = new FormData();
        formData.append("fetchOrganisationStandards", "1");

        if (organisationId) {
            formData.append("organisationId", organisationId);
        }

        if (keyword && keyword.trim() !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.clinicalStandard.getClinicalStandards, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    for (let i = 0; i < resp.data.data.length; i++) {
                        resp.data.data[i].localId = uuidv4();
                    }
                    setStandards(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setStandardNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setStandardNetworkInFlight(false);

                AlertModal.showError(API.defaultError("CSF1000C"))
            });
    }

    function fetchOrganisationsFromNetwork() {
        if (organisationNetworkInFlight) return;
        setOrganisationNetworkInFlight(true);

        const formData = new FormData();

        Axios.post(ENDPOINTS.organisation.getOrganisations, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setOrganisations(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setOrganisationNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setOrganisationNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CSOF1000C"));
            });
    }

    function submitClinicalStandardOverNetwork(localId, clinicalStandardId, identifier, text) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        if (clinicalStandardId) {
            formData.append("id", clinicalStandardId);
        }

        formData.append("identifier", identifier);
        formData.append("text", text);

        Axios.post(ENDPOINTS.clinicalStandard.submitClinicalStandard, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Clinical Standard Saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.clinicalStandard) {
                        let localStandard = null;
                        for (let i = 0; i < standards.length; i++) {
                            if (standards[i].localId === localId) {
                                if (!standards[i].id) {
                                    standards[i].id = resp.data.clinicalStandard.id;
                                }
                            }
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);

                AlertModal.showError(API.defaultError("CSS1000C"));
            });
    }

    function submitOrganisationStandardOverNetwork(organisationStandardId, clinicalStandardId, text) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        if (organisationStandardId) {
            formData.append("id", organisationStandardId);
        }

        if (organisationId) {
            formData.append("organisationId", organisationId);
        }

        formData.append("clinicalStandardId", clinicalStandardId);
        formData.append("text", text);

        Axios.post(ENDPOINTS.clinicalStandard.submitOrganisationClinicalStandard, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Organisation Standard Saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);

                AlertModal.showError(API.defaultError("OCSS1000C"));
            })
    }

    // RENDER

    let organisationSelectorElem = [];
    let screenActions = [];
    if (isUserSystemAdmin()) {
        organisationSelectorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>Show Organisation Standards for</label>
                    <select className={"form-control"} onChange={(e) => setOrganisationId(e.target.value)}>
                        {
                            organisations.map((organisation) => (
                                <option value={organisation.id}>{organisation.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        )

        screenActions = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12 text-right"}>
                                    <button className={"btn btn-primary"} onClick={() => addClinicalStandard()}>Add Standard</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let clinicalStandardElems = [];
    if (standardNetworkInFlight) {
        clinicalStandardElems = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner />
                </div>
            </div>
        );
    } else {
        let clinicalStandardWritable = isUserSystemAdmin();
        if (standards && standards.length > 0) {
            clinicalStandardElems = standards.map((standard) => {
                let identifierElem = [];
                if (clinicalStandardWritable) {
                    identifierElem = (
                        <div className={"row mb-2"}>
                            <div className={"col-12"}>
                                <label>Identifier</label>
                                <input type={"text"} className={"form-control"} value={standard.identifier} onChange={(e) => setClinicalStandardIdentifier(standard.localId, e.target.value)} />
                            </div>
                        </div>
                    )
                }

                return (
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>

                                    {identifierElem}

                                    <div className={"row"}>
                                        <div className={"col-12 col-lg-6"}>
                                            <label>Clinical Standard</label>
                                            <ClinicalStandardEditorComponent
                                                content={standard.text}
                                                writable={clinicalStandardWritable}
                                                canSubmit={!submitNetworkInFlight}
                                                callback={(action, content) => clinicalStandardEditorDidSubmit(standard.localId, content)}/>
                                        </div>

                                        <div className={"col-12 col-lg-6"}>
                                            <label>Organisational Standard</label>
                                            <ClinicalStandardEditorComponent
                                                content={standard.organisationText}
                                                writable={true}
                                                canSubmit={!submitNetworkInFlight}
                                                callback={(action, content) => clinicalStandardEditorDidSubmit(standard.localId, undefined, content)}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        } else {
            clinicalStandardElems = (
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <h4>There are no Clinical Standards to display</h4>
                        <div className={"text-center mt-2"}>
                            <button className={"btn btn-primary"} onClick={() => fetchClinicalStandardsFromNetwork()}>Reload</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"clinical-standard-landing-screen app-section"}>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-md-6 col-lg-4"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>Search for Standards</label>
                                    <FilterText callback={setKeyword} />
                                </div>
                            </div>

                            {organisationSelectorElem}

                        </div>
                    </div>
                </div>
            </div>

            {screenActions}

            {clinicalStandardElems}

        </div>
    )

}