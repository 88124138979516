import React from "react";
import BaseComponent from "../../BaseComponent";
import CaseRecordEditorFieldComponent from "./CaseRecordEditorFieldComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./CaseRecordEditorFormComponent.css"
import TimelineComponent from "./TimelineComponent";
import {SectionItem} from "../../../entity/SectionItem";
import BlockUI from "react-block-ui";
import TimelineKeyStatisticsComponent from "./TimelineKeyStatisticsComponent";
import SegmentedButtonComponent from "../../common/SegmentedButtonController";
import CaseRecordItemComponent from "./CaseRecordItemComponent";
import {DataManager} from "../../../util/DataManager";

class CaseRecordEditorFormComponent extends BaseComponent {

    MODE_INPUT = 1;
    MODE_APP_VIEW = 2;

    outcomeLookupTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        let segmentIndex = -1;
        if (this.props !== undefined) {
            if (this.props.match !== undefined) {
                if (this.props.match.params !== undefined) {
                    if (this.props.match.params.index !== undefined) {
                        segmentIndex = parseInt(this.props.match.params.index);
                    }
                }
            }
        }

        let userVisibility = API.getVisibilityForUserRole(user.userRoleId);

        let currentMode = this.MODE_INPUT;
        if (userVisibility === API.visibility.LIMITED) {
            currentMode = this.MODE_APP_VIEW;
        }

        this.initState({
            caseRecord: this.props.location.state.caseRecord,
            segmentIndex : segmentIndex,
            user,
            data : {},
            networkInFlight : false,
            outcomesNetworkInFlight : false,
            displayValues : [],
            userVisibility,
            currentMode,
            showComplete : true
        });

        this.setup = this.setup.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);
        this.getValueOrDefault = this.getValueOrDefault.bind(this);
        this.submitPatientData = this.submitPatientData.bind(this);
    }

    componentDidMount() {
        this.setup();
    }

    componentWillUnmount() {
        if (window.hasOwnProperty("widthFixCallback")) {
            window.widthFixCallback(false);
        }
    }

    setup() {
        let segment = null;
        if (this.state.segmentIndex >= 0) {
            segment = this.state.caseRecord.segments[this.state.segmentIndex];
        }

        if (segment != null) {
            this.props.title(segment.segmentName);

            if (segment.hasOwnProperty("timeline")) {
                if (segment.timeline.length > 0) {
                    // Let the MainComponent know to apply the width-fix hack
                    if (window.hasOwnProperty("widthFixCallback")) {
                        window.widthFixCallback(true);
                    }
                }
            }
        }

        this.setState({
            segment : segment
        });

        this.getResultOutcomesFromNetwork(segment);
    }

    getResultOutcomesFromNetwork = (segment) => {
        if (this.state.outcomesNetworkInFlight) return;
        this.setState({
            outcomesNetworkInFlight : true
        });

        if (segment === undefined) {
            segment = this.state.segment;
        }

        let formData = new FormData();
        formData.append("segmentId", segment.id);

        Axios.post(ENDPOINTS.caseRecord.getSectionResultOutcomes, formData)
            .then((r) => {
                let resp = API.parse(r);

                if (resp.success) {
                    this.setState({
                        resultOutcomes : resp.data.outcomes
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onFieldChanged(keyName, value, itemId) {
        if (keyName == null) return;

        console.log("Field Changed: " + keyName + " :: Value: " + value + " :: Item ID: " + itemId);
        let data = this.state.data;

        if (data[keyName] === value) {
            console.log("Key: " + keyName + " called for change, but didn't actually change. (" + value + " == " + data[keyName] + "). Aborting.");
            return;
        }

        if (value === "") {
            value = undefined;
        }
        data[keyName] = value;
        this.setState({
            data : data
        });

        if (itemId !== undefined) {
            clearTimeout(this.outcomeLookupTimeout);
            this.outcomeLookupTimeout = setTimeout(() => {
                this.fetchItemOutcomeFromNetwork(itemId, value);
            }, 800);
        }
    }

    toggleShowComplete = () => {
        this.setState({
            showComplete : !this.state.showComplete
        });
    }

    fetchItemOutcomeFromNetwork = (itemId, value) => {
        let formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("itemId", itemId);
        formData.append("value", value);

        Axios.post(ENDPOINTS.caseRecord.calculateProcessSectionItem, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let dataIndex = this.getCaseRecordItemDisplayValue(itemId, true);
                    let displayValues = this.state.displayValues;
                    if (dataIndex !== -1) {
                        displayValues[dataIndex].value = resp.data.value;
                    } else {
                        displayValues.push({
                            id : itemId,
                            value : resp.data.value
                        });
                    }

                    this.setState({
                        displayValues : displayValues
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getCaseRecordItemDisplayValue = (itemId, returnIndex) => {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < this.state.displayValues.length; i++) {
            if (this.state.displayValues[i].id === itemId) {
                if (returnIndex) {
                    return i;
                } else {
                    return this.state.displayValues[i].value;
                }
            }
        }

        if (returnIndex) {
            return -1;
        }
        return null;
    }

    findCaseRecordItem(itemId) {
        let out = null;

        if (this.state.segment !== undefined) {
            for (let i = 0; i < this.state.segment.sections.length; i++) {
                let section = this.state.segment.sections[i];
                for (let x = 0; x < section.items.length; i++) {
                    if (section.items[x].id == itemId) {
                        out = section.items[x];
                        break;
                    }
                }

                if (out != null) {
                    break;
                }
            }
        }

        return out;
    }

    getValueOrDefault(keyName, defaultVal) {
        if (this.state.data.hasOwnProperty(keyName)) {
            return this.state.data[keyName];
        }
        return defaultVal;
    }

    submitPatientData() {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        let formData = new FormData();
        formData.append("patientId", this.state.caseRecord.patientId);
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("departmentId", 1); // TODO this.state.caseRecord.departmentId
        formData.append("data", JSON.stringify(this.state.data));

        Axios.post(ENDPOINTS.caseRecord.submitPatientData, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.props.history.push("/create/" + this.state.caseRecord.id);
                } else {
                   // TODO Show error
                   console.log(resp.error);
                }

                this.setState({
                    networkInFlight : false
                });
            })
            .catch((e) => {
                // TODO Show error
                console.log(e);
            });
    }

    getResultOutcomes = (resultId) => {
        let out = [];

        if (this.state.resultOutcomes !== undefined && this.state.resultOutcomes != null) {
            this.state.resultOutcomes.forEach((outcome) => {
                if (outcome.caseRecordSegmentResultId === resultId) {
                    out.push(outcome);
                }
            });
        }

        return out;
    }

    modeDidChange = (mode) => {
        this.setState({
            currentMode : mode
        });
    }

    render() {
        let elements = [];

        let showModeSelector = this.state.userVisibility === API.visibility.FULL;

        let screenElements = [];

        let timelineComponent = [];
        let keySummaryComponent = [];
        let timelineErrorComponent = [];
        if (this.state.segment != null) {
            if (this.state.segment.hasOwnProperty("timeline")) {
                if (this.state.segment.timeline.length > 0) {
                    showModeSelector = false;

                    timelineComponent = (
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <TimelineComponent
                                    data={this.state.data}
                                    timeline={this.state.segment.timeline}
                                    firstDay={this.state.segment.timelineFirstDay} />
                            </div>
                        </div>
                    );

                    if (this.state.segment.hasOwnProperty("keyStatistics")) {
                        if (this.state.segment.keyStatistics.length > 0) {
                            keySummaryComponent = (
                                <div className={"row"}>
                                    <div className={"hiden-xs col-lg-2"} />
                                    <div className={"col-12 col-lg-8"}>
                                        <TimelineKeyStatisticsComponent
                                            data={this.state.segment.keyStatistics} />
                                    </div>
                                </div>
                            )
                        }
                    }
                }
            }

            if (this.state.segment.timelineErrors !== undefined && this.state.segment.timelineErrors.length > 0) {
                timelineErrorComponent = (
                    <div className={"row mt-4 justify-content-center"}>
                        <div className={"col-12 col-md-10 col-lg-8"}>
                            <div className={"alert alert-warning"}>
                                <h4>Timeline Errors</h4>
                                <div>Some errors were identified with the Timeline. Please review the following:</div>
                                <ul>
                                    {this.state.segment.timelineErrors.map((line) => (
                                        <li>{line}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        screenElements.push(timelineComponent);

        if (this.state.currentMode === this.MODE_INPUT) {
            if (this.state.segment !== undefined) {
                if (this.state.segment.hasOwnProperty("sections")) {
                    this.state.segment.sections.forEach((section) => {
                        if (section.title !== "@ignore") {
                            return;
                        }

                        let items = [];

                        if (section.hasOwnProperty("inputs")) {
                            section.inputs.forEach((item) => {
                                let value = this.getValueOrDefault(item.dataKey, item.value);

                                if (item.title === "@ignore") {
                                    return;
                                }

                                if (this.state.showComplete || value == null || value === "") {
                                    items.push(
                                        <CaseRecordEditorFieldComponent
                                            itemId={item.id}
                                            title={item.title}
                                            keyName={item.dataKey}
                                            value={value}
                                            type={parseInt(item.dataTypeId)}
                                            changeCallback={this.onFieldChanged}
                                            options={item.options}/>
                                    );
                                }
                            });

                            if (items.length > 0) {
                                elements.push(
                                    <div className={"form-section"}>
                                        <h2>{section.title}</h2>
                                        <div className={"card"}>
                                            {items}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    });
                }
            }

            screenElements.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label><input type={"checkbox"} checked={this.state.showComplete} onChange={this.toggleShowComplete} /> Show Completed Items</label>
                    </div>
                </div>
            )
            screenElements.push(elements);
            screenElements.push(keySummaryComponent);

            screenElements.push(
                <div className="fix-save">
                    <div className="button-group">
                        <button className="btn btn-secondary button edit-button" onClick={() => { this.props.history.push("/create/" + this.state.caseRecord.id); }}>Cancel</button>
                        <button className="btn btn-success button edit-button" onClick={this.submitPatientData} >Save</button>
                    </div>
                </div>
            );
        } else {
            let appColumn = [];

            if (this.state.segment !== undefined) {
                if (this.state.segment.hasOwnProperty("sections")) {
                    this.state.segment.sections.forEach((section) => {
                        if (section.title === "@ignore") {
                            return;
                        }

                        appColumn.push(
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h2>{section.title}</h2>
                                </div>
                            </div>
                        )

                        if (section.hasOwnProperty("items")) {
                            section.items.forEach((item) => {
                                if (item.title === "@ignore" || item.value === "@ignore") {
                                    return;
                                }

                                appColumn.push(
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <CaseRecordItemComponent
                                                item={item} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    })
                }
            }

            screenElements.push(
                <div className={"row app-section"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        {appColumn}
                    </div>
                </div>
            );
        }

        let modeSelector = [];
        if (showModeSelector) {
            modeSelector = (
                <div className={"row"}>
                    <div className={"hidden-xs col-md-3 col-lg-4"}/>
                    <div className={"col-12 col-md-6 col-lg-4 pt-3"}>
                        <SegmentedButtonComponent
                            buttons={[
                                {
                                    id: this.MODE_INPUT,
                                    label: "Input"
                                },
                                {
                                    id: this.MODE_APP_VIEW,
                                    label: "App View"
                                }
                            ]}
                            selectedId={this.state.currentMode}
                            callback={this.modeDidChange}/>
                    </div>
                </div>
            )
        }

        screenElements.push(timelineErrorComponent);

        return (
            <BlockUI tag="div" blocking={this.state.networkInFlight}>
                <div className={"case-record-editor-form"}>
                    {modeSelector}

                    {screenElements}
                </div>
            </BlockUI>
        )
    }
}

export default CaseRecordEditorFormComponent;