import BaseComponent from "../../BaseComponent";
import React from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./CaseRecordDeleteConfirmationModalComponent.css";

export default class CaseRecordDeleteConfirmationModalComponent extends BaseComponent {


    constructor(props, context) {
        super(props, context);

        this.initState({
            caseRecordDeleteNetworkInFlight : false,
            confirmation : null,
            errorMessage : null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.shown !== this.props.shown) {
            if (this.props.shown) {
                this.setState({
                    conformation : null,
                    errorMessage : null
                });
            }
        }
    }

    getCaseRecordName = () => {
        console.log(this.props.caseRecord);
        if (this.props.caseRecord !== undefined && this.props.caseRecord !== null) {
            if (this.props.caseRecord.patient !== undefined && this.props.caseRecord.patient !== null) {
                return this.props.caseRecord.patient.givenName + " " + this.props.caseRecord.patient.familyName;
            }
        }
        return "INVALID";
    }

    canSubmit = () => {
        let a = this.getCaseRecordName();
        let b = this.state.confirmation;

        if (a !== "INVALID") {
            return a === b;
        }
        return false;
    }

    dismiss = (result) => {
        if (this.props.callback !== undefined) {
            this.props.callback(result);
        }
    }

    deleteCaseRecordOverNetwork = () => {
        if (!this.canSubmit() || this.state.caseRecordDeleteNetworkInFlight) return;
        this.setState({
            caseRecordDeleteNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", this.props.caseRecord.id);
        formData.append("confirmation", this.state.confirmation);

        Axios.post(ENDPOINTS.caseRecord.deleteCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);

                let errorMessage = null;

                console.log(resp);

                if (resp.success) {
                    this.dismiss(true);
                } else {
                    errorMessage = resp.error.desc + " [" + resp.error.code + "]";
                }

                this.setState({
                    caseRecordDeleteNetworkInFlight : false,
                    errorMessage
                })
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    caseRecordDeleteNetworkInFlight : false,
                    errorMessage : "An unknown error has occurred. Please try again later. [D390]"
                });
            });
    }

    render() {
        if (!this.props.shown) return [];

        let caseRecordName = this.getCaseRecordName();

        let submitButton = (<span className={"btn btn-danger disabled"}>Delete</span>);
        if (this.canSubmit() && !this.state.caseRecordDeleteNetworkInFlight) {
            submitButton = (<span className={"btn btn-danger"} onClick={this.deleteCaseRecordOverNetwork}>Delete</span>);
        }

        let errorAlert = [];
        if (this.state.errorMessage !== null) {
            errorAlert = (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"}>{this.state.errorMessage}</div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"case-record-delete-confirmation-modal-component modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header"}>
                            <h5 className={"modal-title"}>Confirm Deletion</h5>

                            <button type="button" className="close" aria-label={"Close"} onClick={() => this.dismiss(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={"modal-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    You are about to delete the Case Record for &quot;{caseRecordName}&quot;. <strong>This action cannot be undone</strong>. Please ensure that you are certain you are removing the correct Case Record and type the name of the Case Record into the box below and click "Delete" to finalise the deletion of this Case Record.
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>Please type "{caseRecordName}" into the box below.</label>
                                    <input type={"text"} className={"form-control"} name={"confirmation"} value={this.state.confirmation} onChange={this.handleChange} />

                                </div>
                            </div>

                            {errorAlert}
                        </div>
                        <div className={"modal-footer"}>
                            {submitButton}
                            <button type={"button"} className={"btn btn-primary"} onClick={() => this.dismiss(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}