import {Chronos} from "./Chronos";

export const DateUtil = {

    parseServerDate : (input) => {
        let formats = [
            "yyyy/MM/dd HH:mm:ss", // ISO
            "MM/dd/yy HH:mm", // Excel
            "yyyy-MM-dd HH:mm", // Dashboard with time
            "yyyy-MM-dd", // Dashboard no time
            "dd/MM/yyyy HH:mm:ss", // 14/02/2021 00:34:00
            "yyyy-MM-dd HH:mm:ss",
            "yyyy-MM-dd HH:mm",
            "yyyy-MM-dd"
        ];

        for (let i = 0; i < formats.length; i++) {
            let format = formats[i];
            console.log("Trying format: " + format + " for date: " + input);
            try {
                let chronos = Chronos.parseExact(input, format);
                if (chronos != null && chronos.getDate() != null) {
                    if (chronos.isValid()) {
                        console.log("That worked? " + chronos.getDate());
                        return chronos;
                    }
                }
            } catch (e) {
                console.log("Format " + format + " didn't work for date: " + input);
            }
        }

        return Chronos.parse(input);
    }

}
