import React from 'react';
import BaseComponent from "../BaseComponent";
import DatePicker from "react-datepicker";
import {Chronos} from "../../entity/Chronos";

import './ReportCommon.css';

export default class ReportDateFilterComponent extends BaseComponent {

    static FILTER_TYPE_MONTHLY = 1;
    static FILTER_TYPE_DATE_RANGE = 2;

    constructor(props, context) {
        super(props, context);

        let months = [];

        let filterMonthValue = this.getHashProperty("fm", Chronos.now().format("yyyy-MM"));

        let latestMonth = Chronos.now();
        let earliestMonth = Chronos.with(latestMonth.getDate()).add(-12, Chronos.MONTHS);

        let currentMonth = Chronos.with(earliestMonth.getDate());
        while (latestMonth.difference(currentMonth.getDate(), Chronos.MONTHS) >= 0) {
            months.push({
                value : currentMonth.format("yyyy-MM"),
                label : currentMonth.format("MMMM yyyy")
            });

            currentMonth.add(1, Chronos.MONTHS);
        }

        let filterType = ReportDateFilterComponent.FILTER_TYPE_MONTHLY;
        if (this.props.filterType !== undefined) {
            filterType = this.props.filterType;
        }

        let filterStartDate = Chronos.with(latestMonth.getDate()).add(-1, Chronos.MONTHS).getDate();
        let inFilterSD = this.getHashProperty("sd", null);
        if (inFilterSD != null) {
            inFilterSD = parseInt(inFilterSD);
            filterStartDate = Chronos.withTimestampSeconds(inFilterSD).getDate();
            filterType = ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE;
        } else if (this.props.filterStartDate !== undefined) {
            filterStartDate = this.props.filterStartDate;
        }

        let filterEndDate = latestMonth.getDate();
        let inFilterED = this.getHashProperty("ed", null);
        if (inFilterED != null) {
            inFilterED = parseInt(inFilterED);
            filterEndDate = Chronos.withTimestampSeconds(inFilterED).getDate();
            filterType = ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE;
        } else if (this.props.filterEndDate !== undefined) {
            filterEndDate = this.props.filterEndDate;
        }

        this.initState({
            filterType,
            filterMonthValue,
            filterMonthOptions : months,
            filterStartDate,
            filterEndDate,
        })
    }

    setFilterType = (filterType) => {
        this.setState({
            filterType
        })
    }

    handleDateChange = (type, date) => {
        let state = {
            filterType : ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE
        };
        state[type] = date;

        this.setState(state);
    }

    handleSelectChange = (e) => {
        this.setState({
            filterType : ReportDateFilterComponent.FILTER_TYPE_MONTHLY
        });

        this.handleChange(e);
    }

    dispatchCallback = () => {
        if (this.props.callback !== undefined) {
            if (this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
                this.props.callback(this.state.filterType, this.state.filterMonthValue);
            } else {
                this.props.callback(this.state.filterType, this.state.filterStartDate, this.state.filterEndDate);
            }
        }
    }

    render() {
        return (
            <div className={"card filter-area"}>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label><input type={"radio"} checked={(this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY)} onClick={() => this.setFilterType(ReportDateFilterComponent.FILTER_TYPE_MONTHLY)} /> Show by month</label>
                            <div>
                                <select value={this.state.filterMonthValue} name={"filterMonthValue"} className={"form-control"} onChange={this.handleSelectChange}>
                                    {
                                        this.state.filterMonthOptions.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={"row filter-row align-center"}>
                        <div className={"col-12"}>
                            <label><input type={"radio"} checked={(this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE)} onClick={() => this.setFilterType(ReportDateFilterComponent.FILTER_TYPE_DATE_RANGE)} /> Show by date range</label>
                        </div>
                        <div className={"col-12 col-lg-5"}>
                            <div>
                                <DatePicker
                                    selected={this.state.filterStartDate}
                                    onChange={(date) => this.handleDateChange("filterStartDate", date)}
                                    className={"form-control text-center"}
                                    dateFormat={"dd/MM/yyyy"} />
                            </div>
                        </div>
                        <div className={"col-12 col-lg-2 text-center"}>
                            to
                        </div>
                        <div className={"col-12 col-lg-5"}>
                            <DatePicker
                                selected={this.state.filterEndDate}
                                onChange={(date) => this.handleDateChange("filterEndDate", date)}
                                className={"form-control text-center"}
                                dateFormat={"dd/MM/yyyy"} />
                        </div>
                    </div>

                    <div className={"row filter-row"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-success"} onClick={this.dispatchCallback}>Generate Report</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}