import React from 'react';
import BaseComponent from "../BaseComponent";

import "./ReflectionDetailModalComponent.css";
import LoadingSpinner from "../common/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Chronos} from "../../entity/Chronos";

export default class ReflectionDetailModalComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            reflectionNetworkInFlight : false,
            reflection : null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let previousReflectionId = this.getOrDefault(prevProps, "reflectionId", null);
        let currentReflectionId = this.getOrDefault(this.props, "reflectionId", null);

        if (previousReflectionId !== currentReflectionId) {
            this.fetchReflectionFromNetwork(currentReflectionId);

            this.setState({
                reflection : null
            });
        }
    }

    fetchReflectionFromNetwork = (reflectionId) => {
        if (this.state.reflectionNetworkInFlight) return;

        if (reflectionId === undefined) {
            reflectionId = this.props.reflectionId;
        }

        this.setState({
            reflectionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("reflectionId", reflectionId);

        Axios.post(ENDPOINTS.report.getOpenReflection, formData)
            .then((r) => {
                let reflection = null;

                let resp = API.parse(r);
                if (resp.success) {
                    reflection = resp.data.reflection;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    reflectionNetworkInFlight : false,
                    reflection
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    reflectionNetworkInFlight : true
                });
            });
    }

    dismissWasClicked = () => {
        if (this.props.callback !== undefined) {
            this.props.callback("dismiss");
        }
    }

    caseRecordViewWasClicked = () => {
        if (this.props.callback !== undefined) {
            this.props.callback("view", this.state.reflection.caseRecordId);
        }
    }

    render() {
        if (!this.props.open) return [];

        let bodyContent = [];
        let caseRecordButton = [];
        if (this.state.reflectionNetworkInFlight) {
            bodyContent = (
                <LoadingSpinner />
            )
        } else if (this.state.reflection != null) {
            let reflectionFeedbackTypeId = parseInt(this.state.reflection.reflectionFeedbackTypeId);

            let outcomeExtraClass = " neutral";
            let outcomeLabel = "Neutral";
            if (reflectionFeedbackTypeId === 1) {
                outcomeExtraClass = " positive";
                outcomeLabel = "Positive";
            } else if (reflectionFeedbackTypeId === 3) {
                outcomeExtraClass = " negative";
                outcomeLabel = "Negative";
            }

            bodyContent = (
                <div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"outcome-label" + outcomeExtraClass}>
                                {outcomeLabel}
                            </div>
                        </div>
                    </div>

                    <div className={"row card-row"}>
                        <div className={"col-12"}>
                            <div className={"reflection-segment-header"}>
                                <div className={"number"}>{this.state.reflection.measureNumber}</div>
                                <div className={"title"}>{this.state.reflection.measureTitle}</div>
                            </div>
                        </div>
                    </div>

                    <div className={"row card-row"}>
                        <div className={"col-12"}>
                            <label>Open Feedback</label>
                            <div>By <strong>{this.state.reflection.givenName + " " + this.state.reflection.familyName}</strong> on <strong>{Chronos.withTimestampSeconds(this.state.reflection.dateCreated).format("dd/MM/yyyy HH:mm")}</strong></div>
                        </div>
                    </div>

                    <div className={"row card-row"}>
                        <div className={"col-12"}>
                            <div>The following was influential in making things go well:</div>
                            <div className={"card"}>
                                <div className={"card-body"}>{this.state.reflection.reflectionInfluenceName}</div>
                            </div>
                        </div>
                    </div>

                    <div className={"row card-row"}>
                        <div className={"col-12"}>
                            <div>The user provided the following narrative to explain what made this aspect of care go well on this occasion:</div>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    {this.state.reflection.openFeedback}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

            if (this.state.reflection.caseRecordId !== null) {
                caseRecordButton = (
                    <button type={"button"} className={"btn btn-success"} onClick={this.caseRecordViewWasClicked}>View Case Record</button>
                )
            }
        } else {
            bodyContent = (
                <p className={"text-center"}>An error has occurred. Please try again later.</p>
            )
        }

        return (
            <div className={"reflection-detail-modal-component modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header"}>
                            <h5 className={"modal-title"}>Reflection</h5>

                            <button type="button" className="close" aria-label={"Close"} onClick={this.dismissWasClicked}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={"modal-body"}>
                            {bodyContent}
                        </div>
                        <div className={"modal-footer"}>
                            {caseRecordButton}
                            <button type={"button"} className={"btn btn-primary"} onClick={this.dismissWasClicked}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}