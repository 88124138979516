export const OrganisationUtil = {
    IMAGE_TYPES : {
        LOGO : 1
    },
    getImage : (organisation, imageTypeId) => {
        let images = OrganisationUtil.getImages(organisation, imageTypeId);
        if (images.length > 0) {
            return images[0];
        }
        return null;
    },
    getImages : (organisation, imageTypeId) => {
        let out = [];

        if (organisation) {
            if (organisation.hasOwnProperty("images")) {
                organisation.images.forEach((image) => {
                    if (parseInt(image.organisationImageTypeId) === parseInt(imageTypeId)) {
                        out.push(image);
                    }
                });
            }
        }

        return out;
    }
}