import {BaseModal, BaseModalActions} from "../alertmodal/BaseModal";
import React, {useEffect, useState} from "react";

export const TemplateEditorSectionSelectorModal = (props) => {

    const {shown} = props;
    const {callback} = props;
    const {sections} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (!shown) {
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {
                            sections.map((section) => (
                                <li className={"list-group-item clickable"} onClick={() => handleCallback(BaseModalActions.CLOSE, section)}>
                                    {section.title}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setForceDismiss(true)}>Close</button>
                </div>
            </div>

        </BaseModal>
    )

}