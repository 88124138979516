import React, {useEffect, useState} from "react";
import {ImageUtil} from "../../util/ImageUtil";

import "./FilterText.css";

import search from "./search.svg";

export const FilterText = (props) => {

    const {timeout} = props;
    const {placeholder} = props;

    const [text, setText] = useState();
    const [cbTrigger, setCbTrigger] = useState(0);

    useEffect(() => {
        let to = setTimeout(() => {
            setCbTrigger(cbTrigger + 1);
        }, (timeout ? timeout : 600));

        return () => {
            clearTimeout(to);
        }
    }, [text]);

    useEffect(() => {
        if (props.callback !== undefined) {
            props.callback(text);
        }
    }, [cbTrigger]);

    return (
        <div className="input-group flex-nowrap filter-text">
            <div className={"input-group-prepend"}>
                <span className="input-group-text">
                    <span className={"addon-icon"} style={{backgroundImage : ImageUtil.background(search)}} />
                </span>
            </div>

            <input type={"text"} className={"form-control"} value={text} placeholder={placeholder} onChange={(e) => setText(e.target.value)} />
            <div className={"input-group-append"}>
                <span className="input-group-text clickable" onClick={() => setText("")}>Clear</span>
            </div>
        </div>

    )

}