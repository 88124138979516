import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import React, {useEffect, useState} from "react";
import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";

export const OrganisationSegmentEditorModal = (props) => {

    const {shown} = props;
    const {segment} = props;
    const {callback} = props;

    const [number, setNumber] = useState("");
    const [title, setTitle] = useState("");
    const [enabled, setEnabled] = useState(true);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (shown) {
            setCallbackData(undefined);

            if (segment) {
                setNumber(segment.segmentNumber);
                setTitle(segment.segmentName);
                setEnabled(parseInt(segment.enabled) === 1);
            } else {
                setNumber("");
                setTitle("");
                setEnabled(true);
            }
        } else {
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            if (callbackData !== undefined) {
                data = callbackData;
            }
            callback(action, data);
        }
    }

    function submitForm() {
        if (number && number !== "") {
            segment.segmentNumber = number;
        }

        if (title && title !== "") {
            segment.segmentName = title;
        }

        if (enabled !== undefined) {
            segment.enabled = enabled;
        }

        setCallbackData(segment);
        setForceDismiss(true);
    }

    // RENDER

    if (!shown) return [];

    let enableWarningElem = [];
    if (!enabled) {
        enableWarningElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-warning"}>
                        This Segment will no longer be visible to any user within Case Records.
                    </div>
                </div>
            </div>
        );
    }

    let saveButton = (<button className={"btn btn-success"} onClick={() => submitForm()}>Save</button>);
    if (networkInFlight) {
        saveButton = (<button className={"btn btn-success disabled"}>Please wait</button>)
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h5>Configure Segment</h5>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <label className={"qi-label"}>Number</label>
                    <input type={"number"} className={"form-control text-center"} value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <label className={"qi-label"}>Title</label>
                    <input type={"text"} className={"form-control text-center"} value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <label className={"qi-label"}><input type={"checkbox"} checked={enabled} onChange={(e) => setEnabled(e.target.checked)} /> Visible in Case Records</label>
                </div>
            </div>

            {enableWarningElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {saveButton}

                    <button className={"btn ml-1 btn-outline-dark"} onClick={() => setForceDismiss(true)}>Close</button>
                </div>
            </div>

        </BaseModal>
    )

}