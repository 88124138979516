import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./TimelineKeyStatisticsComponent.css";

export default class TimelineKeyStatisticsComponent extends BaseComponent {

    summarySections = [
        {
            keyName : "door_to_theatre",
            title : "Door to theatre time"
        },
        {
            keyName : "referral_to_review",
            title : "Door to 1st Senior Surgical Review"
        },
        {
            keyName : "referral_to_first_senior_surgical_review",
            title : "Referral to 1st Senior Surgical Review"
        },
        {
            keyName : "first_senior_review_to_ct_booking",
            title : "1st Senior Review to Booking CT time"
        },
        {
            keyName : "book_to_report_ct",
            title : "Book to Report CT time"
        },
        {
            keyName : "ct_to_theatre",
            title : "CT Report to Theatre time"
        },
        {
            keyName : "first_senior_review_first_dose_antibiotics",
            title : "1st Senior Review to 1st Dose Antibiotics"
        }
    ]

    getStatOrDefault = (keyName, defaultValue) => {
        if (this.props.data !== undefined) {
            for (let i = 0; i < this.props.data.length; i++) {
                if (this.props.data[i].key === keyName) {
                    return this.props.data[i].value;
                }
            }
        }
        return defaultValue;
    }

    render() {
        return (
            <div className={"key-summary-key-statistics-component"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <h3>Key Summary Statistics</h3>
                            </div>
                        </div>

                        <div className={"row"}>
                            {
                                this.summarySections.map((summary) => (
                                    <div className={"col-12 col-lg-6 summary-item"}>
                                        <label>{summary.title}</label>
                                        <span>{this.getStatOrDefault(summary.keyName, "N/A")}</span>
                                    </div>
                                ))
                            }
                        </div>

                        <div className={"row intermission"}>
                            <div className={"col-12 text-center"}>
                                <h4>Urgency of Surgery</h4>
                                <span><em>‘Based on Clinical Experience this should be the maximum time that a patient could reasonable wait for surgery’</em> NELA 2020</span>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-lg-6 summary-item"}>
                                <label>Pre-op</label>
                                <span>{this.getStatOrDefault("urgency_expected", "N/A")}</span>
                            </div>
                            <div className={"col-12 col-lg-6 summary-item"}>
                                <label>Actual</label>
                                <span>{this.getStatOrDefault("urgency_actual", "N/A")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}