import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import {CommonUtil} from "../../../util/CommonUtil";
import {Chronos} from "../../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {UserApprovalRejectionModal} from "./UserApprovalRejectionModal";
import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../util/DataManager";
import {ca} from "date-fns/locale";

export const UserApprovalDetailScreen = (props) => {

    const {title} = props;

    const {id} = useParams();

    const [approval, setApproval] = useState(null);
    const [metadata, setMetaData] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [rejectionShown, setRejectionShown] = useState(false);

    useEffect(() => {
        if (title) {
            title("User Approval");
        }

        if (id) {
            fetchApprovalFromNetwork();
        }
    }, []);

    function promptToApprove() {
        AlertModal.show(
            "Approve User",
            "Please confirm you would like to approve this user? Please take care in assessing this user to make sure details are correct and you are expecting this individual to register for an account.",
            [
                AlertModal.button(
                    "Approve Now",
                    () => {
                        approveUserOverNetwork();
                        AlertModal.dismiss();
                    },
                    "success"
                ),
                AlertModal.button(
                    "Cancel",
                    () => {
                        AlertModal.dismiss();
                    },
                    "danger"
                )
            ]
        );
    }

    function promptToReject() {
        setRejectionShown(true);
    }

    function rejectionModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setRejectionShown(false);

            if (data) {
                if (data.success) {
                    rejectUserOverNetwork(data.note);
                }
            }
        }
    }

    function formatName(givenName, familyName) {
        let out = "";
        if (givenName !== "") {
            out = givenName;
        }

        if (familyName !== "") {
            if (out !== "") {
                out += " ";
            }
            out += familyName;
        }
        return out;
    }

    function fetchApprovalFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("ids", JSON.stringify([id]));
        formData.append("page", "1");
        formData.append("pageSize", "1");

        Axios.post(ENDPOINTS.approval.getUserApprovals, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        const appr = resp.data.data[0];
                        if (appr.metadata) {
                            setMetaData(JSON.parse(appr.metadata));
                        }
                        setApproval(appr);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UAD1000C"));
            });
    }

    function approveUserOverNetwork() {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("userApprovalId", approval.id);
        formData.append("organisationId", DataManager.getOrganisationId());

        Axios.post(ENDPOINTS.approval.approveUserApproval, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "User has been approved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.approval !== null) {
                        setApproval(resp.data.approval);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("AUN1000C"));
            })
    }

    function rejectUserOverNetwork(notes) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("userApprovalId", approval.id);
        formData.append("organisationId", DataManager.getOrganisationId());
        if (notes) {
            formData.append("notes", notes);
        }

        Axios.post(ENDPOINTS.approval.rejectUserApproval, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "User has been rejected",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.approval !== null) {
                        setApproval(resp.data.approval);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("RUN1000C"));
            })
    }

    // RENDER

    let mainContent = (
        <div className={"row"}>
            <div className={"col-12 text-center"}>
                <LoadingSpinner inline={true} />
            </div>
        </div>
    );

    let actionElem = [];

    if (!networkInFlight) {
        if (approval && metadata) {
            let expectedEndDate = null;
            if (metadata.hasOwnProperty("endDate")) {
                try {
                    expectedEndDate = Chronos.withTimestampSeconds(metadata.endDate).format("dd/MM/yyyy");
                } catch (e) {
                    console.log(e);
                }
            }

            mainContent = [
                <UserApprovalItem label={"Forename"} value={approval.givenName} />,
                <UserApprovalItem label={"Middle Name(s)"} value={CommonUtil.getOrDefault(metadata, "middleName", "")} />,
                <UserApprovalItem label={"Surname"} value={approval.familyName} />,
                <UserApprovalItem label={"Age Band"} value={CommonUtil.getOrDefault(metadata, "ageBand", "")} />,
                <UserApprovalItem label={"Expected End Date"} value={expectedEndDate} />,
                <UserApprovalItem label={"Primary NHS Job Role"} value={CommonUtil.getOrDefault(metadata, "clinicalRoleId", "")} />,
                <UserApprovalItem label={"GCM Number"} value={CommonUtil.getOrDefault(metadata, "gcmNumber", "")} />,
                <UserApprovalItem label={"Main area of practice/responsibility"} value={CommonUtil.getOrDefault(metadata, "responsibility", "")} />,
            ];

            if (parseInt(approval.approved) === 0 && parseInt(approval.rejected) === 0) {
                if (!submitNetworkInFlight) {
                    mainContent.push(
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <div className={"card"}>
                                    <div className={"card-header text-center"}>
                                        <h5 className={"mb-0"}>Actions</h5>
                                    </div>

                                    <div className={"card-body"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"alert alert-warning"}>
                                                    <p>Please be cautious when approving user accounts. Please ensure that you carefully scrutinise the provided information. Only approve people that you are expecting to sign up for QI Notify.</p>
                                                    <p><strong>Once approved, users will be able to view Case Records and see sensitive patient data.</strong></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"row mt-2"}>
                                            <div className={"col-12 col-md-6 text-center"}>
                                                <button className={"btn btn-success"} onClick={() => promptToApprove()}>Approve User</button>
                                            </div>

                                            <div className={"col-12 col-md-6 text-center"}>
                                                <button className={"btn btn-danger"} onClick={() => promptToReject()}>Reject User</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    mainContent.push(
                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <LoadingSpinner inline={true} />
                            </div>
                        </div>
                    )
                }

            } else if (parseInt(approval.approved) === 1) {
                let approverName = formatName(approval.approvalUserGivenName, approval.approvalUserFamilyName);

                let approverDate = "";
                if (approval.approvalDate !== null) {
                    approverDate = " on " + Chronos.withTimestampSeconds(approval.approvalDate).format("dd/MM/yyyy HH:mm");
                }

                mainContent.push(
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>

                            <div className={"alert alert-success"}>
                                This user was approved by {approverName} {approverDate}
                            </div>

                        </div>
                    </div>
                )
            } else if (parseInt(approval.rejected) === 1) {
                let rejectionName = formatName(approval.rejectionUserGivenName, approval.rejectionUserFamilyName);

                let rejectionDate = "";
                if (approval.rejectionDate !== null) {
                    rejectionDate = " on " + Chronos.withTimestampSeconds(approval.rejectionDate).format("dd/MM/yyyy HH:mm");
                }

                let notesElem = [];
                if (approval.rejectionNotes) {
                    notesElem = (
                        <div className={"mt-2"}>
                            <strong>Rejection notes:</strong>
                            <div>{approval.rejectionNotes}</div>
                        </div>
                    )
                }

                mainContent.push(
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-danger"}>
                                This user was rejected by {rejectionName} {rejectionDate}
                                {notesElem}
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            mainContent = (
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <h5>There was a problem fetching the Approval.</h5>

                        <button className={"btn btn-primary"} onClick={fetchApprovalFromNetwork}>Retry</button>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"user-approval-detail-screen app-section mb-4"}>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-lg-8 col-xl-6"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <h5>User Details</h5>
                                </div>
                            </div>

                            {mainContent}

                        </div>
                    </div>

                </div>
            </div>

            <UserApprovalRejectionModal
                shown={rejectionShown}
                callback={rejectionModalDidCallback} />

        </div>
    );

}

export const UserApprovalItem = (props) => {

    const {label} = props;
    const {value} = props;

    return (
        <div className={"row"}>
            <div className={"col-12"}>
                <label className={"qi-label"}>{label}</label>
                <div className={"form-control"}>{value}</div>
            </div>
        </div>
    );
}