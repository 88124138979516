import React from "react";
import BaseComponent from "../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import './UserSelectionDialogComponent.css';

export default class PatientSelectionDialogComponent extends BaseComponent {

    keywordTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        let keyword = "";
        if (this.props.keyword !== undefined) {
            keyword = this.props.keyword;
        }

        this.initState({
            userSearchNetworkInFlight : false,
            currentPage : 1,
            keyword : keyword,
            patients : [],
            patientCount : 0
        });
    }

    componentWillUnmount() {
        clearTimeout(this.keywordTimeout);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!this.props.open && nextProps.open) {
            let keyword = "";
            if (nextProps.keyword !== undefined) {
                keyword = nextProps.keyword;

                this.setState({
                    keyword: keyword
                });
            }

            // If the modal is about to open, fetch users from network
            this.fetchUsersFromNetwork(undefined, keyword);
        }
    }

    fetchUsersFromNetwork = (page, keyword) => {
        if (this.state.userSearchNetworkInFlight) return;

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.keyword;
        }

        this.setState({
            userSearchNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        formData.append("keyword", keyword);

        Axios.post(ENDPOINTS.patient.getPatientTableData, formData)
            .then((r) => {
                let state = {
                    userSearchNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.patients = resp.data.result;
                    state.patientCount = resp.data.count;
                } else {
                    // TODO Show Error
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);

                // TODO
            });
    }

    onPageChanged = (index) => {
        this.fetchUsersFromNetwork(index);

        this.setState({
            currentPage : index
        });
    }

    onUserSelected = (user) => {
        if (this.props.callback !== undefined) {
            this.props.callback(user);
        }
    }

    handleKeywordChange = (event) => {
        this.handleChange(event);

        this.setState({
            currentPage : 1
        });

        clearTimeout(this.keywordTimeout);
        this.keywordTimeout = setTimeout(() => {
            this.fetchUsersFromNetwork();
        }, 800);
    }

    render() {
        let modalOpen = false;
        if (this.props.open !== undefined) {
            modalOpen = this.props.open;
        }

        if (modalOpen) {
            return (
                <div className="users-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Select person to add</h5>
                            </div>
                            <div className="modal-body">
                                <div className={"row user-filter"}>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} name={"keyword"} value={this.state.keyword} placeholder={"Search for Users"} onChange={this.handleKeywordChange}/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <TableComponent
                                            className={"common-table alternating clickable"}
                                            emptyMessage={"No Users match your criteria"}
                                            data={this.state.patients}>

                                            <Column name={"givenName"} title={"Patient Name"} render={(data, row) => {
                                                return row.givenName + " " + row.familyName;
                                            }}/>
                                            <Column name={"nhsNumber"} title={"NHS Number"}/>
                                            <Column name={"genderName"} title={"Gender"}/>
                                            <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                                let buttons = [];
                                                buttons.push(<span className={"btn btn-primary"} onClick={() => this.onUserSelected(row)}>Add</span>);

                                                return buttons;
                                            }}/>

                                        </TableComponent>

                                        <div className={"ep-table-paginate-container"}>
                                            <div className={"push"}/>
                                            <div className={"paginator-area"}>
                                                <TablePaginator
                                                    totalCount={this.state.patientCount}
                                                    pageSize={10}
                                                    page={this.state.currentPage}
                                                    onClick={this.onPageChanged} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button className={"btn btn-primary"} onClick={() => this.onUserSelected(undefined)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return [];
    }

}