import React from 'react';

import "./LoadingSpinner.css";

import watchIcon from "../../assets/qinotify/img/icon_watch.svg";
import watchIconInverse from "../../assets/qinotify/img/icon_watch_inverse.svg";

export default class LoadingSpinner extends React.Component {

    render() {
        let extraClass = "";
        if (this.props.small !== undefined && !!this.props.small) {
            extraClass = " spinner-small";
        }

        if (this.props.inline !== undefined && !!this.props.inline) {
            extraClass += " inline";
        }

        let watchIconCurrent = watchIcon;
        if (this.props.inverse !== undefined && this.props.inverse) {
            watchIconCurrent = watchIconInverse;
            extraClass += " spinner-inverse";
        }

        return (
            <div className={"loading-spinner-component" + extraClass}>
                <div className={"loading-spinner"} style={{backgroundImage : "url(" + watchIconCurrent + ")"}}>
                    <div className={"centre-dot"} />
                    <div className={"watch-hand small"} />
                    <div className={"watch-hand large"} />
                </div>
            </div>
        )
    }

}