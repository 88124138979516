import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import {Button, CheckList, Container, File, Former, FormerActions, Radio, RadioList, Text} from "../../form/Former";
import SegmentedButtonComponent from "../../common/SegmentedButtonController";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Validator from "../../../util/Validator";
import AlertModal from "../../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {Navigator} from "../../../util/Navigator";
import {UrlUtil} from "../../../util/UrlUtil";

export const OrganisationPolicyEditorScreen = (props) => {

    const {organisationId} = useParams();
    const {id} = useParams();

    const {title} = props;

    const INPUT_MODE_TEXT = 1;
    const INPUT_MODE_URL = 2;
    const INPUT_MODE_FILE = 3;

    const [policy, setPolicy] = useState(null);
    const [policyNetworkInFlight, setPolicyNetworkInFlight] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [submitProgress, setSubmitProgress] = useState(0);

    const [formState, setFormState] = useState({});

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [textContent, setTextContent] = useState(undefined);

    const [inputMode, setInputMode] = useState(INPUT_MODE_TEXT);
    const [fileMandatory, setFileMandatory] = useState(true);

    useEffect(() => {
        if (title) {
            title("Policy");
        }

        if (id && id !== "new") {
            fetchPolicyOverNetwork();
        }
    }, []);

    useEffect(() => {
        if (textContent) {
            try {
                const contentBlock = htmlToDraft(textContent);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [textContent]);

    useEffect(() => {
        if (policy) {
            let newInputMode = INPUT_MODE_TEXT;
            if (policy.url !== null) {
                newInputMode = INPUT_MODE_URL;
            } else if (policy.filePath) {
                newInputMode = INPUT_MODE_FILE;
            }
            setInputMode(newInputMode);

            const newFormState = {
                title : policy.title,
                requiresAcceptance : policy.requiresAcceptance,
                url : policy.url
            };

            setFormState(newFormState);

            if (policy.description) {
                setTextContent(policy.description);
            }

            if (policy.filePath) {
                setFileMandatory(false);
            }
        }
    }, [policy]);

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitPolicyOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            console.log(data.keyName + ", " + data.value);
        }
    }

    function launchExistingFile() {
        if (policy && policy.filePath) {
            window.open(policy.filePath);
        }
    }

    function promptToDelete() {
        AlertModal.show(
            "Delete Policy",
            "Are you sure you want to delete this policy? This action cannot be undone.",
            [
                AlertModal.button(
                    "Delete",
                    () => {
                        removeOrganisationOverNetwork();
                        AlertModal.dismiss();
                    },
                    "danger"
                ),
                AlertModal.button(
                    "Cancel",
                    () => {
                        AlertModal.dismiss();
                    }
                )
            ]
        );
    }

    function fetchPolicyOverNetwork() {
        if (policyNetworkInFlight) return;
        setPolicyNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);
        formData.append("ids", JSON.stringify([id]));
        formData.append("page", "1");
        formData.append("pageSize", "1");

        Axios.post(ENDPOINTS.organisation.getOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setPolicy(resp.data.data[0]);
                    } else {
                        AlertModal.showError("Could not find Policy by the given ID. [PNF1001C]");
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPolicyNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPolicyNetworkInFlight(false);
                AlertModal.showError("Could not find Policy by the given ID [PNF1000C]");
            })
    }

    function submitPolicyOverNetwork(state) {
        if (submitNetworkInFlight) return;

        if (id && id !== "new") {
            state.id = id;
        }

        if (organisationId) {
            state.organisationId = organisationId;
        }

        const validationRules = [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("organisationId", "int", "", "organisationId", true),
            Validator.rule("title", "string", "Title", "title"),
            Validator.rule("requiresAcceptance", "string", "acceptance status", "requiresAcceptance")
        ];

        let uploadFile = null;

        if (inputMode === INPUT_MODE_TEXT) {
            validationRules.push(Validator.rule("text", "string", "Policy Text", "description"));
            state.text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        } else if (inputMode === INPUT_MODE_URL) {
            validationRules.push(Validator.rule("url", "string", "URL", "url"));
        } else if (inputMode === INPUT_MODE_FILE) {
            const files = state.file;
            if (files && files.length > 0) {
                uploadFile = files[0].file;
            }

            // We need a file if this is a new policy or the old policy does not have an upload path set.
            if (!uploadFile && policy && !policy.filePath) {
                AlertModal.showError("Please provide a file.");
                return;
            }
        }

        const validationResult = Validator.validateCreateFormData(
            state,
            validationRules
        );

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
        }

        setSubmitProgress(0);
        setPolicyNetworkInFlight(true);

        const formData = validationResult.formData;

        if (uploadFile) {
            formData.append("file", uploadFile);
        }

        Axios.post(ENDPOINTS.organisation.submitOrganisationPolicy, formData, {
                onUploadProgress : (progressEvent) => {
                    let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                    let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);
                    setSubmitProgress(progress);
                }
            })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Policy has been saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    Navigator.navigate("/manage-organisation/" + organisationId);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            });
    }

    function removeOrganisationOverNetwork() {
        if (policyNetworkInFlight) return;
        setPolicyNetworkInFlight(true);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("organisationId", organisationId);

        Axios.post(ENDPOINTS.organisation.removeOrganisationPolicy, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Policy has been deleted.",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    Navigator.navigate("/manage-organisation/" + organisationId);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPolicyNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPolicyNetworkInFlight(false);
                AlertModal.showError("Could not delete this policy at this time. Please try again later. [OPI1000C]");
            });
    }

    // RENDER

    let editorElem = [];
    if (inputMode === INPUT_MODE_TEXT) {
        editorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState} />
                </div>
            </div>
        )
    } else if (inputMode === INPUT_MODE_URL) {
        editorElem = (
            <Container className={"row mt-4"}>
                <Container className={"col-12"}>
                    <Text
                        name={"url"}
                        label={"URL"}
                        className={"form-control"}
                        mandatory={true}
                        placeholder={"https://"}/>
                </Container>
            </Container>
        )
    } else if (inputMode === INPUT_MODE_FILE) {
        let existingFileWidget = [];
        if (policy && policy.filePath) {
            existingFileWidget = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12 d-flex justify-content-between align-items-center"}>

                                        <div>
                                            <strong>Existing File</strong><br />
                                            <small>{UrlUtil.getLastPathPart(policy.filePath)}</small>
                                        </div>


                                        <span className={"btn btn-primary"} onClick={() => launchExistingFile()}>View</span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        editorElem = [
            existingFileWidget,

            <Container className={"row mt-4"}>
                <Container className={"col-12 justify-content-center"}>
                    <File
                        name={"file"}
                        label={"Upload file"}
                        multiSelect={false}
                        preview={false}
                        containerClassName={"text-center"}
                        className={"text-center"}
                        buttonClassName={"btn btn-primary"}
                        mandatory={fileMandatory} />
                </Container>
            </Container>
        ];
    }

    let buttonElem = (<Button className={"btn btn-success"} label={"Submit"} />);
    if (submitNetworkInFlight) {
        buttonElem = (
            <div className={"progress"}>
                <div className={"progress-bar bg-primary"} style={{width : submitProgress + "%"}} />
            </div>
        )
    }

    let deleteButtonElem = [];
    if (policy) {
        deleteButtonElem = (
            <div className={"row mb-2"}>
                <div className={"col-12 text-right"}>
                    <button className={"btn btn-danger"} onClick={() => promptToDelete()}>Delete</button>
                </div>
            </div>
        )
    }

    return (
        <div className={"app-section policy-editor-screen mt-4"}>

            <div className={"row justify-content-center"}>
                <div className={"col-12 col-lg-10 col-xl-8"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <Former
                                state={formState}
                                callback={formDidCallback}>

                                {deleteButtonElem}

                                <Container className={"row"}>
                                    <Container className={"col-12"}>

                                        <Text
                                            name={"title"}
                                            label={"Title"}
                                            className={"form-control"}
                                            mandatory={true} />

                                    </Container>
                                </Container>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12"}>

                                        <RadioList
                                            name={"requiresAcceptance"}
                                            label={"Requires acceptance"}
                                            className={"form-control"}
                                            mandatory={true}>
`
                                            <Radio value={1}>Yes, users must accept to these terms</Radio>
                                            <Radio value={0}>No, this is an informative policy</Radio>

                                        </RadioList>

                                    </Container>
                                </Container>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <SegmentedButtonComponent
                                            buttons={[
                                                {
                                                    id: INPUT_MODE_TEXT,
                                                    label: "Text"
                                                },
                                                {
                                                    id: INPUT_MODE_URL,
                                                    label: "URL/Link"
                                                },
                                                {
                                                    id : INPUT_MODE_FILE,
                                                    label : "Upload file"
                                                }
                                            ]}
                                            selectedId={inputMode}
                                            callback={setInputMode}/>
                                    </div>
                                </div>

                                {editorElem}

                                <Container className={"row mt-4"}>
                                    <Container className={"col-12 text-center"}>
                                        {buttonElem}
                                    </Container>
                                </Container>

                            </Former>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}