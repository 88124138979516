export const DataManager = {
    _callbacks : [],
    keys : {
        sessionToken :  "sessionToken",
        user : "user",
        organisationId : "qinotify_db.organisationId",
        organisationImages : "qinotify_db.organisation_images",
        organisationTheme : "qinotify_db.organisation_theme"
    },
    getSessionToken : () => {
        return DataManager.getItem(DataManager.keys.sessionToken, null);
    },
    setSessionToken : (token) => {
        return DataManager.setItem(DataManager.keys.sessionToken, token);
    },
    getUser : () => {
        return DataManager.getJsonItem(DataManager.keys.user);
    },
    setUser : (user) => {
        return DataManager.setJsonItem(DataManager.keys.user, user);
    },
    getOrganisationId : () => {
        return DataManager.getItem(DataManager.keys.organisationId, null);
    },
    setOrganisationId : (organisationId) => {
        return DataManager.setItem(DataManager.keys.organisationId, organisationId);
    },
    getOrganisationTheme : () => {
        return DataManager.getItem(DataManager.keys.organisationTheme);
    },
    setOrganisationTheme : (theme) => {
        return DataManager.setItem(DataManager.keys.organisationTheme, theme);
    },
    getOrganisationImages : () => {
        return DataManager.getJsonItem(DataManager.keys.organisationImages);
    },
    setOrganisationImages : (images) => {
        return DataManager.setJsonItem(DataManager.keys.organisationImages, images);
    },
    getFCMTokenUploaded : () => {
        return parseInt(DataManager.getItem(DataManager.keys.fcmTokenUploaded, "0")) === 1;
    },
    setFCMTokenUploaded : (value) => {
        return DataManager.setItem(DataManager.keys.fcmTokenUploaded, value ? "1" : "0");
    },
    getSecureLoginData : () => {
        return DataManager.getJsonItem("qinotify_db.secure_login_data");
    },
    setSecureLoginData : (data) => {
        return DataManager.setJsonItem("qinotify_db.secure_login_data", data);
    },
    clearSecureLoginData : () => {
        return DataManager.remove("qinotify_db.secure_login_data");
    },
    getItem : (keyName, defaultValue) => {
        if (defaultValue === undefined) defaultValue = null;

        let item = sessionStorage.getItem(keyName);
        if (item != null) {
            return item;
        }
        return defaultValue;
    },
    setItem : (keyName, value) => {
        sessionStorage.setItem(keyName, value);
        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    getJsonItem : (keyName) => {
        let item = DataManager.getItem(keyName);
        if (item != null) {
            try {
                item = JSON.parse(item);
                return item;
            } catch (e) {
                console.log(e);
            }
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        return DataManager.setItem(keyName, JSON.stringify(item));
    },
    remove : (key) => {
        localStorage.removeItem(key);
        DataManager._triggerCallbacks(key, undefined);
        return true;
    },
    clear : (triggerCallbacks) => {
        if (triggerCallbacks === undefined) {
            triggerCallbacks = true;
        }

        for (const [key, value] of Object.entries(DataManager.keys)) {
            sessionStorage.removeItem(value);

            if (triggerCallbacks) {
                DataManager._triggerCallbacks(value, undefined);
            }
        }

        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    }
}
