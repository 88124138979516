import React from 'react';
import BaseComponent from "../BaseComponent";

import "./UserDetailComponent.css";
import PasswordChangeModalComponent from "./PasswordChangeModalComponent";
import AlertModal from "../common/AlertModal";
import {DataManager} from "../../util/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {CommonUtil} from "../../util/CommonUtil";
import {Navigator} from "../../util/Navigator";
import {Toast} from "../toast/TokyoToaster";

export default class UserDetailComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let user = this.getUser();

        this.initState({
            user,
            passwordModalOpen : false,
            modalOpen : false,
            secureLoginData : DataManager.getSecureLoginData()
        });
    }

    componentDidMount() {
        this.props.title("User Details");
    }

    showPasswordModal = () => {
        this.setState({
            passwordModalOpen : true
        });
    }

    passwordModalDidDismiss = (result) => {
        let alertMessage = undefined;
        if (result !== undefined) {
            if (result) {
                alertMessage = "Successfully changed password"
            }
        }

        this.setState({
            passwordModalOpen : false,
            alertMessage
        });
    }

    dismissAlertModal = () => {
        this.setState({
            alertMessage : undefined
        });
    }

    promptToSignOut = () => {
        this.showModal("Sign Out", "Please confirm you wish to sign out", [
            {
                label : "Cancel",
                style : "btn-secondary",
                click : () => {
                    this.hideModal();
                }
            },
            {
                label : "Sign Out",
                style : "btn-danger",
                click : () => {
                    this.props.onLogout();
                    this.hideModal();
                }
            }
        ])
    }

    showModal = (title, message, buttons) => {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    promptTwoFactorAuthenticationToggle = () => {
        let message = "By enabling Two Factor Authentication you will be prompted to provide a code when logging into the QI Notify system.";
        let buttonLabel = "Turn on now";
        let buttonClass = "success";
        let state = 1;
        if (parseInt(this.state.user.twoFactorEnabled) === 1) {
            message = "By disabling Two Factor Authentication you leave your account vulnerable to compromise. Please consider keeping Two Factor Authentication turned on.";
            buttonLabel = "Turn off now";
            buttonClass = "danger"
            state = 0;
        }

        AlertModal.show(
            "Two Factor Authentication",
            message,
            [
                AlertModal.button(
                    buttonLabel,
                    () => {
                        this.submitTwoFactorAuthenticationStateOverNetwork(state);
                        AlertModal.dismiss();
                    },
                    buttonClass
                ),
                AlertModal.button(
                    "Cancel",
                    () => {
                        AlertModal.dismiss();
                    }
                )
            ]
        )
    }

    submitTwoFactorAuthenticationStateOverNetwork = (state) => {
        if (this.state.tfaNetworkInFlight) return;
        this.setState({ tfaNetworkInFlight : true });

        const formData = new FormData();
        formData.append("state", state);

        Axios.post(ENDPOINTS.user.setTwoFactorAuthenticationState, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.user) {
                        const newUser = CommonUtil.cloneObject(this.state.user);
                        newUser.twoFactorEnabled = resp.data.user.twoFactorEnabled;
                        this.setState({
                            user : newUser
                        });
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                this.setState({ tfaNetworkInFlight : false });
            })
            .catch((e) => {
                AlertModal.showError(API.defaultError("TSS1000C"));
            });
    }

    promptSecureLoginToggle = () => {
        if (!!this.state.secureLoginData) {
            AlertModal.show(
                "Secure Login",
                "Please confirm you would like to turn off Secure Login.",
                [
                    AlertModal.button(
                        "Turn off now",
                        () => {
                            this.disableSecureLoginOverNetwork();
                            DataManager.clearSecureLoginData();
                            this.setState({
                                secureLoginData : null
                            });
                            AlertModal.dismiss();
                        },
                        "danger"
                    ),
                    AlertModal.button(
                        "Cancel",
                        () => {
                            AlertModal.dismiss();
                        }
                    )
                ]
            )
        } else {
            Navigator.navigate("/secure-login-enroll#r=user-details");
        }
    }

    disableSecureLoginOverNetwork = () => {
        if (!!this.state.secureLoginData) {
            const formData = new FormData();
            formData.append("identifier", this.state.secureLoginData.identifier);

            Axios.post(ENDPOINTS.user.disableSecureLogin, formData)
                .then((r) => {
                    const resp = API.parse(r);
                    console.log("Server result: " + resp.success);
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    }

    submitNotificationStateOverNetwork = (state) => {
        if (this.state.notificationNetworkInFlight) return;
        this.setState({notificationNetworkInFlight : true});

        const formData = new FormData();
        formData.append("state", state);

        Axios.post(ENDPOINTS.user.setNotificationsEnabled, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let user = resp.data.user
                    DataManager.setUser(user);

                    this.setState({
                        user
                    });

                    let messageLabel = "Notifications enabled";
                    if (state === "0") {
                        messageLabel = "Notifications disabled";
                    }

                    Toast.show(
                        "Success",
                        messageLabel,
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                this.setState({notificationNetworkInFlight : false});
            })
            .catch((e) => {
                console.log(e);
                this.setState({notificationNetworkInFlight : false});
                AlertModal.showError(API.defaultError("UNS1000C"));
            });
    }

    render() {
        let alertElem = [];
        if (this.state.alertMessage !== undefined) {
            alertElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="alert alert-success clickable" role="alert" onClick={this.dismissAlertModal}>
                            {this.state.alertMessage}
                        </div>
                    </div>
                </div>
            )
        }

        console.log("SLD: ", this.state.secureLoginData);

        let twoFactorBadge = (<span className={"badge badge-success"}>On</span>);
        let twoFactorButton = (<button className={"btn btn-danger"} onClick={() => this.promptTwoFactorAuthenticationToggle()}>Turn off</button>);
        if (this.state.user.hasOwnProperty("twoFactorEnabled") && parseInt(this.state.user.twoFactorEnabled) === 0) {
            twoFactorBadge = (<span className={"badge badge-danger"}>Off</span>);
            twoFactorButton = (<button className={"btn btn-success"} onClick={() => this.promptTwoFactorAuthenticationToggle()}>Turn on</button>)
        }

        let secureLoginBadge = (<span className={"badge badge-success"}>On</span>);
        let secureLoginButton = (<button className={"btn btn-danger"} onClick={() => this.promptSecureLoginToggle()}>Turn off</button>);
        if (!this.state.secureLoginData) {
            secureLoginBadge = (<span className={"badge badge-danger"}>Off</span>)
            secureLoginButton = (<button className={"btn btn-success"} onClick={() => this.promptSecureLoginToggle()}>Turn on</button>);
        }

        let notificationsBadge = (<span className={"badge badge-success"}>On</span>);
        let notificationsButton = (<button className={"btn btn-danger"} onClick={() => this.submitNotificationStateOverNetwork("0")}>Turn off</button>)
        if (this.state.user.hasOwnProperty("notificationsEnabled") && parseInt(this.state.user.notificationsEnabled) === 0) {
            notificationsBadge = (<span className={"badge badge-danger"}>Off</span>);
            notificationsButton = (<button className={"btn btn-success"} onClick={() => this.submitNotificationStateOverNetwork("1")}>Turn on</button>);
        }

        return (
            <div className={"user-detail-component"}>
                {alertElem}

                <div className={"row"}>
                    <div className={"col-8"}>
                        <h2>Personal Details</h2>
                    </div>

                    <div className={"col-4 text-right"}>
                        <button className={"btn btn-danger"} onClick={this.promptToSignOut}>Sign Out</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card user-details"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>First Name</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.givenName} readOnly />
                                    </div>

                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Last Name</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.familyName} readOnly/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Role</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.role} readOnly/>
                                    </div>

                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Email</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.emailAddress} readOnly/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 pb-2"}>
                                        <button className={"btn btn-success"} onClick={this.showPasswordModal}>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h2>Account Security</h2>
                    </div>
                </div>

                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                Two Factor Authentication {twoFactorBadge}
                            </div>

                            <div className={"card-body"}>
                                <p>Enhanced login security by requiring the entry of a code when logging in.</p>
                                <p>
                                    {twoFactorButton}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                Secure Login {secureLoginBadge}
                            </div>

                            <div className={"card-body"}>
                                <p>Quickly and securely login to your QI Notify account with your fingerprint, face or pin.</p>
                                <p>
                                    {secureLoginButton}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                Notifications {notificationsBadge}
                            </div>

                            <div className={"card-body"}>
                                <p>Receive Notifications related to your QI Notify account like updates to Case Records.</p>
                                <p>
                                    {notificationsButton}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <PasswordChangeModalComponent
                    open={this.state.passwordModalOpen}
                    dismissCallback={this.passwordModalDidDismiss} />

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons}
                    dismissHandler={this.hideModal} />
            </div>
        )
    }
}