import React from "react";
import BaseComponent from "./BaseComponent";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {API, ENDPOINTS} from "../network/API";
import LoginComponent from "./login/LoginComponent";
import TemplateEditorComponent from "./debug/TemplateEditorComponent";
import SideNavigationComponent from "./common/SideNavigationComponent";
import TopBarComponent from "./common/TopBarComponent";
import CaseRecordEditorLandingComponent from "./caseRecord/editor/CaseRecordEditorLandingComponent";
import CaseRecordEditorFormComponent from "./caseRecord/editor/CaseRecordEditorFormComponent";
import CaseRecordDatabaseComponent from "./caseRecord/CaseRecordDatabaseComponent";
import UserAccountsComponent from "./users/UserAccountsComponent";
import ViewUserComponent from "./users/ViewUserComponent";
import history from 'history/browser';

import logo from "../assets/qinotify/img/logo.png";
import NewUserComponent from "./users/NewUserComponent";
import PasswordResetComponent from "./login/PasswordResetComponent";
import ForgottenPasswordComponent from "./login/ForgottenPasswordComponent";
import LocationLandingComponent from "./location/LocationLandingComponent";
import LocationDetailComponent from "./location/LocationDetailComponent";
import TemplateEditorLanding from "./debug/TemplateEditorLanding";
import ReportsLandingComponent from "./reports/ReportsLandingComponent";
import UserDetailComponent from "./users/UserDetailComponent";
import {DataManager} from "../util/DataManager";
import {OrganisationListScreen} from "./organisation/OrganisationListScreen";
import {ThemeUtil} from "../util/ThemeUtil";
import AlertModal from "./common/AlertModal";
import {OrganisationManagerScreen} from "./organisation/OrganisationManagerScreen";
import {OrganisationEditorScreen} from "./organisation/editor/OrganisationEditorScreen";
import {TokyoToaster} from "./toast/TokyoToaster";
import {SystemAdminUsersScreen} from "./users/SystemAdminUsersScreen";
import {SecureLoginPromptScreen} from "./login/SecureLoginPromptScreen";
import {InitNavigator, Navigator} from "../util/Navigator";
import {OrganisationPolicyEditorScreen} from "./organisation/editor/OrganisationPolicyEditorScreen";
import {UserApprovalDetailScreen} from "./users/approvals/UserApprovalDetailScreen";
import Axios from "axios";
import {
    OrganisationPolicyAcceptanceActions,
    OrganisationPolicyAcceptanceModal
} from "./organisation/OrganisationPolicyAcceptanceModal";
import {BaseModalActions} from "./alertmodal/BaseModal";
import {SystemSettingsScreen} from "./settings/SystemSettingsScreen";
import {Signal} from "../util/Signal";
import {ClinicalStandardLandingScreen} from "./clinicalStandards/ClinicalStandardLandingScreen";
import {OrganisationSegmentConfigurationScreen} from "./organisation/caseRecordConfig/OrganisationSegmentConfigurationScreen";
import {CaseRecordManualEntryScreen} from "./caseRecord/editor/CaseRecordManualEntryScreen";


class MainComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            user : null,
            organisationId : DataManager.getOrganisationId(),
            sessionToken : null,
            primaryContentMinHeight : 0,
            applyWidthFix : false,
            modalState : { open : false },
            acceptPolicies : []
        });

        this.onUserLogin = this.onUserLogin.bind(this);
        this.onUserLogout = this.onUserLogout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setTitle = this.setTitle.bind(this);

        this.dataManagerListener = (key, value) => {
            if (key === DataManager.keys.organisationId) {
                API.setOrganisationId(value);
                this.setState({
                    organisationId : value
                });

                if (value) {
                    this.fetchAcceptanceOrganisationPoliciesFromNetwork();
                }
            } else if (key === DataManager.keys.organisationTheme) {
                ThemeUtil.setThemeCSS(value);
            }
        };
        DataManager.addCallback(this.dataManagerListener);

        const organisationId = DataManager.getOrganisationId();
        if (organisationId) {
            API.setOrganisationId(organisationId);
        }

        if (!window.hasOwnProperty("widthFixCallback")) {
            setTimeout(() => {
                window.widthFixCallback = this.windowFixCallback;
            }, 10);
        }
    }

    componentDidMount() {
        let user = DataManager.getUser();
        let sessionToken = DataManager.getSessionToken();

        if (user !== null && sessionToken !== null) {
            this.onUserLogin(user, sessionToken);
        }

        API.onAuthFailed(() => {
            console.log("AUTH FAILURE!");
            this.onUserLogout();

            setTimeout(() => {
                //window.location.href = "/"; // Force page reload
                Navigator.navigate("/");
            }, 50);
        })

        if (DataManager.getOrganisationTheme()) {
            ThemeUtil.setThemeCSS(DataManager.getOrganisationTheme());
        }

        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions();

        window._showModal = (title, message, buttons, icon) => {
            const modalState = {
                open : true,
                title,
                message,
                buttons,
                icon
            };

            this.setState({
                modalState
            });
        }

        window._dismissModal = () => {
            this.setState({
                modalState : { open : false }
            });
        }

        // Initialise Signal now
        Signal.init();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.user !== prevState.user && this.state.user) {
            this.fetchAcceptanceOrganisationPoliciesFromNetwork();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (this.dataManagerListener) {
            DataManager.removeCallback(this.dataManagerListener);
        }
    }

    updateWindowDimensions() {
        this.setState({
            primaryContentMinHeight : window.innerHeight - 50
        });
    }

    /**
     * Callback for Window Fix
     *
     * Sometimes the calculation of available screen space fails for whatever reason
     * and causes the timeline to overflow on the Case Record Form screen. There was
     * a fix, but it had a knock-on effect on other screens. So instead we now just
     * request that the fix be applied when its needed rather than just apply it broadly.
     *
     * @param applyFix
     */
    windowFixCallback = (applyFix) => {
        this.setState({
            applyWidthFix : applyFix
        });
    }

    /**
     * Login callback handler
     * @param user              User object returned from API
     * @param sessionToken      Session Token returned from API
     */
    onUserLogin(user, sessionToken) {
        console.log("onUserLogin!", user);
        DataManager.setUser(user);

        if (sessionToken !== undefined) {
            DataManager.setSessionToken(sessionToken);
            API.setAuthenticationToken(sessionToken);
        }

        this.setState({
            user : user,
            topBarTitle : null,
            topBarBackButtonVisible : false,
            topBarBackButtonTo : null
        });
    }

    setTitle(title, options) {
        let backButtonVisible = false;
        let backButtonTo = null;
        if (options !== undefined) {
            if (options.hasOwnProperty("backButton")) {
                let backButton = options.backButton;
                if (backButton.hasOwnProperty("visible")) {
                    backButtonVisible = backButton.visible;
                }

                if (backButton.hasOwnProperty("to")) {
                    backButtonTo = backButton.to;
                }
            }
        }

        this.setState({
            topBarTitle : title,
            topBarBackButtonVisible : backButtonVisible,
            topBarBackButtonTo : backButtonTo
        });
    }

    /**
     * General logout handler. Clears up user data and state.
     */
    onUserLogout() {
        DataManager.clear(false);

        API.setAuthenticationToken(null);
        this.setState({ user : null });

        window.location.href = "/"; // Yes, force a refresh
    }

    organisationPolicyAcceptanceModalDidCallback = (action, data) => {
        if (action === BaseModalActions.CLOSE) {
            if (data) {
                this.setState({
                    acceptPolicies : data
                });
            }
        } else if (action === OrganisationPolicyAcceptanceActions.SIGN_OUT) {
            this.onUserLogout();
        }
    }

    fetchAcceptanceOrganisationPoliciesFromNetwork = () => {
        if (this.state.acceptPoliciesNetworkInFlight) return;
        this.setState({acceptPoliciesNetworkInFlight : true});

        const organisationId = DataManager.getOrganisationId();

        const formData = new FormData();
        if (organisationId) {
            formData.append("organisationId", organisationId);
        }

        Axios.post(ENDPOINTS.organisation.getOrganisationPoliciesRequiringAction, formData)
            .then((r) => {
                const newState = {
                    acceptPoliciesNetworkInFlight : false
                };

                const resp = API.parse(r);
                if (resp.success) {
                    newState.acceptPolicies = resp.data.data;
                } else {
                    console.log(API.formatError(resp));
                }
                this.setState(newState);
            })
            .catch((e) => {
                console.log(e);
                this.setState({acceptPoliciesNetworkInFlight : false});
            });
    }

    render() {
        if (this.state === null) {
            return [];
        }

        if (this.state.user == null) {
            return (
                <>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path={"/"} exact
                                render={(props) =>
                                    <LoginComponent
                                        onLoginCallback={this.onUserLogin} />
                                } />

                            <Route
                                path={"/forgot"} exact
                                render={(props) =>
                                    <ForgottenPasswordComponent {...props} />
                                } />

                            <Route
                                path={"/forgotten/:token"}
                                render={(props) =>
                                    <PasswordResetComponent {...props} />
                                } />
                        </Switch>
                    </BrowserRouter>

                    <AlertModal
                        open={this.state.modalState.open}
                        title={this.getOrDefault(this.state.modalState, "title", "")}
                        content={this.getOrDefault(this.state.modalState, "message", "")}
                        buttons={this.getOrDefault(this.state.modalState, "buttons", [])}
                        dismissHandler={() => AlertModal.dismiss()} />
                </>

            )
        } else {
            let widthFixClass = "";
            console.log("Main Component Render: applyWidthFix: " + (this.state.applyWidthFix ? "TRUE" : "FALSE"));
            if (this.state.applyWidthFix) {
                console.log("Applying style...");
                widthFixClass = " width-fix";
            }

            let routes = [
                <Route
                    path={"/"} exact
                    render={(props) =>
                        <CaseRecordDatabaseComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/create/:id?"} exact
                    render={(props) =>
                        <CaseRecordEditorLandingComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/create/segment/:index/"}
                    render={(props) =>
                        <CaseRecordEditorFormComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/debug_template_editor/"} exact
                    render={(props) =>
                        <TemplateEditorLanding title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/debug_template_editor/:templateId/:segmentId?"}
                    render={(props) =>
                        <TemplateEditorComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/users"} exact
                    render={(props) =>
                        <UserAccountsComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/users/view/:id?"}
                    render={(props) =>
                        <ViewUserComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/users/new"} exact
                    render={(props) =>
                        <NewUserComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/locations"} exact
                    render={(props) =>
                        <LocationLandingComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/locations/view/:id"} exact
                    render={(props) =>
                        <LocationDetailComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/reports/:section?"}
                    render={(props) =>
                        <ReportsLandingComponent title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/user-details"} exact
                    render={(props) =>
                        <UserDetailComponent title={this.setTitle} onLogout={this.onUserLogout} {...props} />
                    } />,

                <Route
                    path={"/organisations"} exact
                    render={(props) =>
                        <OrganisationListScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/manage-organisations"} exact
                    render={(props) =>
                        <OrganisationManagerScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/manage-organisation/:organisationId"}
                    render={(props) =>
                        <OrganisationEditorScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/organisation-policy/:organisationId/:id"}
                    render={(props) =>
                        <OrganisationPolicyEditorScreen title={this.setTitle} {...props} />
                    } />,


                <Route
                    path={"/manage-system-admins/"}
                    render={(props) =>
                        <SystemAdminUsersScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/secure-login-enroll"}
                    render={(props) =>
                        <SecureLoginPromptScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/user-approval/:id"}
                    render={(props) =>
                        <UserApprovalDetailScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/system-settings/"}
                    render={(props) =>
                        <SystemSettingsScreen title={this.title} {...props} />
                    } />,

                <Route
                    path={"/clinical-standards/"}
                    render={(props) =>
                        <ClinicalStandardLandingScreen title={this.title} {...props} />
                    } />,

                <Route
                    path={"/organisation-configuration"}
                    render={(props) =>
                        <OrganisationSegmentConfigurationScreen title={this.setTitle} {...props} />
                    } />,

                <Route
                    path={"/record-manual-entry/:caseRecordId"}
                    render={(props) =>
                        <CaseRecordManualEntryScreen title={this.setTitle} {...props} />
                    } />
            ];

            let sideBarMode = SideNavigationComponent.MODE_FULL;
            if (!this.state.user.organisationId) {
                if (!this.state.organisationId) {
                    sideBarMode = SideNavigationComponent.MODE_NONE;

                    routes = [
                        <Route
                            path={"/secure-login-enroll"}
                            render={(props) =>
                                <SecureLoginPromptScreen title={this.setTitle} {...props} />
                            } />,

                        <Route
                            path={"/"}
                            render={(props) =>
                                <OrganisationListScreen title={this.setTitle}  {...props} />
                        } />
                    ]
                }
            }

            return(
                <>
                    <BrowserRouter>

                        <InitNavigator />

                        <TokyoToaster>

                            <TopBarComponent
                                title={this.state.topBarTitle}
                                backButtonVisible={this.state.topBarBackButtonVisible}
                                backButtonTo={this.state.topBarBackButtonTo} />

                            <div className={"primary-content-container" + widthFixClass} style={{minHeight : this.state.primaryContentMinHeight}}>
                                <SideNavigationComponent mode={sideBarMode} />
                                <div className={"container-fluid"}>
                                    <Switch>
                                        {routes}
                                    </Switch>
                                </div>
                            </div>

                            <OrganisationPolicyAcceptanceModal
                                policies={this.state.acceptPolicies}
                                callback={this.organisationPolicyAcceptanceModalDidCallback} />

                        </TokyoToaster>

                    </BrowserRouter>

                    <AlertModal
                        open={this.state.modalState.open}
                        title={this.getOrDefault(this.state.modalState, "title", "")}
                        content={this.getOrDefault(this.state.modalState, "message", "")}
                        buttons={this.getOrDefault(this.state.modalState, "buttons", [])}
                        dismissHandler={() => AlertModal.dismiss()} />
                </>
            )
        }
    }

}

export default MainComponent;