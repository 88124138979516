import React from 'react';
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./ClinicalRoleSelectionDialog.css";

export default class ClinicalRoleSelectionDialog extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            selectedClinicalRoleId : -1,
            clinicalRolesNetworkInFlight: false,
            clinicalRoles : [],
            error : undefined
        });
    }

    componentDidMount() {
        this.fetchClinicalRolesFromNetwork();
    }

    fetchClinicalRolesFromNetwork = () => {
        if (this.state.clinicalRolesNetworkInFlight) return;

        this.setState({
            clinicalRolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.caseRecord.getClinicalRoles)
            .then((r) => {
                let clinicalRoles = [];

                let resp = API.parse(r);
                if (resp.success) {
                    clinicalRoles = resp.data.clinicalRoles;
                } else {
                    console.log(resp.error);
                }

                let selectedClinicalRoleId = this.state.selectedClinicalRoleId;
                if (selectedClinicalRoleId === -1 && clinicalRoles.length > 0) {
                    selectedClinicalRoleId = clinicalRoles[0].id;
                }

                this.setState({
                    clinicalRolesNetworkInFlight : false,
                    clinicalRoles,
                    selectedClinicalRoleId
                });
            })
            .then((e) => {
                console.log(e);
            });
    }

    onSubmit = () => {
        if (parseInt(this.state.selectedClinicalRoleId) <= 0) {
            this.setState({
                error : "Please select a Clinical Role"
            });
        } else {
            if (this.props.callback !== undefined) {
                console.log("Calling callback...");
                this.props.callback(this.state.selectedClinicalRoleId);
            }
        }
    }

    onCancel = () => {
        if (this.props.callback !== undefined) {
            this.props.callback(undefined);
        }
    }

    render() {
        if (!this.props.shown) {
            return [];
        }

        let label = "Please select a Clinical Role";
        if (this.props.label !== undefined) {
            label = this.props.label;
        }

        return (
            <div className={"clinical-role-selection-dialog-component"}>
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Select Clinical Role</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className={"row"}>
                                    <p>{label}</p>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>Clinical Role</label>
                                        <select className={"form-control"} name={"selectedClinicalRoleId"} onChange={this.handleChange}>
                                            {
                                                this.state.clinicalRoles.map((role) => (
                                                    <option value={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                                <button type="button" className="btn btn-secondary" onClick={this.onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}