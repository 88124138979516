import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import {Chronos} from "../../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";
import {ImageUtil} from "../../../util/ImageUtil";

import "./OrganisationPolicyEditorComponent.css";

import iconFile from "../../../assets/qinotify/img/icon_file.svg";

export const OrganisationPolicyListActions = {
    NEW : "orgpolicyed.new",
    CLICK : "orgpolicyed.click"
}

export const OrganisationPolicyListComponent = (props) => {

    const {organisationId} = props;
    const {callback} = props;

    const [policies, setPolicies] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchPoliciesFromNetwork();
    }, []);

    useEffect(() => {
        fetchPoliciesFromNetwork();
    }, [organisationId]);

    function fetchPoliciesFromNetwork() {
        if (networkInFlight || !organisationId) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);

        Axios.post(ENDPOINTS.organisation.getOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPolicies(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }

    // RENDER

    let policyElems = [];
    policies.forEach((policy) => {
        let useDate = policy.dateCreated;
        if (policy.dateUpdated !== null) {
            useDate = policy.dateUpdated;
        }

        policyElems.push(
            <li className={"list-group-item policy-item clickable"} onClick={() => CommonUtil.safeCallback(callback, OrganisationPolicyListActions.CLICK, policy)}>
                <div className={"icon"} style={{backgroundImage : ImageUtil.background(iconFile)}} />

                <div className={"content"}>
                    <div><strong>{policy.title}</strong></div>
                    <div>Last Updated: {Chronos.withTimestampSeconds(useDate).format("dd/MM/yyyy HH:mm")}</div>
                </div>

                <button className={"btn btn-success"}>View</button>
            </li>
        )
    })

    if (networkInFlight) {
        policyElems.push(
            <li className={"list-group-item text-center"}>
                <LoadingSpinner inline={true} small={true} />
            </li>
        )
    }

    return (
        <div className={"row mt-4 justify-content-center organisation-policy-list"}>
            <div className={"col-12 col-lg-10 col-xl-8"}>

                <div className={"card"}>
                    <div className={"card-header d-flex justify-content-between"}>
                        <h3>Policy Documents</h3>

                        <span className={"btn btn-primary"} onClick={() => CommonUtil.safeCallback(callback, OrganisationPolicyListActions.NEW)}>New Policy</span>
                    </div>

                    <div className={"card-body"}>

                        <div className={"row"}>
                            <div className={"col-12"}>

                                <ul className={"list-group"}>
                                    {policyElems}
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )

}