import Axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {CommonUtil} from "./CommonUtil";

export const AnalyticsTags = {
    TAG_MANUAL_ENTRY_NHS_ENTRY_SUCCESS : "case_record.manual_entry_nhs_entry_success",
    TAG_MANUAL_ENTRY_NHS_ENTRY_FAILURE : "case_record.manual_entry_nhs_entry_failure"
};

export const Analytics = {
    track : (tag, metadata) => {
        if (tag) {
            const data = new FormData();
            data.append("tag", tag);
            if (metadata) {
                if (CommonUtil.isObject(metadata)) {
                    metadata = JSON.stringify(metadata);
                }
                data.append("metadata", metadata);
            }

            Axios.post(ENDPOINTS.analytics.track, data)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        console.log("ANALYTICS: Tracked tag: " + tag, metadata);
                    } else {
                        console.log("ANALYTICS ERROR! " + API.formatError(resp));
                    }
                })
                .catch((e) => {
                    console.log("ANALYTICS ERROR!", e);
                });
        }
    }
}