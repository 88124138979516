import React from "react";
import BaseComponent from "../BaseComponent";

import "./TemplateEditorComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import { v4 as uuidv4 } from "uuid";
import {DataType} from "../../entity/DataType";
import {Chronos} from "../../entity/Chronos";
import {SectionItem} from "../../entity/SectionItem";
import {TemplateEditorSectionSelectorModal} from "./TemplateEditorSectionSelectorModal";
import {BaseModalActions} from "../alertmodal/BaseModal";
import {CommonUtil} from "../../util/CommonUtil";
import {Toast} from "../toast/TokyoToaster";

class TemplateEditorComponent extends BaseComponent {

    MODE_ITEMS = 1;
    MODE_INPUTS = 2;

    constructor(props, context) {
        super(props, context);

        const templateId = this.getUriProperty("templateId", null);
        const segmentId = this.getUriProperty("segmentId", null);

        console.log("TID: " + templateId + " :: SID: " + segmentId);

        this.initState({
            templateId,
            segmentId,
            templateData : null,
            submitNetworkSuccessDate : null,
            submitNetworkError : null,
            floatControls : false,
            currentMode : this.MODE_ITEMS,
            sectionSelectorShown : false,
            sectionSelectorSections : [],
            selectedItems : []
        });

        this.fetchTemplateFromNetwork = this.fetchTemplateFromNetwork.bind(this);
        this.onTemplateLoadClicked = this.onTemplateLoadClicked.bind(this);
        this.processInputTemplate = this.processInputTemplate.bind(this);
        this.getSection = this.getSection.bind(this);
        this.getSectionProperty = this.getSectionProperty.bind(this);
        this.addSegment = this.addSegment.bind(this);
        this.addSection = this.addSection.bind(this);
        this.onTemplateSaveClicked = this.onTemplateSaveClicked.bind(this);
    }

    componentDidMount() {
        this.props.title("Template Editor", {
            backButton : {
                visible : true,
                to : "/"
            }
        });

        if (this.state.segmentId != null) {
            this.fetchTemplateFromNetwork(this.state.templateId, this.state.segmentId);
        } else {
            this.addSegment();
        }

        window.addEventListener('scroll', this.updateWindowDimensions);
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.updateWindowDimensions);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = (event) => {
        let scrollTop = event.target.scrollingElement.scrollTop;
        let floatControls = false;
        if (scrollTop > 250) {
            floatControls = true;
        }

        this.setState({
            floatControls : floatControls
        });
    }

    onTemplateLoadClicked() {
        if (this.state.templateId != null) {
            this.fetchTemplateFromNetwork(this.state.templateId);
        }
    }

    modeDidChange = (mode) => {
        this.setState({
            currentMode : mode
        });
    }

    fetchTemplateFromNetwork(id, segmentId) {
        if (id !== "") {
            let formData = new FormData();
            formData.append("id", id);
            formData.append("segmentId", segmentId);

            Axios.post(ENDPOINTS.debug.getTemplate, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.setState({
                            templateData: this.processInputTemplate(resp.data)
                        });
                    } else {
                        console.log("ERROR! " + resp.error.desc);
                    }
                })
                .catch((e) => {
                    console.log("EXCEPTION!");
                    console.log(e);
                });
        }
    }

    onTemplateSaveClicked() {
        this.submitTemplateOverNetwork();
    }

    submitTemplateOverNetwork() {
        if (this.state.submitNetworkInFlight) return;
        this.setState({
            submitNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("templateId", this.state.templateId);
        formData.append("data", JSON.stringify(this.state.templateData));

        Axios.post(ENDPOINTS.debug.submitTemplate, formData)
            .then((r) => {
                let success = false;
                let templateData = this.state.templateData;
                let resp = API.parse(r);

                if (this.state.templateData.length > 0) {
                    this.setState({
                        templateId : resp.data[0].templateId
                    });
                }

                let error = null;

                if (resp.success) {
                    success = true;
                    templateData = resp.data;
                } else {
                    error = resp.data.desc;
                }

                this.setState({
                    submitNetworkInFlight : false,
                    submitNetworkSuccess : success,
                    submitNetworkSuccessDate : Chronos.now().format("dd/MM/yyyy HH:mm:ss"),
                    submitNetworkError : error,
                    templateData : this.processInputTemplate(templateData)
                });
            })
            .catch((e) => {
                this.setState({
                    submitNetworkInFlight : false,
                    submitNetworkError : "An unknown error has occurred. Please shout at Chris."
                });
            })
    }

    /**
     * Assigns local IDs to data received from network so it is suitable for local modification
     * @param data      Data to process
     * @returns {*}     Processed Data
     */
    processInputTemplate(data) {
        data.forEach((segment, index) => {
            segment.localId = uuidv4();

            if (segment.hasOwnProperty("sections")) {
                segment.sections.forEach((section, index) => {
                    section.localId = uuidv4();
                    section.segmentLocalId = segment.localId;

                    if (section.hasOwnProperty("items")) {
                        section.items.forEach((item) => {
                            item.localId = uuidv4();
                            item.sectionLocalId = section.localId;
                            item.segmentLocalId = segment.localId;

                            if (item.hasOwnProperty("outcomes")) {
                                item.outcomes.forEach((item) => {
                                    item.localId = uuidv4();
                                });
                            }
                        });
                    }

                    if (section.hasOwnProperty("inputs")) {
                        section.inputs.forEach((input) => {
                            input.localId = uuidv4();

                            if (input.hasOwnProperty("options")) {
                                input.options.forEach((option) => {
                                    option.localId = uuidv4();
                                });
                            }
                        });
                    }
                });
            }
        });

        return data;
    }

    itemIsSelected = (item, returnIndex) => {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < this.state.selectedItems.length; i++) {
            if (this.state.selectedItems[i].localId === item.localId) {
                if (returnIndex) {
                    return i;
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    itemWasSelected = (item) => {
        let selectedItems = [...this.state.selectedItems];

        let sectionIndex = this.itemIsSelected(item, true);
        if (sectionIndex !== false) {
            selectedItems.splice(sectionIndex, 1);
        } else {
            selectedItems.push(item);
        }

        console.log(selectedItems);

        this.setState({
            selectedItems : selectedItems
        });
    }

    summonSectionSelector = () => {
        let segment = null;
        if (this.state.templateData) {
            if (this.state.templateData.length > 0) {
                segment = this.state.templateData[0];
            }
        }

        console.log("Show Sections", segment);

        if (segment) {
            this.setState({
                sectionSelectorShown: true,
                sectionSelectorSections : segment.sections
            });
        }
    }

    sectionSelectorDidCallback = (action, data) => {
        if (action === BaseModalActions.CLOSE) {
            if (data) {
                this.moveItemsToSection(this.state.selectedItems, data);
            }

            this.setState({
                sectionSelectorShown : false,
                selectedItems : []
            });
        }
    }

    moveItemsToSection = (items, targetSection) => {
        console.log("MOVE ITEMS", items);
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            console.log("MOVE ITEM: " + item.localId + " :: " + item.segmentLocalId + " :: " + item.sectionLocalId, item);
            let currentSection = this.getSection(item.segmentLocalId, item.sectionLocalId);
            if (currentSection) {
                for (let x = 0; x < currentSection.items.length; x++) {
                    if (currentSection.items[x].localId === item.localId) {
                        console.log("Removed item: " + currentSection.items[x].localId + " :: Matched: " + item.localId);
                        currentSection.items.splice(x, 1);
                        break;
                    }
                }
            }

            console.log(currentSection.items);

            item.sectionLocalId = targetSection.localId;
            targetSection.items.push(item);
        }

        this.forceUpdate();
    }

    splitIntoSection = () => {
        if (this.state.selectedItems && this.state.selectedItems.length > 0) {
            let segmentId = this.state.selectedItems[0].segmentLocalId;
            let firstSection = this.getSection(segmentId, this.state.selectedItems[0].sectionLocalId);
            let sectionId = null;
            if (firstSection) {
                sectionId = firstSection.localId;
            }

            console.log("First item: ", this.state.selectedItems[0]);
            console.log("Section ID: " + sectionId);

            let newSection = this.addSection(segmentId, sectionId);
            if (newSection) {
                this.moveItemsToSection(this.state.selectedItems, newSection);

                Toast.show(
                    "Success",
                    "Moved items to new section",
                    Toast.SUCCESS,
                    Toast.LONG
                );
            }
        }

        this.setState({
            selectedItems : []
        });
    }

    getSegment(segmentLocalId) {
        for (let i = 0; i < this.state.templateData.length; i++) {
            if (this.state.templateData[i].localId === segmentLocalId) {
                return this.state.templateData[i];
            } 
        }
        return null;
    }

    getSection(segmentLocalId, sectionLocalId) {
        let segment = this.getSegment(segmentLocalId);
        if (segment != null) {
            for (let i = 0; i < segment.sections.length; i++) {
                if (segment.sections[i].localId === sectionLocalId) {
                    return segment.sections[i];
                }
            }
        }
        return null;
    }

    getSectionItem = (segmentLocalId, sectionLocalId, sectionItemLocalId) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            for (let x = 0; x < section.items.length; x++) {
                if (section.items[x].localId === sectionItemLocalId) {
                    return section.items[x];
                }
            }
        }
        return null;
    }

    getSectionProperty(segmentLocalId, sectionLocalId, propertyName) {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            if (section.hasOwnProperty(propertyName)) {
                return section[propertyName]
            }
        }
        return null;
    }

    getSectionItemProperty(segmentLocalId, sectionLocalId, sectionItemLocalId, propertyName) {
        let item = this.getSectionItem(sectionLocalId, sectionItemLocalId);
        if (item != null) {
            if (item.hasOwnProperty(propertyName)) {
                return item[propertyName];
            }
        }
        return null;
    }

    onSegmentPropertyChange(segmentLocalId, propertyName, value) {
        let segment = this.getSegment(segmentLocalId);
        if (segment != null) {
            segment[propertyName] = value;
            this.setState({
                templateData : this.state.templateData
            });
        }
    }

    addSegment() {
        let templateData = this.state.templateData;
        if (templateData == null) {
            templateData = [];
        }

        templateData.push({
            localId : uuidv4(),
            sections : [],
            results : []
        });
        this.setState({
            templateData : templateData
        });
    }

    onSectionPropertyChange(segmentLocalId, sectionLocalId, propertyName, value) {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            section[propertyName] = value;
            this.setState({
                templateData: this.state.templateData
            });
        }
    }

    addSection(segmentLocalId, sectionAfterLocalId) {
        let segment = this.getSegment(segmentLocalId);
        if (segment != null) {
            let position = -1;
            let ordering = 1;

            if (sectionAfterLocalId !== undefined) {
                let reorderItems = false;
                for (let i = 0; i < segment.sections.length; i++) {
                    if (segment.sections[i].localId === sectionAfterLocalId) {
                        position = i + 1;
                        ordering = parseInt(segment.sections[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }

                        reorderItems = true;

                        if (position >= segment.sections.length) {
                            position = -1;
                            ordering = 1;
                            reorderItems = false;
                        }
                    } else if (reorderItems) {
                        ordering = parseInt(segment.sections[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }
                    }
                }
            }

            if (position === -1 && segment.sections.length > 0) {
                ordering = segment.sections[segment.sections.length - 1].ordering;
            }

            let section = {
                localId : uuidv4(),
                title : "",
                ordering : ordering,
                items : []
            };

            if (position === -1) {
                segment.sections.push(section);
            } else {
                segment.sections.splice(position, 0, section);
            }

            this.setState({
                templateData : this.state.templateData
            });

            this.recalculateSectionOrder(segmentLocalId);

            return section;
        }

        return null;
    }

    recalculateSectionOrder = (segmentLocalId) => {
        let segment = this.getSegment(segmentLocalId);
        for (let i = 0; i < segment.sections.length; i++) {
            segment.sections[i].ordering = (i + 1);
        }

        this.setState({
            templateData : this.state.templateData
        });
    }

    onSectionItemPropertyChange = (segmentLocalId, sectionLocalId, itemLocalId, propertyName, value) => {
        let item = this.getSectionItem(segmentLocalId, sectionLocalId, itemLocalId);
        if (item != null) {
            item[propertyName] = value;
            this.setState({
                templateData : this.state.templateData
            });
        }
    }

    addSectionItem = (segmentLocalId, sectionLocalId, itemAfterLocalId) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            let position = -1;
            let ordering = 1;
            if (itemAfterLocalId !== undefined) {
                let reorderItems = false;
                for (let i = 0; i < section.items.length; i++) {
                    if (section.items[i].localId === itemAfterLocalId) {
                        position = i + 1;
                        ordering = parseInt(section.items[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }

                        reorderItems = true;

                        if (position >= section.items.length) {
                            position = -1;
                            ordering = 1;
                            reorderItems = false;
                        }
                    } else if (reorderItems) {
                        ordering = parseInt(section.items[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }
                    }
                }
            }

            if (position === -1 && section.items.length > 0) {
                ordering = section.items[section.items.length - 1].ordering;
            }

            let newItem = {
                localId : uuidv4(),
                segmentLocalId,
                sectionLocalId,
                number : "",
                title : "",
                defaultValue : "",
                defaultIncomplete : 0,
                dataType : DataType.TEXT,
                clinicalStandard : "",
                userEditable : 1,
                ordering : ordering,
                outcomes : []
            };

            if (position === -1) {
                section.items.push(newItem);
            } else {
                section.items.splice(position, 0, newItem);
            }

            this.setState({
                templateData : this.state.templateData
            });

            this.recalculateSectionItemOrder(segmentLocalId, sectionLocalId);
        }
    }

    moveSectionItem = (segmentLocalId, sectionLocalId, itemLocalId, direction) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        let initialPosition = 0;
        let item = null;
        for (let i = 0; i < section.items.length; i++) {
            if (section.items[i].localId === itemLocalId) {
                initialPosition = i;
                item = section.items[i];
                break;
            }
        }

        if (item != null) {
            section.items.splice(initialPosition, 1);

            let newPos = initialPosition;
            if (direction === "up") {
                if (initialPosition > 0) {
                    newPos--;
                }
            } else {
                if (initialPosition < (section.items.length - 1)) {
                    newPos++;
                }
            }

            section.items.splice(newPos, 0, item);
        }

        this.setState({
            templateData : this.state.templateData
        });

        this.recalculateSectionItemOrder(segmentLocalId, sectionLocalId);
    }

    recalculateSectionItemOrder = (segmentLocalId, sectionLocalId) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        for (let i = 0; i < section.items.length; i++) {
            section.items[i].ordering = (i + 1);
        }

        this.setState({
            templateData : this.state.templateData
        });
    }

    getSectionItemOutcome = (segmentLocalId, sectionLocalId, itemLocalId, outcomeLocalId) => {
        let item = this.getSectionItem(segmentLocalId, sectionLocalId, itemLocalId);
        if (item != null) {
            if (item.hasOwnProperty("outcomes")) {
                for (let i = 0; i < item.outcomes.length; i++) {
                    if (item.outcomes[i].localId === outcomeLocalId) {
                        return item.outcomes[i];
                    }
                }
            }
        }
        return null;
    }

    addSectionItemOutcome = (segmentLocalId, sectionLocalId, itemLocalId) => {
        let item = this.getSectionItem(segmentLocalId, sectionLocalId, itemLocalId);
        if (item != null) {
            if (!item.hasOwnProperty("outcomes")) {
                item.outcomes = [];
            }

            item.outcomes.push({
                localId : uuidv4()
            });
            this.setState({
                templateData : this.state.templateData
            });
        }
    }

    onSectionItemOutcomePropertyChange = (segmentLocalId, sectionLocalId, itemLocalId, outcomeLocalId, propertyName, value) => {
        let outcome = this.getSectionItemOutcome(segmentLocalId, sectionLocalId, itemLocalId, outcomeLocalId);
        if (outcome != null) {
            outcome[propertyName] = value;
            this.setState({
                templateData : this.state.templateData
            });
        }
    }

    addSectionInput = (segmentLocalId, sectionLocalId, itemAfterLocalId) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            let position = -1;
            let ordering = 1;
            if (itemAfterLocalId !== undefined) {
                let reorderItems = false;
                for (let i = 0; i < section.inputs.length; i++) {
                    if (section.inputs[i].localId === itemAfterLocalId) {
                        position = i + 1;
                        ordering = parseInt(section.inputs[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }

                        reorderItems = true;

                        if (position >= section.inputs.length) {
                            position = -1;
                            ordering = 1;
                            reorderItems = false;
                        }
                    } else if (reorderItems) {
                        ordering = parseInt(section.inputs[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }
                    }
                }
            }

            if (position === -1 && section.inputs.length > 0) {
                ordering = section.inputs[section.inputs.length - 1].ordering;
            }

            let newItem = {
                localId : uuidv4(),
                title : "",
                dataTypeId : DataType.TEXT,
                dataKey : "",
                ordering : ordering,
                options : []
            };

            if (position === -1) {
                section.inputs.push(newItem);
            } else {
                section.inputs.splice(position, 0, newItem);
            }

            this.setState({
                templateData : this.state.templateData
            });

            // TODO Recalculate Input position
        }
    }

    getSectionInput = (segmentLocalId, sectionLocalId, inputLocalId) => {
        let section = this.getSection(segmentLocalId, sectionLocalId);
        if (section != null) {
            for (let x = 0; x < section.inputs.length; x++) {
                if (section.inputs[x].localId === inputLocalId) {
                    return section.inputs[x];
                }
            }
        }
        return null;
    }

    onSectionInputPropertyChange = (segmentLocalId, sectionLocalId, inputLocalId, propertyName, value) => {
        let input = this.getSectionInput(segmentLocalId, sectionLocalId, inputLocalId);
        if (input != null) {
            input[propertyName] = value;
            this.setState({
                templateData : this.state.templateData
            });
        }
        return null;
    }

    addSelectionInputByBulk = (segmentLocalId, sectionLocalId, inputLocalId, bulkInput) => {
        let bulkSplit = bulkInput.split("\n");
        bulkSplit.forEach((row) => {
            let rowSplit = row.split("=");

            let value = "";
            let label = "";

            if (rowSplit.length === 1) {
                value = rowSplit[0].trim();
                label = rowSplit[0].trim();
            } else if (rowSplit.length > 1) {
                value = rowSplit[0].trim();
                label = rowSplit[1].trim();
            }

            if (value !== "") {
                this.addSectionInputOption(segmentLocalId, sectionLocalId, inputLocalId, undefined, value, label);
            }
        });

        let input = this.getSectionInput(segmentLocalId, sectionLocalId, inputLocalId);
        input.showBulkInput = false;
        input.bulkInput = "";

        this.setState({
            templateData : this.state.templateData
        });
    }

    showSectionInputBulk = (segmentLocalId, sectionLocalId, inputLocalId) => {
        let input = this.getSectionInput(segmentLocalId, sectionLocalId, inputLocalId);
        if (input.showBulkInput === undefined) {
            input.showBulkInput = true;
        } else {
            input.showBulkInput = !input.showBulkInput;
        }

        this.setState({
            templateData : this.state.templateData
        });
    }

    addSectionInputOption = (segmentLocalId, sectionLocalId, inputLocalId, itemAfterLocalId, defaultValue, defaultLabel) => {
        let input = this.getSectionInput(segmentLocalId, sectionLocalId, inputLocalId);
        if (input != null) {
            let position = -1;
            let ordering = input.options.length + 1;
            if (itemAfterLocalId !== undefined) {
                let reorderItems = false;
                for (let i = 0; i < input.options.length; i++) {
                    if (input.options[i].localId === itemAfterLocalId) {
                        position = i + 1;
                        ordering = parseInt(input.options[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }

                        reorderItems = true;

                        if (position >= input.options.length) {
                            position = -1;
                            ordering = 1;
                            reorderItems = false;
                        }
                    } else if (reorderItems) {
                        ordering = parseInt(input.options[i].ordering);
                        if (!isNaN(ordering)) {
                            ordering++;
                        } else {
                            ordering = position;
                        }
                    }
                }
            }

            if (position === -1 && input.options.length > 0) {
                ordering = input.options[input.options.length - 1].ordering;
            }

            let label = "";
            if (defaultLabel !== undefined) {
                label = defaultLabel;
            }

            let value = "";
            if (defaultValue !== undefined) {
                value = defaultValue;
            }

            let newItem = {
                localId : uuidv4(),
                label : label,
                value : value,
                ordering : ordering
            };

            if (position === -1) {
                input.options.push(newItem);
            } else {
                input.options.splice(position, 0, newItem);
            }

            this.setState({
                templateData : this.state.templateData
            });

            // TODO Recalculate Input position
        }
    }

    getSectionInputOption = (segmentLocalId, sectionLocalId, inputLocalId, optionLocalId) => {
        let input = this.getSectionInput(segmentLocalId, sectionLocalId, inputLocalId);
        if (input != null) {
            for (let x = 0; x < input.options.length; x++) {
                if (input.options[x].localId === optionLocalId) {
                    return input.options[x];
                }
            }
        }
        return null;
    }

    onSectionInputOptionPropertyChange = (segmentLocalId, sectionLocalId, inputLocalId, optionLocalId, propertyName, value) => {
        let option = this.getSectionInputOption(segmentLocalId, sectionLocalId, inputLocalId, optionLocalId);
        if (option != null) {
            option[propertyName] = value;
            this.setState({
                templateData : this.state.templateData
            });
        }
        return null;
    }

    render() {
        let submissionFeedback = [];
        if (this.state.submitNetworkSuccessDate != null) {
            submissionFeedback = (
                <strong>Saved: {this.state.submitNetworkSuccessDate}</strong>
            );
        } else if (this.state.submitNetworkError != null) {
            submissionFeedback = (
                <strong style={{color : "#CC0000"}}>ERROR: {this.state.submitNetworkError}</strong>
            );
        }

        let editorContent = [];
        let dataTypes = [
            {
                id : DataType.TEXT,
                label : "Text"
            },
            {
                id : DataType.INTEGER,
                label : "Integer"
            },
            {
                id : DataType.DOUBLE,
                label : "Double"
            },
            {
                id : DataType.BOOLEAN,
                label : "Boolean"
            },
            {
                id : DataType.DATETIME,
                label : "Date Time"
            }
        ];

        if (this.state.templateData != null) {
            this.state.templateData.forEach((segment) => {
                let sections = [];

                if (segment.hasOwnProperty("sections")) {
                    segment.sections.forEach((section) => {
                        let sectionItems = [];
                        let sectionInputs = [];

                        if (parseInt(this.state.currentMode) === this.MODE_ITEMS) {
                            if (section.hasOwnProperty("items")) {
                                section.items.forEach((item) => {
                                    let outcomes = [];

                                    if (item.hasOwnProperty("outcomes")) {
                                        item.outcomes.forEach((outcome) => {
                                            outcomes.push(
                                                <div className={"row editor-segment-section-fields"}>
                                                    <div className={"col-12"}>
                                                        <label>Comparator</label>
                                                        <input type={"text"} className={"form-control"}
                                                               name={"outcome_comparator"} value={outcome.comparator}
                                                               onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "comparator", e.target.value)}/>
                                                    </div>

                                                    <div className={"col-12"}>
                                                        <label>Value</label>
                                                        <input type={"text"} className={"form-control"}
                                                               name={"outcome_value"} value={outcome.value}
                                                               onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "value", e.target.value)}/>
                                                    </div>

                                                    <div className={"col-12 col-md-6"}>
                                                        <label>Outcome Type</label>
                                                        <select className={"form-control"} name={"outcome_type"}
                                                                value={outcome.caseRecordSegmentSectionItemOutcomeTypeId}
                                                                onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "caseRecordSegmentSectionItemOutcomeTypeId", e.target.value)}>
                                                            <option value={3}>Neutral</option>
                                                            <option value={1}>Positive</option>
                                                            <option value={2}>Negative</option>
                                                            <option value={5}>Error</option>
                                                        </select>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <label>Outcome Considered &quot;Incomplete&quot;?</label>
                                                        <select className={"form-control"} name={"outcome_incomplete"}
                                                                value={outcome.incomplete}
                                                                onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "incomplete", e.target.value)}>
                                                            <option value={0}>No</option>
                                                            <option value={1}>Yes</option>
                                                        </select>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <label>Outcome Considered &quot;Error&quot;?</label>
                                                        <select className={"form-control"} name={"outcome_error"}
                                                                value={outcome.error}
                                                                onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "error", e.target.value)}>
                                                            <option value={0}>No</option>
                                                            <option value={1}>Yes</option>
                                                        </select>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <label>Error Message</label>
                                                        <input type={"text"} className={"form-control"}
                                                               name={"outcome_error_message"} value={outcome.errorMessage}
                                                               onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "errorMessage", e.target.value)}/>
                                                    </div>

                                                    <div className={"col-12 col-md-6"}>
                                                        <label>Ordering</label>
                                                        <input type={"number"} className={"form-control"}
                                                               name={"outcome_ordering"} value={outcome.ordering}
                                                               onChange={(e) => this.onSectionItemOutcomePropertyChange(segment.localId, section.localId, item.localId, outcome.localId, "ordering", e.target.value)}/>
                                                    </div>
                                                </div>
                                            );
                                        });
                                    }



                                    sectionItems.push(
                                        <div className={"row editor-segment-section-fields"}>
                                            <div className={"col-12 mb-2"}>
                                                <input type={"checkbox"} checked={this.itemIsSelected(item)}
                                                       onChange={() => this.itemWasSelected(item)}/>
                                            </div>

                                            <div className={"col-10"}>
                                                <label>Item Type</label>
                                                <select className={"form-control"} name={"item_type"}
                                                        value={item.caseRecordSegmentSectionItemTypeId}
                                                        onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "caseRecordSegmentSectionItemTypeId", e.target.value)}>
                                                    <option value={1}>Field</option>
                                                    <option value={2}>Result</option>
                                                </select>
                                            </div>

                                            <div className={"col-2 text-right"}>
                                                <label>&nbsp;</label><br/>
                                                <div className={"btn btn-light"}
                                                     onClick={() => this.moveSectionItem(segment.localId, section.localId, item.localId, "up")}>&uarr;</div>
                                                &nbsp;
                                                <div className={"btn btn-light"}
                                                     onClick={() => this.moveSectionItem(segment.localId, section.localId, item.localId, "down")}>&darr;</div>
                                            </div>

                                            <div className={"col-12 col-md-4"}>
                                                <label>Number</label>
                                                <input type={"text"} className={"form-control"} name={"item_number"}
                                                       value={item.number}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "number", e.target.value)}/>
                                            </div>

                                            <div className={"col-12 col-md-4"}>
                                                <label>Data Key</label>
                                                <input type={"text"} className={"form-control"} name={"item_data_key"}
                                                       value={item.dataKey}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "dataKey", e.target.value)}/>
                                            </div>

                                            <div className={"col-12 col-md-4"}>
                                                <label>Count Category</label>
                                                <input type={"text"} className={"form-control"} name={"item_count_category"}
                                                       value={item.countCategory}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "countCategory", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Title</label>
                                                <input type={"text"} className={"form-control"} name={"item_title"}
                                                       value={item.title}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "title", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Message/Body</label>
                                                <textarea className={"form-control"} name={"item_message"}
                                                          value={item.body ? item.body : ""}
                                                          onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "body", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Default Value</label>
                                                <input type={"text"} className={"form-control"}
                                                       name={"item_default_value"} value={item.defaultValue}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "defaultValue", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Actions</label>
                                                <input type={"text"} className={"form-control"} name={"item_actions"}
                                                       value={item.actions ? item.actions : ""}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "actions", e.target.value)}/>
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Data Type</label>
                                                <select className={"form-control"} name={"item_data_type"}
                                                        value={item.dataType}
                                                        onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "dataType", e.target.value)}>
                                                    {
                                                        dataTypes.map((type) => (
                                                            <option value={type.id}>{type.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Default considered &quot;Incomplete&quot;</label>
                                                <select className={"form-control"} name={"item_default_incomplete"}
                                                        value={item.defaultIncomplete}
                                                        onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "defaultIncomplete", e.target.value)}>
                                                    <option value={0}>No</option>
                                                    <option value={1}>Yes</option>
                                                </select>
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Default considered &quot;Error&quot;</label>
                                                <select className={"form-control"} name={"item_default_error"}
                                                        value={item.defaultError}
                                                        onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "defaultError", e.target.value)}>
                                                    <option value={0}>No</option>
                                                    <option value={1}>Yes</option>
                                                </select>
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Default Error Message</label>
                                                <input type={"text"} className={"form-control"}
                                                       name={"item_default_error_message"}
                                                       value={item.defaultErrorMessage}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "defaultErrorMessage", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Clinical Standard</label>
                                                <textarea className={"form-control"} name={"item_clinical_standard"}
                                                          value={item.clinicalStandard}
                                                          onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "clinicalStandard", e.target.value)}/>
                                            </div>

                                            <div className={"col-12 col-md-6"}>
                                                <label>User Editable</label>
                                                <select className={"form-control"} name={"item_user_editable"}
                                                        value={item.userEditable}
                                                        onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "userEditable", e.target.value)}>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </select>
                                            </div>

                                            <div className={"col-12 col-md-6"}>
                                                <label>Ordering</label>
                                                <input type={"number"} className={"form-control"} name={"item_ordering"}
                                                       value={item.ordering}
                                                       onChange={(e) => this.onSectionItemPropertyChange(segment.localId, section.localId, item.localId, "ordering", e.target.value)}/>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Outcomes</label>
                                                <div className={"editor-section-fields"}>
                                                    {outcomes}

                                                    <div className={"button-container"}>
                                                        <span className={"btn btn-primary"}
                                                              onClick={() => this.addSectionItemOutcome(segment.localId, section.localId, item.localId)}>Add Outcome</span>
                                                        &nbsp;
                                                        <span className={"btn btn-warning"}
                                                              onClick={() => this.addSectionItem(segment.localId, section.localId, item.localId)}>Add Item Below</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        } else {
                            if (section.hasOwnProperty("inputs")) {
                                section.inputs.forEach((input) => {
                                    let options = [];
                                    if (input.hasOwnProperty("options")) {
                                        input.options.forEach((option) => {
                                            options.push(
                                                <div className={"row editor-segment-sections"}>
                                                    <div className={"col-6"}>
                                                        <label>Option Label</label>
                                                        <input type={"text"} className={"form-control"} value={option.label} name={"label"} onChange={(e) => this.onSectionInputOptionPropertyChange(segment.localId, section.localId, input.localId, option.localId, e.target.name, e.target.value)}  />
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <label>Option Value</label>
                                                        <input type={"text"} className={"form-control"} value={option.value} name={"value"} onChange={(e) => this.onSectionInputOptionPropertyChange(segment.localId, section.localId, input.localId, option.localId, e.target.name, e.target.value)}  />
                                                    </div>
                                                </div> )
                                        });
                                    }

                                    let optionBulkElem = [];
                                    if (input.showBulkInput !== undefined) {
                                        if (input.showBulkInput) {
                                            optionBulkElem = (
                                                <div className={"row"}>
                                                    <div className={"col-12 pb-1"}>
                                                        <label>Bulk Option Input</label>
                                                    </div>
                                                    <div className={"col-12 pb-1"}>
                                                        <div>On each line provide value=label</div>
                                                        <div>
                                                            <textarea className={"form-control"} value={input.bulkInput} onChange={(e) => input.bulkInput = e.target.value} />
                                                        </div>
                                                    </div>
                                                    <div className={"col-12"}>
                                                        <button className={"btn btn-primary"} onClick={() => this.addSelectionInputByBulk(segment.localId, section.localId, input.localId, input.bulkInput)}>Submit Bulk</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }

                                    sectionInputs.push(
                                        <div className={"row editor-segment-sections"}>
                                            <div className={"col-12"}>
                                                <label>Title</label>
                                                <input type={"text"} className={"form-control"} value={input.title} name={"title"} onChange={(e) => this.onSectionInputPropertyChange(segment.localId, section.localId, input.localId, e.target.name, e.target.value)} />
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Data Key</label>
                                                <input type={"text"} className={"form-control"} value={input.dataKey} name={"dataKey"} onChange={(e) => this.onSectionInputPropertyChange(segment.localId, section.localId, input.localId, e.target.name, e.target.value)} />
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Data Type</label>
                                                <select className={"form-control"} name={"dataTypeId"} value={input.dataTypeId} onChange={(e) => this.onSectionInputPropertyChange(segment.localId, section.localId, input.localId, e.target.name, e.target.value)}>
                                                    {
                                                        dataTypes.map((type) => (
                                                            <option value={type.id}>{type.label}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Ordering</label>
                                                <input type={"text"} className={"form-control"} value={input.ordering} name={"ordering"} onChange={(e) => this.onSectionInputPropertyChange(segment.localId, section.localId, input.localId, e.target.name, e.target.value)} />
                                            </div>

                                            <div className={"col-6"}>
                                                <label>Active</label>
                                                <select className={"form-control"} name={"active"} value={input.active} onChange={(e) => this.onSectionInputPropertyChange(segment.localId, section.localId, input.localId, e.target.name, e.target.value)}>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </select>
                                            </div>

                                            <div className={"col-12"}>
                                                <label>Options</label>

                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        {options}
                                                    </div>
                                                </div>

                                                {optionBulkElem}
                                            </div>

                                            <div className={"col-12 text-center button-container"}>
                                                <button className={"btn btn-primary"} onClick={() => this.addSectionInputOption(segment.localId, section.localId, input.localId)}>Add Option</button>
                                                &nbsp;
                                                <button className={"btn btn-secondary"} onClick={() => this.showSectionInputBulk(segment.localId, section.localId, input.localId)}>Add Options in Bulk</button>
                                            </div>
                                        </div>
                                    ) // TODO Options button
                                });
                            }
                        }

                        let buttons = [];
                        if (parseInt(this.state.currentMode) === this.MODE_ITEMS) {
                            buttons.push(
                                <span className={"btn btn-primary"} onClick={() => this.addSectionItem(segment.localId, section.localId)}>Add Item</span>
                            );

                            buttons.push(
                                <span className={"btn btn-info ml-1"} onClick={() => this.addSection(segment.localId, section.localId)}>Add Section Below</span>
                            );
                        } else {
                            buttons.push(
                                <span className={"btn btn-primary"} onClick={() => this.addSectionInput(segment.localId, section.localId)}>Add Input</span>
                            )
                        }

                        sections.push(
                            <div key={section.localId} className={"editor-section mb-2"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>

                                        <div className={"card"}>
                                            <div className={"card-body"}>

                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        <label>Section Title</label>
                                                        <input type={"text"}
                                                               className={"form-control"}
                                                               name={"section_title"}
                                                               value={section.title}
                                                               onChange={(e) => this.onSectionPropertyChange(segment.localId, section.localId, "title", e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className={"row mt-2"}>
                                                    <div className={"col-12 col-md-6"}>
                                                        <label>Ordering</label>
                                                        <input type={"number"}
                                                               className={"form-control"}
                                                               name={"section_ordering"}
                                                               value={section.ordering}
                                                               onChange={(e) => this.onSectionPropertyChange(segment.localId, section.localId, "ordering", e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        <label>{parseInt(this.state.currentMode) === this.MODE_ITEMS ? "Items" : "Inputs"}</label>
                                                        <div className={"editor-section-fields"}>
                                                            {sectionItems}
                                                            {sectionInputs}

                                                            <div className={"button-container"}>
                                                                {buttons}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    });
                }

                let segmentElem = (
                    <div key={segment.localId} className={"row editor-segment"}>
                        <div className={"col-12"}>
                            <label>Segment Title</label>
                            <input type={"text"} className={"form-control"} name={"segment_title"}
                                   value={segment.segmentName}
                                   onChange={(e) => this.onSegmentPropertyChange(segment.localId, "segmentName", e.target.value)}/>
                        </div>
                        <div className={"col-6"}>
                            <label>Segment Type</label>
                            <input type={"number"} className={"form-control"} name={"segment_type"}
                                   value={segment.segmentType}
                                   onChange={(e) => this.onSegmentPropertyChange(segment.localId, "segmentType", e.target.value)}/>
                        </div>
                        <div className={"col-6"}>
                            <label>Segment Number</label>
                            <input type={"text"} className={"form-control"} name={"segment_number"}
                                   value={segment.segmentNumber}
                                   onChange={(e) => this.onSegmentPropertyChange(segment.localId, "segmentNumber", e.target.value)}/>
                        </div>
                        <div className={"col-6"}>
                            <label>Primary Colour (HEX)</label>
                            <input type={"text"} className={"form-control"} name={"segment_pcolour"}
                                   value={segment.primaryColour}
                                   onChange={(e) => this.onSegmentPropertyChange(segment.localId, "primaryColour", e.target.value)}/>
                        </div>
                        <div className={"col-6"}>
                            <label>Cell Colour (HEX)</label>
                            <input type={"text"} className={"form-control"} name={"segment_ccolour"} value={segment.cellColour} onChange={(e) => this.onSegmentPropertyChange(segment.localId, "cellColour", e.target.value)} />
                        </div>

                        <label>Sections</label>
                        <div className={"col-12 editor-segment-sections"}>
                            {sections}
                        </div>

                        <div className={"col-12 button-container"}>
                            <span className={"btn btn-primary"} onClick={() => this.addSection(segment.localId)}>Add Section</span>
                        </div>

                    </div>
                );

                editorContent.push(segmentElem);
            });

            // editorContent.push(
            //     <div key={"SEGMENT_BUTTON_AREA"} className={"button-container"}>
            //         <span className={"btn btn-primary"} onClick={this.addSegment}>Add Segment</span>
            //     </div>
            // )
        }

        let topbarClassName = "";
        if (this.state.floatControls) {
            topbarClassName = " floating";
        }

        let modes = [
            {
                label : "Items",
                value : this.MODE_ITEMS
            },
            {
                label : "Inputs",
                value : this.MODE_INPUTS
            }
        ]

        let sectionMoveButton = [];
        let splitButton = [];
        if (this.state.selectedItems.length > 0) {
            sectionMoveButton = (
                <button className={"btn btn-primary mr-1"} onClick={() => this.summonSectionSelector()}>
                    Move Selected
                </button>
            );

            splitButton = (
                <button className={"btn btn-primary mr-1"} onClick={() => this.splitIntoSection()}>
                    Split
                </button>
            )
        }

        return(
            <div className={"template-editor"}>
                <div className={"editor-topbar" + topbarClassName}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            {/*<span className={"btn btn-primary"} onClick={this.onTemplateLoadClicked}>Load</span>*/}
                            <span className={"btn btn-success"} onClick={this.onTemplateSaveClicked}>Save</span>
                            {submissionFeedback}
                        </div>
                        <div className={"col-4 text-center"}>
                            <select className={"form-control"} name={"currentMode"} value={this.state.currentMode} onChange={this.handleChange}>
                                {
                                    modes.map((mode) => (
                                        <option value={mode.value}>{mode.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className={"col-4 text-right"}>
                            {splitButton}
                            {sectionMoveButton}
                            <button className={"btn btn-primary"} onClick={() => this.props.history.goBack()}>Return to List</button>
                        </div>
                    </div>
                </div>

                <div className={"editor-container"}>
                    {editorContent}
                </div>

                <TemplateEditorSectionSelectorModal
                    shown={this.state.sectionSelectorShown}
                    sections={this.state.sectionSelectorSections}
                    callback={this.sectionSelectorDidCallback} />
            </div>
        )
    }

}

export default TemplateEditorComponent;