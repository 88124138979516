import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import LoadingSpinner from "../../common/LoadingSpinner";
import {CommonUtil} from "../../../util/CommonUtil";
import {Button, Container, DropDown, Former, FormerActions, Number, Text} from "../../form/Former";
import {Toast} from "../../toast/TokyoToaster";
import {
    CaseRecordManualEntryUserListActions,
    CaseRecordManualEntryUserListComponent, CaseRecordManualEntryUserListMode
} from "./CaseRecordManualEntryUserListComponent";
import UserSelectionDialogComponent from "../../users/UserSelectionDialogComponent";
import {Chronos} from "../../../entity/Chronos";
import {OrganisationStaffActions, OrganisationStaffModal} from "./OrganisationStaffModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import "./CaseRecordManualEntryScreen.css";
import {Analytics, AnalyticsTags} from "../../../util/Analytics";
import {DataManager} from "../../../util/DataManager";

export const CaseRecordManualEntryScreen = (props) => {

    const {departmentId} = useParams();
    const {caseRecordId} = useParams();

    const {title} = props;

    const [caseRecord, setCaseRecord] = useState(null);
    const [patientData, setPatientData] = useState({});
    const [patientDataNetworkInFlight, setPatientDataNetworkInFlight] = useState(false);

    const [confirmationInput, setConfirmationInput] = useState("");

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [userSelectionOpen, setUserSelectionOpen] = useState(false);
    const [userSelectionDataKey, setUserSelectionDataKey] = useState();

    const [nhsCheckStatus, setNhsCheckStatus] = useState(undefined);

    const changedPatientData = useRef({});

    useEffect(() => {
        if (title) {
            title("Case Review - Manual Entry");
        }

        fetchPatientDataFromNetwork();
    }, []);

    function getPatientData(key, defaultValue) {
        return CommonUtil.getOrDefault(patientData, key, defaultValue);
    }

    function putPatientData(key, value) {
        console.log("SET PATIENT DATA: " + key, value);
        putPatientDatas([
            {key, value}
        ]);
    }

    function putPatientDatas(data) {
        if (patientData) {
            const newPD = CommonUtil.cloneObject(patientData);
            data.forEach((data) => {
                let key = data.key;
                let value = data.value;

                newPD[key] = value;
                changedPatientData.current[key] = value;
            });
            setPatientData(newPD);
            console.log("SET DATA:", newPD);
        }
    }

    function doesConfirmationMatch() {
        let nhsNumber = getPatientData("NHS_NUMBER", null);
        if (!nhsNumber && caseRecord) {
            if (caseRecord.hasOwnProperty("patient") && caseRecord.patient) {
                if (caseRecord.patient.hasOwnProperty("NHSNumber")) {
                    nhsNumber = caseRecord.patient.NHSNumber;
                }
            }
        }

        let status = confirmationInput === nhsNumber;

        Analytics.track(
            status ? AnalyticsTags.TAG_MANUAL_ENTRY_NHS_ENTRY_SUCCESS : AnalyticsTags.TAG_MANUAL_ENTRY_NHS_ENTRY_FAILURE,
            {
                userId : DataManager.getUser().id,
                caseRecordId,
                nhsNumber,
                confirmationInput
            }
        );

        setNhsCheckStatus(status);
    }

    function getPatientName() {
        let out = "";

        let forename = getPatientData("S01Forename", null);
        let surname = getPatientData("S01Surname", null);

        if (forename !== null) {
            out = forename;
        }

        if (surname !== null) {
            if (out !== "") {
                out += " ";
            }
            out += surname;
        }

        if (out === "") {
            if (caseRecord) {
                console.log("getPatientName()", caseRecord.patient);
                if (caseRecord.hasOwnProperty("patient")) {
                    if (caseRecord.patient.hasOwnProperty("givenName")) {
                        out += caseRecord.patient.givenName;
                    }

                    if (caseRecord.patient.hasOwnProperty("familyName")) {
                        if (out !== "") {
                            out += " ";
                        }

                        out += caseRecord.patient.familyName;
                    }
                }
            }
        }

        return out;
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.CHANGE) {
            putPatientData(data.name, data.value);
        } else if (action === FormerActions.SUBMIT) {
            submitForm();
        }
    }

    function userListDidCallback(action, data, dataKey) {
        if (action === CaseRecordManualEntryUserListActions.REQUEST_USER) {
            setUserSelectionDataKey(dataKey);
            setUserSelectionOpen(true);
        } else if (action === CaseRecordManualEntryUserListActions.REMOVE_USER) {
            AlertModal.show(
                "Confirm removal",
                "Are you sure you want to remove this person?",
                [
                    AlertModal.button(
                        "Remove",
                        () => {
                            removeUserFromPatientData(dataKey, data);
                            AlertModal.dismiss();
                        },
                        "delete"
                    ),
                    AlertModal.button(
                        "Cancel",
                        () => {
                            AlertModal.dismiss();
                        }
                    )
                ]
            );
        }
    }

    function organisationStaffModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            console.log("ORG STAFF MODAL CLOSED", data);
            if (data) {
                addUserToPatientData(data);
            }
            setUserSelectionOpen(false);
        }
    }

    function addUserToPatientData(data) {
        if (data) {
            if (userSelectionDataKey) {
                let patientDataItem = getPatientData(userSelectionDataKey + "_data", "[]");
                if (patientDataItem) {
                    patientDataItem = JSON.parse(patientDataItem);

                    let alreadyAdded = false;
                    for (let i = 0; i < patientDataItem.length; i++) {
                        if (patientDataItem[i].id === data.id) {
                            alreadyAdded = true;
                            break;
                        }
                    }

                    if (!alreadyAdded) {
                        patientDataItem.push(data);
                        // Store data & string representation
                        putPatientDatas([
                            {key: userSelectionDataKey + "_data", value: JSON.stringify(patientDataItem)},
                            {key: userSelectionDataKey, value: getUserListString(patientDataItem)}
                        ]);
                    }
                }
            }
        }

        setUserSelectionOpen(false);
        setUserSelectionDataKey(undefined);
    }

    function removeUserFromPatientData(dataKey, data) {
        let patientDataItem = getPatientData(dataKey + "_data", "[]");
        if (patientDataItem) {
            patientDataItem = JSON.parse(patientDataItem);
            for (let i = patientDataItem.length - 1; i >= 0; i--) {
                if (patientDataItem[i].id === data.id) {
                    patientDataItem.splice(i, 1);
                }
            }
            // Store data & string representations
            putPatientDatas([
                { key : dataKey + "_data", value : JSON.stringify(patientDataItem) },
                { key : dataKey, value : getUserListString(patientDataItem) }
            ]);
        }
    }

    function getUserListString(listArray) {
        if (listArray && Array.isArray(listArray) && listArray.length > 0) {
            let out = "";

            listArray.forEach((user) => {
                if (!user) return;

                let parts = [];

                if (user.hasOwnProperty("title")) {
                    parts.push(user.title);
                }

                if (user.hasOwnProperty("givenName")) {
                    parts.push(user.givenName);
                }

                if (user.hasOwnProperty("familyName")) {
                    parts.push(user.familyName);
                }

                if (user.hasOwnProperty("gmcNumber")) {
                    parts.push("(" + user.gmcNumber + ")")
                }

                out += "<li>" + parts.join(" ") + "</li>";
            });

            out = "<ul>" + out + "</ul>";

            return out;
        }
        return "";
    }

    function getUnableToIdentifyFormItem(name, label) {
        if (label === undefined) {
            label = "Unable to identify from clinical record";
        }

        let value = parseInt(getPatientData(name, 2));

        return (
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <label>
                        <input
                            type={"checkbox"}
                            className={"mr-1"}
                            name={name}
                            checked={value === 1}
                            onChange={(e) => {
                                let value = 2;
                                if (e.target.checked) {
                                    value = 1;
                                }
                                console.log("CB CHANGE: " + e.target.name, value);
                                putPatientData(e.target.name, value);
                            }} />

                        {label}
                    </label>
                </Container>
            </Container>
        );
    }

    function getQuestionSkipElement(label) {
        return (
            <div className={"card"}>
                <div className={"card-body"}>
                    {label}
                </div>
            </div>
        );
    }

    function submitForm() {
        if (!canSubmitForm()) return;

        submitPatientDataOverNetwork();
    }

    function canSubmitForm() {
        console.log("Can submit form: " + Object.keys(changedPatientData.current).length);
        if (Object.keys(changedPatientData.current).length === 0) {
            return false;
        }

        return nhsCheckStatus === true;
    }

    function fetchPatientDataFromNetwork() {
        if (patientDataNetworkInFlight) return;
        setPatientDataNetworkInFlight(true);

        const formData = new FormData();
        formData.append("caseRecordId", caseRecordId);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecordPatientData, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCaseRecord(resp.data.caseRecord);
                    setPatientData(resp.data.patientData);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPatientDataNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPatientDataNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CRPD1000C"));
            })
    }

    function submitPatientDataOverNetwork() {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("patientId", caseRecord ? caseRecord.patientId : undefined);
        formData.append("caseRecordId", caseRecordId);
        formData.append("data", JSON.stringify(changedPatientData.current));

        Axios.post(ENDPOINTS.caseRecord.submitPatientData, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Case Record has been updated",
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    changedPatientData.current = {};

                    fetchPatientDataFromNetwork();
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CRMES1000C"));
            });
    }

    // RENDER

    let mainContent = [];

    if (patientDataNetworkInFlight) {
        mainContent = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        );
    } else {
        const formContents = [];

        // CRME 1.0.0
        let CRME_ED_DutyConsNotConfig = parseInt(getPatientData("CRME.ED_DutyConsNotConfig", "1"));
        // let CRMEEDDutyConsLabel = (<div className={"alert alert-light text-center"}>The ED duty consultant  at time of arrival at hospital question is not configured at your site</div>);
        // if (CRME_ED_DutyConsNotConfig === 2) {
        //     CRMEEDDutyConsLabel = (<div className={"alert alert-secondary text-center"}>Please provide the following information about the  consultant on duty in ED at the time of arrival at hospital</div>);
        // }

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q1.0.0 - ED consultant on duy at time of arrival at hospital.</h2>
                </div>
            </div>
        );

        if (CRME_ED_DutyConsNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>The ED duty consultant  at time of arrival at hospital question is not configured at your site</div>
                    </div>
                </div>
            );
        } else if (CRME_ED_DutyConsNotConfig === 2) {
            let S01Adm_Type = parseInt(getPatientData("S01Adm_Type", -1));
            let S01InitialRoute = getPatientData("S01InitialRoute", "");
            let S01Adm_TimeNotEntered = CommonUtil.parseBool(getPatientData("S01Adm_TimeNotEntered", "false"));
            let S01Adm_Datetime = getPatientData("S01Adm_Datetime", "---");

            let showCRME100 = false;

            if (S01Adm_Type === 1) {
                formContents.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-warning text-center"}>The initial admission was elective, this information NOT required</div>
                        </div>
                    </div>
                );
            } else if (S01Adm_Type === 2 && ["ASAU", "GP", "IPR", "CLIN", "HT"].includes(S01InitialRoute.toUpperCase())) {
                formContents.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-warning text-center"}>The initial admission was not via ED, this information NOT required</div>
                        </div>
                    </div>
                );
            } else if (S01Adm_Type === 2 && S01InitialRoute === "ED" && S01Adm_TimeNotEntered === false) {
                showCRME100 = true;

                formContents.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-secondary text-center"}>Please provide the following information about the consultant on duty in ED at the time of arrival at hospital. Date/Time of arrival was: <strong>{S01Adm_Datetime}</strong></div>
                        </div>
                    </div>
                );
            } else if (S01Adm_Type === 2 && S01InitialRoute === "ED" && S01Adm_TimeNotEntered === true) {
                showCRME100 = true;

                formContents.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-secondary text-center"}>Please provide the following information about the  consultant on duty in ED at the time of arrival at hospital. Date/Time of arrival was: NELA 1.9 Date/Time of arrival missing information</div>
                        </div>
                    </div>
                );
            } else {
                formContents.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-danger text-center"}>Error! No logic found to support current data: {CommonUtil.printObject({CRME_ED_DutyConsNotConfig, S01Adm_Type, S01InitialRoute, S01Adm_TimeNotEntered, S01Adm_Datetime})}</div>
                        </div>
                    </div>
                );
            }

            if (showCRME100) {
                // CRME 1.0.1 & 10.2
                formContents.push(
                    <Container className={"row mt-2"}>
                        <Container className={"col-12"}>
                            <Container className={"card"}>
                                <Container className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <strong>Who was the ED consultant on duty when the patient was in the department?</strong>
                                        </div>

                                        <div className={"col-12 mt-2"}>
                                            <CaseRecordManualEntryUserListComponent
                                                mode={CaseRecordManualEntryUserListMode.SINGLE}
                                                data={getPatientData("CRME.ED_DutyCons_data", "[]")}
                                                callback={(action, data) => {
                                                    userListDidCallback(action, data, "CRME.ED_DutyCons");
                                                }}/>
                                        </div>
                                    </div>

                                    {getUnableToIdentifyFormItem("CRME.ED_DutyConsNF")}

                                </Container>
                            </Container>
                        </Container>
                    </Container>
                )
            }
        }

        // CRME Q1.1.0

        let CRME_Con_EGS_FirstReviewNotConfig = parseInt(getPatientData("CRME.Con_EGS_FirstReviewNotConfig", "2"));
        let surgOnCallFirstConsultantReviewLabel = (<p>Please provide the following information about the duty (on-call) consultant surgeon at the time of 1st EGS consultant review</p>);
        let S02Date_1StsurgTimeNotEntered = CommonUtil.parseBool(getPatientData("S02Date_1StsurgTimeNotEntered", "false"));
        let S02Date_1StsurgNotSeen = parseInt(getPatientData("S02Date_1StsurgNotSeen", "1"));

        let showCRME110 = false;

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q1.1.0 - Consultant Surgeon on-call at time of 1st Consultant review</h2>
                </div>
            </div>
        );

        if (CRME_Con_EGS_FirstReviewNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>The on-call duty consultant surgeon at time of 1st consultant review/admission question is not currently configured at your site</div>
                    </div>
                </div>
            );
        } else if (CRME_Con_EGS_FirstReviewNotConfig === 2 && S02Date_1StsurgNotSeen === 2) {
            showCRME110 = true;

            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please provide the following information about the duty (on-call) consultant surgeon at the time of 1st EGS consultant review. Review occurred at: <strong>{getPatientData("S02Date_1StsurgDatetime", "(Not set)")}</strong></div>
                    </div>
                </div>
            )
        } else if (CRME_Con_EGS_FirstReviewNotConfig === 2 && S02Date_1StsurgNotSeen === 2 && S02Date_1StsurgTimeNotEntered === false) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>Please provide the following information about the duty (on-call) consultant surgeon at the time of 1st EGS consultant review (or if not clear at the time patient admitted to hospital).</p>
                    </div>
                </div>
            )
        } else {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"}>Developer Error! No logic strings held true for data: {CommonUtil.printObject({ CRME_Con_EGS_FirstReviewNotConfig, S02Date_1StsurgTimeNotEntered, S02Date_1StsurgNotSeen })}</div>
                    </div>
                </div>
            );
        }


        if (showCRME110) {
            // let firstSurgDateLabel = "The NELA record doesn't provide a date and time of 1st EGS Cons review. The date and time of arrival/addmission to hospital is recorded as: " + getPatientData("S01Adm_Datetime", "(Not set)");
            // if (!S02Date_1StsurgTimeNotEntered && S02Date_1StsurgNotSeen === 2) {
            //     let surgTimeLabel = "(not set)";
            //     let surgTimeValue = getPatientData("S02Date_1StsurgDatetime", null);
            //     if (surgTimeValue !== null) {
            //         surgTimeLabel = Chronos.parse(surgTimeValue).format("dd/MM/yyyy HH:mm");
            //     }
            //
            //
            //     firstSurgDateLabel = (<div className={"alert alert-info text-center mb-0"}>The NELA indicates the date and Time of the 1st EGS Cons review occurred at: <strong>{surgTimeLabel}</strong></div>);
            // }
            //
            // formContents.push(
            //     <div className={"row mt-2 mb-4"}>
            //         <div className={"col-12"}>
            //             <div className={"card"}>
            //                 <div className={"card-body"}>
            //                     <p>For information the output from the NELA record relating to date and time of 1st EGS consultant review or patient admission is provided below.</p>
            //                     {firstSurgDateLabel}
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // );

            // CRME 1.1.1 & 1.1.2
            formContents.push(
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <p><strong>Identify the on-call (duty) consultant surgeon at the date/time
                                            identified above (either time of 1st EGS consultant surgeon review or at the
                                            time the patient was admitted to hospital)</strong></p>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Con_EGS_FirstReview_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Con_EGS_FirstReview");
                                            }} />
                                    </div>
                                </div>

                                <Container className={"row"}>
                                    <Container className={"col-12"}>
                                        {getUnableToIdentifyFormItem("CRME.Con_EGS_FirstReviewNF")}
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            )
        }

        // Q2.0.0
        let CRME_CT_ClinInfo_ReportNotConfig = parseInt(getPatientData("CRME.CT_ClinInfo_ReportNotConfig", "1"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q2.0.0 - Pre-operative (this admission) abdominal CT scan</h2>
                </div>
            </div>
        );

        if (CRME_CT_ClinInfo_ReportNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>
                            This question about the performance and reporting of pre-operative CT scanning is not configured for use at your site.
                        </div>
                    </div>
                </div>
            )
        } else if (CRME_CT_ClinInfo_ReportNotConfig === 2) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please provide the 'Clinical Information given to radiologist' and 'CT findings reported by radiologist' data in the boxes below.</div>
                    </div>
                </div>
            );

            // CRME 2.0.1 & CRME 2.0.2
            formContents.push(
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <Container className={"row"}>
                                    <Container className={"col-12"}>
                                        <Text
                                            name={"CRME.CT_Clin_Info"}
                                            label={"Q2.0.1 What clinical information was provided to the radiologist on the CT request form?"}
                                            multiline={true}
                                            className={"form-control"}
                                            mandatory={true}/>
                                    </Container>
                                </Container>

                                {getUnableToIdentifyFormItem("CRME.CT_Clin_InfoNF", "Q2.0.2 No pre-operative abdominal CT booking request found during this admission")}

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );


            // CRME 2.0.3 & CRME 2.0.4
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <Container className={"row"}>
                                    <Container className={"col-12"}>
                                        <Text
                                            name={"CRME.CT_Report_Findings"}
                                            label={"Q2.0.3 What were the CT findings reported by the Radiologist?"}
                                            multiline={true}
                                            className={"form-control"}
                                            mandatory={true}/>
                                    </Container>
                                </Container>

                                {getUnableToIdentifyFormItem("CRME.CT_Report_FindingsNF", "2.0.4 No pre-operative abdominal CT report found relating to this admission")}

                            </Container>
                        </Container>
                    </Container>
                </Container>

            );

            // CRME 2.1.0
            let CRME_CT_Report_FindingsNF = parseInt(getPatientData("CRME.CT_Report_FindingsNF", "2"));
            if (CRME_CT_Report_FindingsNF === 2) {
                formContents.push(
                    <Container className={"row mt-2"}>
                        <Container className={"col-12"}>
                            <Container className={"card"}>
                                <Container className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <strong>Q2.1.0 Who was the named consultant from within the Trust who validated the CT report?</strong>
                                        </div>

                                        <div className={"col-12 mt-2"}>
                                            <CaseRecordManualEntryUserListComponent
                                                mode={CaseRecordManualEntryUserListMode.SINGLE}
                                                data={getPatientData("CRME.CT_ReportingRadiologist_data", "[]")}
                                                callback={(action, data) => {
                                                    userListDidCallback(action, data, "CRME.CT_ReportingRadiologist");
                                                }}/>
                                        </div>
                                    </div>

                                    {getUnableToIdentifyFormItem("CRME.CT_ReportingRadiologistNF")}
                                    {getUnableToIdentifyFormItem("CRME.CT_ReportingRadiologistSpR", "Registrar only report")}
                                    {getUnableToIdentifyFormItem("CRME.CT_ReportingRadiologistExRS", "Reported by external reporting service")}

                                </Container>
                            </Container>
                        </Container>
                    </Container>
                );
            }
        }

        // Q3.0.0

        const CRME_Consent_MortRiskNotConfig = parseInt(getPatientData("CRME.Consent_MortRiskNotConfig", "1"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q3.0.0 - Estimated mortality risk and consent process</h2>
                </div>
            </div>
        );

        if (CRME_Consent_MortRiskNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>This question about the documentation of estimated mortality risk on the consent form is not configured for use at your site</div>
                    </div>
                </div>
            )
        } else if (CRME_Consent_MortRiskNotConfig === 2) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Was there an estimate of the patients
                            mortality risk included on the consent form for the emergency laparotomy?
                        </div>
                    </div>
                </div>
            );

            // CRME 3.0.1
            formContents.push(
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <DropDown
                                    name={"CRME.MortalityRisk_Consent"}
                                    label={"3.0.1 - Was an estimate of the patient's risk of mortality explicitly written on their consent form?"}
                                    className={"form-control"}>

                                    <option value={"1"}>Yes</option>
                                    <option value={"2"}>No</option>
                                    <option value={"3"}>Consent not available</option>
                                    <option value={""}>Not Answered</option>

                                </DropDown>

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );
        }

        // Q4.0.0
        // CRME Q4 NELA data completeness
        const CRME_NELAdata_CompNotConfig = parseInt(getPatientData("CRME.NELAdata_CompNotConfig", "1"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q4.0.0 - NELA data completeness</h2>
                </div>
            </div>
        );

        if (CRME_NELAdata_CompNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>This question about NELA data entry is not
                            configured for use at your site
                        </div>
                    </div>
                </div>
            )
        } else if (CRME_NELAdata_CompNotConfig === 2) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>What was the percentage (%) of NELA data entered at time of report generation?</div>
                    </div>
                </div>
            )

            formContents.push(
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <Number
                                    name={"CRME.NELA_%_Complete"}
                                    label={"Percentage of NELA Data entered when QIN-EmLap-CR generated"}
                                    className={"form-control"}
                                    suffix={"%"} />

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );
        }

        // Q5.0.0
        const CRME_NELA_RecordCreatorNotConfig = parseInt(getPatientData("CRME.NELA_RecordCreatorNotConfig", "1"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q5.0.0 - NELA case record creator/reporter</h2>
                </div>
            </div>
        );

        if (CRME_NELA_RecordCreatorNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>This question about the NELA case record
                            creator/reporter is not configured for use at your site
                        </div>
                    </div>
                </div>
            );
        } else if (CRME_NELA_RecordCreatorNotConfig === 2) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please select the name of the individual who created the NELA case record.</div>
                    </div>
                </div>
            )

            // CRME 5.0.1 & 5.0.2
            formContents.push(
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <strong>Who was NELA case record creator?</strong>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.NELA_RecordCreator_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.NELA_RecordCreator");
                                            }} />
                                    </div>
                                </div>

                                {getUnableToIdentifyFormItem("CRME.NELA_RecordCreatorNF")}

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );
        }

        // Q6

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q6.0.0  - Consultant surgeon and anaesthetist responsible for in-theatre care</h2>
                </div>
            </div>
        );

        formContents.push(
            <div className={"alert alert-info text-center mt-2"}>
                NELA recorded consultant surgeon was: <strong>{getPatientData("OperatingConsultant", "(Not set)")}</strong>
            </div>
        )

        // CRME 6.0.1 & CRME 6.0.2
        formContents.push(
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <Container className={"card"}>
                        <Container className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>Who was the consultant surgeon in theatre/responsible for performance of operation?</strong>
                                </div>

                                <div className={"col-12"}>
                                    <CaseRecordManualEntryUserListComponent
                                        mode={CaseRecordManualEntryUserListMode.SINGLE}
                                        data={getPatientData("CRME.Th_Con_EGS_data", "[]")}
                                        callback={(action, data) => {
                                            userListDidCallback(action, data, "CRME.Th_Con_EGS");
                                        }} />
                                </div>
                            </div>

                            {getUnableToIdentifyFormItem("CRME.Th_Con_EGSNF")}

                        </Container>
                    </Container>
                </Container>

            </Container>
        );

        // CRME 6.1.0

        formContents.push(
            <div className={"alert alert-info text-center mt-4 mb-0"}>
                NELA recorded consultant anaesthetist was: <strong>{getPatientData("AnaesthetistConsultant", "(Not set)")}</strong>
            </div>
        );

        // CRME 6.1.0 & 6.1.1
        formContents.push(
            <Container className={"row mt-2"}>
                <Container className={"col-12"}>
                    <Container className={"card"}>
                        <Container className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>Who was the consultant anaesthetist in theatre/responsible for delivery of care?</strong>
                                </div>

                                <div className={"col-12 mt-2"}>
                                    <CaseRecordManualEntryUserListComponent
                                        mode={CaseRecordManualEntryUserListMode.SINGLE}
                                        data={getPatientData("CRME.Th_Con_Anaes_data", "[]")}
                                        callback={(action, data) => {
                                            userListDidCallback(action, data, "CRME.Th_Con_Anaes");
                                        }} />
                                </div>
                            </div>

                            {getUnableToIdentifyFormItem("CRME.Th_Con_AnaesNF")}

                        </Container>
                    </Container>
                </Container>

            </Container>
        );

        // Q7.0.0
        const CRME_Th_TraineesNotConfig = parseInt(getPatientData("CRME.Th_TraineesNotConfig", "2"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q7.0.0 - In theatre surgical and anaesthetic trainee team</h2>
                </div>
            </div>
        );

        if (CRME_Th_TraineesNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>This question about trainees in theatre is not
                            configured for use at your site
                        </div>
                    </div>
                </div>
            )
        } else if (CRME_Th_TraineesNotConfig === 2) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please select the names of surgical and anaesthetic trainees in theatre for the emergency laparotomy.</div>
                    </div>
                </div>
            )

            formContents.push(
                // CRME 7.0.1, 7.0.2, 7.0.3
                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>
                                <Container className={"row"}>

                                    <div className={"col-12"}>
                                        <strong>Who was surgical trainee 1?</strong>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Th_SurgTrainee1_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Th_SurgTrainee1");
                                            }}/>
                                    </div>

                                </Container>

                                {getUnableToIdentifyFormItem("CRME.Th_SurgTrainee1NF")}

                                {getUnableToIdentifyFormItem("CRME.Th_SurgTrainee1NIT", "No surgical trainee in theatre")}
                            </Container>
                        </Container>
                    </Container>

                </Container>
            );

            // Q7.1.0

            // CRME 7.1.0, 7.1.1, 7.1.2
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <Container className={"row"}>
                                    <Container className={"col-12"}>
                                        <strong>Who was surgical trainee 2?</strong>
                                    </Container>

                                    <Container className={"col-12"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Th_SurgTrainee2_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Th_SurgTrainee2");
                                            }}/>
                                    </Container>
                                </Container>

                                {getUnableToIdentifyFormItem("CRME.Th_SurgTrainee2NF")}
                                {getUnableToIdentifyFormItem("CRME.Th_SurgTrainee2NIT", "No surgical trainee in theatre")}

                            </Container>
                        </Container>
                    </Container>

                </Container>
            );

            // Q7.2.0

            // CRME 7.2.0, 7.2.1
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <strong>Who was anaesthetic trainee 1?</strong>
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Th_AnaesTrainee1_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Th_AnaesTrainee1");
                                            }}/>
                                    </div>

                                </div>

                                {getUnableToIdentifyFormItem("CRME.Th_AnaesTrainee1NF")}

                            </Container>
                        </Container>
                    </Container>

                </Container>
            );

            // Q7.3.0

            // CRME 7.3.0, CRME 7.3.1
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <strong>Who was anaesthetic trainee 2?</strong>
                                    </div>

                                    <div className={"col-12"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Th_AnaesTrainee2_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Th_AnaesTrainee2");
                                            }}/>
                                    </div>
                                </div>

                                {getUnableToIdentifyFormItem("CRME.Th_AnaesTrainee2NF")}

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );
        }


        // Q8.0.0
        const CRME_Con_CritCareNotConfig = parseInt(getPatientData("CRME.Con_CritCareNotConfig", "2"));
        const S04ArrivalInTheatreDateNK = parseInt(getPatientData("S04ArrivalInTheatreDateNK", "0"));
        const S04ArrivalInTheatreTimeNK = parseInt(getPatientData("S04ArrivalInTheatreTimeNK", "0"));
        const S04ArrivalInTheatreTimeNE = CommonUtil.parseBool(getPatientData("S04ArrivalInTheatreTimeNE", "false"));

        let showCRME800 = false;

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q8.0.0 - Critical Care Consultant(s) on duty at time of emergency laparotomy</h2>
                </div>
            </div>
        );

        if (CRME_Con_CritCareNotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>The questions about the critical care consultant on-call at time of emergency laparotomy is not currently configured at your site</div>
                    </div>
                </div>
            )
        } else if (
            CRME_Con_CritCareNotConfig === 2
            && S04ArrivalInTheatreTimeNE === false
        ) {
            showCRME800 = true;

            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please provide the following information about the duty (on-call) critical care consultant(s) at the time of emergency laparotomy. EmLap op started at: {getPatientData("S04ArrivalInTheatreDatetime", "(Not set)")}</div>
                    </div>
                </div>
            )
        } else if (
            CRME_Con_CritCareNotConfig === 2
            && S04ArrivalInTheatreTimeNE === true
        ) {
            showCRME800 = true;

            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please provide the following information about the duty (on-call) critical care consultant(s) at the time of the emergency laparotomy. Date/Time operation started not available in NELA</div>
                    </div>
                </div>
            )
        }

        let q8AdditionalInformation = (<div className={"alert alert-light text-center"}>No additional information</div>);
        if (
            CRME_Con_CritCareNotConfig === 2
            && S04ArrivalInTheatreDateNK === 2
            && S04ArrivalInTheatreTimeNK === 2
            && S04ArrivalInTheatreTimeNE === false
        ) {
            let critCareDateLabel = "(Not set)";
            let critCareDateValue = getPatientData("S04ArrivalInTheatreDatetime", null);
            if (critCareDateValue !== null) {
                critCareDateLabel = Chronos.parse(critCareDateValue).format("dd/MM/yyyy HH:mm");
            }

            q8AdditionalInformation = (<div className={"alert alert-info text-center"}>The emergency laparotomy started at: <strong>{critCareDateLabel}</strong></div>);
        } else if (
            CRME_Con_CritCareNotConfig === 2
            && S04ArrivalInTheatreTimeNE === true
        ) {
            q8AdditionalInformation = [];
        }

        if (showCRME800) {
            formContents.push(
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div><strong>Additional Information</strong></div>
                        {q8AdditionalInformation}
                    </div>
                </div>
            );

            // CRME 8.0.1 & CRME 8.0.2
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <strong>Who was critical care consultant 1?</strong>
                                    </div>

                                    <div className={"col-12"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Con_CritCare1_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Con_CritCare1");
                                            }}/>
                                    </div>
                                </div>

                                {getUnableToIdentifyFormItem("CRME.Con_CritCare1NF")}

                            </Container>
                        </Container>
                    </Container>
                </Container>
            );

            // CRME 8.0.3 & 8.0.4
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Container className={"card"}>
                            <Container className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <strong>Who was critical care consultant 2?</strong>
                                    </div>

                                    <div className={"col-12"}>
                                        <CaseRecordManualEntryUserListComponent
                                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                                            data={getPatientData("CRME.Con_CritCare2_data", "[]")}
                                            callback={(action, data) => {
                                                userListDidCallback(action, data, "CRME.Con_CritCare2");
                                            }}/>
                                    </div>
                                </div>

                                {getUnableToIdentifyFormItem("CRME.Con_CritCare2NF")}

                            </Container>
                        </Container>
                    </Container>

                </Container>
            );
        }

        // Q9.0.0
        const S07Status_Disch = parseInt(getPatientData("S07Status_Disch", ""));
        const CRME_Con_COTENotConfig = parseInt(getPatientData("CRME.Con_COTENotConfig", "2"));
        const CRME_COTE_InputNotConfig = parseInt(getPatientData("CRME.COTE_InputNotConfig", "2"));
        const QIN_CaseRecordStatusId = parseInt(getPatientData("QIN_CaseRecordStatusId", "1"));
        const S07Geriatric_Postop = parseInt(getPatientData("S07Geriatric_Postop", "0"));
        const S01AgeOnArrival = getPatientData("S01AgeOnArrival", "0");
        const S02FrailtyScore = parseInt(getPatientData("S02FrailtyScore", "0"));

        formContents.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h2>CRME Q9.0.0 Care of the Elderly (COTE) / Frailty review</h2>
                </div>
            </div>
        );

        let showQ9Questions = false;

        if (CRME_Con_COTENotConfig === 1) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-warning text-center"}>This question about COTE input is not configured for use at your site, no data entry required.</div>
                    </div>
                </div>
            )
        } else if (
            CRME_Con_COTENotConfig === 2
            && S01AgeOnArrival >= 65
        ) {
            showQ9Questions = true;

            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Please complete all 9.x.x questions below</div>
                    </div>
                </div>
            )
        } else if (
            CRME_Con_COTENotConfig === 2
            && S01AgeOnArrival < 65
        ) {
            formContents.push(
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-secondary text-center"}>Patient &lt; 65, further information not required</div>
                    </div>
                </div>
            )
        }

        if (showQ9Questions) {
            // CRME 9.0.1
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown
                            name={"CRME.COTE_DeliriumAssess"}
                            label={"Can you identify the documented performance of a delirium screening assessment within the clinical record, using a recognised tool eg the 4AT?"}
                            className={"form-control"}>

                            <option value={"1"}>Yes - 4AT tool used</option>
                            <option value={"2"}>Yes - Other delirium tool used</option>
                            <option value={"3"}>No delirium assessment identified in clinical record</option>
                            <option value={"4"}>Unable to access clinical record to check</option>

                        </DropDown>
                    </Container>
                </Container>
            );

            // CRME 9.0.2
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown
                            name={"CRME.COTE_EscalationPlan"}
                            label={"Can you identify a documented escalation plan present in the clinical record?"}
                            className={"form-control"}>

                            <option value={"1"}>Yes - Escalation plan documented</option>
                            <option value={"2"}>No escalation plan identified in clinical record</option>
                            <option value={"3"}>Unable to access clinical record to check</option>

                        </DropDown>
                    </Container>
                </Container>
            );

            // CRME 9.0.3
            formContents.push(
                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <DropDown
                            name={"CRME.COTE_F2F"}
                            label={"Is there documented evidence of an assessment by the perioperative frailty/Elderly Care/Geriatric team, and was their input  Face to Face (f2f) or virtual?"}
                            className={"form-control"}>

                            <option value={"1"}>Yes - F2F</option>
                            <option value={"2"}>Yes - But remote/virtual assessment only</option>
                            <option value={"3"}>Yes - But can't assess whether F2F or virtual/remote</option>
                            <option value={"4"}>No evidence in clinical record of either virtual or f2f input from perioperative frailty team in clinical record</option>
                            <option value={"5"}>Unable to access clinical record to check</option>

                        </DropDown>
                    </Container>
                </Container>
            );

            // CRME 9.0.4
            formContents.push(
                <div className={"row mt-3"}>
                    <div className={"col-12"}>
                        <label className={"mb-0"}>Who was COTE specialist 1?</label>
                    </div>

                    <div className={"col-12"}>
                        <CaseRecordManualEntryUserListComponent
                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                            data={getPatientData("CRME.Con_COTE1_data", "[]")}
                            callback={(action, data) => {
                                userListDidCallback(action, data, "CRME.Con_COTE1");
                            }} />
                    </div>
                </div>
            );

            // CRME 9.0.5
            formContents.push(getUnableToIdentifyFormItem("CRME.Con_COTE1NF"));

            // CRME 9.0.6
            formContents.push(
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label className={"mb-0"}>Who was COTE specialist 2?</label>
                    </div>

                    <div className={"col-12 mt-2"}>
                        <CaseRecordManualEntryUserListComponent
                            mode={CaseRecordManualEntryUserListMode.SINGLE}
                            data={getPatientData("CRME.Con_COTE2_data", "[]")}
                            callback={(action, data) => {
                                userListDidCallback(action, data, "CRME.Con_COTE2");
                            }} />
                    </div>
                </div>
            );

            // CRME 9.0.7
            formContents.push(getUnableToIdentifyFormItem("CRME.Con_COTE2NF"));
        }

        // END OF QUESTIONS

        let submitButton = (<Button label={"Save Changes"} className={"btn btn-success disabled"} onClick={() => 1 + 1} />);
        if (canSubmitForm()) {
            submitButton = (<Button label={"Save Changes"} className={"btn btn-success"} />);
        }

        let nhsCheckButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-success"} onClick={doesConfirmationMatch}>Check</button>
                </div>
            </div>
        );

        let showForm = false;
        let confirmationMessage = [];
        if (nhsCheckStatus !== undefined) {
            confirmationMessage = (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-success"}>
                            The NHS number entered matches the NHS number of this QIN-EmLap-CR.
                        </div>
                    </div>
                </div>
            );

            if (!nhsCheckStatus) {
                confirmationMessage = (
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-danger"}>
                                The NHS number entered does not match the NHS number of this QIN-EmLap-CR, data entry is
                                only allowed when NHS numbers match. Please confirm the clinical and QIN-EmLap-CR refer
                                to the same patient and repeat the process.
                            </div>
                        </div>
                    </div>
                )
            } else {
                showForm = true;
                nhsCheckButton = [];
            }
        }

        let formElem = [];
        if (showForm) {
            formElem = (
                <div className={"manual-entry-form"}>
                    <Former
                        state={patientData}
                        callback={formDidCallback}>

                        {formContents}

                        <Container className={"row mt-4"}>
                            <Container className={"col-12 text-center"}>
                                {submitButton}
                            </Container>
                        </Container>

                    </Former>
                </div>
            );
        }

        mainContent = (
            <div className={"row justify-content-center mb-4"}>
                <div className={"col-12 col-md-10 col-lg-8"}>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"alert alert-light text-center"}>
                                If your site has opted out of collecting some or all of the information requested on this input page, or if the information does not relate to this patient, then those questions will be greyed out and unavailable for you to complete.  Complete all questions where input is possible.
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-header"}>
                                    Data Conformity
                                </div>

                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>Patient Name</label>
                                            <div className={"form-control"}>
                                                {getPatientName()}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row mt-2"}>
                                        <div className={"col-12"}>
                                            <label>Please enter the NHS Number of the patient you are entering data for. You <strong>must</strong> complete this field.</label>
                                            <input type={"text"} className={"form-control"} value={confirmationInput} onChange={(e) => setConfirmationInput(e.target.value)} />
                                        </div>
                                    </div>

                                    {confirmationMessage}

                                    {nhsCheckButton}
                                </div>
                            </div>
                        </div>
                    </div>

                    {formElem}

                </div>
            </div>
        )
    }

    return (
        <div className={"app-section case-record-manual-entry-screen"}>

            {mainContent}

            <OrganisationStaffModal
                shown={userSelectionOpen}
                callback={organisationStaffModalDidCallback} />

        </div>
    )

}
/*
<UserSelectionDialogComponent
                open={userSelectionOpen}
                allowCreation={true}
                callback={addUserToPatientData}/>
 */

/*
const [debugKeyName, setDebugKeyName] = useState("");
    const [debugValue, setDebugValue] = useState("");


<div className={"row"}>
                <div className={"col-6"}>
                    <label>Override Patient Data</label>
                    <input type={"text"} className={"form-control"} value={debugKeyName} onChange={(e) => setDebugKeyName(e.target.value)} />
                    <input type={"text"} className={"form-control"} value={debugValue} onChange={(e) => setDebugValue(e.target.value)} />
                    <button className={"btn btn-primary"} onClick={() => {
                        putPatientData(debugKeyName, debugValue);
                    }}>Set</button>
                </div>
            </div>

 */