export const PatientUtil = {
    genders : {
        MALE : 2,
        FEMALE : 1,
        UNSPECIFIED : 3
    },
    getGenderLabel : (genderId) => {
        genderId = parseInt(genderId);
        if (genderId === PatientUtil.genders.MALE) {
            return "Male";
        } else if (genderId === PatientUtil.genders.FEMALE) {
            return "Female";
        }
        return "Unspecified";
    }
}