import React from 'react';
import './AlertModal.css';

class AlertModal extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    static show = (title, message, buttons, icon) => {
        window._showModal(title, message, buttons, icon);
    }

    static dismiss = () => {
        window._dismissModal();
    }

    static showError = (message) => {
        AlertModal.show(
            "Error",
            message,
            [
                AlertModal.button(
                    "OK",
                    () => {
                        AlertModal.dismiss();
                    }
                )
            ]
        );
    }

    static button = (label, callback, className) => {
        return {
            label,
            click : callback,
            style : className
        };
    }

    dismissClicked() {
        if (this.props.dismissHandler != null) {
            this.props.dismissHandler();
        }
    }

    render() {
        let isOpen = false;
        if (this.props.open !== null) {
            isOpen = this.props.open;
        }

        var buttons = [];

        if (this.props.buttons != null) {
            this.props.buttons.forEach(function(element, index) {
                var buttonClass = "btn";
                if (element.style != null) {
                    buttonClass += " " + element.style;
                } else {
                    buttonClass += " btn-outline-dark";
                }

                var clickListener = () => {};
                if (element.click != null) {
                    clickListener = element.click;
                }

                buttons.push(<span key={index} className={ buttonClass } onClick={() => clickListener()}>{ (element.label != null) ? element.label : "" }</span>);
            });
        }

        if (isOpen) {
            console.log("AMC: ");
            console.log(this.props.content);

            return (
                <div className={"alert-modal"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-header"}>
                            {(this.props.title != null) ? this.props.title : "Alert"}
                            <span className={"close"} onClick={() => this.dismissClicked() }>X</span>
                        </div>
                        <div className={"modal-body"}>
                            {(this.props.content != null) ? this.props.content : ""}
                        </div>
                        <div className={"modal-footer"}>
                            { buttons }
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<React.Fragment />);
        }
    }

}

export default AlertModal;