import React, {useEffect, useState} from "react";
import {BaseModal, BaseModalSize} from "../../alertmodal/BaseModal";
import {CommonUtil} from "../../../util/CommonUtil";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {Button, Container, DropDown, Former, FormerActions, Text} from "../../form/Former";
import SegmentedButtonComponent from "../../common/SegmentedButtonController";
import {FilterText} from "../../filter/FilterText";

export const OrganisationStaffActions = {
    ACTION_SELECT : "organisationStaffActions.select"
};

export const OrganisationStaffModal = (props) => {

    const MODE_SELECT = 1;
    const MODE_CREATE = 2;

    const {shown} = props;
    const {callback} = props;

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);

    const [formState, setFormState] = useState({});
    const [formError, setFormError] = useState(null);

    const [callbackData, setCallbackData] = useState();

    const [mode, setMode] = useState(MODE_SELECT);

    useEffect(() => {
        if (shown) {
            fetchStaffFromNetwork();
        } else {
            setMode(MODE_SELECT);
            setPage(1);
            setTableData([]);
            setTotalCount(0);
            setFormState({});
            setFormError(undefined);
            setForceDismiss(false);
            setNetworkInFlight(false);
            setSubmitNetworkInFlight(false);
            setKeyword("");
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        fetchStaffFromNetwork();
    }, [page, keyword]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function tableRowWasSelected(row) {
        staffWasSelected(row);
    }

    function staffWasSelected(staff) {
        setCallbackData(staff);
        setForceDismiss(true);
    }

    function formerDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitStaffMemberOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            console.log("CHANGE", data);
            const newState = CommonUtil.cloneObject(formState);

            if (data.value !== null) {
                newState[data.name] = data.value;
            } else {
                delete newState[data.name];
            }
            setFormState(newState);
        }
    }

    function fetchStaffFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = new FormData();
        data.append("page", page);

        if (keyword && keyword !== "") {
            data.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.organisation.getOrganisationStaff, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.show(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.show(API.defaultError("FOS1000C"));
            });
    }

    function submitStaffMemberOverNetwork(data) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        if (data.hasOwnProperty("title")) {
            formData.append("title", data.title);
        }
        formData.append("givenName", data.givenName);
        formData.append("familyName", data.familyName);

        if (data.hasOwnProperty("speciality")) {
            formData.append("speciality", data.speciality);
        }

        if (data.hasOwnProperty("gmcNumber")) {
            formData.append("gmcNumber", data.gmcNumber);
        }

        Axios.post(ENDPOINTS.organisation.submitOrganisationStaff, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    staffWasSelected(resp.data.staff);
                } else {
                    setFormError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                setFormError(API.defaultError("SOS1000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    let formErrorElem = [];
    if (formError) {
        formErrorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger text-center"}>
                        {formError}
                    </div>
                </div>
            </div>
        );
    }

    let mainContent = [];

    if (mode === MODE_SELECT) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <FilterText
                                        placeholder={"Start typing to search"}
                                        callback={setKeyword}/>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <div className={"table-container"}>
                                        <TableComponent
                                            className={"common-table alternating clickable"}
                                            emptyMessage={"There are no Staff Members available"}
                                            data={tableData}
                                            onRowClick={tableRowWasSelected}>

                                            <Column name={"title"} title={"Title"}/>
                                            <Column name={"givenName"} title={"First Name"}/>
                                            <Column name={"familyName"} title={"Surname"}/>
                                            <Column name={"speciality"} className={"text-center"} title={"Speciality"}/>
                                            <Column name={"gmcNumber"} className={"text-center"} title={"GMC Number"}/>
                                            <Column name={"id"} className={"text-center"} title={""}
                                                    render={(data, row) => (
                                                        <span className={"btn btn-primary"}>Select</span>
                                                    )}/>
                                        </TableComponent>
                                    </div>

                                    <div className={"paginator-container d-flex mt-2"}>
                                        <div className={"spacer"}/>
                                        <div className={"paginator-area"}>
                                            <TablePaginator
                                                totalCount={totalCount}
                                                pageSize={20}
                                                page={page}
                                                onClick={(page) => setPage(page)}/>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <Former
                                state={formState}
                                callback={formerDidCallback}>

                                <Container className={"row"}>
                                    <Container className={"col-12 col-md-4"}>
                                        <DropDown
                                            name={"title"}
                                            label={"Title"}
                                            className={"form-control"}>

                                            <option value={""}>(None)</option>
                                            <option value={"Dr"}>Dr</option>
                                            <option value={"Ms"}>Ms</option>
                                            <option value={"Mrs"}>Mrs</option>
                                            <option value={"Mr"}>Mr</option>

                                        </DropDown>
                                    </Container>

                                    <Container className={"col-12 col-md-4"}>
                                        <Text
                                            name={"givenName"}
                                            label={"First Name"}
                                            className={"form-control"}
                                            mandatory={true}/>
                                    </Container>

                                    <Container className={"col-12 col-md-4"}>
                                        <Text
                                            name={"familyName"}
                                            label={"Surname"}
                                            className={"form-control"}
                                            mandatory={true}/>
                                    </Container>
                                </Container>

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12 col-md-6"}>
                                        <Text
                                            name={"speciality"}
                                            label={"Speciality"}
                                            className={"form-control"}/>
                                    </Container>

                                    <Container className={"col-12 col-md-6"}>
                                        <Text
                                            name={"gmcNumber"}
                                            label={"GMC Number"}
                                            className={"form-control"}/>
                                    </Container>
                                </Container>

                                {formErrorElem}

                                <Container className={"row mt-2"}>
                                    <Container className={"col-12 text-center"}>
                                        <Button
                                            className={"btn btn-success"}
                                            label={"Submit"}/>
                                    </Container>
                                </Container>

                            </Former>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <BaseModal
            shown={shown}
            size={BaseModalSize.XLARGE}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row mb-4"}>
                <div className={"col-12"}>
                    <SegmentedButtonComponent
                        buttons={[
                            {
                                id: MODE_SELECT,
                                label: "Select Staff"
                            },
                            {
                                id: MODE_CREATE,
                                label: "Add Staff"
                            }
                        ]}
                        selectedId={mode}
                        callback={setMode}/>

                </div>
            </div>

            {mainContent}

            <div className={"row mt-4"}>
                <div className={"col-12 text-end"}>
                    <button className={"btn btn-outline-dark"} onClick={() => setForceDismiss(true)}>Cancel</button>
                </div>
            </div>

        </BaseModal>
    )

}