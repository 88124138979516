import React from 'react';
import {useEffect, useState} from "react";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import HTMLReactParser from "html-react-parser";

import "./ClinicalStandardEditorComponent.css";

export const ClinicalStandardEditorActions = {
    SUBMIT : "clinstanded.submit"
};

export const ClinicalStandardEditorComponent = (props) => {

    const {content} = props;
    const {writable} = props;
    const {canSubmit} = props;
    const {callback} = props;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (content && writable) {
            try {
                const contentBlock = htmlToDraft(content);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [content]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitForm() {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        handleCallback(ClinicalStandardEditorActions.SUBMIT, content);
    }

    // RENDER

    let useContent = "";
    if (content) {
        useContent = content;
    }

    let mainContent = [];
    if (writable) {
        let saveButton = (<button className={"btn btn-primary"} onClick={() => submitForm()}>Save</button>);
        if (!canSubmit) {
            saveButton = (<button className={"btn btn-primary disabled"}>Save</button>);
        }

        mainContent = [
            <div className={"row"}>
                <div className={"col-12"}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={{
                            options : ['inline', 'list', 'link', 'remove']
                        }} />
                </div>
            </div>,
            <div className={"row mt-2"}>
                <div className={"col-12 text-right"}>
                    {saveButton}
                </div>
            </div>
        ];
    } else {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 standard-display"}>
                    {HTMLReactParser(useContent)}
                </div>
            </div>
        );
    }

    return (
        <div className={"card clinical-standard-editor"}>
            <div className={"card-body"}>
                {mainContent}
            </div>
        </div>
    )

}