import React from "react";
import BaseComponent from "../../BaseComponent";
import {Chronos} from "../../../entity/Chronos";

import zoomInIcon from "../../../assets/qinotify/img/icon_zoom_in.svg";
import zoomOutIcon from "../../../assets/qinotify/img/icon_zoom_out.svg";

import "./TimelineComponent.css";

export default class TimelineComponent extends BaseComponent {

    MAX_VERTICAL_STEP = 3;

    DAY_ELEMENT_WIDTH = 480; // px

    timelineItems = [];

    constructor(props, context) {
        super(props, context);

        this.initState({
            data : this.props.data,
            firstDay : this.props.firstDay,
            timelineItems : [],
            dayZoom : 1
        });
    }

    getValueOrDefault = (keyName, defaultValue) => {
        if (this.props.data !== undefined) {
            if (this.props.data.hasOwnProperty(keyName)) {
                return this.props.data[keyName];
            }
        }
        return defaultValue;
    }

    parsePatientData() {
        let timelineItems = [];

        if (this.props.timeline !== undefined) {
            for (let i = 0; i < this.props.timeline.length; i++) {
                let timeline = this.props.timeline[i];

                let value = this.getValueOrDefault(timeline.dataKey, timeline.value);

                if (value != null) {
                    value = Chronos.parse(value).getDate();
                    timelineItems.push({
                        date: value,
                        title : timeline.title,
                        key : timeline.dataKey
                    });
                }
            }
        }

        // Sort the properties so they appear in sequential order
        timelineItems.sort((t1, t2) => {
            if (Chronos.before(t1.date, t2.date)) {
                return -1;
            } else if (Chronos.after(t1.date, t2.date)) {
                return 1;
            }
            return 0;
        });

        this.timelineItems = timelineItems;
    }

    generateDaySegmentElement = (date, dayItems) => {
        let cronosDate = Chronos.with(date);

        let startOfDay = Chronos.with(cronosDate.startOfDay());
        let startOfDaySeconds = startOfDay.seconds();

        let daySinceStart = 0;
        if (this.state.firstDay !== undefined && this.state.firstDay > 0) {
            let firstDayStartOfDay = Chronos.withTimestampSeconds(this.state.firstDay).startOfDay();
            daySinceStart = startOfDay.difference(firstDayStartOfDay, Chronos.DAYS);
            daySinceStart = Math.max(0, daySinceStart);
            daySinceStart++;
        }

        let dayTitle = [];
        if (daySinceStart > 0) {
            dayTitle = (
                <div className={"day-title"}>Day {daySinceStart}</div>
            )
        }

        let elementWidth = (this.DAY_ELEMENT_WIDTH * this.state.dayZoom) + "px";

        return (
            <div className={"day-segment"} style={{ width : elementWidth }}>
                <div className={"day-container"} style={{ width : elementWidth }}>
                    {dayTitle}
                    <div className={"day-items"}>
                        <div className={"vertical-line start-line"} />
                        {
                            dayItems.map((dayItem) => {
                                let dayCronos = Chronos.with(dayItem.date);

                                let position = ((dayCronos.seconds() - startOfDaySeconds) / 86400) * 100;

                                return (
                                    <div className={"day-item i" + dayItem.step} style={{ marginLeft : position + "%"}}>
                                        <div className={"date"}>{dayCronos.format("HH:mm")}</div>
                                        <div className={"detail"}>{dayItem.title}</div>
                                        <div className={"line"} />
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className={"bottom-line"}>
                        <div className={"line-container"}>
                            <div className={"space"} />
                            <div className={"line"} />
                            <div className={"space"} />
                            <div className={"line"} />
                        </div>

                        <div className={"text-container"}>
                            <div className={"text text-day"}>
                                {cronosDate.format("dd MMM")}
                            </div>
                            <div className={"space"}/>
                            <div className={"text"}>12pm</div>
                            <div className={"space"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    controlDayZoom = (increment) => {
        let currentZoom = this.state.dayZoom;
        currentZoom += increment;
        if (currentZoom < 1) {
            currentZoom = 1;
        } else if (currentZoom > 10) {
            currentZoom = 10;
        }

        this.setState({
            dayZoom : currentZoom
        });
    }

    render() {
        this.parsePatientData();

        let bookend = (
            <div className={"day-segment bookend"}>
                <div className={"day-container"}>
                    <div className={"day-items"} />
                    <div className={"bottom-line"}>
                        <div className={"line-container"}>
                            <div className={"space"} />
                            <div className={"line"} />
                        </div>
                        <div className={"text-container"}/>
                    </div>
                </div>
            </div>
        );

        let daySegments = [];
        let dayItems = [];
        let lastDate = null;
        let lastDateString = null;

        let verticalStep = 0;

        for (let i = 0; i < this.timelineItems.length; i++) {
            let timelineItem = this.timelineItems[i];

            if (timelineItem.date == null) {
                continue;
            }

            let currentDate = Chronos.with(timelineItem.date);
            if (currentDate == null) {
                continue;
            }

            let currentDateString = currentDate.format("yyyy-MM-dd");

            if (currentDateString !== lastDateString) {
                if (dayItems.length > 0) {
                    let dayDifferenceBetween = currentDate.difference(lastDate, Chronos.DAYS);

                    let extraAppends = [];

                    if (dayDifferenceBetween > 0) {
                        if (dayDifferenceBetween > 2) {
                            extraAppends.push(
                                <div className={"day-segment time-gap"}>
                                    <div className={"day-container"}>
                                        <div className={"day-items"}>
                                            <div className={"vertical-line start-line"} />
                                        </div>
                                        <div className={"bottom-line"}>
                                            <div className={"line-container"}>
                                                <div className={"space"} />
                                                <div className={"line"} />
                                            </div>
                                            <div className={"text-container"}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            // Generate in-between days
                            let lastDateChronos = Chronos.with(lastDate);
                            for (let x = 0; x < (dayDifferenceBetween - 1); x++) {
                                lastDateChronos.add(1, Chronos.DAYS);
                                extraAppends.push(
                                    this.generateDaySegmentElement(lastDateChronos.getDate(), [])
                                );
                            }
                        }
                    }

                    daySegments.push(
                        this.generateDaySegmentElement(lastDate, dayItems)
                    );

                    for (let x = 0; x < extraAppends.length; x++) {
                        daySegments.push(extraAppends[x]);
                    }
                }

                lastDate = currentDate.getDate();
                lastDateString = currentDate.format("yyyy-MM-dd");
                dayItems = [];
            }

            timelineItem.step = verticalStep;
            dayItems.push(timelineItem);

            verticalStep++;
            if (verticalStep > this.MAX_VERTICAL_STEP) {
                verticalStep = 0;
            }
        }

        if (dayItems.length > 0) {
            daySegments.push(
                this.generateDaySegmentElement(lastDate, dayItems)
            );
        }

        // Make sure we have at least 3 days represented
        if (daySegments.length < 3) {
            let addDays = 1;
            if (lastDate === null) {
                lastDate = Chronos.now().getDate();
                addDays = 0;
            }

            let fillerDate = Chronos.with(lastDate);

            if (addDays > 0) {
                fillerDate.add(addDays, Chronos.DAYS);
            }

            let fillerItems = 3 - daySegments.length;
            for (let i = 0; i < fillerItems; i++) {
                daySegments.push(
                    this.generateDaySegmentElement(fillerDate.getDate(), [])
                );
                fillerDate.add(1, Chronos.DAYS);
            }
        }

        return (
            <div className={"timeline-wrapper"}>
                <div className={"timeline-component"}>
                    <div className={"timeline-day-wrapper"}>
                        {bookend}

                        {daySegments}

                        {bookend}
                    </div>
                </div>

                <div className={"zoom-controls"}>
                    <span className={"zoom-control zoom-in"} onClick={() => this.controlDayZoom(1)} style={{backgroundImage : "url(" + zoomInIcon + ")"}} />
                    <span className={"zoom-control zoom-out"} onClick={() => this.controlDayZoom(-1)} style={{backgroundImage : "url(" + zoomOutIcon + ")"}} />
                </div>
            </div>

        )
    }

}