import {BaseModal, BaseModalButton, BaseModalButtonTypes} from "../../alertmodal/BaseModal";
import React, {useEffect, useState} from "react";
import {CommonUtil} from "../../../util/CommonUtil";
import {CaseRecord} from "../../../util/CaseRecord";

export const CaseRecordPublicationQuestionDialog = (props) => {

    const {shown} = props;
    const {question} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (!shown) {
            setForceDismiss(false);
            setCallbackData(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    // RENDER

    if (!shown) return [];

    let title = "";
    let message = "";
    let inputElem = [];

    if (question) {
        title = CommonUtil.getOrDefault(question, "title", title);
        message = CommonUtil.getOrDefault(question, "message", message);

        let inputType = parseInt(CommonUtil.getOrDefault(question, "inputTypeId", CaseRecord.publicationQuestionTypes.TEXT));

        if (inputType === CaseRecord.publicationQuestionTypes.TEXT) {
            // TODO Text input
        } else if (inputType === CaseRecord.publicationQuestionTypes.MULTI) {
            if (question.hasOwnProperty("options")) {
                if (question.options && question.options.length > 0) {
                    question.options.forEach((option) => {
                        let positiveAction = true;
                        if (option.hasOwnProperty("positiveAction")) {
                            positiveAction = parseInt(option.positiveAction) === 1;
                        }

                        let className = BaseModalButtonTypes.SUCCESS;
                        if (!positiveAction) {
                            className = BaseModalButtonTypes.DANGER;
                        }

                        inputElem.push(
                            <BaseModalButton
                                label={option.title}
                                className={className}
                                callback={() => {
                                    setCallbackData({
                                        question,
                                        option
                                    });
                                    setForceDismiss(true);
                                }} />
                        );
                    });
                }
            }

            inputElem = (
                <div className={"alert-modal-dialog-buttons"}>
                    {inputElem}

                    <BaseModalButton
                        label={"Cancel"}
                        callback={() => {
                            setCallbackData({
                                cancelled : true
                            });
                            setForceDismiss(true);
                        }} />
                </div>
            )
        }
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"alert-modal-dialog-title"}>{title}</div>
            <div className={"alert-modal-dialog-message"}>{message}</div>

            {inputElem}

        </BaseModal>
    )

}