import React, {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import {CommonUtil} from "../../../util/CommonUtil";

export const OrganisationEditorAppConfigComponent = (props) => {

    const keys = ["qin_admin_email", "qin_user_email_subject", "qin_user_email_body"];

    const {organisationId} = props;

    const [appConfig, setAppConfigData] = useState({});

    const [configNetworkInFlight, setConfigNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchConfigFromNetwork();
    }, []);

    function setAppConfig(key, value) {
        let newAppConfig = {};
        if (appConfig) {
            newAppConfig = CommonUtil.cloneObject(appConfig);
        }

        newAppConfig[key] = value;

        console.log(newAppConfig);

        setAppConfigData(newAppConfig);
    }

    function fetchConfigFromNetwork() {
        if (configNetworkInFlight) return;
        setConfigNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", organisationId);
        formData.append("keys", JSON.stringify(keys));

        Axios.post(ENDPOINTS.appConfig.getOrganisationConfig, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setAppConfigData(resp.data.appConfig);
                } else {
                    console.log(API.formatError(resp));
                }
                setConfigNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setConfigNetworkInFlight(false);
            });
    }

    function submitConfigOverNetwork() {
        if (configNetworkInFlight) return;
        setConfigNetworkInFlight(true);

        const formData = new FormData();
        formData.append("data", JSON.stringify(appConfig));

        Axios.post(ENDPOINTS.appConfig.submitOrganisationConfig, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        "Success",
                        "Configuration saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setConfigNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setConfigNetworkInFlight(false);
                AlertModal.showError(API.defaultError("OACS1000C"));
            });
    }

    // RENDER

    let saveButton = (<button className={"btn btn-success"} onClick={() => submitConfigOverNetwork()}>Save</button>);
    if (configNetworkInFlight) {
        saveButton = (<button className={"btn btn-success disabled"}>Save</button>)
    }

    return (
        <div className={"card"}>
            <div className={"card-header"}>
                <div className={"d-flex align-items-center"}>
                    <div className={"flex-fill"}>
                        <h5>Configuration</h5>
                    </div>

                    <div>
                        {saveButton}
                    </div>
                </div>
            </div>

            <div className={"card-body"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h6>Contact Details</h6>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>QIN Admin Email</label>
                        <input
                            type={"text"}
                            className={"form-control"}
                            value={CommonUtil.getOrDefault(appConfig, "qin_admin_email", "")}
                            onChange={(e) => setAppConfig("qin_admin_email", e.target.value)}
                        />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h6>Email Configuration</h6>
                    </div>

                    <div className={"col-12"}>
                        <div className={"alert alert-info"}>
                            Set the default Subject and Email Message for inter-user communications.
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Email Subject</label>
                        <input
                            type={"text"}
                            className={"form-control"}
                            value={CommonUtil.getOrDefault(appConfig, "qin_user_email_subject", "")}
                            onChange={(e) => setAppConfig("qin_user_email_subject", e.target.value)}
                        />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Email Body</label>
                        <input
                            type={"text"}
                            className={"form-control"}
                            value={CommonUtil.getOrDefault(appConfig, "qin_user_email_body", "")}
                            onChange={(e) => setAppConfig("qin_user_email_body", e.target.value)}
                        />
                    </div>
                </div>

            </div>
        </div>
    )

}