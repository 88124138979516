import React from "react";
import BaseComponent from "../BaseComponent";

import "./PasswordResetComponent.css";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Link} from "react-router-dom";

export default class ForgottenPasswordComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            requestNetworkInFlight : false,
            submissionSuccess : false,
            emailAddress : "",
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : []
        });
    }

    componentDidMount() {
        super.componentDidMount();
    }

    submitPasswordResetRequest = () => {
        if (this.state.requestNetworkInFlight) return;

        if (this.state.emailAddress !== "" && !this.state.emailAddress.includes("@")) {
            this.showError("Please provide a valid Email Address");
            return;
        }

        this.setState({
            requestNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("emailAddress", this.state.emailAddress);

        Axios.post(ENDPOINTS.login.requestPasswordReset, formData)
            .then((r) => {
                let resp = API.parse(r);

                let state = {
                    requestNetworkInFlight : false
                };

                if (resp.success) {
                    state.submissionSuccess = true;
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
            });
    }

    showError = (message) => {
        this.showModal("Error", message, [
            { label : "OK", click : () => { this.hideModal() }}
        ]);
    }

    showModal = (title, message, buttons) => {
        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        let content = [];

        if (this.state.submissionSuccess) {
            content = (
                <div className={"text-center"}>
                    <h2>Check your email</h2>
                    <p>We have sent you a Password Reset email. Please check your inbox for the email, it will contain further instructions for you to follow.</p>
                    <p>If you do not receive the email, please check your Spam or Junk folders or categories. If you receive no email at all, you may not have an account in the system. Please contact a system administrator to confirm that your account exists and is still valid.</p>
                    <p><Link to={"/"}>Back to Login</Link></p>
                </div>
            )
        } else {
            let submitButton = (<button className={"btn btn-primary"} onClick={this.submitPasswordResetRequest}>Request Password Reset</button>);
            if (this.state.requestNetworkInFlight) {
                submitButton = (<button className={"btn btn-primary disabled"}>Please Wait</button>)
            }

            content = (
                <div>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <h2>Password Reset</h2>
                            <p>Please provide the email address of a valid account. Further details on how to reset your password will be sent to the provided email address.</p>
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12"}>
                            <label>Email Address</label>
                            <input type={"email"} className={"form-control"} name={"emailAddress"} value={this.state.emailAddress} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12 submit-area"}>
                            {submitButton}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={"password-reset-screen"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-4"} />
                    <div className={"col-12 col-md-8 col-lg-4"}>
                        <div className={"field-box"}>
                            {content}
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons} />
            </div>
        )
    }
}