import React, {useEffect, useState} from "react";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import {BaseModal, BaseModalSize} from "../../alertmodal/BaseModal";

export const OrganisationItemEditorModal = (props) => {

    const {shown} = props;
    const {item} = props;
    const {callback} = props;

    const [title, setTitle] = useState("");
    const [bodyState, setBodyState] = useState(EditorState.createEmpty());
    const [enabled, setEnabled] = useState(true);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (shown) {
            setCallbackData(undefined);

            if (item) {
                setTitle(item.title);
                setEnabled(parseInt(item.enabled) === 1);

                if (item.body) {
                    try {
                        const contentBlock = htmlToDraft(item.body);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            setBodyState(EditorState.createWithContent(contentState));
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            } else {
                setTitle("");
                setBodyState(EditorState.createEmpty());
                setEnabled(true);
            }
        } else {
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            if (callbackData !== undefined) {
                data = callbackData;
            }
            callback(action, data);
        }
    }

    function submitForm() {
        if (callback && item) {
            if (title && title !== "") {
                item.title = title;
            } else {
                item.title = null;
            }

            if (bodyState.getCurrentContent().hasText()) {
                item.body = draftToHtml(convertToRaw(bodyState.getCurrentContent()));
            } else {
                item.body = null;
            }

            if (enabled !== undefined) {
                item.enabled = enabled;
            }

            setCallbackData(item);
            setForceDismiss(true);
        }
    }

    // RENDER

    if (!shown) return [];

    let enableWarningElem = [];
    if (!enabled) {
        enableWarningElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-warning"}>
                        This Item will no longer be visible to any user within Case Records.
                    </div>
                </div>
            </div>
        );
    }

    let saveButton = (<button className={"btn btn-success"} onClick={() => submitForm()}>Save</button>);
    if (networkInFlight) {
        saveButton = (<button className={"btn btn-success disabled"}>Please wait</button>)
    }

    return (
        <BaseModal
            shown={true}
            size={BaseModalSize.LARGE}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h5>Configure Item</h5>
                </div>
            </div>

            <div className={"row mt-2 text-center"}>
                <div className={"col-12"}>
                    <label className={"qi-label"}>Title</label>
                    <input type={"text"} className={"form-control"} value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2 text-center"}>
                <div className={"col-12"}>
                    <label className={"qi-label"}>Subtitle</label>
                    <Editor
                        editorState={bodyState}
                        onEditorStateChange={setBodyState}
                        toolbar={{
                            options : ['inline', 'list', 'link', 'remove']
                        }} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <label className={"qi-label"}><input type={"checkbox"} checked={enabled} onChange={(e) => setEnabled(e.target.checked)} /> Visible in Case Records</label>
                </div>
            </div>

            {enableWarningElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {saveButton}

                    <button className={"btn ml-1 btn-outline-dark"} onClick={() => setForceDismiss(true)}>Close</button>
                </div>
            </div>

        </BaseModal>
    )

}