import React from "react";
import BaseComponent from "../../BaseComponent";

import './UserVerificationComponent.css';

class UserVerificationComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        
        this.initState({

        });
    }


    // MARK: Life-cycle

    componentDidMount() {

    }

    componentWillUnmount() {

    }
    
    
    // MARK: Actions
    
    sendReminderTapped() {
        if (this.props.hasOwnProperty("onSendReminder")) {
            this.props.onSendReminder();
        }
    }

    cancelRegistrationAndDeleteUserTapped() {
        if (this.props.hasOwnProperty("onDeleteUserAndCancelRegistration")) {
            this.props.onDeleteUserAndCancelRegistration();
        }
    }
    
    // MARK: Render

    render() { 
        return (
            <div className={"user-verification-section"}>
                <div className={"header"}>Registration Incomplete</div>
                <div className={"label"}>This user has not accepted their invitation to complete setting up their account.</div>
                <div className={"verification-actions"}>
                    <div className={"spacer"} />
                    <div className={"verification-action"}>
                        <span className={"btn btn-warning"} onClick={() => this.sendReminderTapped()}>Send Reminder</span>
                    </div>
                    <div className={"fixed-space"} />
                    <div className={"verification-action"}>
                        <span className={"btn btn-danger"} onClick={() => this.cancelRegistrationAndDeleteUserTapped()}>Cancel Registration and Delete User</span>
                    </div>
                </div>
            </div>
        )
    }


    // MARK: Render Helper Methods

}
 
export default UserVerificationComponent;