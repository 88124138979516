export const ThemeUtil = {
    setThemeCSS : (css) => {
        let headerStyleElements = document.head.getElementsByTagName("style");
        for (let i = 0; i < headerStyleElements.length; i++) {
            if (headerStyleElements[i].id === "header_styles") {
                headerStyleElements[i].innerHTML = css;
            }
        }
    },
    getBrandImage : (images, imageTypeId) => {
        if (images && images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                if (parseInt(images[i].organisationImageTypeId) === parseInt(imageTypeId)) {
                    return images[i];
                }
            }
        }
        return undefined;
    }
}