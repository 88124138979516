import {
    CaseRecordStructure,
    CaseRecordStructureActions,
    CaseRecordStructureComponent
} from "../../caseRecord/CaseRecordStructureComponent";
import React, {useEffect, useState} from "react";

import editIcon from "../../../assets/qinotify/img/icon_edit.svg";
import {OrganisationSegmentEditorModal} from "./OrganisationSegmentEditorModal";
import {OrganisationSectionEditorModal} from "./OrganisationSectionEditorModal";
import {CommonUtil} from "../../../util/CommonUtil";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {OrganisationItemEditorModal} from "./OrganisationItemEditorModal";

import "./OrganisationSegmentConfigurationScreen.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import {OrganisationPatientDataComponent} from "./OrganisationPatientDataComponent";

export const OrganisationSegmentConfigurationScreen = (props) => {

    const {title} = props;

    const [segmentEditorShown, setSegmentEditorShown] = useState(false);
    const [segmentEditorSegment, setSegmentEditorSegment] = useState(null);

    const [sectionEditorShown, setSectionEditorShown] = useState(false);
    const [sectionEditorSection, setSectionEditorSection] = useState(null);

    const [itemEditorShown, setItemEditorShown] = useState(false);
    const [itemEditorItem, setItemEditorItem] = useState(null);

    const [segmentStructure, setSegmentStructure] = useState(undefined);
    const [segmentChanged, setSegmentChanged] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    useEffect(() => {
        if (title) {
            title("Organisation Configuration");
        }
    }, []);

    useEffect(() => {
        if (segmentChanged) {
            window.onbeforeunload = () => {
                return "You have unsaved changes. Are you sure you want to leave this page?"
            }
        } else {
            window.onbeforeunload = undefined;
        }
    }, [segmentChanged]);

    function caseRecordStructureDidCallback(action, data) {
        if (action === CaseRecordStructureActions.SECTION_ITEM_CLICKED) {
            summonItemEditor(data);
        } else if (action === CaseRecordStructureActions.DATA_UPDATED) {
            setSegmentStructure(data);
        }
    }

    function updateSegmentStructure(segmentStructure) {
        setSegmentStructure([...segmentStructure]);
        setSegmentChanged(true);
    }

    function updateCaseRecordSegmentSectionItem(item) {
        let didUpdate = false;
        if (item) {
            console.log("PASS 1");
            if (segmentStructure && Array.isArray(segmentStructure)) {
                segmentStructure.forEach((segment) => {
                    if (segment.sections && Array.isArray(segment.sections)) {
                        console.log("PASS 2");
                        segment.sections.forEach((section) => {
                            if (parseInt(section.id) === parseInt(item.caseRecordSegmentSectionId)) {
                                console.log("PASS 3");
                                if (section.items && Array.isArray(section.items)) {
                                    section.items.forEach((sItem) => {
                                        if (sItem.id === item.id) {
                                            console.log("FOUND ITEM. Updating...");
                                            console.log(item);
                                            console.log(sItem);
                                            sItem.title = CommonUtil.getOrDefault(item, "title", sItem.baseTitle);
                                            sItem.body = CommonUtil.getOrDefault(item, "body", sItem.baseBody);
                                            sItem.enabled = CommonUtil.getOrDefault(item, "enabled", sItem.enabled);

                                            if (sItem.enabled === true) {
                                                sItem.enabled = 1;
                                            } else if (sItem.enabled === false) {
                                                sItem.enabled = 0;
                                            }

                                            sItem.changed = true;
                                            section.changed = true;
                                            segment.changed = true;

                                            didUpdate = true;
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }

        if (didUpdate) {
            console.log("Notifying segment structure change...");
            updateSegmentStructure(segmentStructure);
        }
    }

    function summonSegmentEditor(segment) {
        setSegmentEditorSegment(segment);
        setSegmentEditorShown(true);
    }

    function segmentEditorDidCallback(action, data) {
        if (BaseModalActions.CLOSE) {
            setSegmentEditorShown(false);

            let didUpdate = false;
            if (data) {
                if (segmentStructure && Array.isArray(segmentStructure)) {
                    segmentStructure.forEach((segment) => {
                        if (parseInt(segment.id) === parseInt(data.id)) {
                            segment.segmentNumber = CommonUtil.getOrDefault(data, "segmentNumber", segment.baseSegmentNumber);
                            segment.segmentName = CommonUtil.getOrDefault(data, "segmentName", segment.baseSegmentName);
                            segment.enabled = CommonUtil.getOrDefault(data, "enabled", segment.enabled);

                            if (segment.enabled === true) {
                                segment.enabled = 1;
                            } else if (segment.enabled === false) {
                                segment.enabled = 0;
                            }

                            segment.changed = true;

                            didUpdate = true;
                        }
                    });
                }
            }

            if (didUpdate) {
                updateSegmentStructure(segmentStructure);
            }
        }
    }

    function summonSectionEditor(section) {
        setSectionEditorSection(section);
        setSectionEditorShown(true);
    }

    function sectionEditorDidCallback(action, data) {
        if (BaseModalActions.CLOSE) {
            setSectionEditorShown(false);

            let didUpdate = false;
            if (data) {
                if (segmentStructure && Array.isArray(segmentStructure)) {
                    segmentStructure.forEach((segment) => {
                        if (parseInt(segment.id) === parseInt(data.caseRecordSegmentId)) {
                            if (segment.sections && Array.isArray(segment.sections)) {
                                segment.sections.forEach((section) => {
                                    if (parseInt(section.id) === parseInt(data.id)) {
                                        section.title = CommonUtil.getOrDefault(data, "title", section.baseTitle);
                                        section.enabled = CommonUtil.getOrDefault(data, "enabled", section.enabled);

                                        if (section.enabled === true) {
                                            section.enabled = 1;
                                        } else if (section.enabled === false) {
                                            section.enabled = 0;
                                        }

                                        section.changed = true;
                                        segment.changed = true;

                                        didUpdate = true;
                                    }
                                });
                            }
                        }
                    })
                }
            }

            if (didUpdate) {
                updateSegmentStructure(segmentStructure);
            }
        }
    }

    function summonItemEditor(item) {
        setItemEditorItem(item);
        setItemEditorShown(true);
    }

    function itemEditorDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setItemEditorShown(false);

            if (data) {
                updateCaseRecordSegmentSectionItem(data);
            }
        }
    }

    function submitOrganisationStructureOverNetwork() {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        // Process data, remove any titles that match the base title, etc
        const submitData = [];
        segmentStructure.forEach((segment) => {
            if (segment.sections) {
                if (segment.changed) {
                    let outSegment = {
                        caseRecordSegmentId : segment.id,
                        enabled : segment.enabled,
                        sections : []
                    };

                    if (segment.segmentNumber !== segment.baseSegmentNumber) {
                        outSegment.segmentNumber = segment.segmentNumber;
                    }

                    if (segment.segmentName !== segment.baseSegmentName) {
                        outSegment.segmentName = segment.segmentName;
                    }

                    segment.sections.forEach((section) => {
                        if (section.changed) {
                            let outSection = {
                                caseRecordSegmentSectionId : section.id,
                                enabled : section.enabled,
                                items : []
                            };

                            if (section.title !== section.baseTitle) {
                                outSection.title = section.title;
                            }

                            if (section.items) {
                                section.items.forEach((item) => {
                                    if (item.changed) {
                                        let outItem = {
                                            caseRecordSegmentSectionItemId: item.id,
                                            enabled: item.enabled
                                        };

                                        if (item.title !== item.baseTitle) {
                                            outItem.title = item.title;
                                        }

                                        if (item.body !== item.baseBody) {
                                            outItem.body = item.body;
                                        }

                                        outSection.items.push(outItem);
                                    }
                                });
                            }

                            outSegment.sections.push(outSection);
                        }
                    });

                    submitData.push(outSegment);
                }
            }
        });

        const formData = new FormData();
        formData.append("data", JSON.stringify(submitData));

        Axios.post(ENDPOINTS.caseRecord.submitOrganisationCaseRecordStructure, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSegmentStructure(resp.data.segments);
                    setSegmentChanged(false);
                    
                    Toast.show(
                        "Success",
                        "Configuration saved",
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("OCRS1000C"));
            });
    }

    // RENDER

    let floatingActionsElem = [];
    if (segmentChanged) {
        let saveButton = (<button className={"btn btn-success"} onClick={() => submitOrganisationStructureOverNetwork()}>Save Changes</button>);
        if (submitNetworkInFlight) {
            saveButton = (<button className={"btn btn-success disabled"}>Saving...</button>);
        }

        floatingActionsElem = (
            <div className={"floating-actions"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 text-right"}>
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={"organisation-segment-configuration-screen"}>
            <div className={"row mt-4 justify-content-center "}>
                <div className={"col-12 mb-2 text-center"}>
                    <h3>Configure Case Record Segments</h3>
                </div>

                <div className={"col-12 col-md-8 col-lg-6"}>
                    <CaseRecordStructureComponent
                        data={segmentStructure}
                        callback={caseRecordStructureDidCallback}
                        segmentActions={[
                            CaseRecordStructure.action(
                                editIcon,
                                "Edit",
                                (segment) => {
                                    summonSegmentEditor(segment);
                                }
                            )
                        ]}
                        sectionActions={[
                            CaseRecordStructure.action(
                                editIcon,
                                "Edit",
                                (section) => {
                                    summonSectionEditor(section);
                                }
                            )
                        ]}
                    />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 mb-2 text-center"}>
                    <h3>Data Collection Preferences</h3>
                </div>

                <div className={"col-12 col-md-10 col-lg-8"}>
                    <OrganisationPatientDataComponent />
                </div>
            </div>

            {floatingActionsElem}

            <OrganisationSegmentEditorModal
                shown={segmentEditorShown}
                segment={segmentEditorSegment}
                callback={segmentEditorDidCallback} />

            <OrganisationSectionEditorModal
                shown={sectionEditorShown}
                section={sectionEditorSection}
                callback={sectionEditorDidCallback} />

            <OrganisationItemEditorModal
                shown={itemEditorShown}
                item={itemEditorItem}
                callback={itemEditorDidCallback} />

        </div>
    )

}