import React, {useEffect, useState} from "react";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import {FilterText} from "../filter/FilterText";
import {SystemAdminEditorModal} from "./SystemAdminEditorModal";
import {BaseModalActions} from "../alertmodal/BaseModal";

export const SystemAdminUsersScreen = (props) => {

    const {title} = props;

    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);

    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [editorShown, setEditorShown] = useState(false);
    const [editorUserId, setEditorUserId] = useState();

    useEffect(() => {
        if (title) {
            title("System Administrators");
        }

        fetchUsersFromNetwork();
    }, []);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, [page, keyword, pageSize]);

    function rowWasClicked(row) {
        if (row) {
            summonEditorModal(row.userId);
        }
    }

    function summonEditorModal(userId) {
        setEditorUserId(userId);
        setEditorShown(true);
    }

    function editorDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setEditorShown(false);
            fetchUsersFromNetwork();
        }
    }

    function fetchUsersFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        if (keyword && keyword !== "") {
            formData.append("keyword", keyword);
        }
        formData.append("page", page);
        formData.append("pageSize", pageSize);

        Axios.post(ENDPOINTS.user.getSystemAdminUsers, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.data);
                    setCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SAU1000C"));
            });
    }

    // RENDER

    return (
        <div className={"system-admin-users-screen app-section"}>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-lg-10 col-xl-8"}>
                    <div className={"alert alert-warning"}>
                        <strong>System Administrators can view all Organisations, system-wide reporting and manage other System Administrators.</strong> Please be careful when creating System Administrator accounts.
                    </div>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 col-md-6 col-lg-8"}>
                    <button className={"btn btn-primary"} onClick={() => summonEditorModal()}>Add System Admin</button>
                </div>

                <div className={"col-12 col-md-6 col-lg-4 text-right"}>
                    <FilterText callback={(keyword) => setKeyword(keyword)} />
                </div>
            </div>

            <div className={"row table-area"}>
                <div className={"col-12"}>
                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"There are no Users to display"}
                        data={users}
                        onRowClick={rowWasClicked}>

                        <Column name={"name"} title={"Name"} />
                        <Column name={"role"} title={"Clinical Role"} />
                        <Column name={"userType"} title={"User Role"} />

                        <Column name={"active"} className={"text-center"} title={"Active"} render={(data, row) => {
                            let badgeClass = " badge-success";
                            let badgeLabel = "Active";

                            if (parseInt(data) === 0) {
                                badgeClass = " badge-danger";
                                badgeLabel = "Inactive";
                            }

                            return (
                                <span className={"badge" + badgeClass}>{badgeLabel}</span>
                            )
                        }} />

                    </TableComponent>

                    <div className={"paginator-container"}>
                        <div className={"spacer"} />
                        <div className={"paginator-area"}>
                            <TablePaginator
                                totalCount={count}
                                pageSize={pageSize}
                                page={page}
                                onClick={(page) => setPage(page)} />
                        </div>
                    </div>
                </div>
            </div>

            <SystemAdminEditorModal
                shown={editorShown}
                userId={editorUserId}
                callback={editorDidCallback} />

        </div>
    )

}