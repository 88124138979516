import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./CaseRecordItemComponent.css";
import {SectionItem} from "../../../entity/SectionItem";

import outcomeIconGood from "../../../assets/qinotify/img/outcome_good.png";
import outcomeBadIcon from "../../../assets/qinotify/img/outcome_improve.png";

export default class CaseRecordItemComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            clinicalStandardsOpen : false
        });
    }

    toggleClinicalStandards = () => {
        this.setState({
            clinicalStandardsOpen : !this.state.clinicalStandardsOpen
        });
    }

    render() {
        let clinicalStandardsButton = [];
        let iconImage = undefined;
        if (this.props.item.caseRecordSegmentSectionItemTypeId == SectionItem.ITEM_TYPE_RESULT) {
            if (this.props.item.clinicalStandard != null && this.props.item.clinicalStandard !== "") {
                clinicalStandardsButton = (
                    <button className={"btn btn-primary"} onClick={this.toggleClinicalStandards}>Read the Clinical Standards</button>
                );
            }
        }

        if (parseInt(this.props.item.caseRecordSegmentSectionItemOutcomeTypeId) === SectionItem.OUTCOME_TYPE_POSITIVE) {
            iconImage = outcomeIconGood;
        } else if (parseInt(this.props.item.caseRecordSegmentSectionItemOutcomeTypeId) === SectionItem.OUTCOME_TYPE_NEGATIVE) {
            iconImage = outcomeBadIcon;
        }

        let clinicalStandards = [];
        if (this.state.clinicalStandardsOpen) {
            clinicalStandards = (
                <div className={"case-record-clinical-standard"}>
                    <div className={"clinical-standard-title"}>Clinical Standard</div>
                    <div className={"clinical-standard-body"} dangerouslySetInnerHTML={{__html: this.props.item.clinicalStandard}} />
                </div>
            );
        }

        return (
            <div className={"case-record-item-component"}>
                <div className={"body-container"}>
                    <div className={"case-record-number"}>
                        <span className={"number-text"}>{this.props.item.number}</span>
                        <div className={"icon"} style={{backgroundImage : "url(" + iconImage + ")"}} />
                    </div>
                    <div className={"case-record-body"}>
                        <div className={"case-record-title"}>{this.props.item.title}</div>
                        <div className={"case-record-value"} dangerouslySetInnerHTML={{__html: this.props.item.value}} />
                    </div>
                </div>

                <div className={"case-record-button-area"}>
                    {clinicalStandardsButton}
                </div>

                {clinicalStandards}
            </div>
        );
    }

}