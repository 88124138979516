import React from 'react';
import './SegmentedButtonComponent.css';

export default class SegmentedButtonComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        const selectedId = this.props.selectedId;

        this.state = {
            selectedId : selectedId
        };
        this.setState(this.state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedId && prevProps.selectedId !== this.props.selectedId) {
            this.setState({
                selectedId : this.props.selectedId
            });
        }
    }

    segmentWasClicked = (id) => {
        this.setState({
            selectedId : id
        });

        if (this.props.callback) {
            this.props.callback(id);
        }
    }

    render() {
        var context = this;

        var buttons = [];

        this.props.buttons.forEach(function(element, index) {
            var className = "segment";
            if (context.state.selectedId == element.id) {
                className += " active";
            }

            buttons.push(
                <div className={className} key={element.id} onClick={() =>  context.segmentWasClicked(element.id)}>{ element.label }</div>
            );
        });

        return (
            <div className={"segmented-buttons"}>
                { buttons }
            </div>
        );
    }

}
