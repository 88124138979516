import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {CommonUtil} from "../../util/CommonUtil";
import {ImageUtil} from "../../util/ImageUtil";
import ArrayUtil from "../../util/ArrayUtil";
import {FileUtil} from "../../util/FileUtil";

import "./Former.css";
import AlertModal from "../common/AlertModal";

export const FormerActions = {
    HANDSHAKE : "former.handshake",
    SUBMIT : "former.submit",
    CHANGE : "former.change"
}

export const FormerValues = {
    /** Specific value to identify items with no specified value. Basically an "undefined". **/
    NOVALUE : "__novalue",
    /**
     * Creates an image object compatible with Former state
     * @param imageUrl      Image URL to convert
     * @returns {{data}}    An Image object for direct use in Former
     */
    createImage : (imageUrl) => {
        return { data : imageUrl };
    }
}

export const Former = (props) => {

    const {callback} = props;
    const {state} = props;

    const [formState, setFormState] = useState({});
    const [forceValidate, setForceValidate] = useState();
    const [errors, setErrors] = useState([]);

    const [fileRequest, setFileRequest] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const fileInput = useRef();
    const validationManifest = useRef([]);

    useEffect(() => {
        handleCallback(FormerActions.HANDSHAKE, {
            // DO NOT CALL validateForm() IMMEDIATELY. USE STATE MUTATION INSTEAD.
            submit : () => setForceValidate(Math.random())
        });
    }, []);

    useEffect(() => {
        if (state) {
            setFormState(state);
        }
    }, [state]);

    useEffect(() => {
        if (forceValidate) {
            validateForm();
        }
    }, [forceValidate]);

    useEffect(() => {
        if (filePreview) {
            let file = filePreview.file;
            let data = filePreview.data;

            let iVal = getValue(fileRequest.name);
            if (iVal) {
                iVal = [...iVal];
                for (let i = 0; i < iVal.length; i++) {
                    if (iVal[i].file === file) {
                        iVal[i].data = data
                    }
                }
                setValue(fileRequest.name, iVal);
            }
        }
    }, [filePreview]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function validateForm() {
        let success = true;
        let errors = [];

        if (validationManifest.current.length > 0) {
            for (let child of validationManifest.current) {
                let mandatory = false;
                if (child.props.hasOwnProperty("mandatory")) {
                    mandatory = child.props.mandatory === true || child.props.mandatory === "true" || child.props.mandatory === "yes";
                }

                if (mandatory) {
                    let value = getValue(child.props.name);
                    if (value === undefined || value === null) {
                        success = false;
                        errors.push({
                            name : child.props.name,
                            message : "This field is mandatory"
                        })
                    }
                }
            }
        }

        if (success) {
            handleCallback(FormerActions.SUBMIT, {
                success,
                data : formState
            });
        } else {
            handleCallback(FormerActions.SUBMIT, {
                success,
                errors : errors
            });
        }
        setErrors(errors);
    }

    function processChildren(children, root) {
        if (!children) return children;
        if (root === undefined) {
            root = false;
        }

        if (root) {
            validationManifest.current = [];
        }

        let out = [];

        React.Children.map(children, (child) => {
            out.push(processChild(child));
        });

        return out;
    }

    function setStateValue(e, child) {
        if (child.props.hasOwnProperty("name")) {
            let value = e.target.value;

            const newState = CommonUtil.cloneObject(formState);

            if (value !== FormerValues.NOVALUE) {
                newState[child.props.name] = value;
            } else {
                delete newState[child.props.name];
                value = null;
            }

            setFormState(newState);
            clearError(child.props.name);

            handleCallback(FormerActions.CHANGE, {
                name : child.props.name,
                value : value
            });
        }
    }

    function setValue(name, value) {
        const newState = CommonUtil.cloneObject(formState);
        newState[name] = value;
        setFormState(newState);

        clearError(name);

        handleCallback(FormerActions.CHANGE, {
            name : name,
            value : value
        });
    }

    function clearError(name) {
        let newErrors = [...errors];
        for (let i = 0; i < newErrors.length; i++) {
            if (newErrors[i].name === name) {
                newErrors.splice(i, 1);
            }
        }
        setErrors(newErrors);
    }

    function getValue(name) {
        if (formState.hasOwnProperty(name)) {
            return formState[name];
        }
        return undefined;
    }

    function getStateValue(child) {
        if (child.props.hasOwnProperty("name")) {
            if (formState.hasOwnProperty(child.props.name)) {
                return formState[child.props.name];
            }
        }
        return undefined;
    }

    function processChild(child) {
        let out = child;
        if (out) {
            let children = [];
            if (child.hasOwnProperty("props")) {
                if (child.props.hasOwnProperty("children")) {
                    children = processChildren(child.props.children);
                }
            }

            let typeName = child.type;
            //  if (child.hasOwnProperty("type") && child.type.hasOwnProperty("name")) {
            //      typeName = child.type.name;
            // }

            let validateable = true;

            switch (typeName) {
                case Container : validateable = false; out = createContainer(child, children); break;
                case Text : out = createInput("text", child); break;
                case Number : out = createInput("number", child); break;
                case DropDown : out = createSelect(child, children); break;
                case RadioList : out = createRadioList(child); break;
                case CheckList : out = createCheckList(child); break;
                case DateTime : out = createInput("date", child); break;
                case File : out = createFileInput(child); break;
                case Button : out = createButton(child); break;
                default: validateable = false;
            }

            if (validateable) {
                validationManifest.current.push(child);
            }
        }

        return out;
    }

    function getLabel(child) {
        let label = [];
        if (child.props.label !== undefined) {
            label = (<label>{child.props.label}</label>);
        }
        return label;
    }

    function getError(name) {
        if (errors) {
            if (errors.length > 0) {
                for (let error of errors) {
                    if (error.name === name) {
                        return error;
                    }
                }
            }
        }
        return false;
    }

    function createContainer(child, children) {
        return (
            <div {...child.props}>
                {children}
            </div>
        )
    }

    function getErrorElement(child) {
        let errorPrompt = null;

        if (child) {
            if (child.props.name) {
                let error = getError(child.props.name);
                if (error) {
                    errorPrompt = (<div className={"invalid-feedback"}>{error.message}</div>);
                }
            }
        }

        return errorPrompt;
    }

    function createInput(type, child) {
        let className = "";
        if (child.props.hasOwnProperty("className")) {
            className = child.props.className;
        }

        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        let value = getStateValue(child);
        if (!value) {
            value = "";
        }

        let mainElem = (<input type={type} {...child.props} className={className}  value={value} onChange={(e) => setStateValue(e, child)} />);
        if (type === "text" && (child.props.hasOwnProperty("multiline") && child.props.multiline)) {
            mainElem = (<textarea {...child.props} value={value} onChange={(e) => setStateValue(e, child)} />);
        }

        let suffixElem = [];
        if (child.props.hasOwnProperty("suffix")) {
            suffixElem = (<div className={"input-group-append"}>
                <div className={"input-group-text"}>
                    {child.props.suffix}
                </div>
            </div>);
        }

        return (
            <div className={"former-text-input"}>
                {getLabel(child)}
                <div className={"input-group"}>
                    {mainElem}
                    {suffixElem}
                </div>
                {errorPrompt}
            </div>
        );
    }

    function createSelect(child, children) {
        let className = "";
        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        return (
            <div className={"former-select-input" + className}>
                {getLabel(child)}
                <select {...child.props} value={getStateValue(child)} onChange={(e) => setStateValue(e, child)}>
                    <option value={FormerValues.NOVALUE}>Please select</option>
                    {children}
                </select>
                {errorPrompt}
            </div>
        )
    }

    function createRadioList(child) {
        let children = [];

        if (child.hasOwnProperty("props")) {
            React.Children.map(child.props.children, (c) => {
                if (c.type === Radio) {
                    children.push(createRadioOption(child.props.name, c));
                }
            });
        }

        let className = "";
        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        return (
            <div className={"former-radio-list" + className}>
                {getLabel(child)}
                <form>
                    {children}
                </form>
                {errorPrompt}
            </div>
        )
    }

    function createRadioOption(name, child) {
        let children = [];
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("children")) {
                children = child.props.children;
            }
        }

        let valueIsNumeric = false;

        let value = null;
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("value")) {
                value = child.props.value;

                if (!isNaN(value)) {
                    // If the value of the Radio element is numeric,
                    // expect the input from state to be numeric.
                    valueIsNumeric = true;
                }
            }
        }

        let stateValue = getValue(name);
        if (valueIsNumeric && !isNaN(stateValue)) {
            // The value specified against the Radio element is numeric.
            // We suspect that the state value is numeric. So parse int now
            // so === comparisons will evaluate correctly.
            stateValue = parseInt(stateValue);
        }

        let checked = stateValue === value;

        let className = "";
        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        return (
            <div className={"former-radio-option" + className}>
                <label><input type={"radio"} value={value} checked={checked} onChange={(e) => {
                    if (e.target.checked) {
                        setValue(name, value);
                    }
                }} /> {children}</label>
                {errorPrompt}
            </div>
        )
    }

    function createCheckList(child) {
        let children = [];

        if (child.hasOwnProperty("props")) {
            React.Children.map(child.props.children, (c) => {
                if (c.type === Check) {
                    children.push(createCheckboxOption(child.props.name, c));
                }
            });
        }

        let className = "";
        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        return (
            <div className={"former-check-list" + className}>
                {getLabel(child)}
                <form>
                    {children}
                </form>
                {errorPrompt}
            </div>
        )
    }

    function createCheckboxOption(name, child) {
        let children = [];
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("children")) {
                children = child.props.children;
            }
        }

        let value = null;
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("value")) {
                value = child.props.value;
            }
        }

        if (isNaN(value)) {
            value = (value + "").trim();
        }

        let checked = false;
        let curValue = getValue(name);
        if (curValue) {
            if (!Array.isArray(curValue)) {
                curValue = [curValue];
            }

            for (let i = 0; i < curValue.length; i++) {
                if (curValue[i] === value || (curValue[i] + "") === (value + "")) {
                    checked = true;
                    break;
                }
            }
        }

        return (
            <div className={"former-checkbox-option"}>
                <label><input type={"checkbox"} value={value} checked={checked} onChange={(e) => handleCheckboxChange(name, value, e.target.checked)} /> {children}</label>
            </div>
        )
    }

    function handleCheckboxChange(name, value, checked) {
        let out = getValue(name);
        if (!out) {
            out = [];
        } else {
            out = [...out];
        }

        if (checked) {
            out.push(value);
        } else {
            for (let i = 0; i < out.length; i++) {
                if (out[i] === value) {
                    out.splice(i, 1);
                    break;
                }
            }
        }

        setValue(name, out);
    }

    function createFileInput(child) {
        let className = "";
        let containerClassName = "";
        let buttonLabel = "Please select";
        let buttonClass = "";
        let preview = false;
        let previewClass = "";
        let multiSelect = false;
        let previewRowLength = 3;
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("className")) {
                className = child.props.className;
            }

            if (child.props.hasOwnProperty("containerClassName")) {
                containerClassName = child.props.containerClassName;
            }

            if (child.props.hasOwnProperty("buttonLabel")) {
                buttonLabel = child.props.buttonLabel;
            }

            if (child.props.hasOwnProperty("buttonClassName")) {
                buttonClass = child.props.buttonClassName;
            }

            if (child.props.hasOwnProperty("preview")) {
                preview = child.props.preview;
            }

            if (child.props.hasOwnProperty("previewClassName")) {
                previewClass = child.props.previewClassName;
            }

            if (child.props.hasOwnProperty("multiSelect")) {
                multiSelect = child.props.multiSelect;
            }

            if (child.props.hasOwnProperty("previewRowLength")) {
                previewRowLength = parseInt(child.props.previewRowLength);
            }
        }

        let value = getStateValue(child);

        let previewElem = (
            <span>None selected</span>
        );

        if (preview === "image") {
            if (previewClass === "") {
                previewClass = "ratio ratio-1x1";
            }

            previewElem = [];
            if (value && value.length > 0) {
                for (let item of value) {
                    let backgroundImage = null;
                    if (item && item.data) {
                        backgroundImage = item.data;
                    }

                    previewElem.push(
                        <div
                            className={"former-image-preview " + previewClass}
                            style={{backgroundImage: ImageUtil.background(backgroundImage)}}
                            onClick={() => {
                                AlertModal.show(
                                    "Remove Image",
                                    "Do you want to remove this image?",
                                    [
                                        AlertModal.button(
                                            "Remove",
                                            () => {
                                                let value = getValue(child.props.name);
                                                if (value) {
                                                    let newArray = ArrayUtil.removeFromArray(value, item, true);
                                                    setValue(child.props.name, newArray);
                                                }

                                                AlertModal.dismiss();
                                            },
                                            "danger"
                                        ),
                                        AlertModal.button(
                                            "Cancel",
                                            () => { AlertModal.dismiss() }
                                        )
                                    ]
                                )
                            }}/>
                    )
                }
            } else {
                previewElem.push(<div className={"former-image-preview " + previewClass} />)
            }

            let rows = [];
            let curRow = [];
            for (let elem of previewElem) {
                curRow.push(elem);
                if (curRow.length >= previewRowLength) {
                    rows.push(curRow);
                    curRow = [];
                }
            }
            if (curRow.length > 0) {
                let paddingCount = previewRowLength - curRow.length;

                for (let i = 0; i < paddingCount; i++) {
                    curRow.push(
                        <div className={"former-image-preview " + previewClass} />
                    );
                }

                rows.push(curRow);
            }

            previewElem = (
                <div className={"former-image-preview-container"}>
                    {rows.map((row) => (
                        <div className={"former-image-preview-row"}>
                            {row}
                        </div>
                    ))}
                </div>
            )
        } else if (value && value.length > 0) {
            for (let file of value) {
                if (file.file !== undefined) {
                    let filename = file.file.name;
                    let filesize = FileUtil.formatFileSize(file.file.size, "short");

                    let previewLabel = filename + "(" + filesize + ")";

                    previewElem = (
                        <span className={previewClass}>{previewLabel}</span>
                    )
                }
            }
        }

        let errorPrompt = getErrorElement(child);
        if (errorPrompt) {
            className += " is-invalid";
        }

        return (
            <div className={"former-file-input " + containerClassName}>
                {getLabel(child)}
                <div>
                    <div className={"former-file-input-info"}>
                        {previewElem}
                    </div>
                    <div className={"former-file-input-button " + className}>
                        <button className={buttonClass} onClick={() => fileWasRequested(child)}>{buttonLabel}</button>
                        {errorPrompt}
                    </div>
                </div>
            </div>
        );
    }

    function createButton(child) {
        let className = "";
        let containerClassName = "";
        let onClick = null;
        let label = "";
        let title= "";
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("className")) {
                className = child.props.className;
            }

            if (child.props.hasOwnProperty("containerClassName")) {
                containerClassName = child.props.containerClassName;
            }

            if (child.props.hasOwnProperty("label")) {
                label = child.props.label;
            }

            if (child.props.hasOwnProperty("title")) {
                title = child.props.title;
            }

            if (child.props.hasOwnProperty("onClick")) {
                onClick = child.props.onClick;
            }
        }

        if (!onClick) {
            onClick = () => {
                validateForm();
            }
        }

        return (
            <div className={"former-button " + containerClassName}>
                <button className={className} onClick={onClick}>{(label ? label : title)}</button>
            </div>
        )
    }

    function fileWasRequested(child) {
        let requestName = null;
        let preview = false;
        let multiSelect = false;
        if (child.hasOwnProperty("props")) {
            if (child.props.hasOwnProperty("name")) {
                requestName = child.props.name;
            }

            if (child.props.hasOwnProperty("preview")) {
                preview = child.props.preview === "image";
            }

            if (child.props.hasOwnProperty("multiSelect")) {
                multiSelect = child.props.multiSelect;
            }
        }

        if (requestName !== null) {
            setFileRequest({
                name : requestName,
                preview,
                multiSelect
            });
            fileInput.current.click();
        }
    }

    function fileDidCallback(e) {
        if (fileRequest !== null) {
            if (e.target.files.length > 0) {
                let value = getValue(fileRequest.name);
                if (value && fileRequest.multiSelect) {
                    value = [...value];
                } else {
                    value = [];
                }

                const file = e.target.files[0];

                let fileObject = {
                    file,
                    data : null
                };
                value.push(fileObject);

                setValue(fileRequest.name, value);

                if (fileRequest.preview) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.result) {
                            setFilePreview({
                                file,
                                data : reader.result
                            });
                        }
                    };
                    reader.readAsDataURL(fileObject.file);
                }
            }
        }
    }

    return (
        <div className={"former-form"}>
            {processChildren(props.children, true)}

            <div className={"file-hide"}>
                <input type={"file"} ref={fileInput} onChange={fileDidCallback} />
            </div>
        </div>
    )

}

/**
 * A Container which converts out to a div on the other end.
 * @param className CSS class to apply to the component
 */
export const Container = (props) => { return [] };
export const Text = (props) => { return [] };
export const Number = (props) => { return [] };
export const DropDown = (props) => { return [] };
export const RadioList = (props) => { return [] };
export const Radio = (props) => { return [] };
export const CheckList = (props) => { return [] };
export const Check = (props) => { return [] };
export const DateTime = (props) => { return [] };
export const File = (props) => { return [] };
export const Button = (props) => { return [] };
