import {CommonUtil} from "./CommonUtil";

export const AuthUtil = {
    supportsSecureLogin : (callback) => {
        if (window.PublicKeyCredential) {
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
                .then((available) => {
                    if (available) {
                        if (callback) {
                            callback(true);
                        }
                    } else {
                        if (callback) {
                            callback(false);
                        }
                    }
                })
                .catch((e) => {
                    if (callback) {
                        callback(false);
                    }
                });
        } else {
            if (callback) {
                callback(false);
            }
        }
    },
    /**
     * Creates a JSON encoded response payload as a result of a WebAuthN login request
     *
     * The callback parameter must be a function which has the structure (success : bool, data : any)
     *
     * When the callback `success` parameter is `true`, a JSON string is expected. Otherwise, it will be an error message.
     *
     * @param options       Options returned by the server
     * @param callback      Callback with parameters (bool, any)
     */
    createSecureLoginResponse : (options, callback) => {
        // Treat data from the server
        const originalChallenge = options.challenge;
        options.challenge = new Uint8Array(options.challenge);

        options.allowCredentials.forEach((key, index) => {
            options.allowCredentials[index].id = new Uint8Array(key.id);
        });

        // Request credentials now
        navigator.credentials.get({publicKey : options})
            .then((assertion) => {
                const rawId = AuthUtil.toUint8Array(assertion.rawId);

                const clientData = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(assertion.response.clientDataJSON)
                    )
                );

                const clientDataJSONarray = AuthUtil.toUint8Array(assertion.response.clientDataJSON);
                const authenticatorData = AuthUtil.toUint8Array(assertion.response.authenticatorData);
                const signature = AuthUtil.toUint8Array(assertion.response.signature);

                const info = {
                    type : assertion.type,
                    originalChallenge,
                    rawId,
                    response : {
                        authenticatorData,
                        clientData,
                        clientDataJSONarray,
                        signature
                    }
                };

                let out = JSON.stringify(info);
                callback(true, out);
            })
            .catch((e) => {
                console.log(e);
                callback(false, null);
            });
    },
    /**
     * Creates a JSON encoded response payload to a WebAuthN challenge to a register request
     *
     * The callback parameter must be a function which has the structure (success : bool, data : any)
     *
     * When the callback `success` parameter is `true`, a JSON string is expected. Otherwise, it will be an error message.
     *
     * @param options       Options payload provided by the server
     * @param challenge     The challenge payload provided by the server
     * @param callback      Callback with parameters (bool, any)
     */
    createSecureRegistrationResponse : (options, challenge, callback) => {
        options.attestation = undefined;
        options.challenge = new Uint8Array(options.challenge);
        options.user.id = new Uint8Array(options.user.id);

        options = {
            publicKey : options
        };

        navigator.credentials.create(options)
            .then((credentials) => {
                const clientData = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(credentials.response.clientDataJSON)));
                if (challenge !== clientData.challenge) {
                    console.log("Challenges did not match");
                    console.log(challenge + " :: " + clientData.challenge);
                    callback(false, "Could not process login. [INVCHA]");
                    return;
                }

                if (!clientData.hasOwnProperty("type")) {
                    console.log("Client data has no type property");
                    callback(false, "Could not process login. [NOTYP]");
                    return;
                }

                if (clientData.type !== "webauthn.create") {
                    console.log("Invalid type: " + clientData.type);
                    callback(false, "Could not process login. [INVTYP]");
                    return;
                }

                let attestationObjects = [];
                (new Uint8Array(credentials.response.attestationObject)).forEach((v) => {
                    attestationObjects.push(v);
                })

                let rawId = [];
                (new Uint8Array(credentials.rawId)).forEach((v) => {
                    rawId.push(v);
                });

                const info = {
                    rawId,
                    id : credentials.id,
                    type : credentials.type,
                    response : {
                        attestationObject : attestationObjects,
                        clientDataJSON : JSON.parse(String.fromCharCode.apply(null, new Uint8Array(credentials.response.clientDataJSON)))
                    }
                };

                callback(true, JSON.stringify(info));
            })
            .catch((e) => {
                console.log(e);
                callback(false, null);
            });
    },
    toUint8Array : (input) => {
        const out = [];
        (new Uint8Array(input)).forEach((v) => {
            out.push(v);
        });
        return out;
    }
}