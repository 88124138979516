import React, {useState} from "react";
import {MonthYearPicker, MonthYearPickerActions} from "../../common/MonthYearPicker";
import {Chronos} from "../../../entity/Chronos";
import LoadingSpinner from "../../common/LoadingSpinner";
import AlertModal from "../../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import "./LocalStatisticsReportComponent.css";

export const LocalStatisticsReportComponent = (props) => {

    const [reportData, setReportData] = useState(null);
    const [fetchNetworkInFlight, setFetchNetworkInFlight] = useState(false);

    const [startDate, setStartDate] = useState(Chronos.parse(Chronos.now().format("yyyy-MM-01")).seconds());

    function datePickerDidCallback(action, data) {
        if (action === MonthYearPickerActions.CHANGE) {
            setStartDate(data);
        }
    }

    function fetchReportDataFromNetwork() {
        if (!startDate) {
            AlertModal.show(
                "Error",
                "Please select a Report Date",
            );
            return;
        }

        if (fetchNetworkInFlight) return;
        setFetchNetworkInFlight(true);

        let endDate = Chronos.withTimestampSeconds(startDate)
            .add(1, Chronos.MONTHS)
            .seconds();

        const formData = new FormData();
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        Axios.post(ENDPOINTS.report.getLocalUserStatisticsReport, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setReportData(resp.data.reportData);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setFetchNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFetchNetworkInFlight(false);
                AlertModal.showError(API.defaultError("RLUSR1000C"));
            });
    }

    // RENDER

    let mainContent = [];
    if (fetchNetworkInFlight) {
        mainContent = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        )
    } else {
        if (reportData) {
            // Active Users
            mainContent.push(
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center mb-2"}>
                        <h4>User Accounts</h4>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Total Active Users
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.userCounts.activeUsers}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Newly Created Users
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.userCounts.newUsers}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Users Accounts Closed
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.userCounts.removedUsers}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            );

            // CRME questions

            mainContent.push(
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 text-center"}>
                        <h4>CRME Usage Statistics</h4>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <CRMEQuestionList
                            title={"Enabled at Site"}
                            items={reportData.crmeQuestionStatus.enabled}
                            className={"list-group-item-success"} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <CRMEQuestionList
                            title={"Disabled at Site"}
                            items={reportData.crmeQuestionStatus.disabled}
                            className={"list-group-item-warning"} />
                    </div>
                </div>
            )

            // Case Record Views
            mainContent.push(
                <div className={"row mt-4 justify-content-center"}>

                    <div className={"col-12 text-center mb-2"}>
                        <h4>Total number of QI Notify EmLap Case Records</h4>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                6 months
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordCreation.sixMonth}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                1 month
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordCreation.oneMonth}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Average Per Week
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordCreation.byWeek}</h2>
                            </div>
                        </div>
                    </div>

                </div>
            );

            // User Views - Total Number
            mainContent.push(
                <div className={"row justify-content-center mt-4"}>

                    <div className={"col-12 text-center mb-2"}>
                        <h4>Total number of QI Notify EmLap Case Record Views</h4>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                6 Months
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordViews.sixMonth}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                1 Month
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordViews.oneMonth}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            )

            // User Views - 1, 5, 10 views last month
            mainContent.push(
                <div className={"row justify-content-center mt-4"}>

                    <div className={"col-12 text-center mb-2"}>
                        <h4>Number of users whom viewed Case Records</h4>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                At least one
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordUserViews.oneMonth.oneUserCount}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                At least five
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordUserViews.oneMonth.fiveUserCount}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                At least five
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordUserViews.oneMonth.tenUserCount}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            );

            // User Views - Average Case Record Views 1 & 6 months
            mainContent.push(
                <div className={"row justify-content-center mt-4"}>

                    <div className={"col-12 text-center mb-2"}>
                        <h4>Average number of users viewing Case Records</h4>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                6 Months
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordUserViews.sixMonth.averageViews}</h2>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                1 Month
                            </div>
                            <div className={"card-body text-center"}>
                                <h2>{reportData.caseRecordUserViews.oneMonth.averageViews}</h2>
                            </div>
                        </div>
                    </div>

                </div>
            );

            // Segment Views
            mainContent.push(
                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 mb-2 text-center"}>
                        <h4>Percentage of Overall Views of Case Record Sections</h4>
                    </div>

                    {reportData.caseRecordSegmentViews.segmentViews.map((segment) => {
                        let percentage = 0;
                        if (reportData.caseRecordSegmentViews.segmentPercentages.hasOwnProperty(segment.segmentId)) {
                            console.log("Found percentage");
                            percentage = reportData.caseRecordSegmentViews.segmentPercentages[segment.segmentId];
                        } else {
                            console.log("Could not find percentage for " + segment.segmentId);
                        }

                        return (
                            <div className={"col-12 col-md-4 mb-2"}>
                                <div className={"card"}>
                                    <div className={"card-header text-center"}>{segment.title}</div>
                                    <div className={"card-body text-center"}>
                                        <h4>{percentage}%</h4>
                                        <div className={"progress mt-2"}>
                                            <div className={"progress-bar"} style={{width : percentage + "%"}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );

            // Case Record Info Views
            const infoViewData = [
                { key : "case_record.urgency_table_view", label : "Urgency Table" },
                { key : "case_record.asa_guidance_view", label : "EmLap ASA Guidance" },
                { key : "case_record.clinical_frailty_score_view", label : "Clinical Frailty Score" },
                { key : "case_record.surgical_categories_view", label : "RCS 'Immediate Surgery' Categories" },
                { key : "case_record.high_risk_definition_guidance_view", label : "High-Risk Definition and Guidance" },
                { key : "clinical_standards", label : "Clinical Standards (all)" }
            ];

            mainContent.push(
                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 text-center"}>
                        <h4>Number of views of...</h4>
                    </div>

                    {infoViewData.map((item) => {
                        let value = 0;

                        if (item.key !== "clinical_standards") {
                            // We should find all values in the infoTotals array EXCEPT Clinical Standards
                            reportData.caseRecordInfoViews.infoTotals.forEach((infoTotal) => {
                                if (infoTotal.tag === item.key) {
                                    value = infoTotal.hitCount;
                                }
                            });
                        } else {
                            // We fetch Clinical Standard count from another property.
                            value = reportData.clinicalStandard.totalCount;
                        }

                        return (
                            <div className={"col-12 col-md-4 mb-2"}>
                                <div className={"card"}>
                                    <div className={"card-header text-center"}>{item.label}</div>
                                    <div className={"card-body text-center"}>
                                        <h2>{value}</h2>
                                        <div>Views</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );

            // Clinical Standards

            mainContent.push(
                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 text-center mb-2"}>
                        <h4>Top {reportData.clinicalStandard.topFive.length} most-viewed Clinical Standards (CS)</h4>
                    </div>

                    {reportData.clinicalStandard.topFive.map((standard, index) => {
                        let textString = standard.clinicalStandard.substring(0, 100);
                        textString = textString.replace(/(<([^>]+)>)/gi, "")
                        if (standard.clinicalStandard.length > 100) {
                            textString += "...";
                        }

                        return (
                            <div className={"col-12 col-md-4"}>
                                <div className={"card"}>
                                    <div className={"card-header"}>#{(index + 1)}</div>
                                    <div className={"card-body"}>
                                        {textString}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );

            // Reflections

            mainContent.push(
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center mt-4"}>
                        <h3>User Reflection &amp; Feedback</h3>
                    </div>
                </div>
            );

            const reflectionData = [
                {
                    title : "Over Six Months",
                    data : reportData.userReflections.sixMonth
                },
                {
                    title : "Over One Month",
                    data : reportData.userReflections.oneMonth
                }
            ];

            reflectionData.forEach((data) => {
                mainContent.push(
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 text-center mt-4"}>
                            <h4>{data.title}</h4>
                        </div>

                        {data.data.map((item) => (
                            <div className={"col-12 col-md-4 mb-2"}>
                                <div className={"card"}>
                                    <div className={"card-header text-center"}>
                                        {item.label}
                                    </div>
                                    <div className={"card-body text-center"}>
                                        <h2>{item.count}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            })

            let mostCommonUserRole = "---";
            let mostCommonResponsibility = "---";

            if (reportData.userReflections.hasOwnProperty("commonRole")) {
                if (reportData.userReflections.commonRole.length > 0) {
                    mostCommonUserRole = reportData.userReflections.commonRole[0].roleName;
                }
            }

            if (reportData.userReflections.hasOwnProperty("commonResponsibility")) {
                if (reportData.userReflections.commonResponsibility.length > 0) {
                    mostCommonResponsibility = reportData.userReflections.commonResponsibility[0].responsibility;
                }
            }

            mainContent.push(
                <div className={"row justify-content-center"}>
                    <div className={"col-12 text-center mt-4"}>
                        <h4>Users most likely to submit Reflections and Feedback</h4>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Clinical Role
                            </div>
                            <div className={"card-body text-center"}>
                                <h4>{mostCommonUserRole}</h4>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header text-center"}>
                                Area of Responsibility
                            </div>
                            <div className={"card-body text-center"}>
                                <h4>{mostCommonResponsibility}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            );

            let casualCategoryCount = 0;
            reportData.userReflections.casualCategories.forEach((category) => {
                casualCategoryCount += category.count;
            });

            mainContent.push(
                <div className={"row justify-content-center"}>
                    <div className={"col-12 text-center mt-4"}>
                        <h2>Use of Casual Categories in Reflections and Feedback</h2>
                    </div>

                    <div className={"col-12 col-md-8"}>

                        <ul className={"list-group"}>
                            <li className={"list-group-item list-group-item-secondary text-center"}>
                                <strong>Casual Categories</strong>
                            </li>

                            {reportData.userReflections.casualCategories.map((category) => {
                                let percentage = ((category.count / casualCategoryCount) * 100).toFixed(0);

                                return (
                                    <li className={"list-group-item"}>
                                        <div>{category.label}</div>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"flex-fill mr-2"}>
                                                <div className={"progress"}>
                                                    <div className={"progress-bar"} style={{width : percentage + "%"}} />
                                                </div>
                                            </div>
                                            <div><strong>{percentage}%</strong></div>
                                        </div>
                                    </li>
                                )}
                            )}
                        </ul>

                    </div>
                </div>
            )

            // Wrap content
            mainContent = (
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8"}>
                        <div className={"row mt-2"}>
                            <div className={"col-12 text-right mb-4"}>
                                <button className={"btn btn-outline-dark no-print"} onClick={() => window.print()}>Print/Export</button>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <h3>Local User Statistics Report</h3>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <h1>{reportData.organisation ? reportData.organisation.name : ""}</h1>
                            </div>
                        </div>

                        {mainContent}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={"local-statistics-report-component"}>

            <div className={"row justify-content-center mt-4 no-print"}>
                <div className={"col-12 col-md-10 col-lg-8"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-9"}>
                                <label>Reporting Month</label>
                                    <MonthYearPicker
                                        date={Chronos.now()}
                                        callback={datePickerDidCallback} />
                                </div>

                                <div className={"col-3 text-right"}>
                                    <label>&nbsp;</label><br />
                                    <button className={"btn btn-primary"} onClick={() => fetchReportDataFromNetwork()}>Generate</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {mainContent}

        </div>
    )

}

export const CRMEQuestionList = (props) => {

    const {title} = props;
    const {items} = props;
    const {className} = props;

    let mainContent = [];
    if (items) {
        mainContent = items.map((item) => (
            <li className={"list-group-item"}>{item.label}</li>
        ));
    }

    if (mainContent.length === 0) {
        mainContent.push(
            <li className={"list-group-item text-center"}>
                <em>(None)</em>
            </li>
        )
    }

    return (
        <ul className={"list-group"}>
            <li className={"list-group-item text-center " + className}>
                <strong>{title}</strong>
            </li>
            {mainContent}
        </ul>
    );

}