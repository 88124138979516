import React from "react";
import BaseComponent from "../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import { v4 as uuidv4 } from "uuid";

import './UserSelectionDialogComponent.css';
import SegmentedButtonComponent from "../common/SegmentedButtonController";
import {Button, Container, DropDown, Former, FormerActions, Text} from "../form/Former";
import {CommonUtil} from "../../util/CommonUtil";

export default class UserSelectionDialogComponent extends BaseComponent {

    MODE_LIST = 1;
    MODE_CREATE_TEMP = 2;

    keywordTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        let keyword = "";
        if (this.props.keyword !== undefined) {
            keyword = this.props.keyword;
        }

        let allowCreation = false;
        if (props.hasOwnProperty("allowCreation")) {
            allowCreation = props.allowCreation;
        }

        this.initState({
            mode : this.MODE_CREATE_TEMP,
            userSearchNetworkInFlight : false,
            currentPage : 1,
            keyword : keyword,
            users : [],
            userCount : 0,
            allowCreation,
            formState : []
        });
    }

    componentWillUnmount() {
        clearTimeout(this.keywordTimeout);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!this.props.open && nextProps.open) {
            let keyword = "";
            if (nextProps.keyword !== undefined) {
                keyword = nextProps.keyword;

                this.setState({
                    keyword: keyword
                });
            }
            console.log("SHOW USER SEL. KEYWORD: " + keyword);

            // If the modal is about to open, fetch users from network
            this.fetchUsersFromNetwork(undefined, keyword);
        }
    }

    fetchUsersFromNetwork = (page, keyword) => {
        if (this.state.userSearchNetworkInFlight) return;

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.keyword;
        }

        this.setState({
            userSearchNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        formData.append("keyword", keyword);

        Axios.post(ENDPOINTS.user.getUsers, formData)
            .then((r) => {
                let state = {
                    userSearchNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.users = resp.data.users;
                    state.userCount = resp.data.count;
                } else {
                    // TODO Show Error
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);

                // TODO
            });
    }

    onPageChanged = (index) => {
        this.fetchUsersFromNetwork(index);

        this.setState({
            currentPage : index
        });
    }

    onUserSelected = (user) => {
        if (this.props.callback !== undefined) {
            this.props.callback(user);
        }
    }

    handleKeywordChange = (event) => {
        this.handleChange(event);

        this.setState({
            currentPage : 1
        });

        clearTimeout(this.keywordTimeout);
        this.keywordTimeout = setTimeout(() => {
            this.fetchUsersFromNetwork();
        }, 800);
    }

    formDidCallback = (action, data) => {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                let outUser = {
                    id : uuidv4(),
                    isTemporary : true,
                    givenName : data.data.givenName,
                    familyName : data.data.familyName,
                    title : CommonUtil.getOrDefault(data.data, "title", undefined),
                    gmcNumber : CommonUtil.getOrDefault(data.data, "gmcNumber", undefined)
                };

                this.onUserSelected(outUser);
            }
        }
    }

    render() {
        let modalOpen = false;
        if (this.props.open !== undefined) {
            modalOpen = this.props.open;
        }

        if (modalOpen) {
            document.body.style.overflow = "hidden";

            let modalHeader = "Select person to add";
            let modalContent = [];

            if (this.state.allowCreation === true) {
                modalContent.push(
                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <SegmentedButtonComponent
                                selectedId={this.state.mode}
                                buttons={[
                                    {
                                        id : this.MODE_CREATE_TEMP,
                                        label : "People"
                                    },
                                    {
                                        id : this.MODE_LIST,
                                        label : "QIN Users"
                                    }
                                ]}
                                callback={(mode) => {
                                    this.setState({
                                        mode
                                    })
                                }} />
                        </div>
                    </div>
                );
            }

            if (this.state.mode === this.MODE_LIST) {
                modalContent.push([
                    <div className={"row user-filter"}>
                        <div className={"col-12"}>
                            <input type={"text"} className={"form-control"} name={"keyword"} value={this.state.keyword} placeholder={"Search for Users"} onChange={this.handleKeywordChange}/>
                        </div>
                    </div>,

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <TableComponent
                                className={"common-table alternating clickable"}
                                emptyMessage={"No Users match your criteria"}
                                data={this.state.users}>

                                <Column name={"name"} title={"Person Name"}/>
                                <Column name={"userType"} title={"Staff Role"}/>
                                <Column name={"gmcNumber"} title={"GCM Number"}/>
                                <Column name={"userId"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                    let buttons = [];
                                    buttons.push(<span className={"btn btn-primary"} onClick={() => this.onUserSelected(row)}>Add</span>);

                                    return buttons;
                                }}/>

                            </TableComponent>

                            <div className={"ep-table-paginate-container"}>
                                <div className={"push"}/>
                                <div className={"paginator-area"}>
                                    <TablePaginator
                                        totalCount={this.state.userCount}
                                        pageSize={10}
                                        page={this.state.currentPage}
                                        onClick={this.onPageChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                ]);
            } else if (this.state.mode === this.MODE_CREATE_TEMP) {
                modalContent.push(
                    <Former
                        state={this.state.formState}
                        callback={this.formDidCallback}>

                        <Container className={"row"}>
                            <Container className={"col-12 col-lg-4 mt-2"}>
                                <DropDown
                                    name={"title"}
                                    label={"Title"}
                                    className={"form-control"}>

                                    <option value={""}>(Not specified)</option>
                                    <option value={"Dr"}>Dr</option>
                                    <option value={"Ms"}>Ms</option>
                                    <option value={"Mrs"}>Mrs</option>
                                    <option value={"Mr"}>Mr</option>

                                </DropDown>
                            </Container>

                            <Container className={"col-12 col-lg-4 mt-2"}>
                                <Text
                                    name={"givenName"}
                                    label={"First Name"}
                                    mandatory={true}
                                    className={"form-control"} />
                            </Container>

                            <Container className={"col-12 col-lg-4 mt-2"}>
                                <Text
                                    name={"familyName"}
                                    label={"Surname"}
                                    mandatory={true}
                                    className={"form-control"} />
                            </Container>
                        </Container>

                        <Container className={"row justify-content-center"}>
                            <Container className={"col-12 col-lg-6 mt-2"}>
                                <Text
                                    name={"gmcNumber"}
                                    label={"GMC Number"}
                                    className={"form-control"} />
                            </Container>
                        </Container>

                        <Container className={"row mt-4"}>
                            <Container className={"col-12 text-center"}>
                                <Button label={"Submit"} className={"btn btn-success"} />
                            </Container>
                        </Container>

                    </Former>

                )
            }

            return (
                <div className="users-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalHeader}</h5>
                            </div>
                            <div className="modal-body">
                                {modalContent}
                            </div>
                            <div className={"modal-footer"}>
                                <button className={"btn btn-primary"} onClick={() => this.onUserSelected(undefined)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            document.body.style.overflow = "auto";
        }

        return [];
    }

}