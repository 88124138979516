import {CommonUtil} from "../../../util/CommonUtil";
import React from "react";

export const OrganisationThemeEditorActions = {
    REQUEST_COLOUR : "orgthemeeditor.request_colour",
    SUBMIT : "orgthemeeditor.submit"
};

export const OrganisationThemeEditorComponent = (props) => {

    const {allowSubmit} = props;
    const {themeData} = props;
    const {callback} = props;

    const themeItems = [
        {
            "keyName" : "primary_colour",
            "title" : "Primary",
            "description" : "Used to colour the header bar, buttons and other controls."
        },
        {
            "keyName" : "primary_colour_alt",
            "title" : "Secondary",
            "description" : "Should be a darker variation of the Primary Colour, used for alternative button colours."
        },
        {
            "keyName" : "button_text_colour",
            "title" : "Button Text",
            "description" : "Button text colour. Should contrast against the Primary Colour."
        },
        {
            "keyName" : "status_colour",
            "title" : "Status",
            "description" : "Shown when displaying Case Record status"
        },
        {
            "keyName" : "status_text_colour",
            "title" : "Status Text",
            "description" : "The text colour shown on the Status Colour. Should contrast against Status Colour."
        },
        {
            "keyName" : "badge_colour",
            "title" : "Badge",
            "description" : "Notification/Alert Badge Colour. Should stand out against Primary and Status colours."
        },
        {
            "keyName" : "badge_text_colour",
            "title" : "Badge Text",
            "description" : "The colour of text displayed in badges. Should contrast against Badge Colour."
        }
    ];

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let themeSubmitButton = (<button className={"btn btn-success"} onClick={() => handleCallback(OrganisationThemeEditorActions.SUBMIT)}>Apply Theme</button>);
    if (!allowSubmit) {
        themeSubmitButton = (<button className={"btn btn-success disabled"}>Saving, please wait.</button>)
    }

    return (
        <div className={"row mt-4 mb-4"}>
            <div className={"col-12"}>

                <div className={"card"}>
                    <div className={"card-header"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex justify-content-between"}>
                                <h3>Theme Colours</h3>
                                {themeSubmitButton}
                            </div>
                        </div>
                    </div>

                    <div className={"card-body"}>
                        <div className={"row"}>
                            {
                                themeItems.map((themeItem) => (
                                    <div className={"col-12 col-md-4 col-lg-3 mb-4"}>
                                        <div className={"card theme-item clickable"} onClick={() => handleCallback(OrganisationThemeEditorActions.REQUEST_COLOUR, themeItem.keyName)}>
                                            <div className={"card-img-top"} style={{backgroundColor : CommonUtil.getOrDefault(themeData, themeItem.keyName, "")}} />
                                            <div className={"card-body"}>
                                                <h5 className={"card-title"}>{themeItem.title}</h5>
                                                <div className={"card-text"}>
                                                    {themeItem.description}

                                                    <div className={"mt-2"}>
                                                        <button className={"btn btn-primary"}>Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}