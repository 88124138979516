export const FileUtil = {

    /**
     * Gets a string representation of a file size.
     *
     * @param sizeBytes     The size in bytes to format
     * @param short         If the string should be "short" or not. I.e. a "long" string is "12 megabytes", whereas short is "123mb".
     * @returns {*}         Formatted string
     */
    formatFileSize : (sizeBytes, short) => {
        if (short === undefined) {
            short = true;
        }

        const units = [
            { size : 1073741824, name : " gigabytes", abbr : "gb" },
            { size : 1048576, name : " megabytes", abbr : "mb" },
            { size : 1024, name : " kilobytes", abbr : "kb" }
        ]

        let out = null;

        for (const unit of units) {
            let calc = sizeBytes / unit.size;
            if (calc >= 1) {
                let useString = unit.name;
                if (short) {
                    useString = unit.abbr;
                }

                out = calc.toFixed(1) + useString;
                break;
            }
        }

        if (out === null) {
            let useString = " bytes";
            if (short) {
                useString = "b";
            }
            out = sizeBytes + useString;
        }

        return out;
    }

}