import React from 'react';
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

export default class PasswordChangeModalComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            passwordNetworkInFlight : false,
            showPassword : false,
            userPassword : "",
            userPasswordConfirm : "",
            errorMessage : null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== undefined) {
            if (this.props.open !== prevProps.open) {
                // Clear password fields on show/dismiss
                this.setState({
                    userPassword : "",
                    userPasswordConfirm : "",
                    errorMessage : null
                });
            }
        }
    }

    dismissClicked = (outcome) => {
        if (this.props.dismissCallback !== undefined) {
            this.props.dismissCallback(outcome);
        }
    }

    submitPasswordChange = () => {
        if (this.state.passwordNetworkInFlight) return;

        if (!this.validateForm()) return;

        this.setState({
            passwordNetworkInFlight : true,
            errorMessage : null
        });

        let formData = new FormData();
        formData.append("newPassword", this.state.userPassword);

        Axios.post(ENDPOINTS.user.updateUserPassword, formData)
            .then((r) => {
                let errorMessage = null;

                let resp = API.parse(r);
                if (resp.success) {
                    this.dismissClicked(true);
                } else {
                    errorMessage = resp.error.desc + " (" + resp.error.code + ")";
                }

                this.setState({
                    passwordNetworkInFlight : false,
                    errorMessage
                })
            })
            .catch((e) => {
                this.setState({
                    passwordNetworkInFlight : false,
                    errorMessage : "An unknown error has occurred. Please reload the page."
                });
            });
    }

    validateForm = () => {
        if (this.state.userPassword !== "" && this.state.userPasswordConfirm !== "") {
            if (this.state.userPassword === this.state.userPasswordConfirm) {
                return true;
            }
        }
        return false;
    }

    render() {
        if (!this.props.open) return [];

        let passwordFieldType = "password";
        if (this.state.showPassword) {
            passwordFieldType = "text";
        }

        let submitButtonExtraClass = "";
        if (!this.validateForm()) {
            submitButtonExtraClass = " disabled";
        }

        let errorElem = [];
        if (this.state.errorMessage !== null) {
            errorElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-danger"} style={{marginBottom : 0}}>
                            {this.state.errorMessage}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={"alert-modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-header"}>
                        Change Password
                        <span className={"close"} onClick={() => this.dismissClicked() }>X</span>
                    </div>

                    <div className={"modal-body"}>
                        <div className={"row"}>
                            <div className={"col-12 pb-2"}>
                                <p>When you change your password, your password will change for both the Dashboard and the QI Notify app.</p>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 pb-2"}>
                                <label>New Password</label>
                                <input type={passwordFieldType} className={"form-control"} name={"userPassword"} value={this.state.userPassword} onChange={this.handleChange} />
                            </div>
                        </div>


                        <div className={"row"}>
                            <div className={"col-12 pb-2"}>
                                <label>Confirm New Password</label>
                                <input type={passwordFieldType} className={"form-control"} name={"userPasswordConfirm"} value={this.state.userPasswordConfirm} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 pb-2"}>
                                <label><input type={"checkbox"} name={"showPassword"} checked={this.state.showPassword} onChange={this.handleChange} /> Show Passwords</label>
                            </div>
                        </div>

                        {errorElem}
                    </div>

                    <div className={"modal-footer"}>
                        <button className={"btn btn-secondary"} onClick={this.dismissClicked}>Cancel</button>
                        <button className={"btn btn-primary" + submitButtonExtraClass} onClick={this.submitPasswordChange}>Change Password</button>
                    </div>
                </div>
            </div>
        )
    }
}