import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = 15000;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authtoken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authtoken"];
            }
        },
        setOrganisationId : (organisationId) => {
            if (organisationId) {
                axios.defaults.headers.common["x-organisation-id"] = organisationId;
            } else {
                delete axios.defaults.headers.common["x-organisation-id"];
            }
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        parse(resp) {
            var out = {
                success : false,
                data : null,
                error : "An unknown error has occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        getAPIUrl : (path, includeAuthToken) => {
            let out = process.env.REACT_APP_BASE_URL + path;
            if (includeAuthToken) {
                out = API.appendAuthTokenToUrl(out);
            }
            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        defaultError : (errorCode) => {
            return "An unknown error has occurred. Please try again later. [" +errorCode + "]";
        },
        appendAuthTokenToUrl : (url) => {
            let suffix = {
                at : escape(axios.defaults.headers.common["authtoken"])
            };
            return url + API.createQueryString(suffix);
        },
        createQueryString : (data) => {
            let out = "";
            if (data !== undefined && data != null) {
                for (const [key, value] of Object.entries(data)) {
                    if (out === "") {
                        out = "?"
                    } else {
                        out += "&"
                    }

                    out += key + "=" + escape(value);
                }
            }
            return out;
        },
        caseRecordFileTypes : {
            NELA : 1,
            CRME : 2,
            THDB : 3
        },
        isInRoles : (roleId, roleIds) => {
            if (roleId) {
                roleId = parseInt(roleId);

                if (roleIds && Array.isArray(roleIds)) {
                    for (let i = 0; i < roleIds.length; i++) {
                        if (roleId === parseInt(roleIds[i])) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        userRoles : {
            SUPER_ADMIN : 1,
            ADMIN : 2,
            USER : 3,
            SUPER_USER : 4,
            SYSTEM_ADMIN : 5
        },
        visibility : {
            FULL : 1,
            LIMITED : 2
        },
        getVisibilityForUserRole : (role) => {
            role = parseInt(role);
            switch (role) {
                case API.userRoles.SUPER_ADMIN:
                case API.userRoles.ADMIN:
                    return API.visibility.FULL;
            }
            return API.visibility.LIMITED;
        }
    }

})(axios);
 
export const ENDPOINTS = {
    login : {
        submit : "auth/login",
        requestPasswordReset : "auth/requestPasswordReset",
        validatePasswordResetToken : "auth/validatePasswordResetToken",
        submitPasswordReset : "auth/submitPasswordReset",
        requestSecureRegistration : "auth/requestSecureRegistration",
        registerSecureLogin : "auth/registerSecureLogin",
        requestSecureLogin : "auth/requestSecureLogin",
        submitSecureLogin : "auth/submitSecureLogin",
        keepAlive : "keep-alive"
    },
    user : {
        submitUser: "user/submitUser",
        getUser: "user/fetchUser",
        getAllUsers : "user/fetchAllUsers",
        sendPasswordReset : "user/sendPasswordReset",
        sendRegistrationReminder : "user/sendRegistrationReminder",
        deleteUser : "user/deleteUser",
        getUsers : "user/getUsers",
        getCaseRecords : "user/getCaseRecords",
        updateUserPassword : "user/updateUserPassword",
        getUserRoles : "user/getUserRoles",
        getOrganisations : "user/getOrganisations",
        getSystemAdminUsers : "user/getSystemAdminUsers",
        submitSystemAdminUser : "user/submitSystemAdminUser",
        setTwoFactorAuthenticationState : "user/setTwoFactorAuthenticationState",
        disableSecureLogin : "user/disableSecureLogin",
        reenableUser : "user/reenableUser",
        setNotificationsEnabled : "user/setNotificationsEnabled",
        getCaseRecordFilters : "user/getCaseRecordFilters"
    },
    caseRecord : {
        uploadCaseRecordFile : "caseRecord/uploadCaseRecordFile",
        submitCaseRecord : "caseRecord/submitCaseRecord",
        getCaseRecord : "caseRecord/getCaseRecord",
        getCaseRecords : "caseRecord/getCaseRecords",
        submitPatientData : "caseRecord/submitPatientData",
        getSectionResultOutcomes : "caseRecord/getSectionResultOutcomes",
        addUserToCaseRecord : "caseRecord/addUserToCaseRecord",
        removeUserFromCaseRecord : "caseRecord/removeUserFromCaseRecord",
        getMissingClinicians : "caseRecord/getMissingClinicians",
        removeMissingClinician : "caseRecord/removeMissingClinician",
        getCaseRecordStages : "caseRecord/getCaseRecordStages",
        calculateProcessSectionItem : "caseRecord/calculateProcessSectionItem",
        getCaseRecordFileHistory : "caseRecord/getCaseRecordFileHistory",
        generateCaseRecordExport : "caseRecord/generateCaseRecordExport",
        getClinicalRoles : "caseRecord/getClinicalRoles",
        notifyUsersOfCaseRecordUpdate : "caseRecord/notifyUsersOfCaseRecordUpdate",
        submitCaseRecordAutoAssign : "caseRecord/submitCaseRecordAutoAssign",
        removeCaseRecordAutoAssign : "caseRecord/removeCaseRecordAutoAssign",
        getCaseRecordAutoAssignRecords : "caseRecord/getCaseRecordAutoAssignRecords",
        deleteCaseRecord : "caseRecord/deleteCaseRecord",
        publishCaseRecord : "caseRecord/publishCaseRecord",
        getCaseRecordStructure : "caseRecord/getCaseRecordStructure",
        submitOrganisationCaseRecordStructure : "caseRecord/submitOrganisationCaseRecordStructure",
        getCaseRecordPatientData : "caseRecord/getCaseRecordPatientData",
        submitCaseRecordPublicationAnswers : "caseRecord/submitCaseRecordPublicationAnswers"
    },
    location : {
        getLocation : "location/getLocation",
        getLocations : "location/getLocations",
        getLocationUsers : "location/getLocationUsers",
        submitLocation : "location/submitLocation",
        addUserToLocation : "location/addUserToLocation",
        removeUserFromLocation : "location/removeUserFromLocation"
    },
    debug : {
        getTemplate : "caseRecord/debug_getCaseRecordTemplate",
        getSegments : "caseRecord/debug_getCaseRecordTemplateSegments",
        submitTemplate : "caseRecord/debug_submitCaseRecordTemplate"
    },
    report : {
        generateSummaryReport : "report/generateSummaryReport",
        getMortalityReportTableData : "report/getMortalityReportTableData",
        generateMortalityReportExport : "report/generateMortalityReportExport",
        getBTPSummaryData : "report/getBTPSummaryData",
        getBPTSummaryTableData : "report/getBPTSummaryTableData",
        generateBPTSummaryExport : "report/generateBPTSummaryExport",
        getReflectionTableData : "report/getReflectionTableData",
        getFeedbackFilterConfig : "report/getFeedbackFilterConfig",
        getOpenReflection : "report/getOpenReflection",
        generateOpenReflectionExport : "report/generateOpenReflectionExport",
        getLocalUserStatisticsReport : "report/getLocalUserStatisticsReport",
        getNationalStatisticReports : "report/getNationalStatisticReports",
        getNationalStatisticReportDownload : "download/getNationalStatisticReportDownload/"
    },
    patient : {
        getPatientTableData : "patient/getPatientTableData"
    },
    organisation : {
        getOrganisations : "organisation/getOrganisations",
        submitOrganisation : "organisation/submitOrganisation",
        submitOrganisationTheme : "organisation/submitOrganisationTheme",
        submitOrganisationImage : "organisation/submitOrganisationImage",
        getOrganisationPolicies : "organisation/getOrganisationPolicies",
        submitOrganisationPolicy : "organisation/submitOrganisationPolicy",
        removeOrganisationPolicy : "organisation/removeOrganisationPolicy",
        getOrganisationPoliciesRequiringAction : "organisation/getOrganisationPoliciesRequiringAction",
        acceptOrganisationPolicies : "organisation/acceptOrganisationPolicies",
        getOrganisationPatientData : "organisation/getOrganisationPatientData",
        submitOrganisationPatientData : "organisation/submitOrganisationPatientData",
        getOrganisationStaff : "organisation/getOrganisationStaff",
        submitOrganisationStaff : "organisation/submitOrganisationStaff"
    },
    approval : {
        getUserApprovals : "approval/getUserApprovals",
        approveUserApproval : "approval/approveUserApproval",
        rejectUserApproval : "approval/rejectUserApproval"
    },
    appConfig : {
        getAppConfig : "appConfig/getAppConfig",
        submitAppConfig : "appConfig/submitAppConfig",
        getOrganisationConfig : "appConfig/getOrganisationConfig",
        submitOrganisationConfig : "appConfig/submitOrganisationConfig"
    },
    clinicalStandard : {
        getClinicalStandards : "clinicalStandard/getClinicalStandards",
        submitClinicalStandard : "clinicalStandard/submitClinicalStandard",
        getOrganisationClinicalStandards : "clinicalStandard/getOrganisationClinicalStandards",
        submitOrganisationClinicalStandard : "clinicalStandard/submitOrganisationClinicalStandard"
    },
    analytics : {
        track : "analytics/track"
    }
}