import {BaseModal} from "../../alertmodal/BaseModal";
import React, {useEffect, useRef, useState} from "react";

export const UserApprovalRejectionModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const [note, setNote] = useState("");

    const callbackData = useRef();

    useEffect(() => {
        if (!shown) {
            setForceDismiss(false);
            setNote("");
            callbackData.current = undefined;
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            if (!data && callbackData.current) {
                data = callbackData.current;
            }
            callback(action, data);
        }
    }

    function submitForm() {
        callbackData.current = {
            success : true,
            note
        };
        setForceDismiss(true);
    }

    // RENDER

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <div className={"alert-modal-dialog-title"}>Reject User</div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    Please confirm you would like to reject this user. Please consider providing an explaination as to why this approval has been rejected.
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <label>Rejection Reason</label>
                    <textarea className={"form-control"} value={note} onChange={(e) => setNote(e.target.value)} />
                </div>
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <button className={"alert-dialog-button danger"} onClick={() => submitForm()}>Reject User</button>
                <button className={"alert-dialog-button"} onClick={() => setForceDismiss(true)}>Cancel</button>
            </div>

        </BaseModal>
    )

}