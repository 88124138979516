import React from "react";
import BaseComponent from "../../BaseComponent";
import DatePicker from "react-datepicker";

import './UserDetailsComponent.css';
import "react-datepicker/dist/react-datepicker.css";
import {Chronos} from "../../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

class UserDetailsComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);



        this.initState({
            roles : [],
            rolesNetworkInFlight : false,
            clinicalRoles : []
        });
    }

    componentDidMount() {
        this.fetchUserRolesFromNetwork();
        this.fetchClinicalRolesFromNetwork();
    }

    fetchUserRolesFromNetwork = () => {
        if (this.state.rolesNetworkInFlight) return;
        this.setState({
            rolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.user.getUserRoles)
            .then((r) => {
                let resp = API.parse(r);

                let roles = [];

                if (resp.success) {
                    roles = resp.data.userRoles;
                }

                this.setState({
                    rolesNetworkInFlight : false,
                    roles
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchClinicalRolesFromNetwork = () => {
        if (this.state.clinicalRolesNetworkInFlight) return;
        this.setState({
            clinicalRolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.caseRecord.getClinicalRoles)
            .then((r) => {
                let resp = API.parse(r);

                let clinicalRoles = [];

                if (resp.success) {
                    clinicalRoles = resp.data.clinicalRoles;
                }

                this.setState({
                    clinicalRolesNetworkInFlight : false,
                    clinicalRoles
                });
            })
            .catch((e) => {
                console.log("ERROR LOADING CLINICAL ROLES");
                console.log(e);
            });
    }

    // MARK: Actions

    handleChange = (e) => {
        let user = this.props.user;
        user[e.target.id] = e.target.value;

        if (this.props.hasOwnProperty("userStateDidChange")) {
            this.props.userStateDidChange(user);
        }
    }

    handleDateChange = (id, date) => {
        let outDate = date.getTime() / 1000;

        let user = this.props.user;
        user[id] = outDate;

        if (this.props.hasOwnProperty("userStateDidChange")) {
            this.props.userStateDidChange(user);
        }
    }


    // MARK: Render

    render() { 
        let user = this.props.user;
        if (user == null) { user = {}; } // Prevent crash from user being null (important when creating a user)

        return (
            <div className={"user-details"}> 
                {this.renderPersonalDetails(user)}
                {this.renderMedicalRoleDetails(user)}
                {this.renderContactDetails(user)}
            </div>
        )
    }

    /*
    <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card user-detials-section no-margin"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>Password (leave blank to keep the same)</label>
                                    <input type={"text"} className={"form-control"} name={"password"} value={this.state.password} onChange={this.handleChange} />
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    <label>Confirm Password</label>
                                    <input type={"text"} className={"form-control"} name={"passwordConfirm"} value={this.state.passwordConfirm} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     */

    // MARK: Render Helper Methods

    renderPersonalDetails(user) {
        if (user == null) { return }
        
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section no-margin"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-header"}>{this.fullName(user)}</div>
                                <div className={"section-title"}>Personal Details</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>First Name</label>
                                        <input type="text" className={"form-control"} id="givenName" defaultValue={user.givenName} onChange={e => this.handleChange(e)}/>
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Middle Name(s)</label>
                                        <input type="text" className={"form-control"} id="middleName" defaultValue={user.middleName} onChange={e => this.handleChange(e)}/>
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Last Name</label>
                                        <input type="text" className={"form-control"} id="familyName" defaultValue={user.familyName} onChange={e => this.handleChange(e)}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Date of Birth</label><br />
                                        <DatePicker
                                            selected={(user.dateOfBirth != null) ? Chronos.withTimestampSeconds(user.dateOfBirth).getDate() : null}
                                            onChange={(date) => this.handleDateChange("dateOfBirth", date)}
                                            className={"form-control"}
                                            dateFormat={"dd/MM/yyyy"} />
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>GMC Number</label>
                                        <input type="text" className={"form-control"} id="gmcNumber" defaultValue={user.gmcNumber} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderMedicalRoleDetails(user) {
        if (user == null) { return }

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-title"}>Details of current NHS Duties</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Current NHS Role</label>
                                        <select className={"form-control"} id={"clinicalRoleId"} value={user.clinicalRoleId} onChange={this.handleChange}>
                                            <option value={null}>Please Select</option>
                                            {
                                                this.state.clinicalRoles.map((role) =>
                                                    <option value={role.id}>{role.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Place of Work</label>
                                        <input type="text" className={"form-control"} id="placeOfWork" defaultValue={user.placeOfWork} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Expected End Date</label><br />
                                        <DatePicker
                                            selected={(this.props.user.expectedEndDate != null) ? Chronos.withTimestampSeconds(this.props.user.expectedEndDate).getDate() : null}
                                            onChange={(date) => this.handleDateChange("expectedEndDate", date)}
                                            className={"form-control"}
                                            dateFormat={"dd/MM/yyyy"} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderContactDetails(user) {
        if (user == null) { return [] }

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-title"}>Contact and Account Details</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Email</label>
                                        <input type="text" className={"form-control"} id="emailAddress" defaultValue={user.emailAddress} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Phone Number</label>
                                        <input type="text" className={"form-control"} id="phoneNumber" defaultValue={user.phoneNumber} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>QIN Account Type</label>
                                        <select className={"form-control"} id={"userRoleId"} value={user.userRoleId} onChange={this.handleChange}>
                                            <option value={-1}>Please Select</option>
                                            {
                                                this.state.roles.map((role) => (
                                                    <option value={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    fullName = (user) => {
        // The snippet below is to include middle name
        // if (user.givenName && user.middleName && user.familyName) {
        //     console.log("here");
        //     return user.givenName + " " + user.middleName + " " + user.familyName;

        // } else if (user.givenName && user.middleName) {
        //     return user.givenName + " " + user.middleName;

        // } else 
        if (user.givenName && user.familyName) {
            return user.givenName + " " + user.familyName;

        } else if (user.givenName) {
            return user.givenName;

        } else {
            return ""
        }
    }
}
 
export default UserDetailsComponent;