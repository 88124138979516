import React from "react";

import {useEffect, useRef, useState} from "react";
import Axios from "axios";

import "./OrganisationListScreen.css";
import {DataManager} from "../../util/DataManager";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import LoadingSpinner from "../common/LoadingSpinner";
import {OrganisationUtil} from "../../util/OrganisationUtil";
import {ImageUtil} from "../../util/ImageUtil";

export const OrganisationListScreen = (props) => {

    const {title} = props;

    const [organisations, setOrganisations] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [organisationId, setOrganisationId] = useState(null);

    const dmListener = useRef();

    useEffect(() => {
        if (title) {
            title("Select an Organisation");
        }

        const orgId = DataManager.getOrganisationId();
        if (orgId) {
            setOrganisationId(orgId);
        }

        dmListener.current = (key, value) => {
            if (key === DataManager.keys.organisationId) {
                setOrganisationId(value);
            }
        };
        DataManager.addCallback(dmListener.current);

        fetchOrganisationsFromNetwork();

        return () => {
            DataManager.removeCallback(dmListener.current);
        }
    }, []);

    function organisationWasClicked(organisation) {
        if (organisation) {
            DataManager.setOrganisationId(organisation.id);
            DataManager.setOrganisationImages(organisation.images);
            DataManager.setOrganisationTheme(organisation.theme);
        }
    }

    function fetchOrganisationsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();

        Axios.post(ENDPOINTS.user.getOrganisations, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setOrganisations(resp.data.data);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ORGL1000C"));
            });
    }

    // RENDER

    let mainContent = [];

    if (networkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        )
    } else {
        let listItems = [];

        organisations.forEach((org) => {
            let selectedElem = [];
            if (parseInt(organisationId) === parseInt(org.id)) {
                selectedElem = (
                    <span className={"d-block"}>
                        <span className={"badge text-bg-success"}>Selected</span>
                    </span>
                )
            }

            let iconUrl = null;
            let organisationImage = OrganisationUtil.getImage(org, OrganisationUtil.IMAGE_TYPES.LOGO);
            if (organisationImage) {
                iconUrl = organisationImage.imagePath;
            }

            listItems.push(
                <li className={"list-group-item organisation-item clickable"} onClick={() => organisationWasClicked(org)}>
                    <span className={"org-icon"} style={{backgroundImage : ImageUtil.background(iconUrl)}} />
                    <span className={"org-content"}>
                        <span className={"org-title"}>{org.name}</span>
                        {selectedElem}
                    </span>
                </li>
            )
        });

        if (listItems.length === 0) {
            listItems.push(
                <li className={"list-group-item text-center"}>
                    No Organisations to display
                </li>
            );
        }

        mainContent = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {listItems}
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className={"organisation-list-screen app-screen"}>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>
                        {mainContent}
                    </div>
                </div>

            </div>

        </div>
    )

}