import React from 'react';
import BaseComponent from "../BaseComponent";
import UserSelectionDialogComponent from "./UserSelectionDialogComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./AutoAssignEditorModalComponent.css";

export default class AutoAssignEditorModalComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            recordId : null,
            selectedUser : null,
            selectedClinicalRoleId : -1,
            clinicalRoles : [],
            userSelectionOpen : false,
            userSelectionKeyword : undefined,
            errorMessage : null
        });
    }

    componentDidMount() {
        this.fetchClinicalRolesFromNetwork();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.record !== prevProps.record) {
            if (this.props.record !== undefined) {
                this.setState({
                    recordId: this.props.record.id,
                    selectedUser: {
                        userId: this.props.record.userId,
                        recordId: this.props.record.id,
                        givenName: this.props.record.givenName,
                        familyName: this.props.record.familyName
                    },
                    selectedClinicalRoleId: this.props.record.clinicalRoleId
                })
            } else {
                this.setState({
                    recordId : null,
                    selectedUser : null,
                    selectedClinicalRoleId : -1
                });
            }
        }
    }

    fetchClinicalRolesFromNetwork = () => {
        if (this.state.clinicalRolesNetworkInFlight) return;
        this.setState({
            clinicalRolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.caseRecord.getClinicalRoles)
            .then((r) => {
                let resp = API.parse(r);

                let clinicalRoles = [];

                if (resp.success) {
                    clinicalRoles = resp.data.clinicalRoles;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    clinicalRolesNetworkInFlight : false,
                    clinicalRoles
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    summonUserSelectionDialog = () => {
        this.setState({
            userSelectionOpen : true
        });
    }

    userWasSelected = (user) => {
        console.log(user);

        let newState = {
            userSelectionOpen : false
        };

        if (user !== undefined && user !== null) {
            newState.selectedUser = user;
        }

        this.setState(newState);
    }

    onCancel = () => {
        if (this.props.callback !== undefined) {
            this.props.callback(undefined);
        }
    }

    onSubmit = () => {
        if (this.state.autoAssignNetworkInFlight) return;

        let validationResult = this.validateForm();

        console.log("VALIDATE: " + validationResult);

        if (validationResult) {
            this.setState({
                autoAssignNetworkInFlight : true
            });

            let formData = new FormData();
            formData.append("userId", this.state.selectedUser.userId);
            formData.append("clinicalRoleId", this.state.selectedClinicalRoleId);

            if (this.state.recordId !== null) {
                formData.append("id", this.state.recordId);
            }

            Axios.post(ENDPOINTS.caseRecord.submitCaseRecordAutoAssign, formData)
                .then((r) => {
                    let resp = API.parse(r);

                    if (resp.success) {
                        this.onCancel(true);
                    } else {
                        this.showError(resp.error.desc + "[" + resp.error.code + "]");
                    }

                    this.setState({
                        autoAssignNetworkInFlight : false
                    });
                })
                .catch((e) => {
                    console.log(e);
                    // TODO Error
                    this.showError("Could not submit Auto Assign at this time. Please try again later. [A830]");
                });
        }
    }

    validateForm = () => {
        let message = null;
        let result = true;

        if (this.state.selectedUser === null) {
            result = false;
            message = "Please select a User";
        }

        if (this.state.selectedClinicalRoleId === -1) {
            result = false;
            message = "Please select a Clinical Role";
        }

        this.showError(message);

        return result;
    }

    showError = (message) => {
        this.setState({
            errorMessage : message
        });
    }

    render() {
        if (!this.props.open) return [];

        let modalTitle = "New Auto Assign";
        if (this.state.recordId !== undefined) {
            modalTitle = "Edit Auto Assign";
        }

        let submitButton = (<button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>);
        if (this.state.autoAssignNetworkInFlight) {
            submitButton = (<button type="button disabled" className="btn btn-primary">Please Wait</button>)
        }

        let errorElem = [];
        if (this.state.errorMessage != null) {
            errorElem = (
                <div className={"col-12 pt-2"}>
                    <div className={"alert alert-danger"}>{this.state.errorMessage}</div>
                </div>
            )
        }

        return (
            <div className={"auto-assign-editor-modal-component"}>
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>User</label>

                                        <div className={"user-selection-widget"} onClick={this.summonUserSelectionDialog}>
                                            <div className={"title"}>{(this.state.selectedUser != null ? this.state.selectedUser.givenName + " " + this.state.selectedUser.familyName : "No User Selected")}</div>
                                            <div className={"subtitle"}>{(this.state.selectedUser != null ? "" : "Click to select")}</div>
                                        </div>
                                    </div>

                                    <div className={"col-12"}>
                                        <label>Clinical Role</label>
                                        <select className={"form-control"} name={"selectedClinicalRoleId"} value={this.state.selectedClinicalRoleId} onChange={this.handleChange}>
                                            <option value={-1}>Please Select</option>
                                            {
                                                this.state.clinicalRoles.map((role) => (
                                                    <option value={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    {errorElem}
                                </div>
                            </div>
                            <div className="modal-footer">
                                {submitButton}
                                <button type="button" className="btn btn-secondary" onClick={this.onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                <UserSelectionDialogComponent
                    open={this.state.userSelectionOpen}
                    callback={this.userWasSelected}
                    keyword={this.state.userSelectionKeyword} />
            </div>
        )
    }
}