import React from "react";
import {NavLink} from "react-router-dom";
import BaseComponent from "../BaseComponent";
import {API} from "../../network/API";
import {DataManager} from "../../util/DataManager";

const MODE_FULL = 1;
const MODE_LIMITED = 2;
const MODE_NONE = 3;

export default class SideNavigationComponent extends BaseComponent {

    static get MODE_FULL() { return MODE_FULL }
    static get MODE_LIMITED() { return MODE_LIMITED }
    static get MODE_NONE() { return MODE_NONE; }

    constructor(props, context) {
        super(props, context);

        let state = {
            navigationItems : []
        };

        this.initState(state);
    }

    componentDidMount() {
        super.componentDidMount();
        this.createNavigationItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode !== prevProps.mode) {
            this.createNavigationItems();
        }
    }

    createNavigationItems = () => {
        let state = {};
        let user = this.getUser();
        let mode = this.props.mode;
        if (!mode) {
            mode = user.visibility === API.visibility.FULL ? MODE_FULL : MODE_LIMITED;
        }

        if (mode !== MODE_NONE) {
            state.navigationItems = [
                {label: "QIN-EmLap Case Review Database", to: "/", exact: true},
                {label: "Summary Reports", to: "/reports", exact: false}
            ]

            if (mode === MODE_FULL) {
                state.navigationItems.unshift({label: "Create a QIN-EmLap Case Review", to: "/create", exact: false});
                state.navigationItems.push({label: "User Accounts", to: "/users", exact: false});
                // state.navigationItems.push({label: "Locations", to: "/locations", exact: false});
                state.navigationItems.push({ label : "Clinical Standards", to: "/clinical-standards", exact : false});

                if (parseInt(user.userRoleId) === API.userRoles.SYSTEM_ADMIN) {
                    state.navigationItems.push({ label : "Manage Case Record", to : "/organisation-configuration", exact : false });
                    state.navigationItems.push({ label : "Manage Organisations", to : "/manage-organisations", exact : false});
                    state.navigationItems.push({ label : "Manage System Admins", to : "/manage-system-admins", exact : false});
                    state.navigationItems.push({ label : "System Configuration", to : "/system-settings/", exact : false });
                }
            }
        } else {
            state.navigationItems = [];
        }

        this.setState(state);
    }

    render() {
        return(
            <div className={"header-sidenav"}>
                {this.state.navigationItems.map((item) => (
                    <NavLink
                        to={item.to}
                        exact={item.exact}
                        className={"nav-link"}
                        activeClassName={"active"}>
                        {item.label}
                    </NavLink>
                ))}
            </div>
        )
    }

}
