import React from 'react';
import BaseComponent from "../BaseComponent";
import DatePicker from "react-datepicker";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./ReportsMortalityComponent.css";
import BlockUI from "react-block-ui";
import ReportDateFilterComponent from "./ReportDateFilterComponent";

export default class ReportsMortalityComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let state;
        if (this.props.savedState !== undefined) {
            state = this.props.savedState;
            state.reportNetworkInFlight = false;
        } else {
            state = {
                filterType : ReportDateFilterComponent.FILTER_TYPE_MONTHLY,
                filterMonthValue : Chronos.now().format("yyyy-MM"),
                filterStartDate : undefined,
                filterEndDate : undefined,
                reportNetworkInFlight : false,
                tableData : null,
                recordsCount : 0,
                currentPage : 1
            }
        }

        this.initState(state);
    }

    componentDidMount() {
        this.fetchTableDataFromNetwork();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            if (this.props.stateCallback !== undefined) {
                this.props.stateCallback(this.state);
            }
        }
    }

    getFilterDates = () => {
        let startDate = 0;
        let endDate = 0;

        if (this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            startDate = Chronos.parse(this.state.filterMonthValue + "-01");
            endDate = Chronos.with(startDate.getDate()).add(1, Chronos.MONTHS);

            startDate = parseInt(startDate.seconds());
            endDate = parseInt(endDate.seconds());
        } else {
            startDate = parseInt(Chronos.with(this.state.filterStartDate).seconds());
            endDate = parseInt(Chronos.with(this.state.filterEndDate).seconds());
        }

        return {
            startDate,
            endDate
        };
    }

    fetchTableDataFromNetwork = (page, startDate, endDate) => {
        if (this.state.reportNetworkInFlight) return;
        this.setState({
            reportNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        let filterDates = this.getFilterDates();

        let formData = new FormData();
        formData.append("page", page);
        formData.append("startDate", filterDates.startDate);
        formData.append("endDate", filterDates.endDate);

        Axios.post(ENDPOINTS.report.getMortalityReportTableData, formData)
            .then((r) => {
                let tableData = null;
                let recordsCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    recordsCount = resp.data.count;
                } else {
                    // TODO
                    console.log(resp.error);
                }

                this.setState({
                    reportNetworkInFlight : false,
                    tableData,
                    recordsCount
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchMortalityReportFromNetwork = () => {
        if (this.state.reportNetworkInFlight) return;

        this.setState({
            reportNetworkInFlight : true
        });

        let filterDates = this.getFilterDates();

        let formData = new FormData();
        formData.append("startDate", filterDates.startDate);
        formData.append("endDate", filterDates.endDate);

        Axios.post(ENDPOINTS.report.generateMortalityReportExport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let reportUrl = API.appendAuthTokenToUrl(resp.data.url);
                    console.log(reportUrl);
                    window.open(reportUrl);
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    reportNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    reportNetworkInFlight : false
                });
            })
    }

    dateFilterDidChange = (filterType, date1, date2) => {
        let filterMonthValue = null;
        let filterStartDate = 0;
        let filterEndDate = 0;

        if (filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            filterMonthValue = date1;
        } else {
            filterStartDate = date1;
            filterEndDate = date2;
        }

        this.setState({
            filterType,
            filterMonthValue,
            filterStartDate,
            filterEndDate,
            currentPage : 1,
            totalRows : 0,
            bptFilters : [],
            bptTableData : []
        });

        setTimeout(() => {
            this.fetchTableDataFromNetwork();
        }, 10);
    }

    moveToCaseRecord = (row) => {
        window.open("/create/" + row.id);
    }

    render() {
        let exportButton = (<button className={"btn btn-primary"} onClick={this.fetchMortalityReportFromNetwork}>Export PDF</button>);
        if (this.state.reportNetworkInFlight) {
            exportButton = (<button className={"btn btn-primary disabled"}>Exporting, please wait...</button>)
        }

        return (
            <div className={"reports-mortality-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Mortality Reports</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ReportDateFilterComponent
                            filterType={this.state.filterType}
                            filterStartDate={this.state.filterStartDate}
                            filterEndDate={this.state.filterEndDate}
                            callback={this.dateFilterDidChange} />
                    </div>
                </div>

                <div className={"row row-space"}>
                    <div className={"col-12 text-right"}>
                        {exportButton}
                    </div>
                </div>

                <div className={"row table-container"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"common-table alternating clickable"}
                            emptyMessage={"No data to display"}
                            data={this.state.tableData}>

                            <Column name={"dateCreated"} title={"Date of Case Record"}  render={(data, row) => {
                                if (data != null) {
                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                }
                                return "";
                            }}/>
                            <Column name={"dischargeDate"} title={"Date of death"} render={(data, row) => {
                                if (data != null) {
                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                }
                                return "";
                            }}/>
                            <Column name={"passPercent"} title={"Percentage of Passes"} render={(data, row) => {
                                return data + "%";
                            }} />
                            <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                let buttons = [];
                                buttons.push(<span className={"btn btn-primary"} onClick={() => this.moveToCaseRecord(row)}>View</span>);

                                return buttons;
                            }}/>

                        </TableComponent>

                        <div className={"ep-table-paginate-container"}>
                            <div className={"push"}/>
                            <div className={"paginator-area"}>
                                <TablePaginator
                                    totalCount={this.state.recordsCount}
                                    pageSize={10}
                                    page={this.state.currentPage}
                                    onClick={this.onPageChanged} />
                            </div>
                        </div>
                    </div>
                </div>

                <BlockUI />
            </div>
        )
    }
}