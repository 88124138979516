import React from 'react';

import BaseComponent from "../BaseComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import LoadingSpinner from "../common/LoadingSpinner";
import ReportDateFilterComponent from "./ReportDateFilterComponent";

import "./ReportsStatisticsComponent.css";

export default class ReportsStatisticsComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let state;
        if (this.props.savedState !== undefined) {
            state = this.props.savedState;
            state.reportNetworkInFlight = false;
        } else {
            state = {
                filterType : ReportDateFilterComponent.FILTER_TYPE_MONTHLY,
                filterMonthValue : Chronos.now().format("yyyy-MM"),
                filterStartDate : undefined,
                filterEndDate : undefined,
                reportNetworkInFlight : false,
                reportData : null
            };
        }

        this.initState(state);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            if (this.props.stateCallback !== undefined) {
                this.props.stateCallback(this.state);
            }
        }
    }

    moveToFeedbackReport = (segmentId) => {
        let filterDates = this.getFilterDates();

        let data = {
            sid: segmentId,
            sd : filterDates.startDate,
            ed : filterDates.endDate
        };

        this.props.history.push("/reports/open-feedback/" + this.createHashParameters(data));
    }

    dateFilterDidChange = (filterType, date1, date2) => {
        let filterMonthValue = null;
        let filterStartDate = 0;
        let filterEndDate = 0;

        if (filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            filterMonthValue = date1;
        } else {
            filterStartDate = date1;
            filterEndDate = date2;
        }

        this.setState({
            filterType,
            filterMonthValue,
            filterStartDate,
            filterEndDate
        });

        setTimeout(() => {
            this.fetchReportFromNetwork()
        }, 10);
    }

    getFilterDates = () => {
        let startDate = 0;
        let endDate = 0;

        if (this.state.filterType === ReportDateFilterComponent.FILTER_TYPE_MONTHLY) {
            startDate = Chronos.parse(this.state.filterMonthValue + "-01");
            endDate = Chronos.with(startDate.getDate()).add(1, Chronos.MONTHS);

            startDate = parseInt(startDate.seconds());
            endDate = parseInt(endDate.seconds());
        } else {
            startDate = parseInt(Chronos.with(this.state.filterStartDate).seconds());
            endDate = parseInt(Chronos.with(this.state.filterEndDate).seconds());
        }

        return {
            startDate,
            endDate
        };
    }

    fetchReportFromNetwork = () => {
        if (this.state.reportNetworkInFlight) return;

        this.setState({
            reportNetworkInFlight : true
        });

        let formData = new FormData();

        let filterDates = this.getFilterDates();

        formData.append("filterStartDate", filterDates.startDate);
        formData.append("filterEndDate", filterDates.endDate);

        Axios.post(ENDPOINTS.report.generateSummaryReport, formData)
            .then((r) => {
                let reportData = null;

                let resp = API.parse(r);
                if (resp.success) {
                    reportData = resp.data;
                } else {
                    // TODO Error
                }

                this.setState({
                    reportNetworkInFlight : false,
                    reportData
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    reportNetworkInFlight : false
                })
            });
    }

    fetchReportExportFromNetwork = () => {
        if (this.state.exportNetworkInFlight) return;

        this.setState({
            exportNetworkInFlight : true
        });

        let formData = new FormData();

        let filterDates = this.getFilterDates();

        formData.append("filterStartDate", filterDates.startDate);
        formData.append("filterEndDate", filterDates.endDate);
        formData.append("outputType", "pdf");

        Axios.post(ENDPOINTS.report.generateSummaryReport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    window.open(API.appendAuthTokenToUrl(resp.data.url));
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    exportNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    exportNetworkInFlight : false
                });
            })
    }

    render() {
        let reportBody = (
            <div className={"text-center"}><em>Click &quot;Generate Report&quot; to view Report</em></div>
        )

        if (this.state.reportNetworkInFlight) {
            reportBody = (
                <div className={"text-center"}>
                    <LoadingSpinner />
                </div>
            )
        } else if (this.state.reportData != null) {
            reportBody = [];

            let exportLabel = "Export PDF";
            if (this.state.exportNetworkInFlight) {
                exportLabel = "Exporting, please wait...";
            }

            reportBody.push(
                <div className={"row general-space"}>
                    <div className={"col-12 text-right"}>
                        <button className={"btn btn-primary"} onClick={this.fetchReportExportFromNetwork}>{exportLabel}</button>
                    </div>
                </div>
            )

            reportBody.push(
                <div className={"row report-component"}>
                    <div className={"col-12 col-lg-6"}>
                        <div className={"report-title"}>Number of app installs</div>
                        <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "appInstallCount", 0)}</div>
                    </div>

                    <div className={"col-12 col-lg-6"}>
                        <div className={"report-title"}>Number of Notifications sent</div>
                        <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "notificationCount", 0)}</div>
                    </div>
                </div>
            );

            reportBody.push(
                <div className={"row report-component"}>
                    <div className={"col-12"}>
                        <div className={"report-title"}>General Statistics</div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of EmLaps</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "emLapCount", 0)}</div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of clinicians involved</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "clinicianInvolvedCount", 0)}</div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of clinicians that viewed</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "clinicianViewCount", 0)}</div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of clinicians that reflected</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "reflectionTotal", 0)}</div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of clinicians with open reflections</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "reflectionOpen", 0)}</div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"sub-report-component"}>
                            <div className={"report-title"}>Number of clinicians with private feedback</div>
                            <div className={"report-headline"}>{this.getOrDefault(this.state.reportData.generalStatistics, "reflectionPrivate", 0)}</div>
                        </div>
                    </div>
                </div>
            )

            let aggregatedSubs = [];

            let totalFeedback = 0;

            this.state.reportData.segmentStatistics.forEach((segment) => {
                let segmentTotal = parseInt(segment.openCount) + parseInt(segment.privateCount);

                totalFeedback += segmentTotal;

                aggregatedSubs.push(<div className={"col-12 col-md-6 col-lg-4"}>
                    <div className={"sub-report-component"}>
                        <div className={"report-title"}>Feedback in</div>
                        <div className={"report-subtitle"}>{segment.segmentName}</div>
                        <div className={"report-headline"}>{segmentTotal}</div>

                        <div className={"report-block-container"}>
                            <div className={"report-block"}>
                                <div className={"report-title"}>open feedback</div>
                                <div className={"report-headline"}>{segment.openCount}</div>
                            </div>

                            <div className={"report-block"}>
                                <div className={"report-title"}>private reflection</div>
                                <div className={"report-headline"}>{segment.privateCount}</div>
                            </div>
                        </div>

                        <button className={"btn btn-primary report-button"} onClick={() => this.moveToFeedbackReport(segment.id)}>View open feedback submissions</button>
                    </div>
                </div>);
            });

            reportBody.push(
                <div className={"row report-component"}>
                    <div className={"col-12"}>
                        <div className={"report-title"}>Aggregated Feedback</div>
                        <div className={"report-subtitle"}>Total feedback</div>
                        <div className={"report-headline"}>{totalFeedback}</div>
                    </div>

                    {aggregatedSubs}
                </div>
            )
        }

        return (
            <div className={"reports-statistics-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Statistics Reports</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ReportDateFilterComponent
                            filterType={this.state.filterType}
                            filterStartDate={this.state.filterStartDate}
                            filterEndDate={this.state.filterEndDate}
                            callback={this.dateFilterDidChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        {reportBody}
                    </div>
                </div>
            </div>
        )
    }

}