import React, {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {Chronos} from "../../entity/Chronos";
import {useHistory} from "react-router-dom";

export const OrganisationManagerScreen = (props) => {

    const {title} = props;

    const [organisations, setOrganisations] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [count, setCount] = useState(0);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [keyword, setKeyword] = useState("");
    const [ordering, setOrdering] = useState();

    const history = useHistory();

    useEffect(() => {
        fetchOrganisationsFromNetwork();

        if (title) {
            title("Manage Organisations");
        }
    }, []);

    function organisationRowWasClicked(row) {
        if (row) {
            history.push("/manage-organisation/" + row.id);
        }
    }

    function fetchOrganisationsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("page", page);
        formData.append("pageSize", pageSize);

        if (keyword && keyword.trim().length > 0) {
            formData.append("keyword", keyword);
        }

        if (ordering) {
            formData.append("ordering", JSON.stringify(ordering));
        }

        Axios.post(ENDPOINTS.organisation.getOrganisations, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setOrganisations(resp.data.data);
                    setCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ORGL1000C"));
            })
    }

    // RENDER

    return (
        <div className={"organisation-manager app-section"}>

            <div className={"row table-area"}>
                <div className={"col-12"}>
                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"There are no Organisations to display"}
                        data={organisations}
                        onRowClick={organisationRowWasClicked}>

                        <Column name={"name"} title={"Case Record Number"} />

                        <Column name={"dateCreated"} className={"text-center"} title={"Date Record Added"} render={(data, row) => {
                            if (row != null) {
                                return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                            }
                            return "";
                        }} />
                        <Column name={"active"} className={"text-center"} title={"Active"} render={(data, row) => {
                            let badgeClass = " badge-success";
                            let badgeLabel = "Active";

                            if (parseInt(data) === 0) {
                                badgeClass = " badge-danger";
                                badgeLabel = "Inactive";
                            }

                            return (
                                <span className={"badge" + badgeClass}>{badgeLabel}</span>
                            )
                        }} />

                    </TableComponent>

                    <div className={"paginator-container"}>
                        <div className={"spacer"} />
                        <div className={"paginator-area"}>
                            <TablePaginator
                                totalCount={count}
                                pageSize={pageSize}
                                page={page}
                                onClick={(page) => setPage(page)} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}