import React from "react";
import BaseComponent from "../BaseComponent";
import LocationEditorComponent from "./LocationEditorComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./LocationDetailComponent.css";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import UserSelectionDialogComponent from "../users/UserSelectionDialogComponent";
import AlertModal from "../common/AlertModal";

export default class LocationDetailComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            locationFetchNetworkInFlight : false,
            location : null,
            usersFetchNetworkInFlight : false,
            users : [],
            usersCount : 0,
            currentPage : 1,
            filterKeyword : null,
            usersPageSize : 10,
            userSelectionOpen : false,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : []
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.title("Location Details");

        let locationId = undefined;
        if (this.props.match.params !== undefined) {
            if (this.props.match.params.id !== undefined) {
                locationId = parseInt(this.props.match.params.id);
            }
        }

        this.fetchLocationFromNetwork(locationId);
    }

    fetchLocationFromNetwork = (locationId) => {
        if (this.state.locationFetchNetworkInFlight) return;

        if (locationId === undefined) {
            return;
        }

        this.setState({
            locationFetchNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("locationId", locationId);

        Axios.post(ENDPOINTS.location.getLocation, formData)
            .then((r) => {
                let state = {
                    locationFetchNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.location = resp.data.location;
                    state.locationName = resp.data.location.name;

                    this.fetchLocationUsersFromNetwork(resp.data.location);
                } else {
                    console.log(resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchLocationUsersFromNetwork = (location, page, keyword) => {
        console.log("FETCH USERS");
        if (this.state.usersFetchNetworkInFlight) {
            return;
        }

        if (location === undefined) {
            location = this.state.location;
        }

        if (location === undefined || location == null) {
            return;
        }

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.filterKeyword;
        }

        let formData = new FormData();
        formData.append("locationId", location.id);
        formData.append("page", page);
        formData.append("limit", this.state.usersPageSize);

        if (keyword !== undefined && keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.location.getLocationUsers, formData)
            .then((r) => {
                let state = {
                    usersFetchNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.users = resp.data.users;
                    state.usersCount = resp.data.count;
                } else {
                    console.log(resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    usersFetchNetworkInFlight : false
                });
            });
    }

    addUserToLocation = (user) => {
        console.log(user);

        if (user !== undefined) {
            let formData = new FormData();
            formData.append("locationId", this.state.location.id);
            formData.append("userId", user.userId);

            Axios.post(ENDPOINTS.location.addUserToLocation, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.fetchLocationUsersFromNetwork();
                    } else {
                        this.showError(resp.error.desc);
                    }
                })
                .catch((e) => {
                    this.showError("An unknown error has occurred. Please reload the page.");
                });
        }

        this.setState({
            userSelectionOpen : false
        });
    }

    promptToRemoveUser = (user) => {
        this.showModal(
            "Confirmation",
            "Are you sure you want to remove " + user.givenName + " " + user.familyName + " from this location?",
            [
                {
                    label : "Cancel",
                    click : () => this.hideModal()
                },
                {
                    label : "Remove",
                    style : "btn-danger",
                    click : () => {
                        this.hideModal();
                        this.removeUserFromLocation(user);
                    }
                }
            ]
        );
    }

    removeUserFromLocation = (user) => {
        if (user !== undefined) {
            let formData = new FormData();
            formData.append("locationId", this.state.location.id);
            formData.append("userId", user.id);

            Axios.post(ENDPOINTS.location.removeUserFromLocation, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.fetchLocationUsersFromNetwork();
                    } else {
                        this.showError(resp.error.desc);
                    }
                })
                .catch((e) => {
                    this.showError("An unknown error has occurred. Please reload the page.");
                });
        }
    }

    moveToUserDetail = (user) => {
        this.props.history.push("/users/view/" + user.id);
    }

    showError = (message) => {
        this.showModal("Error", message, [
            {
                label : "OK",
                click : () => {
                    this.hideModal();
                }
            }
        ]);
    }

    showModal = (title, message, buttons) => {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        if (this.state.location != null) {
            return (
                <div className={"location-detail-screen"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <LocationEditorComponent
                                mode={"EDIT"}
                                location={this.state.location}
                                callback={() => this.fetchLocationUsersFromNetwork()}
                                navigateCallback={(id) => this.moveToLocationDetail({id: id})}
                                errorCallback={(message) => this.showError(message)}/>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"row"}>
                            <div className={"col-8"}>
                                <div className={"screen-title"}>Location Users</div>
                            </div>
                            <div className={"col-4 text-right"}>
                                <button className={"btn btn-primary"} onClick={() => this.setState({ userSelectionOpen : true })}>Add User</button>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <TableComponent
                                    className={"common-table alternating clickable"}
                                    emptyMessage={"There are no users to display"}
                                    data={this.state.users}>

                                    <Column name={"givenName"} title={"Name"} render={(data, row) => {
                                        return row.givenName + " " + row.familyName;
                                    }} />
                                    <Column name={"roleName"} title={"Role Name"} />
                                    <Column name={"id"} title={"Actions"} className={"text-center"} render={ (data, row) => {
                                        return (
                                            <span>
                                                <button className={"btn btn-primary"} onClick={() => this.moveToUserDetail(row)}>View</button>
                                                &nbsp;
                                                <button className={"btn btn-danger"} onClick={() => this.promptToRemoveUser(row)}>Remove</button>
                                            </span>);
                                    } } />
                                </TableComponent>

                                <div className={"paginator-container"}>
                                    <div className={"spacer"} />
                                    <div className={"paginator-area"}>
                                        <TablePaginator
                                            totalCount={this.state.locationCount}
                                            pageSize={this.state.locationPageSize}
                                            page={this.state.currentPage}
                                            onClick={ (page) => this.tablePageDidChange(page) } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <AlertModal
                        open={this.state.modalOpen}
                        title={this.state.modalTitle}
                        content={this.state.modalContent}
                        buttons={this.state.modalButtons}
                        dismissHandler={this.hideModal} />

                    <UserSelectionDialogComponent
                        open={this.state.userSelectionOpen}
                        callback={this.addUserToLocation} />
                </div>
            );
        } else {
            // TODO Loading
            return [];
        }
    }

}