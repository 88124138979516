import React from "react";
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";

import "./CaseRecordSelectionDialogComponent.css";
import {Chronos} from "../../entity/Chronos";

export default class CaseRecordSelectionDialogComponent extends BaseComponent {

    keywordTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        let keyword = "";
        if (this.props.keyword !== undefined) {
            keyword = this.props.keyword;
        }

        this.initState({
            recordSearchNetworkInFlight : false,
            currentPage : 1,
            keyword : keyword,
            records : [],
            recordsCount : 0
        });
    }

    componentWillUnmount() {
        clearTimeout(this.keywordTimeout);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!this.props.open && nextProps.open) {
            let keyword = "";
            if (nextProps.keyword !== undefined) {
                keyword = nextProps.keyword;

                this.setState({
                    keyword: keyword
                });
            }
            console.log("SHOW USER SEL. KEYWORD: " + keyword);

            // If the modal is about to open, fetch users from network
            this.fetchCaseRecordsFromNetwork(undefined, keyword);
        }
    }

    fetchCaseRecordsFromNetwork = (page, keyword) => {
        if (this.state.recordSearchNetworkInFlight) return;

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.keyword;
        }

        this.setState({
            recordSearchNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        formData.append("keyword", keyword);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecords, formData)
            .then((r) => {
                let state = {
                    recordSearchNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.records = resp.data.caseRecords;
                    state.userCount = resp.data.count;
                } else {
                    // TODO Show Error
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);

                // TODO
            });
    }

    onPageChanged = (index) => {
        this.fetchCaseRecordsFromNetwork(index);

        this.setState({
            currentPage : index
        });
    }

    onRecordSelected = (user) => {
        if (this.props.callback !== undefined) {
            this.props.callback(user);
        }
    }

    handleKeywordChange = (event) => {
        this.handleChange(event);

        this.setState({
            currentPage : 1
        });

        clearTimeout(this.keywordTimeout);
        this.keywordTimeout = setTimeout(() => {
            this.fetchCaseRecordsFromNetwork();
        }, 800);
    }

    render() {
        let modalOpen = false;
        if (this.props.open !== undefined) {
            modalOpen = this.props.open;
        }

        if (modalOpen) {
            return (
                <div className="records-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.title}</h5>
                            </div>
                            <div className="modal-body">
                                <div className={"row record-filter"}>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} name={"keyword"} value={this.state.keyword} placeholder={"Search for Case Records"} onChange={this.handleKeywordChange}/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <TableComponent
                                            className={"common-table alternating clickable"}
                                            emptyMessage={"No Case Records match your criteria"}
                                            data={this.state.records}>

                                            <Column name={"recordId"} title={"Record ID"}/>
                                            <Column name={"givenName"} title={"Patient Name"} render={(data, row) => {
                                                return row.givenName + " " + row.familyName;
                                            }}/>
                                            <Column name={"recordDate"} title={"Record Date"} render={(data, row) => {
                                                if (data != null) {
                                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                                }
                                                return "";
                                            }}/>
                                            <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                                let buttons = [];
                                                buttons.push(<span className={"btn btn-primary"} onClick={() => this.onRecordSelected(row)}>Add</span>);

                                                return buttons;
                                            }}/>

                                        </TableComponent>

                                        <div className={"ep-table-paginate-container"}>
                                            <div className={"push"}/>
                                            <div className={"paginator-area"}>
                                                <TablePaginator
                                                    totalCount={this.state.recordsCount}
                                                    pageSize={10}
                                                    page={this.state.currentPage}
                                                    onClick={this.onPageChanged} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button className={"btn btn-primary"} onClick={() => this.onRecordSelected(undefined)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return [];
    }


}