import {ColourPickerInput, ColourPickerInputActions} from "./ColourPickerInput";
import React, {useEffect, useState} from "react";
import {BaseModal, BaseModalActions} from "../alertmodal/BaseModal";
import {SketchPicker} from "react-color";

import "./ColourPickerModal.css";

export const ColourPickerModal = (props) => {

    const {shown} = props;
    const {colour} = props;
    const {callback} = props;

    const [value, setValue] = useState("");
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            setValue(colour);
        } else {
            setForceDismiss(false);
            setValue("");
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitForm() {
        handleCallback(BaseModalActions.CLOSE, value);
        setForceDismiss(true);
    }

    // RENDER

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            className={"colour-picker-modal"}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 d-flex justify-content-center text-center"}>

                    <SketchPicker
                        disableAlpha={true}
                        color={value}
                        onChangeComplete={(colour) => {
                            setValue(colour.hex);
                        }}
                    />

                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"colour-block"} style={{backgroundColor : value }} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-success mr-2"} onClick={submitForm}>Select</button>
                    <button className={"btn btn-danger"} onClick={() => setForceDismiss(true)}>Cancel</button>
                </div>
            </div>

        </BaseModal>
    )

}