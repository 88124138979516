import React, {useEffect, useState} from "react";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import {Navigator} from "../../../util/Navigator";

export const UserApprovalsScreen = (props) => {

    const MODE_PENDING = 1;
    const MODE_APPROVED = 2;
    const MODE_REJECTED = 3;

    const [approvals, setApprovals] = useState([]);
    const [count, setCount] = useState(0);
    const [approvalNetworkInFlight, setApprovalNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [mode, setMode] = useState(MODE_PENDING);

    useEffect(() => {
        if (page > 1) {
            setPage(1);
        } else {
            fetchUserApprovalsFromNetwork();
        }
    }, [mode]);

    useEffect(() => {
        fetchUserApprovalsFromNetwork();
    }, [page]);

    function tableDidCallback(row) {
        Navigator.navigate("/user-approval/" + row.id);
    }

    function pageDidChange(page) {
        setPage(page);
    }

    function fetchUserApprovalsFromNetwork() {
        if (approvalNetworkInFlight) return;
        setApprovalNetworkInFlight(true);

        let approved = false;
        let rejected = false;
        if (mode === MODE_APPROVED) {
            approved = true;
        } else if (mode === MODE_REJECTED) {
            rejected = true;
        }

        const formData = new FormData();
        formData.append("approved", approved ? "1" : "0");
        formData.append("rejected", rejected ? "1" : "0");
        formData.append("page", page);
        formData.append("pageSize", pageSize);

        Axios.post(ENDPOINTS.approval.getUserApprovals, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setApprovals(resp.data.data);
                    setCount(resp.data.count);
                } else {
                    AlertModal.show(API.formatError(resp));
                }
                setApprovalNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setApprovalNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UAS1000C"));
            });
    }

    // RENDER

    let paginator = [];
    if (count > pageSize) {
        paginator = (
            <div className={"ep-table-paginate-container mt-4"}>
                <div className={"push"}/>
                <div className={"paginator-area"}>
                    <TablePaginator
                        totalCount={count}
                        pageSize={pageSize}
                        page={page}
                        onClick={pageDidChange} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={"row mt-4"}>
                <div className={"col-12 col-md-6 col-lg-4"}>
                    <select className={"form-control"} value={mode} onChange={(e) => setMode(parseInt(e.target.value))}>
                        <option value={MODE_PENDING}>Pending Approvals</option>
                        <option value={MODE_APPROVED}>Approved Approvals</option>
                        <option value={MODE_REJECTED}>Rejected Approvals</option>
                    </select>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"No approvals to display"}
                        data={approvals}
                        onRowClick={tableDidCallback}>

                        <Column name={"emailAddress"} title={"Email Address"} />
                        <Column name={"givenName"} title={"Forename"} />
                        <Column name={"familyName"} title={"Surname"} />

                        <Column name={"id"} className={"text-center"} title={"Actions"} render={(data, row) => {
                            let buttons = [];
                            buttons.push(<span className={"btn btn-primary"}>View</span>);

                            return buttons;
                        }} />
                    </TableComponent>

                    {paginator}
                </div>
            </div>
        </>
    )

}